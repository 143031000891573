import { ReactNode, useEffect } from 'react';

import { isRejected } from '@reduxjs/toolkit';
import { StatusTip } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { showConfirmDialog } from './components/universal/ConfirmDialogTips/ConfirmDialogTips';
import { ERROR_CODES } from './constants/manage';
import { RootState, useAppDispatch } from './store';
import { loginInstance } from './store/slices/app.thunk';
import sessionManage from './utils/sessionManage';

export const InstanceLogin = (props: {
  sdkAppId: string;
  userId: string;
  isSaasDemoPage: boolean;
  children?: ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const sdkAppId = useSelector((state: RootState) => state.userInfo.sdkAppId);
  useEffect(() => {
    if (props.isSaasDemoPage) {
      return;
    }
    if (!sdkAppId || sdkAppId !== props.sdkAppId) {
      dispatch(
        loginInstance({
          sdkAppId: props.sdkAppId,
          userId: props.userId,
        }),
      ).then((res) => {
        if (isRejected(res)) {
          if (res.error.code === ERROR_CODES.LACK_OF_SEAT) {
            showConfirmDialog({
              content: (
                <span>
                  {t('登录失败，座席已全部过期，')}
                  <a target="_blank" href="https://buy.cloud.tencent.com/ccc_seat" rel="noreferrer">
                    {t('请前往控制台续费或重新购买座席')}
                  </a>
                </span>
              ),
              cancelText: '',
              onEnter() {
                sessionManage.loginOut();
              },
            });
            return;
          }
          showConfirmDialog({
            content: res.error.message || t('登录失败，请重试'),
            cancelText: '',
            onEnter() {
              sessionManage.loginOut();
            },
          });
        }
      });
    }
  }, [dispatch, props.sdkAppId, sdkAppId, props.userId, props.isSaasDemoPage]);
  if (props.isSaasDemoPage) return props.children;
  if (!sdkAppId || sdkAppId !== props.sdkAppId)
    return (
      <StatusTip
        status="loading"
        style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
      />
    );
  return props.children;
};
