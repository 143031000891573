import { useCallback, useState, useRef, useMemo } from 'react';

import { isRejected } from '@reduxjs/toolkit';
import { Button } from '@tencent/tea-component';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { ImageViewer } from 'src/components/ImageViewer/ImageViewer';
import { selectBySessionId } from 'src/store';
import { updateImageViewer } from 'src/store/actions/im';
import { followUpIMSession } from 'src/store/slices/sessions/sessions.thunk';
import { showError } from 'src/utils';

import { Loader } from '../../im-list/Loader';
import { getComponent, getActiveImageIndex, getImagesFromMessageList } from '../../utils/messageHandle';

import useStyles from './MessageList.style';

function MessageList({ list, onBeforeMessage, isTop, onAfterMessage, customerInfo, showFollowUpBtn = false }) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const imageViewer = useSelector((state) => state.tim.imageViewer);
  const seatInfo = useSelector((state) => state.userInfo);
  const currentCallInfo = useSelector((state) => selectBySessionId(state, seatInfo.currentUser.sessionId));
  const callInfo = customerInfo || currentCallInfo;
  const [loadingMore, setLoadingMore] = useState(false);
  const [triggerShowMoreWhenReachTop, setTriggerShowMoreWhenReachTop] = useState(false);
  const [callOutDisabled, setCallOutDisabled] = useState(false);
  const boxRef = useRef();

  const getMoreMessage = useCallback(
    debounce(
      (onBeforeMessage) => {
        if (isTop || loadingMore) {
          return;
        }
        setLoadingMore(true);
        const preBoxHeight = boxRef.current?.scrollHeight || 0;
        onBeforeMessage?.()?.then(() => {
          if (boxRef.current && Math.abs(boxRef.current.scrollHeight - preBoxHeight) > 2) {
            boxRef.current.scrollTop = boxRef.current.scrollHeight - preBoxHeight;
          }
          setLoadingMore(false);
        });
      },
      1000,
      { leading: true, trailing: false },
    ),
    [isTop, loadingMore, boxRef.current],
  );

  const handleFollowUpSession = async (item) => {
    setCallOutDisabled(true);
    const currentURL = location.pathname;
    if (currentURL.includes('record')) {
      history.push(`/agent/${seatInfo.sdkAppId}`);
    }
    const res = await dispatch(
      followUpIMSession({ channelAgentID: item.channelAgentID, ClientUserID: item.userId, isFromRecord: true }),
    );
    if (isRejected(res)) {
      setCallOutDisabled(false);
      showError(t('发起会话失败：{{message}}。', { message: res.error.message }), true);
    }
  };

  const onYReachEnd = useCallback(async () => {
    onAfterMessage && (await onAfterMessage());
  }, [onAfterMessage]);

  const MemoImageViewer = useMemo(() => {
    const imagesFromMessage = getImagesFromMessageList(list);

    return (
      <ImageViewer
        activeIndex={getActiveImageIndex(imagesFromMessage, imageViewer.currentImageId)}
        visible={imageViewer.visible}
        images={imagesFromMessage}
        onMaskClick={() => dispatch(updateImageViewer({ visible: false }))}
        onClose={() => dispatch(updateImageViewer({ visible: false }))}
      />
    );
  }, [list, imageViewer.currentImageId, imageViewer.visible, dispatch]);
  return (
    <div id="messageBox" className={classes.historyMessage}>
      <PerfectScrollbar
        className={classes.historyMessageInner}
        onYReachEnd={onYReachEnd}
        onScrollUp={() => setTriggerShowMoreWhenReachTop(true)}
        onYReachStart={() => {
          if (triggerShowMoreWhenReachTop) {
            getMoreMessage(onBeforeMessage);
            setTriggerShowMoreWhenReachTop(false);
          }
        }}
        containerRef={(ref) => {
          boxRef.current = ref;
        }}
      >
        {!isTop && (
          <div onClick={() => getMoreMessage(onBeforeMessage)} className={classes.showMore}>
            {loadingMore ? <Loader /> : t('查看该用户更早的消息')}
          </div>
        )}
        {list?.reduce((pre, item, index) => {
          const component = getComponent({
            data: item,
            classes,
            callInfo,
            index,
            list,
            userInfo: seatInfo,
          });
          if (component) {
            pre.push(component);
          }
          return pre;
        }, [])}
        {showFollowUpBtn && (
          <Button
            disabled={callOutDisabled}
            onClick={() => {
              handleFollowUpSession(callInfo);
            }}
            style={{
              position: 'absolute',
              right: 0,
              left: 0,
              margin: 'auto',
              width: '200px',
              height: '40px',
              borderRadius: '20px',
              fontSize: '16px',
              fontWeight: '500',
            }}
            type="primary"
          >
            {t('发起会话')}
          </Button>
        )}
      </PerfectScrollbar>
      {MemoImageViewer}
    </div>
  );
}
MessageList.defaultProps = {
  isTop: false,
};

MessageList.propTypes = {
  list: PropTypes.array.isRequired,
  onBeforeMessage: PropTypes.func,
  onAfterMessage: PropTypes.func,
  isTop: PropTypes.bool,
};

export default MessageList;
