import * as t from 'io-ts';

import { numberFromString } from 'src/utils/codecs';

// /ccc/im/startCallOut 以后可能会变更为工作台主动把会话插入会话列表，先保存此类型
export const SessionItem = t.intersection([
  t.partial({
    userId: t.string,
    roomId: t.string,
    userSig: t.string,
    privateMapKey: t.string,
    peerSource: t.union([
      t.literal('0'),
      t.literal('1'),
      t.literal('2'),
      t.literal('3'),
      t.literal('4'),
      t.literal('5'),
      t.literal('6'),
      t.literal('7'),
      t.literal('8'),
      t.literal('10'),
    ]),
    user_type: t.number,
    timestamp: t.number,
    direction: numberFromString,
    wxNick: t.string,
    wxSession: t.string,
    phone: t.string,
    phoneEncodeType: t.string,
    userPhoneLocation: t.string,
    servingNumber: t.string,
    transferFrom: t.string,
    transferFromType: t.string,
    remark: t.string,
    callerLocation: t.string,
    duration: t.number,
    key_press: t.string,
    keyPress: t.string,
    clientData: t.string,
    clientChannelName: t.string,
    pre: t.string,
    avatar: t.string,
    ringTimestamp: t.string,
    acceptTimestamp: t.string,
    onLineState: t.boolean,
    ivrPressLabel: t.union([t.null, t.array(t.string)]),
    protectedPhone: t.string,
    inner: t.boolean,
    imGroupId: t.string,
    currentMedia: t.union([t.undefined, t.literal('video'), t.literal('audio')]),
    autoEnter: t.union([t.undefined, t.literal('video'), t.literal('audio')]),
    hangupSide: t.union([t.literal('user'), t.literal('seat'), t.literal('system')]),
    hangupType: t.union([
      t.literal('giveup'),
      t.literal(0),
      t.literal(1),
      t.literal(2),
      t.literal(104),
      t.literal(202),
      t.literal(203),
      t.literal(204),
      t.literal(205),
      t.literal(206),
      t.literal(207),
      t.literal(208),
      t.literal(209),
      t.literal(210),
      t.literal(211),
    ]),
    state: t.union([
      t.literal('finished'),
      t.literal('inProgress'),
      t.literal('InProgress'),
      t.literal('ringing'),
      t.literal('seatJoining'),
      t.literal(''),
    ]),
    serverType: t.union([
      t.literal('staffSeat'),
      t.literal('staffPhoneSeat'),
      t.literal('miniProgramSeat'),
      t.literal('staffExtensionSeat'),
    ]),
    isStartRealtimeAsr: t.boolean,
    sequence: t.number,
    sessionEndState: t.type({
      hangupCode: t.number,
      hangupSide: t.string,
    }),
    sessionType: t.string,
    isHost: t.boolean,
  }),
  t.type({
    sessionId: t.string,
    status: t.union([t.literal('100'), t.literal('150'), t.literal('200'), t.literal('400')]),
    type: t.union([
      t.literal('phone'),
      t.literal('monitor'),
      t.literal('dialBack'),
      t.literal('internal'),
      t.literal('im'),
      t.literal('voip'),
      t.literal('video'),
      t.literal('audio'),
    ]),
    state: t.union([t.literal('ringing'), t.literal('finished'), t.literal('inProgress'), t.literal('seatJoining')]),
    direction: t.string,
  }),
]);
export const cccAPIs = {
  '/ccc/black/addBlackUser': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/remark/setRemark': {
    Input: t.type({
      sessionId: t.string,
      remark: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/im/sendAddWeChatNotify': {
    Input: t.type({
      sessionId: t.string,
      wxWorkUserId: t.string,
    }),
    Output: t.type({
      errorCode: t.string,
      msg: t.string,
    }),
  },
  '/ccc/sendsms': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  // im座席对用户发起会话
  '/ccc/im/startCallOut': {
    Input: t.type({
      channelAgentID: t.string,
      ClientUserID: t.string,
      isFromRecord: t.boolean,
    }),
    Output: t.type({
      errorCode: t.string,
    }),
  },
};
