import { RobotTextElementWrapper, RobotText } from './robotElement.style';

export function RobotTextElement(props) {
  const { data } = props;
  return (
    <RobotTextElementWrapper>
      <RobotText dangerouslySetInnerHTML={{ __html: data.content }} />
    </RobotTextElementWrapper>
  );
}
