export class RequestError extends Error {
  code?: string | number;
  url?: string;
  msg?: string;
  constructor(message?: string, options: { code?: string | number; url?: string } = {}) {
    super(message);
    this.message = message || '';
    this.code = options.code;
    this.url = options.url;
    this.msg = message || '';
  }

  toString() {
    if (this.code && Number(this.code) !== 0) {
      return `${this.message}[${this.code}${this.url}]`;
    }
    return this.message;
  }
}
