import { useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import style from './VideoElement.style';

const useStyles = makeStyles(style);

export default function VideoElement(props) {
  const classes = useStyles();
  const ref = useRef(null);
  const { payload, _elements: elements } = props.data;
  if (!payload) return null;
  const videoSrcFromElements = elements?.find((i) => i.type === 'TIMVideoFileElem')?.MsgContent?.VideoUrl;
  const videoSrc = payload.videoUrl || videoSrcFromElements;
  const playVideo = () => {
    ref.current.play();
  };
  return (
    <div className={classes.root} onClick={playVideo}>
      <video controls src={videoSrc} ref={ref} className={classes.video} />
    </div>
  );
}

VideoElement.propTypes = {
  data: PropTypes.object.isRequired,
};
