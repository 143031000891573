import { styled, SvgIcon } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 24,
  height: 24,
  '& path': {
    stroke: '#FFF',
    fill: (props) => (props.active ? '#FFF' : ''),
  },
  '& g': {
    opacity: (props) => (props.active ? '1' : '.7'),
  },
  // '&:hover': {
  //   '& path': {
  //     fill: '#FFF',
  //   },
  //   '& g': {
  //     opacity: 1,
  //   },
  // },
});
export function CallOutSvg(props) {
  return (
    <Svg viewBox="0 0 24 24" {...props} active={props.active ? 1 : 0}>
      <g fill="none" fillRule="evenodd" opacity=".7">
        <path
          d="M19.209 20.51c-1.304 1.32-6.178.05-10.984-4.734-4.822-4.801-6.058-9.671-4.72-10.974C4.639 3.67 6.029 1.68 8.089 4.27c2.06 2.59 1.064 3.292-.137 4.459-.824.806.91 2.863 2.677 4.647 1.802 1.766 3.844 3.498 4.65 2.675 1.185-1.183 1.906-2.178 4.446-.137 2.591 2.092.618 3.498-.515 4.595z"
          stroke="#FFF"
        />
      </g>
    </Svg>
  );
}
