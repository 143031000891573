import { createTheme } from '@material-ui/core';

import { primaryColor } from '../assets/jss/material-dashboard-react';

export default createTheme({
  palette: {
    primary: {
      main: primaryColor[0],
    },
    typography: {
      fontFamily: '"PingFang SC", "Microsoft YaHei", "Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
});
