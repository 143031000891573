export function setSession(mobile, sessionId, time = 60) {
  localStorage.setItem(
    `${mobile}sessionId`,
    JSON.stringify({
      sessionId,
      createTime: Date.now(),
      timeout: time,
    }),
  );
}

export function getSession(mobile) {
  const sessionValue = localStorage.getItem(`${mobile}sessionId`);
  if (!sessionValue) return '';
  const data = JSON.parse(sessionValue);
  if (Date.now() - data.createTime > data.timeout * 1000) {
    return '';
  }
  return data.sessionId;
}
