import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { SvgIcon, styled, SvgIconProps, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { RootState } from 'src/store';
import tccc from 'src/utils/tccc';

const Svg = styled(SvgIcon)({
  width: 16,
  height: 16,
  transition: 'all 0.3s',
});
interface MicStatusProps {
  darkMode?: boolean;
  side: 'local' | 'remote';
  sessionId: string;
  disabled?: boolean;
  memberId?: string;
}

// TODO: 设备插拔、切换
export const MicStatus: React.FC<SvgIconProps & MicStatusProps> = ({
  darkMode,
  side,
  sessionId,
  memberId,
  disabled,
  ...svgProps
}) => {
  const { t } = useTranslation();
  const { userId } = useSelector((state: RootState) => state.userInfo);
  const [volume, setVolume] = useState(0);
  const uuid = useMemo(() => `uuid-${Math.random() * 100}`, []);

  const lineColor = darkMode ? '#FFF' : '#4D4D4D';

  const handleLocalAudioVolume = useCallback(
    (event: { audioVolume: number; sessionId: string; userId: string }[]) => {
      if (side === 'local') {
        const audioVolume =
          event.find((item) => item.userId === userId && item.sessionId === sessionId)?.audioVolume || 0;
        const volume = audioVolume * 5 > 100 ? 100 : audioVolume * 5;
        setVolume(volume / 100);
      } else {
        const audioVolume =
          event.find((item) => item.userId !== userId && item.sessionId === sessionId)?.audioVolume || 0;
        const volume = audioVolume * 5 > 100 ? 100 : audioVolume * 5;
        setVolume(volume / 100);
      }
    },
    [sessionId, side, userId],
  );
  useEffect(() => {
    if (!memberId) {
      tccc.on('audioVolume', handleLocalAudioVolume);
    }
    return () => {
      tccc.off('audioVolume', handleLocalAudioVolume);
    };
  }, [handleLocalAudioVolume, memberId]);

  return (
    <Tooltip title={t('音量指示器')}>
      <>
        <Svg viewBox="0 0 16 16" {...svgProps}>
          <linearGradient id={uuid} spreadMethod="pad" gradientTransform="rotate(90)">
            <stop offset="0" stopColor="rgba(0,0,0,0)" />
            <stop offset={1 - volume} stopColor="rgba(0,0,0,0)" />
            <stop offset={`${0.999 - volume}`} stopColor="#0ABF5B" />
          </linearGradient>
          <g fill="none" fillRule="evenodd">
            <path
              d="M8 2.64a3.5 3.5 0 0 1 3.5 3.5V8.5a3.5 3.5 0 0 1-7 0V6.14A3.5 3.5 0 0 1 8 2.64z"
              fill={`url(#${uuid})`}
            />
            <path fill={lineColor} d="M7 13h2v3H7z" />
            <path
              d="M8 2a2.99 2.99 0 0 1 2.121.879A2.99 2.99 0 0 1 11 5v3a2.99 2.99 0 0 1-.879 2.121A2.99 2.99 0 0 1 8 11a2.99 2.99 0 0 1-2.121-.879A2.99 2.99 0 0 1 5 8V5c0-.828.336-1.578.879-2.121A2.99 2.99 0 0 1 8 2z"
              stroke={lineColor}
              strokeWidth="2"
            />
            <path
              d="M8 15a7.002 7.002 0 0 1-6.93-6H3.1a5.002 5.002 0 0 0 9.8 0h2.03A7.002 7.002 0 0 1 8 15z"
              fill={lineColor}
              fillRule="nonzero"
            />
            {disabled && (
              <rect x="1" y="1.70703" width="2" height="18" rx="1" transform="rotate(-45 1 1.70703)" fill="#EE444A" />
            )}
          </g>
        </Svg>
      </>
    </Tooltip>
  );
};
