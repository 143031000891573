import { ReactNode, CSSProperties } from 'react';

import { makeStyles, CircularProgress } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { List } from '@tencent/tea-component';

import { useTranslation } from 'react-i18next';

interface Option {
  email: any;
  name: ReactNode;
  status: keyof typeof StatusColor;
}
const StatusColor = {
  '-1': '#999',
  0: '#999',
  100: '#3FCFB8',
  150: '#FFBE2D',
  200: '#FFBE2D',
  300: '#006EFF',
  400: '#006EFF',
  500: '#006EFF',
  700: '#FC4F05',
  900: '#999',
  undefined: '#999',
};

interface SeatListProps {
  options: Option[];
  button?: boolean;
  loading?: boolean;
  value: string;
  onSelected?: (value: string, index: number) => void;
  onRefresh?: () => void;
  isLoadMore: boolean;
  onLoadMore: () => void;
  emptyTips: ReactNode;
}

const useStyles = makeStyles({
  seatListContainer: {
    width: '100%',
    maxWidth: '381px',
    border: '1px solid rgba(0,0,0,0.15)',
    borderRadius: '5px',
  },
  title: {
    padding: '5px 0px 5px 16px',
    display: 'flex',
    borderBottom: '1px solid rgba(0,0,0,0.15)',
    justifyContent: 'space-around',
    color: 'white',
    opacity: 0.6,
    fontSize: 14,
    lineHeight: 1.75,
    alignItems: 'center',
  },
  name: {
    display: 'inline-block',
    width: '30%',
  },
  email: {
    display: 'inline-block',
    width: '50%',
  },
  status: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '20%',
  },
  listRoot: {
    width: '100%',
    padding: '0px',
    marginTop: '0px',
    maxWidth: '381px',
    maxHeight: 310,
    minHeight: 50,
    overflowY: 'auto',
    background: '#282828',
    listStyle: 'none',
    cursor: 'pointer',

    '&:hover': {
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.3)',
        backgroundColor: '#d1d1d1',
      },
    },
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&:-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      background: 'rgba(0,0,0,0.1)',
      boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.3)',
    },
    '&::-webkit-scrollbar-thumb:window-inactive': {
      background: 'rgba(255,0,0,0.4)',
    },
  },
  listItem: {
    color: 'white',
    opacity: 0.6,
    fontSize: 14,
    padding: '8px 16px',
    marginBottom: '0px !important',
  },
  activeListItem: {
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  item: {
    width: '100%',
    lineHeight: 1,
    display: 'flex',
  },
  itemLabel: {
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    width: '30%',
  },
  itemValue: {
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    width: '50%',
  },
  refresh: {
    color: '#d1d1d1',
    fontWeight: 800,
    fontSize: 'medium',
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
    },
  },
});
const seatStatusStyle = {
  width: '20%',
  display: 'inline-block',
  textAlign: 'left',
  fontWeight: 800,
  paddingLeft: 32,
  boxSizing: 'border-box',
};

export const SeatList: React.FC<SeatListProps> = (props) => {
  const { loading, options, value: currentValue, onSelected, onRefresh, isLoadMore, onLoadMore, emptyTips } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const StatusText = {
    '-1': t('离线'),
    0: t('离线'),
    100: t('示闲'),
    150: t('忙碌'),
    200: t('忙碌'),
    300: t('小休'),
    400: t('话后倒计时'),
    500: t('话后调整'),
    700: t('示忙'),
    900: t('离线'),
    undefined: t('未知状态'),
  };
  return (
    <div className={classes.seatListContainer}>
      <div className={classes.title}>
        <span className={classes.name}>{t('座席姓名')}</span>
        <span className={classes.email}>{t('座席邮箱')}</span>
        <span className={classes.status}>
          {t('座席状态')}
          <Refresh className={classes.refresh} onClick={() => onRefresh?.()} />
        </span>
      </div>
      <List
        className={classes.listRoot}
        onScrollBottom={() => {
          if (isLoadMore) {
            onLoadMore();
          }
        }}
      >
        {loading && <CircularProgress size={20} />}
        {options?.length === 0 && (
          <List.Item selected className={`${classes.listItem} ${classes.activeListItem}`}>
            {emptyTips}
          </List.Item>
        )}
        {!loading &&
          options?.map(({ email, name, status }, index) => (
            <List.Item
              selected={email === currentValue}
              className={`${classes.listItem} ${email === currentValue ? classes.activeListItem : ''} `}
              onClick={() => onSelected?.(email, index)}
            >
              <div className={classes.item}>
                <span className={classes.itemLabel}>{name}</span>
                <span className={classes.itemValue}>{email}</span>
                <span style={{ color: StatusColor[status], ...(seatStatusStyle as CSSProperties) }}>
                  {StatusText[status]}
                </span>
              </div>
            </List.Item>
          ))}
      </List>
    </div>
  );
};
