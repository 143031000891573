const getStyles = (theme) => ({
  root: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContentRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    width: 30,
    height: 30,
  },
  icon: {
    width: 30,
    height: 30,
  },
  paper: {
    width: 460,
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
  },
  actionsButton: {
    marginRight: 5,
    marginLeft: 5,
  },
  baseButton: {
    borderRadius: '0',
    boxShadow: 'none',
  },
  cancelButton: {
    border: '1px solid #D5D5D5',
  },
  titleIcon: {
    verticalAlign: 'middle',
    marginRight: 5,
  },
});

export default getStyles;
