import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    padding: '9px 12px',
    margin: 0,
    fontSize: 12,
    fontFamily: theme.base.fontFamily.Regular,
    color: '#333',
    lineHeight: '18px',
    background: 'rgb(223, 225, 229)',
    borderRadius: 4,
  },
  title: {
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
  },
  img: {
    maxHeight: 200,
  },
}));
