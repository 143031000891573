import { useCallback, useEffect, useMemo, useState } from 'react';

import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { isInternational } from 'tccc-utils';

import FullscreenLoading from 'src/components/FullscreenLoading/FullscreenLoading';
import TipPage from 'src/components/TipPage/TipPage';
import config from 'src/config';
import services, { debugLogRequest } from 'src/services';
import store from 'src/store';
import { loginInstance } from 'src/store/slices/app.thunk';
import {
  checkIsBrowserSupported,
  checkIsDeskAuthing,
  checkIsSDKAuthing,
  getDemoSDKAppId,
  getUserAgent,
  isDebugMode,
} from 'src/utils';
import { handleLoginError } from 'src/utils/loginHelper';
import ServiceRecords from 'src/views/Agent/ServiceRecords';

import { InstanceLogin } from './InstanceLogin';
import Agent from './layouts/Agent/Agent';
import { offline } from './store/actions/agent.thunk';
import { updateUserAgent } from './store/slices/userAgent';
import { checkLogin } from './store/slices/userInfo';
import sessionManage from './utils/sessionManage';
import { getUrlSearchParam } from './utils/urls';
import Login from './views/Login';
import Online from './views/Online';
import PerfectInfo from './views/PrefectInfo/PerfectInfo';
// import RecoverPassword from './views/RecoverPassword/RecoverPassword';
import { ResetPassword } from './views/ResetPassword/ResetPassword';

/**
 * @return {null}
 */
export default function RouterBeforeEnter() {
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const LoginComponent = useSelector((s) => s.appSettings.featureFlag.loginComponent) || Login;
  const loginChallenge = getUrlSearchParam('login_challenge');
  const isFromDesk = getUrlSearchParam('source') === 'desk_console';
  const [isOAuthing, setIsOAuthing] = useState(!!loginChallenge && sessionManage.getGlobalSdkAppId());
  const { t } = useTranslation();
  const isDeskAuthing = useMemo(() => checkIsDeskAuthing(), []);
  const isSDKAuthing = useMemo(() => checkIsSDKAuthing(), []);

  const loginOAuth = useCallback(
    (loginChallenge, sdkAppId, userId) => {
      services['/tccclogin/oauth/login']({ loginChallenge, sdkAppId, userId })
        .then((response) => {
          window.location.href = response.url;
        })
        .catch(() => {
          setIsOAuthing(false);
        });
    },
    [setIsOAuthing],
  );
  const isDebug = isDebugMode();
  const isSupported = checkIsBrowserSupported();
  const ua = getUserAgent();
  store.dispatch(updateUserAgent(ua));

  useEffect(() => {
    if (!isSupported && !isDebug) return;
    const params = new URLSearchParams(history.location.search);
    // TODO: 涉及到一键登录的，需要做一个专门的可复用的 Router 去实现才对，否则以后这坨逻辑就烂在这个文件了
    if (
      history.location.pathname === '/login' &&
      params.has('userid') &&
      params.has('login_token') &&
      params.has('sdkappid')
    ) {
      const userId = params.get('userid') || '';
      const token = params.get('login_token') || '';
      const sdkAppId = params.get('sdkappid') || '';
      const origin = params.get('source') || '';
      const target = params.get('target') || '';
      if (origin === 'im_console') {
        config.isDeskKit = true;
      }
      if (sessionManage.isLogin()) {
        sessionManage.clearSession();
      }
      const suspenseSeatFeat = target === 'manage';
      dispatch(loginInstance({ userId, token, sdkAppId, type: 'token', suspenseSeatFeat, origin }))
        ?.then(unwrapResult)
        ?.then(() => {
          if (target === 'manage') {
            window.location.href = `/saas-manage/${sdkAppId}/home`;
            return;
          }
          if (target === 'agent') {
            history.replace(`/agent/${sdkAppId}?source=desk_console`);
            return;
          }
          history.replace(`/agent/${sdkAppId}`);
        })
        .catch((e) => {
          if (isDeskAuthing) {
            return handleLoginError(e, t, userInfo.sdkAppId);
          }
          history.replace('/login');
        });
      return;
    }
    if (sessionManage.isLogin() && !userInfo.loginChecked) {
      dispatch(checkLogin());
    }
  }, [userInfo.loginChecked, userInfo.sdkAppId, isSupported, isDebug, history, dispatch, t, isDeskAuthing]);

  useEffect(() => {
    history.listen((location) => {
      // 浏览器点击后后退到实例选择页面
      if (history.action.toUpperCase() === 'POP' && location.pathname === '/online') {
        debugLogRequest('popstate to online');
        dispatch(offline());
      }
    });
  }, [history, dispatch]);

  // url包含test或者isdebug为debug mode。可以进入正常使用tccc方便跟踪问题。
  // 不对企微内置浏览器做检测
  if (!isSupported && !isDebug) {
    return (
      <Switch>
        <Route path={'/tippage'}>
          <TipPage />
        </Route>
        <Route path="/" render={() => <Redirect to="/tippage" />} />
      </Switch>
    );
  }
  if (sessionManage.isLogin() && !userInfo.loginChecked) {
    if (isDeskAuthing || isSDKAuthing) {
      return <FullscreenLoading text={t('认证中...')} />;
    }
    return null;
  }
  const isSaasDemoPage = location.pathname.includes('/demo');
  // TODO: 国际站 demo 逻辑需要明确，暂时下掉
  if (isSaasDemoPage && !location.pathname.includes(getDemoSDKAppId()) && !isInternational()) {
    window.location.href = `/demo/${getDemoSDKAppId()}${window.location.search || ''}`;
    return null;
  }
  if (!isSaasDemoPage && !userInfo.isBindMobile && userInfo.loginChecked && !userInfo.isDemoAccount && !isFromDesk) {
    return (
      <Switch>
        <Route path={'/perfectInfo'}>
          <PerfectInfo />
        </Route>
        <Redirect from="/" to={'/perfectInfo'} />
      </Switch>
    );
  }
  if ((userInfo.loginChecked && userInfo.userId) || isSaasDemoPage) {
    if (isOAuthing) {
      loginOAuth(loginChallenge, userInfo.sdkAppId, userInfo.userId);
    }
    return (
      !isOAuthing && (
        <Switch>
          <Route
            path="/:currentView(agent|manage|record|demo|crm)/:sdkAppId"
            render={(routeProps) => (
              <InstanceLogin
                isSaasDemoPage={isSaasDemoPage}
                sdkAppId={routeProps.match.params.sdkAppId}
                userId={userInfo.userId}
              >
                <Agent {...routeProps} />
              </InstanceLogin>
            )}
          />
          <Route path={'/passwordReset'} children={<ResetPassword />} />
          <Route path={'/online'} children={isSDKAuthing ? <FullscreenLoading text={t('认证中...')} /> : <Online />} />
          <Route path={'/record'} children={<ServiceRecords />} />
          <Redirect from="/" to={{ pathname: '/online', search: location.search }} />
        </Switch>
      )
    );
  }
  return (
    <Switch>
      <Route path={'/login'}>
        <LoginComponent />
      </Route>
      {userInfo.multipleUserId && <Route path={'/online/:userId'} children={<Online />} />}
      <Route path={'/passwordReset'} children={<ResetPassword />} />
      <Redirect from="/" to={'/login'} />
    </Switch>
  );
}
