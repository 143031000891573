import { useEffect, useRef, useState } from 'react';

import { Button, InputAdornment, InputBase, makeStyles } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

import { Form, Icon, Radio } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { PHONE_REGEXP } from 'tccc-utils';

import { PhoneSelector } from 'src/components/PhoneSelector/PhoneSelector';
import { RootState } from 'src/store';
import { enablePhoneNumReflect } from 'src/utils/checkIsPhoneNumReflectEnabled';
import useSessionStorage from 'src/utils/hooks/useSessionStorage';

const StyledRadio = styled(Radio)`
  margin-bottom: 0;
  display: inline-flex;
`;

const useStyles = makeStyles({
  editablePhone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    margin: '10px auto 20px',
    width: 248,
  },
  inputRoot: {
    width: 210,
    height: 33,
    margin: '0 auto 20px',
    border: 'none',
    display: 'block',
  },
  phoneNumberInput: {
    height: 33,
    padding: 0,
    margin: 0,
    textAlign: 'center',
    fontSize: 24,
    color: '#101B2A',
    fontWeight: 500,
    border: 'none',
  },
  inputAdornment: {
    position: 'absolute',
    width: 16,
    height: 16,
    borderRadius: '50%',
    overflow: 'hidden',
    background: '#f2f2f2',
    top: 8,
    right: -16,
    cursor: 'pointer',
  },
  clearIcon: {
    width: 16,
    height: 16,
    background: '#F2F2F2',
    color: '#999',
  },
  calloutButton: {
    width: 140,
    height: 32,
    borderRadius: 17,
    margin: '0 auto',
  },
  typeLabel: {
    width: 248,
    display: 'inline-flex',
    '& .tea-form__label': {
      width: 98,
      marginRight: 4,
      display: 'inline-flex',
    },
  },
  selectLabel: {
    width: 248,
    display: 'inline-flex',
    '& .tea-form__label': {
      width: 98,
      display: 'inline-flex',
    },
    '& label': {
      paddingTop: 6,
    },
    '& .tea-cascader': {
      minWidth: 152,
    },
  },
});

interface EditablePhoneNumberProps {
  handleCallout: (phoneNumber: string, servingNum?: string) => void;
  active: boolean;
  editingPhoneNumber: string;
  setEditingPhoneNumber: (value: string) => void;
  numberReflectMode: number;
  isCalling: boolean;
}

export const EditablePhoneNumber: React.FC<EditablePhoneNumberProps> = ({
  handleCallout,
  active,
  editingPhoneNumber,
  setEditingPhoneNumber,
  numberReflectMode,
  isCalling,
}) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const staffAssignedCalloutNumber = useSelector((state: RootState) => state.appSettings.staffAssignedCalloutNumber);

  const [calloutMode, setCalloutMode] = useState('auto');
  const [phone, setPhone] = useSessionStorage('selected-phone-number', '');
  useEffect(() => {
    const timer = setInterval(() => {
      active && inputRef.current?.getElementsByTagName('input')[0].focus();
    }, 50);
    if (calloutMode === 'select') {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [active, calloutMode]);

  return (
    <div className={classes.editablePhone}>
      {staffAssignedCalloutNumber && (
        <Form className={classes.form}>
          <Form.Item className={classes.typeLabel} label={t('主叫号码类型')}>
            <Radio.Group
              value={calloutMode}
              onChange={(value) => {
                setCalloutMode(value);
              }}
              style={{ display: 'flex', justifyContent: 'flex-start', width: 250 }}
            >
              <StyledRadio name={'auto'}>{t('自动')}</StyledRadio>
              <StyledRadio name={'select'}>{t('指定号码')}</StyledRadio>
            </Radio.Group>
          </Form.Item>
          {calloutMode === 'select' && (
            <Form.Item
              className={classes.selectLabel}
              label={t('选择主叫号码')}
              tips={t('若暂无可选择号码范围，请联系管理员进行配置。')}
            >
              <PhoneSelector value={phone} onChange={(phone) => setPhone(phone)} />
            </Form.Item>
          )}
        </Form>
      )}
      <>
        <InputBase
          classes={{
            root: classes.inputRoot,
            input: classes.phoneNumberInput,
          }}
          style={{ marginTop: staffAssignedCalloutNumber ? 0 : 20 }}
          value={editingPhoneNumber}
          placeholder={enablePhoneNumReflect(numberReflectMode) ? t('请输入外呼ID') : t('请输入外呼号码')}
          ref={inputRef}
          onChange={(event) => {
            let { value } = event.target;
            if (!enablePhoneNumReflect(numberReflectMode)) {
              value = value.replace(PHONE_REGEXP, '');
            }
            setEditingPhoneNumber(value);
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              handleCallout(editingPhoneNumber, calloutMode === 'auto' ? undefined : phone);
            }
          }}
          endAdornment={
            <InputAdornment
              className={classes.inputAdornment}
              position="end"
              onClick={() => setEditingPhoneNumber('')}
              style={
                editingPhoneNumber?.length
                  ? {}
                  : {
                      display: 'none',
                    }
              }
            >
              <Clear className={classes.clearIcon} />
            </InputAdornment>
          }
        />
      </>
      <Button
        className={classes.calloutButton}
        variant={'contained'}
        color={'primary'}
        onClick={() => handleCallout(editingPhoneNumber, calloutMode === 'auto' ? undefined : phone)}
        disabled={isCalling || (calloutMode === 'auto' ? !editingPhoneNumber : !editingPhoneNumber || !phone)}
      >
        {isCalling ? (
          <>
            <Icon type="loading" />
            <span style={{ paddingLeft: 5 }}>{t('拨打中')}</span>
          </>
        ) : (
          t('拨打电话')
        )}
      </Button>
    </div>
  );
};
