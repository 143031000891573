const getStyles = () => ({
  conversation: {
    display: 'flex',
    height: '100%',
  },
  IM: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 360,
    flexGrow: 1,
    maxWidth: '100%',
  },
  chart: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#F2F2F2',
  },
  brightChart: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
  },
  chartIn: {
    position: 'relative',
  },
  chartTool: {
    height: '40px',
    background: '#f3f3f3',
  },
  chartHeader: {
    textAlign: 'center',
    height: '50px',
    lineHeight: '50px',
  },
  button: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  qucikReplyPanel: {
    minWidth: '400px',
    background: '#f2f2f2',
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translateX(100%)',
    zIndex: 1,
    borderLeft: '1px solid #e5e5e5',
    width: 'calc(100vw - 100% - 230px)',
    overflowY: 'auto',
    height: '100%',
  },
});

export default getStyles;
