import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { PLAIN_PHONE, UserStatusType } from 'tccc-utils';

import { UserStatusMap } from 'src/constants/agent';
import services from 'src/services';
import { RootState } from 'src/store';
import { Session } from 'src/store/slices/sessions';
import { callHold, cancelCallHold, updateReceiveStatus } from 'src/store/slices/sessions/pstn';
import { changeMicStatus } from 'src/store/slices/sessions/rtc';
import { endSession } from 'src/store/slices/sessions/sessions.thunk';

import { isInternational } from 'src/utils';

import { formatTel } from '../../../../utils/formatTel';

export type ConferenceState = {
  callee: 'skillGroupId' | 'userId' | 'extension' | 'outbound';
  type: 'conference' | 'transfer';
};

export function usePhoneState({ callInfo, agentStatus }: { callInfo: Session; agentStatus: UserStatusType }) {
  const dispatch = useDispatch();
  const smsPrivilege = useSelector((state: RootState) => state.appSettings.smsPrivilege);
  const telBlackUserEnabled = useSelector((state: RootState) => state.appSettings.telBlackUserEnable);
  const isPhone = ['phone', 'dialBack'].includes(callInfo.type);
  const isVoip = ['voip'].includes(callInfo.type);
  const isInternal = callInfo.type === 'internal';
  const isCall = isPhone || isVoip;
  const isWeApp = ['video'].includes(callInfo.type);

  // 转接、会议
  const [conferenceState, setConferenceState] = useState<ConferenceState>();

  // 收号
  const receiveStatus = callInfo.receiveStatus || '';
  // 下方显示号码, 呼入显示被叫，呼出显示主叫
  const displayNumber = callInfo.direction === 0 ? callInfo.calleePhoneNumber : callInfo.callerPhoneNumber;
  const servingNumber = displayNumber ? formatTel(displayNumber, '-') : '';

  const isExtensionNumber = displayNumber?.match(PLAIN_PHONE) !== null;

  // 是否可添加黑名单
  const canAddBlackList =
    callInfo.status === '400' && ((isCall && callInfo.isHost) || isWeApp) && telBlackUserEnabled && !isExtensionNumber;
  // 是否可发送短信
  const canSendSms =
    callInfo.status === '400' &&
    agentStatus !== UserStatusMap.countdown &&
    isPhone &&
    smsPrivilege &&
    !isExtensionNumber;

  // 是否可回拨
  const canDiaBack = callInfo.status === '400' && agentStatus !== UserStatusMap.countdown && isPhone;
  // 是否可转接
  const canTransfer = callInfo.status === '200' && (isCall || isInternal) && callInfo.isHost;
  // 是否可呼叫保持
  const canHold = callInfo.status === '200' && (isCall || isInternal) && callInfo.isHost;
  // 是否已静音
  const mute = callInfo.micStatus === 'off';
  // 通话保持
  const holdStatus = callInfo.holdStatus || false;

  // 是否可加微
  const canAddWechat = callInfo.status === '400' && agentStatus !== UserStatusMap.countdown && isPhone;
  // 是否可收号
  const canReceive = callInfo.status === '200' && isCall && callInfo.isHost;

  const canSendDTMF = callInfo.status === '200' && (isCall || isInternal) && callInfo.isHost;

  const [displayAddWechat, setDisplayAddWechat] = useState(false);
  // 加微标识
  const [addWechatFlag, setAddWechatFlag] = useState(false);

  const handleHangup = () => {
    dispatch(endSession({ sessionId: callInfo.sessionId, closeBy: 'seat' }));
  };

  const onHandleMuteAudio = () =>
    dispatch(changeMicStatus({ sessionId: callInfo.sessionId, micStatus: mute ? 'on' : 'off' }));

  const handleToggleHold = () => {
    const { sessionId } = callInfo;
    return dispatch(holdStatus ? cancelCallHold({ sessionId }) : callHold({ sessionId }));
  };

  const updateReceiveNumberStatus = useCallback(
    (status) => {
      dispatch(updateReceiveStatus({ sessionId: callInfo.sessionId, receiveStatus: status }));
    },
    [callInfo.sessionId, dispatch],
  );

  useEffect(() => {
    if (callInfo.status === '400') {
      setConferenceState(undefined);
    }
    if (callInfo.status !== '200') {
      updateReceiveNumberStatus('');
    }
  }, [callInfo.status, updateReceiveNumberStatus]);

  useEffect(() => {
    if (callInfo.status === '400') {
      setAddWechatFlag(addWechatFlag);
    }
  }, [callInfo.status, addWechatFlag]);

  // 暂时屏蔽
  useEffect(() => {
    if (isInternational()) {
      return;
    }
    // @ts-ignore
    services['/wxwork/getAuthInfo']({ suiteCategory: 'ClientRelationSuiteID' }).then((rst) => {
      if (rst.suiteId || rst.corpId) {
        setDisplayAddWechat(true);
      } else {
        setDisplayAddWechat(false);
      }
    });
  }, []);
  return {
    transformSkillGroupFlag:
      conferenceState?.callee === 'skillGroupId' && conferenceState?.type === 'transfer' && callInfo.status === '200',
    transformSeatFlag:
      conferenceState?.callee === 'userId' && conferenceState?.type === 'transfer' && callInfo.status === '200',
    transformExtensionFlag:
      conferenceState?.callee === 'extension' && conferenceState?.type === 'transfer' && callInfo.status === '200',
    addWechatFlag: addWechatFlag && callInfo.status === '400',
    conferenceState,
    setConferenceState,
    setTransformSkillGroupFlag: (val: boolean) =>
      setConferenceState(val ? { callee: 'skillGroupId', type: 'transfer' } : undefined),
    setTransformSeatFlag: (val: boolean) =>
      setConferenceState(val ? { callee: 'userId', type: 'transfer' } : undefined),
    setTransformExtensionFlag: (val: boolean) =>
      setConferenceState(val ? { callee: 'extension', type: 'transfer' } : undefined),
    setAddWechatFlag,
    servingNumber,
    canAddBlackList,
    canSendSms,
    canAddWechat: canAddWechat && displayAddWechat,
    updateReceiveNumberStatus,
    receiveStatus,
    handleHangup,
    onHandleMuteAudio,
    handleToggleHold,
    mute,
    canDiaBack,
    holdStatus,
    canHold,
    canTransfer,
    canReceive,
    canSendDTMF,
  };
}
