import { createAction } from '@reduxjs/toolkit';
export const UPDATEMESSAGE = 'UPDATEMESSAGE';
export const ADDMESSAGE = 'ADDMESSAGE';
export const SDKMINIMIZECHANGED = 'SDKMINIMIZECHANGED';
export const UNSHIFtMESSAGE = 'UNSHIFtMESSAGE';
export const UPDATEMESSAGESTATUS = 'UPDATEMESSAGESTATUS';
export const CLEARIMSTATE = 'CLEARIMSTATE';
export const DELETEMESSAGE = 'DELETEMESSAGE';
export const UPDATE_TYPING_STATE = 'UPDATE_TYPING_STATE';
export const UPDATE_REPLY_INFO = 'UPDATE_REPLY_INFO';
export const UPDATE_IMAGE_VIEWER = 'UPDATE_IMAGE_VIEWER';

export const imSdkReadyStateChanged = createAction('imSdkReadyStateChanged');
export const syncMessageLists = createAction('syncMessageLists');
export function updateImageViewer(payload) {
  return {
    type: UPDATE_IMAGE_VIEWER,
    payload,
  };
}
export function updateReplyInfo(payload) {
  return {
    type: UPDATE_REPLY_INFO,
    payload,
  };
}

export function updateTypingState(payload) {
  return {
    type: UPDATE_TYPING_STATE,
    payload,
  };
}

export function updateMessage(payload) {
  return {
    type: UPDATEMESSAGE,
    payload,
  };
}

export function deleteMessage(payload) {
  return {
    type: DELETEMESSAGE,
    payload,
  };
}

export function updateMessageStatus(payload) {
  return {
    type: UPDATEMESSAGESTATUS,
    payload,
  };
}

export function addMessage(payload) {
  return {
    type: ADDMESSAGE,
    payload,
  };
}

export function unshiftMessage(payload) {
  return {
    type: UNSHIFtMESSAGE,
    payload,
  };
}

export function clearIMState() {
  return {
    type: CLEARIMSTATE,
  };
}

export function sdkMinimizedChanged(payload) {
  return {
    type: SDKMINIMIZECHANGED,
    payload,
  };
}
