import React from 'react';

import find from 'lodash/find';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import CustomerIcon from 'src/assets/images/customerAvatar.svg';
import RobotIcon from 'src/assets/images/robotAvatar.svg';
import SeatIcon from 'src/assets/images/seatAvatar.svg';
import { Session } from 'src/store/slices/sessions';
import { UserInfo } from 'src/store/slices/userInfo';
import { getMessageFrom } from 'src/utils/message';

import { ImActions } from '../../im-actions/ImActions';
import { ImReplyInList } from '../../im-elements/text-element/TextElement.style';

import {
  ImAsideWrapper,
  ImAsideHeader,
  ImAsideBody,
  ImAsideContent,
  ImAsideStatus,
  Avatar,
  MessageTime,
} from './imAside.style';

// Add types for message, list, and callInfo
type MessageType = {
  flow: string;
  avatar?: string;
  from?: string;
  isSystemMessage: boolean;
  needReadReceipt: boolean;
  readReceiptInfo: { readCount: number };
  ID: string;
  cloudCustomData?: string;
  time: number;
  type: string;
  detail?: string;
};
type ListType = MessageType[];

type CallInfoType = Session;
type UserInfoType = UserInfo;
type ImAsideProps = {
  message: MessageType;
  content: React.ReactNode;
  header?: React.ReactNode;
  status?: React.ReactNode;
  callInfo: CallInfoType;
  userInfo: UserInfoType;
  renderBackground?: boolean;
  list?: ListType;
};
const getAvatar = (message: MessageType) => {
  const isFlowIn = message.flow === 'in';
  const location = isFlowIn ? 'left' : 'right';
  if (isFlowIn) {
    return {
      location,
      children: <img alt="customer avatar" src={message.avatar || CustomerIcon} />,
    };
  }
  return {
    location,
    children: (
      <img
        src={message.from?.includes('@') ? SeatIcon : RobotIcon}
        alt={message.from?.includes('@') ? 'seat avatar' : 'robot avatar'}
      />
    ),
  };
};

function getMessageTime(timestamp: number): string {
  const time = moment.unix(timestamp);
  if (time.isSameOrBefore(moment().startOf('year'))) {
    return time.format('YYYY-MM-DD HH:mm:ss');
  }
  // if (time.isSameOrAfter(moment().startOf('isoWeek'))) {
  //   return `星期${['天', '一', '二', '三', '四', '五', '六'][time.get('day')]} ${time.format('HH:mm:ss')}`;
  // }
  return time.format('MM-DD HH:mm:ss');
}

export const ImAside: React.FC<ImAsideProps> = (props) => {
  const { t } = useTranslation();
  const { message, content, header, status, callInfo, userInfo, renderBackground, list } = props;
  const { flow: messageFlow, isSystemMessage, from } = message;
  const flow = isSystemMessage || from === t('系统消息') || from === 'administrator' ? 'out-others' : messageFlow;
  const messageUnread =
    flow === 'in' && message.needReadReceipt && (message.readReceiptInfo.readCount > 0 ? undefined : message.ID);
  let replyMessage: MessageType | null = null;
  let replyMessageFrom = '';

  if (!Object.prototype.hasOwnProperty.call(message, 'cloudCustomData')) {
    try {
      if (JSON.parse(message.detail ?? '').CloudCustomData)
        message.cloudCustomData = JSON.parse(message.detail ?? '').CloudCustomData;
    } catch (e) {
      console.log(e);
    }
  }

  if (message.cloudCustomData && list) {
    try {
      const refMessageFromCloud: any = JSON.parse(message.cloudCustomData).find(
        (m: any) => m.customType === 'REPLY_MESSAGE_REF',
      );
      const replyMessageFromList = find(list, { ID: refMessageFromCloud.ID });
      replyMessage = { ...refMessageFromCloud, ...replyMessageFromList };
      replyMessageFrom = (replyMessage as any)?.customFrom;
    } catch {
      replyMessage = find(list, { ID: message.cloudCustomData }) || null;
      if (replyMessage) {
        replyMessageFrom = getMessageFrom(replyMessage, callInfo, userInfo);
      }
    }
  }
  return (
    <ImAsideWrapper
      style={{ marginTop: 30 }}
      messageType={message.type}
      renderBackground={renderBackground}
      flow={flow}
      data-message-id={message.ID}
      {...(messageUnread
        ? {
            'data-unread': true,
          }
        : undefined)}
    >
      {header && (
        <ImAsideHeader>
          {header} <MessageTime>{getMessageTime(message.time)}</MessageTime>
        </ImAsideHeader>
      )}
      <ImActions callInfo={callInfo} userInfo={userInfo} message={message}>
        <ImAsideBody>
          <Avatar {...getAvatar({ ...message, flow })} />
          {flow === 'in' && <ImAsideContent>{content}</ImAsideContent>}

          {status && <ImAsideStatus>{status}</ImAsideStatus>}
          {(flow === 'out' || flow === 'out-others') && <ImAsideContent>{content}</ImAsideContent>}
        </ImAsideBody>
      </ImActions>
      {replyMessage && <ImReplyInList message={replyMessage} from={replyMessageFrom} />}
    </ImAsideWrapper>
  );
};
