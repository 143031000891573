import * as logsAPI from '@opentelemetry/api-logs';
import { OTLPLogExporter } from '@opentelemetry/exporter-logs-otlp-http';
import { LoggerProvider, BatchLogRecordProcessor } from '@opentelemetry/sdk-logs';
import { Resource } from '@opentelemetry/resources';
type LogBody =
  | string
  | {
      msg: string;
      attributes?: logsAPI.LogAttributes;
    };

const getLogBody = (args: LogBody, defaultAttributes: logsAPI.LogAttributes) => ({
  body: typeof args === 'string' ? args : args.msg,
  attributes:
    typeof args === 'string'
      ? defaultAttributes
      : {
          ...defaultAttributes,
          ...args.attributes,
        },
});

export default class Logger {
  logger: logsAPI.Logger;
  attributes: logsAPI.LogAttributes;
  logLevel: logsAPI.SeverityNumber;
  name: string;
  constructor({
    name,
    id,
    url,
    version = 'latest',
    attributes,
    logLevel = logsAPI.SeverityNumber.ERROR2,
  }: {
    name: string;
    id: string;
    url: URL | string;
    version?: string;
    attributes: logsAPI.LogAttributes;
    logLevel?: logsAPI.SeverityNumber;
  }) {
    this.name = name;
    this.logLevel = logLevel;
    const loggerProvider = new LoggerProvider({
      resource: new Resource({
        'service.name': name,
        'tps.tenant.id': id,
      }),
    });
    loggerProvider.addLogRecordProcessor(
      new BatchLogRecordProcessor(
        new OTLPLogExporter({
          url: url.toString(),
          headers: {
            'X-Tps-TenantID': id,
          },
        }),
      ),
    );
    const logger = loggerProvider.getLogger(name, version);
    this.attributes = attributes;
    this.logger = logger;
  }

  debug = (args: LogBody) => {
    if (this.logLevel <= logsAPI.SeverityNumber.DEBUG) {
      console.debug(`[${this.name}]`, args);
    }
    this.logger.emit({
      severityNumber: logsAPI.SeverityNumber.DEBUG,
      severityText: 'debug',
      ...getLogBody(args, this.attributes),
    });
  };
  info(args: LogBody) {
    if (this.logLevel <= logsAPI.SeverityNumber.INFO) {
      console.info(`[${this.name}]`, args);
    }
    return this.logger.emit({
      severityNumber: logsAPI.SeverityNumber.INFO,
      severityText: 'info',
      ...getLogBody(args, this.attributes),
    });
  }
  warn = (args: LogBody) => {
    if (this.logLevel <= logsAPI.SeverityNumber.WARN) {
      console.warn(`[${this.name}]`, args);
    }
    this.logger.emit({
      severityNumber: logsAPI.SeverityNumber.WARN,
      severityText: 'warn',
      ...getLogBody(args, this.attributes),
    });
  };

  error = (args: LogBody) => {
    if (this.logLevel <= logsAPI.SeverityNumber.ERROR) {
      console.error(`[${this.name}]`, args);
    }
    this.logger.emit({
      severityNumber: logsAPI.SeverityNumber.ERROR,
      severityText: 'error',
      ...getLogBody(args, this.attributes),
    });
  };

  setAttribute = (key: string, value: string | number) => {
    Object.assign(this.attributes, {
      [key]: value,
    });
  };

  setAttributes = (attributes: logsAPI.LogAttributes) => {
    Object.assign(this.attributes, attributes);
  };
}
