import * as React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Layout as TeaLayout } from '@tencent/tea-component';
import queryString from 'query-string';

import { TopBar } from './TopBar';

const { Header, Body, Content } = TeaLayout;

type TopBarProps = React.ComponentProps<typeof TopBar>;

type LayoutProps = {
  topBar: TopBarProps;
};

const useStyles = makeStyles(() => ({
  header: {
    height: 56,
  },
  expHeader: {
    width: 905,
    height: 56,
  },
  content: {
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  expContent: {
    width: 905,
    height: 712,
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const Layout: React.FC<LayoutProps> = (props) => {
  const { hideHeader, from } = queryString.parse(window.location.search);
  const isFromExperienceCenter = from === 'exp';

  const classes = useStyles();

  return (
    <TeaLayout>
      {!hideHeader && (
        <Header className={isFromExperienceCenter ? classes.expHeader : classes.header}>
          {props.topBar && <TopBar {...props.topBar} />}
        </Header>
      )}
      <Body>
        <Content className={isFromExperienceCenter ? classes.expContent : classes.content}>{props.children}</Content>
      </Body>
    </TeaLayout>
  );
};
