import { forwardRef } from 'react';

import { makeStyles, Button, ButtonProps } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    borderRadius: 22,
    width: 170,
    height: 44,
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
  },
  containedPrimary: {
    background: '#006EFF',
    '&:hover': {
      background: 'rgba(0, 110, 255, 0.8)',
    },
  },
  containedSecondary: {
    background: '#E54545',
    '&:hover': {
      background: 'rgba(229, 69, 69, 0.8)',
    },
  },
  outlinedPrimary: {
    '&:hover': {
      background: 'rgba(51,139,255,0.10)',
    },
  },
  containedSizeLarge: {
    padding: '8px 16px',
  },
});

export const BaseButton = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
  const classes = useStyles();
  return <Button ref={ref} classes={classes} startIcon={props.startIcon} {...props} />;
});
