import { makeStyles } from '@material-ui/core';

import { useSelector } from 'react-redux';

import sessionManage from '../../utils/sessionManage';

import style from './Header.style';
const useStyle = makeStyles(style);
function Header(props) {
  const classes = useStyle();
  const featureFlag = useSelector((s) => s.appSettings.featureFlag);
  return (
    <div className={classes.logoWrapper}>
      <div className={classes.logoHeader}>
        <div onClick={() => sessionManage.loginOut()} className={classes.siteTitle}>
          <div className={classes.logoImage}></div>
          <h1>{featureFlag.brandName}</h1>
          <h2>{featureFlag.tcccChineseName}</h2>
        </div>
        <div>{props.children}</div>
      </div>
    </div>
  );
}

export default Header;
