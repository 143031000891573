import { isRejected, isFulfilled } from '@reduxjs/toolkit';
import { isDraft, current } from 'immer';
import pick from 'lodash/pick';

import { unwrapResult } from 'tccc-sdk';
import { i18nLanguage } from 'tccc-utils';

import { aegis } from 'src/aegis';
import { CUSTOM_MESSAGE_SRC } from 'src/constants/im';
import { IVR_STATUS, IVR_TYPE } from 'src/constants/manage';
import i18n from 'src/i18n';
import services, { debugLogRequest } from 'src/services';
import { SelfServiceSource, SelfServiceStatus } from 'src/services/httpAPIs/types';
import { request } from 'src/services/httpClient';
import { offline, online } from 'src/store/actions/agent';
import { startRealtimeAsr, stopRealtimeAsr } from 'src/store/slices/sessions/asr';
import {
  triggerReceiveKey,
  callHold,
  cancelCallHold,
  forwardOut,
  updateForwardStatus,
  cancelForwardOut,
  leaveForwardOut,
  restoreForwardOut,
  updateReceiveStatus,
  startCall,
  triggerSelfService,
} from 'src/store/slices/sessions/pstn';

import { monitorRTC, startCallInternal, exitMonitorRTC } from 'src/store/slices/sessions/rtc';

import { updateSession } from 'src/store/slices/sessions/sessions.action';
import {
  endSession,
  transferSession,
  deleteSession,
  accessSession,
  unmuteVideo,
  muteVideo,
} from 'src/store/slices/sessions/sessions.thunk';
import { sendMessage, createCustomMessage, receiveMessageThunk, sdkMinimizedChanged } from 'src/store/slices/tim.thunk';

import { showError, messageBoxScrollToBottom } from 'src/utils';
import logger from 'src/utils/logger';
import tccc from 'src/utils/tccc';

import store, { selectAllSession } from '../store';
import { updateUserSettings } from '../store/slices/app.thunk';
import { dialBack, updatePhoneRemark } from '../store/slices/sessions/pstn';

import { changeMicStatus } from '../store/slices/sessions/rtc';
import { addToBlackList, setActiveUser } from '../store/slices/sessions/sessions.thunk';
import { getCallInfo } from '../utils';
const { t } = i18n;
class SdkMessage {
  events = {
    callIn: 'callIn', // 用户呼入事件
    userAccessed: 'userAccessed', // 座席接听成功事件
    sessionEnded: 'sessionEnded', // 会话结束事件
    sessionCompleted: 'sessionCompleted', // 会话完成事件
    transfer: 'transfer', // 转接事件
    userChanged: 'userChanged', //  用户切换事件
    imMessage: 'imMessage', // 消息接收事件事件
    imMessagePosted: 'imMessagePosted', // 消息发送成功事件
    callOuted: 'callOuted', // 外呼发起事件
    calloutAccepted: 'calloutAccepted', // 外呼接听事件
    statusChanged: 'statusChanged', //  状态改变事件
    autoTransfer: 'autoTransfer', // 超时转接事件
    ready: 'ready', // 超时转接事件
    forwardOut: 'forwardOut', // 转接外线事件
    cancelForwardOut: 'cancelForwardOut',
    leaveForwardOut: 'leaveForwardout',
    restoreForwardOut: 'restoreForwardOut',
    updateForwardStatus: 'updateForwardStatus',
    tokenRefreshNeeded: 'tokenRefreshNeeded',
    liveLocalLevel: 'liveLocalLevel',
    networkQuality: 'networkQuality',
    receiveStatusChanged: 'receiveStatusChanged', // 收号状态变更事件
    updateRecognizedMessages: 'updateRecognizedMessages', // 语音识别内容更新事件
    tokenExpired: 'tokenExpired',
    kickedOut: 'kickedOut', // 多地登录下线
    forcedOffline: 'forcedOffline', // 管理端强制下线

    // 会议相关接口
    memberStateChanged: 'memberStateChanged',

    // im
    messageReceived: 'messageReceived',
    onlineStateChanged: 'onlineStateChanged',
    sendMessage: 'sendMessage',
    asr: 'asr',
    userSettingsUpdated: 'userSettingsUpdated',
    setEnableRealtimeAsr: 'setEnableRealtimeAsr',
  };
  parentWindow = window.parent;
  constructor() {
    window.addEventListener('message', async ({ data, origin }) => {
      if (data.source !== 'tccc-iframe-sdk') {
        return;
      }
      if (data.target === 'api' && data.api === 'heartBeat') {
        this.apiCallback(
          {
            status: 'success',
            data: 'ok',
          },
          data.nonce,
          false,
        );
        return;
      }
      if (data.api !== 'log') {
        logger.info('收到SDK消息', data);
        debugLogRequest(`收到SDK消息: ${JSON.stringify(data)}`);
      }
      // 监听用户调用api的事件，并回调结果给用户
      // data.source 来源， data.target功能目标，none事件唯一id，用于回调区分事件来源
      if (data.target === 'login') {
        return this.login({ ...data.payload, origin });
      }
      if (data.target === 'api' && data.nonce) {
        if (this[data.api]) {
          // 定义的事件必须是promise，api如getUserList
          try {
            const { payload } = data;
            if (data.api === 'login') {
              // file protocol 为空
              if (origin !== 'null') {
                Object.assign(payload, { origin });
              }
            }
            const msg = await this[data.api](payload);
            if (isRejected(msg)) {
              const callbackData = {
                status: 'error',
                errorMsg: msg?.error?.message || `${data.api} call failed`,
              };
              if (msg?.error?.name) {
                callbackData.name = msg.error.name;
              }
              return this.apiCallback(callbackData, data.nonce);
            }
            this.apiCallback(
              {
                status: 'success',
                data: msg?.payload || msg,
              },
              data.nonce,
              data.api !== 'log',
            );
          } catch (e) {
            try {
              this.apiCallback(
                {
                  status: 'error',
                  errorMsg: e?.message || e?.msg || e?.toString(),
                },
                data.nonce,
              );
              if (e.name === 'NetworkError') {
                logger.warn('向sdk发送消息时失败 NetworkError.', e);
              } else {
                logger.error('向sdk发送消息时失败', e);
              }
              debugLogRequest(`向sdk发送消息时失败, ${e?.toString?.()}, url: ${data.api}`);
            } catch {
              this.apiCallback({ status: 'error' }, data.nonce);
              aegis.report(e);
            }
          }
        } else {
          logger.error(`api:${data.api}不存在请确认`);
        }
      }
    });
  }

  // 以下是被调用api
  getUserList() {
    // 获取用户列表的api
    const state = store.getState();
    const sessions = selectAllSession(state);
    return Promise.resolve(sessions);
  }

  async sendCustomMessage(payload) {
    const { currentUser } = store.getState().userInfo;
    if (!currentUser.sessionId) {
      showError(t('当前没有选择会话，无法发自定义消息'));
      return;
    }
    if (!payload?.title || !payload?.description || !payload?.iconUrl) {
      showError(t('请检查传入参数完整性'));
      return;
    }
    const res = await store.dispatch(
      createCustomMessage({
        sessionId: currentUser.sessionId,
        payload: {
          data: JSON.stringify({
            ...payload,
            fromPostMessage: 1,
          }),
        },
      }),
    );
    await store.dispatch(receiveMessageThunk({ data: [res.payload] }));
    await store.dispatch(sendMessage({ message: res.payload }));
    messageBoxScrollToBottom();
  }

  async sendCardMessage(payload) {
    const { currentUser } = store.getState().userInfo;
    if (!currentUser.sessionId) {
      showError(t('当前没有选择会话，无法发自定义消息'));
      return;
    }
    if (!payload?.header || !payload?.desc || !payload?.pic || !payload?.url) {
      showError(t('请检查传入参数完整性'));
      return;
    }
    const res = await store.dispatch(
      createCustomMessage({
        sessionId: currentUser.sessionId,
        payload: {
          data: JSON.stringify({
            src: CUSTOM_MESSAGE_SRC.CustomCard,
            content: payload,
            customerServicePlugin: 0,
          }),
        },
      }),
    );
    await store.dispatch(receiveMessageThunk({ data: [res.payload] }));
    await store.dispatch(sendMessage({ message: res.payload }));
    messageBoxScrollToBottom();
  }

  async sendOrderMessage(payload) {
    const { currentUser } = store.getState().userInfo;
    if (!currentUser.sessionId) {
      showError(t('当前没有选择会话，无法发自定义消息'));
      return;
    }

    const res = await store.dispatch(
      createCustomMessage({
        sessionId: currentUser.sessionId,
        payload: {
          data: JSON.stringify({
            src: CUSTOM_MESSAGE_SRC.ORDER_CARD,
            content: payload,
            customerServicePlugin: 0,
          }),
        },
      }),
    );
    await store.dispatch(receiveMessageThunk({ data: [res.payload] }));
    await store.dispatch(sendMessage({ message: res.payload }));
    messageBoxScrollToBottom();
  }

  accessUser(payload) {
    return store.dispatch(accessSession({ sessionId: payload?.sessionId || payload?.id || payload }));
  }

  async endSession(payload) {
    return store.dispatch(endSession({ sessionId: payload?.sessionId || payload?.id || payload, closeBy: 'seat' }));
  }

  changeStatus(payload) {
    return tccc.Agent.setStatus(payload.agentStatus, payload.restReason);
  }

  async offline() {
    return store.dispatch(offline());
  }

  async callOut({ phoneNumber, phoneDesc, uui, callerPhoneNumber, skillGroupId, servingNumberGroupIds }) {
    return store.dispatch(
      startCall({
        phoneDesc,
        phoneNumber,
        uui,
        callerPhoneNumber,
        skillGroupId,
        servingNumberGroupIds,
      }),
    );
  }

  async forwardOut({ servingNumber, sessionId }) {
    return store.dispatch(forwardOut({ servingNumber, sessionId }));
  }

  async cancelForwardOut({ servingNumber, sessionId }) {
    return store.dispatch(cancelForwardOut({ servingNumber, sessionId }));
  }

  async leaveForwardOut({ sessionId }) {
    return store.dispatch(leaveForwardOut({ sessionId }));
  }

  async restoreForwardOut({ sessionId }) {
    return store.dispatch(restoreForwardOut({ sessionId }));
  }

  async updateForwardStatus(payload) {
    return store.dispatch(updateForwardStatus(payload));
  }

  async deleteCallInfo(callInfo) {
    return store.dispatch(deleteSession(callInfo));
  }

  updateCallInfo({ sessionId, data }) {
    return store.dispatch(
      updateSession({
        id: sessionId,
        changes: data,
      }),
    );
  }

  async hold({ sessionId }) {
    return store.dispatch(callHold({ sessionId }));
  }
  async getCalloutNumberList() {
    return request('/ccc/pstn/getStaffCanCalloutNumberList').then((res) => res.numberList);
  }
  async unHold({ sessionId }) {
    return store.dispatch(cancelCallHold({ sessionId }));
  }

  async getSkillGroupList(payload) {
    return services['/tcccadmin/tech/getSkillGroupList'](payload).then(({ skillGroupList }) => skillGroupList);
  }

  async transfer({ skillGroupId, userId, sessionId, phone, allowQueue }) {
    return store.dispatch(transferSession({ sessionId, userId, skillGroupId, phone, allowQueue }));
  }

  async getReceiveIvrList({ pageIndex, pageSize, sessionId }) {
    const callInfo = getCallInfo(sessionId);
    if (callInfo.type === 'dialBack' || callInfo.type === 'phone') {
      return request('/tcccadmin/ivr/getIvrList', {
        type: IVR_TYPE.inputCallback,
        pageIndex,
        pageSize,
        pubState: IVR_STATUS.IvrPubStateOK,
      }).then(({ ivrList }) => ivrList.map(({ ivrName, ivrId }) => ({ ivrName, ivrId })));
    }
    return [];
  }

  async getSelfServiceList({ pageIndex, pageSize, sessionId }) {
    const callInfo = getCallInfo(sessionId);
    if (callInfo.type === 'voip') {
      return request('/tcccadmin/selfservice/getList', {
        pageIndex,
        pageSize,
        status: SelfServiceStatus.on,
        source: SelfServiceSource.audioSelfService,
      }).then(({ selfServiceList }) => selfServiceList);
    }
    if (callInfo.type === 'dialBack' || callInfo.type === 'phone') {
      return request('/tcccadmin/selfservice/getList', {
        pageIndex,
        pageSize,
        status: SelfServiceStatus.on,
        source: SelfServiceSource.teleSelfService,
      }).then(({ selfServiceList }) => selfServiceList);
    }
    return [];
  }

  // 收号第一步pending状态同步用
  async updateReceiveStatus({ sessionId, receiveStatus }) {
    // 呼出更新
    store.dispatch(
      updateReceiveStatus({
        sessionId,
        receiveStatus,
      }),
    );
  }

  async triggerReceiveKey({ sessionId, ivrId, clientData, param }) {
    const triggerReceiveKeyCallback = (data) => {
      if (isRejected(data)) {
        this.postEventMessage(this.events.receiveStatusChanged, {
          sessionId,
          clientData: clientData || '',
          receiveStatus: 'error',
        });
        return 'error';
      }
      if (isFulfilled(data)) {
        this.postEventMessage(this.events.receiveStatusChanged, {
          sessionId,
          clientData: clientData || '',
          receiveStatus: 'waiting',
        });
        return 'waiting';
      }
      return data?.payload?.status || 'error';
    };
    return store.dispatch(triggerReceiveKey({ sessionId, ivrId, clientData, param })).then(triggerReceiveKeyCallback);
  }

  async triggerSelfService({ sessionId, selfId, clientData, param }) {
    return store.dispatch(triggerSelfService({ sessionId, selfId, clientData, param })).then((data) => {
      if (isRejected(data)) {
        this.postEventMessage(this.events.receiveStatusChanged, {
          sessionId,
          clientData: clientData || '',
          receiveStatus: 'error',
        });
        return 'error';
      }
      if (isFulfilled(data)) {
        this.postEventMessage(this.events.receiveStatusChanged, {
          sessionId,
          clientData: clientData || '',
          receiveStatus: 'waiting',
        });
        return 'waiting';
      }
      return data?.payload?.status || 'error';
    });
  }
  async changeMicStatus({ sessionId, micStatus, memberId }) {
    if (micStatus === 'on' || micStatus === 'off') {
      return store.dispatch(changeMicStatus({ sessionId, micStatus, memberId }));
    }
  }

  async unmuteVideo({ sessionId }) {
    return store.dispatch(unmuteVideo({ sessionId }));
  }

  async muteVideo({ sessionId }) {
    return store.dispatch(muteVideo({ sessionId }));
  }

  async sendDtmf({ sessionId, dtmfText }) {
    if (typeof dtmfText !== 'string') {
      return 'error';
    }
    const keyPress = dtmfText.replace(/[^\d#*]/g, '');
    if (keyPress === '') {
      return 'error';
    }
    try {
      await tccc.Call.sendDigits({
        digits: dtmfText,
        sessionId,
      });
      debugLogRequest(`sendDtmf: ${dtmfText} success`);
    } catch (e) {
      debugLogRequest(`sendDtmf: ${dtmfText} error`, e?.message);
      return 'error';
    }
    return 'ok';
  }

  async dialBack(payload) {
    return store.dispatch(dialBack(payload));
  }

  sendTextMessage(data) {
    const to = getCallInfo(data.sessionId || data.to).userId;
    tccc.Chat.sendTextMessage({ ...data, to });
  }

  async login(payload) {
    debugLogRequest('sdkMessage login', payload.origin);
    return this.loginResolve?.(payload);
  }

  // 以下是内部调用
  /**
   * SaaS ready之后到SDK获取登录参数
   */
  callIframelogin() {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error('timeout'));
      }, 5000);
      this.loginResolve = function (data) {
        clearTimeout(timer);
        resolve(data);
      };
      this.safePostMessage({
        target: 'login', // 功能目标，分为事件event, login登录操作，获取token
      });
    });
  }

  async online() {
    return store.dispatch(online());
  }

  async setActiveUser(data) {
    store.dispatch(setActiveUser(data));
  }

  async notifyClientMinimizeChanged(data) {
    store.dispatch(sdkMinimizedChanged({ sdkMinimized: data }));
  }

  async useMobileAccept(val) {
    store.dispatch(
      updateUserSettings({
        useMobileAcceptType: val,
      }),
    );
  }

  async useMobileCallOut(val) {
    store.dispatch(
      updateUserSettings({
        useMobileCallOut: val,
      }),
    );
  }

  async getServingPhoneCallList() {
    return request('/ccc/pstn/getPSTNActiveSessionList', {});
  }

  async monitorServingPhoneCall({ sessionId, phoneNumber, phoneDesc }) {
    return store.dispatch(
      monitorRTC({
        sessionId,
        phoneNumber,
        phoneDesc,
      }),
    );
  }
  async exitMonitor() {
    return store.dispatch(exitMonitorRTC());
  }

  async startInternalCall({ calleeUserId }) {
    return store.dispatch(startCallInternal({ calleeUserId }));
  }

  async getMicrophones() {
    try {
      //
      const microphones = await tccc.Devices.getMicrophones();
      // TRTC返回了getCapabilities方法导致postMessage失败，需过滤一下
      return microphones.map((item) => ({
        deviceId: item.deviceId,
        groupId: item.groupId,
        kind: item.kind,
        label: item.label,
      }));
    } catch (e) {
      return [];
    }
  }

  async getCameras() {
    return tccc.Devices.getCameras();
  }

  async addToBlocklist({ sessionId }) {
    store.dispatch(addToBlackList({ sessionId }));
  }

  async addPhoneRemark({ sessionId, remark: newRemark }) {
    const callInfo = getCallInfo(sessionId);
    store.dispatch(
      updatePhoneRemark({
        sessionId,
        userMark: newRemark,
        phone: callInfo.phone,
      }),
    );
  }

  setTRTCProxyServer(...params) {
    return tccc.Proxy.setTRTCProxyServer(...params);
  }

  setTRTCTurnServer(...params) {
    return tccc.Proxy.setTRTCTurnServer(...params);
  }

  invite(...params) {
    return tccc.Call.invite(...params).then(unwrapResult);
  }
  kick(...params) {
    return tccc.Call.kick(...params).then(unwrapResult);
  }
  terminateSessions() {
    return tccc.Call.terminateSessions().then(unwrapResult);
  }

  safePostMessage(data, sendLog = true) {
    try {
      this.parentWindow?.postMessage(
        {
          source: 'tccc-source',
          ...data,
        },
        '*',
      );
      if (
        sendLog &&
        ![this.events.liveLocalLevel, this.events.networkQuality, this.events.asr].includes(data.event) &&
        ![this.events.updateRecognizedMessages, this.events.asr, 'callInfoList', 'userInfo', 'asr'].includes(
          data.action,
        )
      ) {
        logger.info('向SDK发送消息', data);
        debugLogRequest(`向SDK发送消息, ${JSON.stringify(data)}`);
      }
    } catch (e) {
      aegis.error(e);
    }
  }

  apiCallback(payload, nonce, sendLog = true) {
    // 回调api结果到客服端
    this.safePostMessage(
      {
        nonce, // none事件唯一id，用于回调区分事件来源
        target: 'api', // 功能目标，分为事件event, api调用回调
        payload,
      },
      sendLog,
    );
  }

  syncData(action, payload) {
    // 回调api结果到客服端
    this.safePostMessage({
      action, // syncCallInfoList
      target: 'sync-data', // 功能目标，分为事件event, api调用回调
      payload,
    });
  }

  postEventMessage(event, data) {
    // 向客户系统发送事件
    this.safePostMessage({
      event: this.events[event], // 事件名
      target: 'event', // 功能目标，分为事件event, api调用回调
      payload: data,
    });
  }

  async setEnableRealtimeAsr(payload) {
    const { sessionId, isStartRealtimeAsr } = payload;
    // 此处enableSpeechRecognition 为将要设置的值
    if (isStartRealtimeAsr) {
      store.dispatch(startRealtimeAsr({ sessionId }));
    } else {
      store.dispatch(stopRealtimeAsr({ sessionId }));
    }
  }

  async getStaffList(payload) {
    try {
      const data = await request('/tcccadmin/staff/getStaffList', payload);
      return data;
    } catch (e) {
      debugLogRequest(`getStaffList error: ${e.message}`);
      throw e;
    }
  }

  getExtensionList(payload) {
    return request('/tcccadmin/extension/getList', { ...payload, isNeedStatus: true }).then((data) => {
      const { extensionList } = data;
      const finalList = extensionList.filter((item) => item.status !== '0');
      return {
        extensionList: finalList,
        total: finalList.length,
      };
    });
  }

  changeLanguage(lng) {
    tccc.changeLanguage(lng);
    i18nLanguage.setLanguage(lng, true);
    i18n.changeLanguage(lng);
    return 'ok';
  }

  log(message = '') {
    debugLogRequest(message);
    return true;
  }
}

export default new SdkMessage();
export function transformCallInfoToSdk(info, calcDuration = false, includeStatus = false) {
  if (!info) {
    return info;
  }
  if (isDraft(info)) {
    // eslint-disable-next-line no-param-reassign
    info = current(info);
  }
  let { duration } = info;
  if (calcDuration && !info.duration && info.startTime) {
    duration = Date.now() / 1000 - info.startTime;
  }
  return pick(
    {
      ...info,
      id: info.sessionId,
      direction: `${info.direction}`,
      // info无duration,endTime用现在now
      ...(calcDuration &&
        !info.duration && {
          duration,
          endTime: Math.floor(Date.now() / 1000),
        }),
      // info有duration,endTime用duration推导
      ...(calcDuration &&
        info.duration && {
          endTime: info.startTime + info.duration * 1000,
        }),
      ...(info.userId &&
        ['2', '3', '5', '6'].includes(info.peerSource) && {
          wxOpenId: info.userId,
        }),
      // ...(info.members && { members: info.members.map(transformMemberState) }),
    },
    [
      'id',
      'sessionId',
      'callerLocation',
      'phone',
      'duration',
      'avatar',
      'ivrPath',
      'wxOpenId',
      'closeBy',
      'remark',
      'nickname',
      'callerPhoneNumber',
      'calleePhoneNumber',
      'direction',
      'isHost',
      'members',
      'count',
      'startCallTime',
      'forwardStartServerTime',
      'outlineStatus',
      'clientStatus',
      'timeout',
      'type',
      'media',
      'clientData',
      'protectedCallee',
      'protectedCaller',
      'callee',
      'caller',
      'skillGroupId',
      'hangupType',
      'mainReason',
      'subReason',
      'serverType',
      'userId',
      'tabUUID',
      'sessionType',
      ...(includeStatus ? ['status'] : []),
    ],
  );
}
export function transformImMessage(message) {
  const sessions = selectAllSession(store.getState());
  const sessionId = sessions.find((info) => info.conversationID === message.conversationID)?.sessionId;
  return pick(
    {
      ...message,
      sessionId,
    },
    [
      'ID',
      'sessionId',
      'avatar',
      'clientSequence',
      'conversationID',
      'conversationSubType',
      'conversationType',
      'flow',
      'from',
      'geo',
      'isPeerRead',
      'isPlaceMessage',
      'isRead',
      'isResend',
      'isRevoked',
      'isSystemMessage',
      'nick',
      'payload',
      'priority',
      'protocol',
      'random',
      'sequence',
      'status',
      'time',
      'to',
      'type',
    ],
  );
}
