import { Bubble, Button, Justify, H4, BubbleProps } from '@tencent/tea-component';

import i18n from 'src/i18n';
const contentStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
};

interface FeatureTipsProps {
  children: React.ReactNode;
  visible: boolean;
  title?: string;
  content: React.ReactNode;
  okText?: React.ReactNode;
  placement?: BubbleProps['placement'];
  onOk: () => void;
}

const { t } = i18n;
export const FeatureTips = ({
  visible,
  placement = 'top-start',
  children,
  title,
  content,
  okText = t('我知道了'),
  onOk,
}: FeatureTipsProps) => (
  <Bubble
    placement={placement}
    visible={visible}
    content={
      <div style={contentStyle}>
        <H4>{title}</H4>
        {content}
        <Justify
          right={
            <Button type={'primary'} onClick={onOk}>
              {okText}
            </Button>
          }
        ></Justify>
      </div>
    }
  >
    {children}
  </Bubble>
);
