import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { Input, InputAdornment, Select } from '@tencent/tea-component';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from 'src/store';

import { isDeskSeat } from 'src/utils';

import { request } from '../../services/httpClient';
import { StyledTable } from '../CentralizedComponents';

interface StaffListProps {
  value?: string;
  onSelected: (userId: string) => void;
  onChangeStaffList?: (staffList: Staff[]) => void;
}

interface StaffListParams {
  pageNum: number;
  pageSize: number;
  currentSkillGroupId: string;
  currentSearchType: 'userId' | 'staffName' | 'staffNo';
  currentSearchValue: string;
}
enum SkillGroupType {
  TEL = 0,
  IM = 1,
  AUDIO = 3,
  VIDEO = 4,
}

const StatusColor = {
  undefined: '#999',
  0: '#999',
  '-1': '#999',
  100: '#3FCFB8',
  200: '#FFBE2D',
  150: '#FFBE2D',
  300: '#006EFF',
  400: '#006EFF',
  500: '#006EFF',
  700: '#FC4F05',
  900: '#999',
};
const useStyles = makeStyles({
  adornment: {
    flexBasis: '50%',
    display: 'flex',
  },
  searchBox: {
    width: '100%',
  },
  beforeSelect: {
    width: 60,
    lineHeight: '17px',
  },
  skillGroupSelect: {
    marginRight: 10,
    flexBasis: '50%',
    '& > div': {
      width: '100%',
    },
  },
  actionPanel: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  refreshContainer: {
    display: 'flex',
  },
  refresh: {
    color: '#999',
    fontWeight: 800,
    fontSize: 'medium',
    cursor: 'pointer',
    transition: 'transform 0.5s',
    '&:active': {
      transform: 'rotate(90deg)',
    },
  },
});
const { scrollable, autotip, radioable } = StyledTable.addons;
const PAGE_SIZE = 100;

/**
 * 用于转接座席、选择内部呼叫筛选座席用
 * 过滤座席自己、只筛选空闲和示忙的座席
 * @returns
 */
export const StaffList = (props: StaffListProps) => {
  const classes = useStyles();
  const { userId: seatUserId } = useSelector((store: RootState) => store.userInfo);
  const appSettings = useSelector((state) => state.appSettings);
  const [staffList, setStaffList] = useState<Staff[]>([]);
  const [skillGroupList, setSkillGroupList] = useState<SkillGroup[]>([]);
  const [currentSkillGroupId, setCurrentSkillGroupId] = useState('');
  const [currentSearchType, setCurrentSearchType] = useState<'staffNo' | 'userId' | 'staffName'>('staffNo');
  const [currentSearchValue, setCurrentSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [total, setTotal] = useState(0);
  const [requested, setRequested] = useState(false);
  const selectRef = useRef<HTMLElement>(null);
  const [selectBoxWidth, setSelectBoxWidth] = useState(0);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { t } = useTranslation();
  const [currentParams, setCurrentParams] = useState({
    pageNum: 0,
    pageSize,
    currentSkillGroupId: '',
    currentSearchType: 'userId',
    currentSearchValue: '',
  });

  const isDesk = useMemo(
    () => isDeskSeat(appSettings?.seatType, appSettings?.imAppType),
    [appSettings?.imAppType, appSettings?.seatType],
  );

  const StatusText = {
    '-1': t('离线'),
    0: t('离线'),
    100: t('示闲'),
    150: t('忙碌'),
    200: t('忙碌'),
    300: t('小休'),
    400: t('话后倒计时'),
    500: t('话后调整'),
    700: t('示忙'),
    900: t('离线'),
    undefined: t('未知状态'),
  };
  const getStaffList = useCallback(
    async ({
      pageNum,
      pageSize,
      currentSkillGroupId,
      currentSearchType,
      currentSearchValue,
    }: StaffListParams): Promise<Staff[]> => {
      setPageNum(pageNum);
      setPageSize(pageSize);
      setCurrentSearchType(currentSearchType);
      setCurrentSearchValue(currentSearchValue);
      setCurrentSkillGroupId(currentSkillGroupId);
      setRequested(true);
      const staffNo = currentSearchType === 'staffNo' ? currentSearchValue : '';
      const userId = currentSearchType === 'userId' ? currentSearchValue : '';
      const staffName = currentSearchType === 'staffName' ? currentSearchValue : '';

      const [{ staffList, total }, { staffList: staffList2 }] = await Promise.all([
        request('/tcccadmin/staff/getStaffList', {
          pageNum,
          pageSize,
          skillGroupId: currentSkillGroupId,
          userId,
          staffName,
          staffNo,
          isNeedStatus: true,
          status: [100, 700],
        }),
        request('/tcccadmin/staff/getStaffList', {
          pageNum,
          pageSize,
          skillGroupId: currentSkillGroupId,
          userId,
          staffName,
          staffNo,
          useMobileAcceptType: [1, 2],
        }),
      ]);
      setRequested(false);
      setTotal(+total);
      const list = staffList
        .reduce((acc, cur) => {
          const staff = find(acc, (item) => item.userId === cur.userId);
          if (staff) {
            return acc;
          }
          return [cur, ...acc];
        }, staffList2)

        ?.filter((i) => i.userId !== seatUserId)
        .sort((a, b) => {
          if (a.status && b.status) {
            if (+a.status === 0 || +b.status === 0) return 0;
            return +a.status - +b.status;
          }
          return 1;
        });
      return list;
    },
    [seatUserId],
  );
  const getSkillGroup = useCallback(
    (): Promise<SkillGroup[]> =>
      request('/tcccadmin/tech/getSkillGroupList', {}).then(({ skillGroupList }) => skillGroupList),
    [],
  );

  const getInitialValue = useCallback(() => {
    setLoading(true);
    Promise.all([
      getStaffList({
        pageNum: 0,
        pageSize: PAGE_SIZE,
        currentSearchType: 'userId',
        currentSearchValue: '',
        currentSkillGroupId: '',
      }),
      getSkillGroup(),
    ])
      .then(([staffList, skillGroupList]) => {
        setStaffList(staffList);
        setSkillGroupList(sortBy(skillGroupList, 'skillGroupType'));
        setLoading(false);
        setError(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [getStaffList, getSkillGroup]);

  const onHandleSearchChange = useCallback(
    debounce(
      ({ pageNum, pageSize, currentSkillGroupId, currentSearchType, currentSearchValue }: StaffListParams) => {
        setLoading(true);
        props.onSelected('');
        setCurrentParams({
          pageNum,
          pageSize,
          currentSkillGroupId,
          currentSearchValue,
          currentSearchType,
        });
        const params = refresh
          ? (currentParams as StaffListParams)
          : {
              pageNum,
              pageSize,
              currentSkillGroupId,
              currentSearchValue,
              currentSearchType,
            };
        getStaffList(params).then((staffList) => {
          setLoading(false);
          setRefresh(false);
          setStaffList(staffList);
        });
      },
      800,
      { leading: false, trailing: true },
    ),
    [getStaffList, refresh],
  );

  const handleRefresh = () => {
    setRefresh(true);
    onHandleSearchChange(currentParams as StaffListParams);
  };

  useEffect(() => {
    getInitialValue();
  }, [getInitialValue]);

  useEffect(() => {
    if (selectRef.current?.offsetWidth) {
      setSelectBoxWidth(selectRef.current.offsetWidth);
    }
  }, []);
  useEffect(() => {
    props?.onChangeStaffList?.(staffList);
  }, [staffList, props]);
  return (
    <>
      <StyledTable.ActionPanel className={classes.actionPanel}>
        {!isDesk && (
          <Select
            clearable
            searchable
            ref={selectRef}
            boxStyle={{
              width: selectBoxWidth,
            }}
            className={classes.skillGroupSelect}
            type={'simulate'}
            appearance={'button'}
            groups={{
              TEL: t('电话技能组'),
              IM: t('在线技能组'),
              AUDIO: t('音频技能组'),
              VIDEO: t('视频技能组'),
            }}
            options={skillGroupList.map(({ skillGroupId, skillGroupName, skillGroupType }) => ({
              groupKey: SkillGroupType[skillGroupType],
              value: skillGroupId,
              text: skillGroupName,
            }))}
            placeholder={t('全部技能组')}
            value={currentSkillGroupId}
            onChange={(value) => {
              setRefresh(false);
              onHandleSearchChange({
                pageNum: 0,
                pageSize,
                currentSearchType,
                currentSkillGroupId: value ? value : '',
                currentSearchValue,
              });
            }}
          />
        )}
        <InputAdornment
          className={classes.adornment}
          before={
            <Select
              className={classes.beforeSelect}
              defaultValue={'userId'}
              value={currentSearchType}
              onChange={(value: 'userId' | 'staffName' | 'staffNo') => {
                setRefresh(false);
                onHandleSearchChange({
                  pageNum: 0,
                  pageSize,
                  currentSearchType: value,
                  currentSkillGroupId,
                  currentSearchValue,
                });
              }}
              options={
                !isDesk
                  ? [
                      { text: t('工号'), value: 'staffNo' },
                      { text: t('邮箱'), value: 'userId' },
                      { text: t('姓名'), value: 'staffName' },
                    ]
                  : [{ text: t('邮箱'), value: 'userId' }]
              }
            />
          }
        >
          <Input
            className={classes.searchBox}
            value={currentSearchValue}
            onChange={(value) => {
              setCurrentSearchValue(value);
              setRefresh(false);
              onHandleSearchChange({
                pageNum: 0,
                pageSize,
                currentSearchType,
                currentSkillGroupId,
                currentSearchValue: value,
              });
            }}
          />
        </InputAdornment>
      </StyledTable.ActionPanel>
      <StyledTable
        records={staffList}
        recordKey={'userId'}
        bordered
        addons={[
          autotip({
            isLoading: loading,
            emptyText: !isDesk ? t('暂无空闲座席可以转接') : t('暂无空闲客服可以转接'),
            isError: error,
            onRetry() {
              getInitialValue();
            },
          }),
          scrollable({
            maxHeight: 265,
            onScrollBottom() {
              if (staffList.length < total && !requested) {
                getStaffList({
                  pageNum: pageNum + 1,
                  pageSize,
                  currentSkillGroupId,
                  currentSearchValue,
                  currentSearchType,
                }).then((staffList) => {
                  setStaffList((preStaffList) => [...preStaffList, ...staffList]);
                });
              }
            },
          }),
          radioable({
            width: 30,
            rowSelect: true,
            value: props.value,
            onChange(key) {
              props.onSelected(key);
            },
          }),
        ]}
        columns={
          !isDesk
            ? [
                {
                  key: 'staffNo',
                  header: t('工号'),
                  width: '20%',
                },
                {
                  key: 'staffName',
                  header: t('姓名'),
                  width: '25%',
                },
                {
                  key: 'userId',
                  header: t('邮箱'),
                  width: '30%',
                },
                {
                  key: 'status',
                  header: (
                    <div className={classes.refreshContainer}>
                      <span>{t('状态')}</span>
                      <Refresh className={classes.refresh} onClick={() => handleRefresh?.()} />
                    </div>
                  ),
                  render: ({ status, useMobile }: { status: any; useMobile: string }) => {
                    let statusText = StatusText[status as unknown as keyof typeof StatusText];
                    if (useMobile === '1' || useMobile === '2') {
                      statusText = t('手机接听');
                    }
                    return (
                      <span
                        style={{ color: StatusColor[status as unknown as keyof typeof StatusColor], fontWeight: 800 }}
                      >
                        {statusText}
                      </span>
                    );
                  },
                },
              ]
            : [
                {
                  key: 'nickName',
                  header: t('昵称'),
                  width: '25%',
                },
                {
                  key: 'userId',
                  header: t('邮箱'),
                  width: '30%',
                },
                {
                  key: 'status',
                  header: (
                    <div className={classes.refreshContainer}>
                      <span>{t('状态')}</span>
                      <Refresh className={classes.refresh} onClick={() => handleRefresh?.()} />
                    </div>
                  ),
                  render: ({ status }: { status: any }) => (
                    <span
                      style={{ color: StatusColor[status as unknown as keyof typeof StatusColor], fontWeight: 800 }}
                    >
                      {StatusText[status as unknown as keyof typeof StatusText]}
                    </span>
                  ),
                },
              ]
        }
      />
    </>
  );
};
