import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { unwrapResult } from 'tccc-sdk';

import sdkMessage, { transformCallInfoToSdk } from 'src/agentSdkIframe/sdkMessage';
import { t } from 'src/i18n';
import { debugLogRequest } from 'src/services';
import { request } from 'src/services/httpClient';
import { RootState, selectBySessionId } from 'src/store';
import { ForwardInfo, ReceiveIvrStatus } from 'src/store/slices/sessions';
import type { Session } from 'src/store/slices/sessions';

import { deviceErrorInfo } from 'src/utils/trtcHelper';

import tccc from '../../../utils/tccc';

enum Direction {
  callIn,
  callOut,
}

export const updateForwardStatus = createAction<{
  sessionId: string;
  forwardInfo: ForwardInfo;
}>('sessions/updateForwardStatus');
export const setIvrSoundPlayFinished = createAction<{
  sessionId: string;
}>('sessions/setIvrSoundPlayFinished');
export const updateReceiveStatus = createAction<{
  sessionId: string;
  receiveStatus?: ReceiveIvrStatus;
  keyPressed?: string;
  clientData?: string;
}>('sessions/updateReceiveStatus');
export const updatePhoneRemark = createAsyncThunk(
  'sessions/updatePhoneCallerRemark',
  async ({ userMark, sessionId }: { userMark: string; sessionId: string }) => {
    await request('/ccc/remark/setRemark', {
      sessionId,
      remark: userMark,
    });
    return {
      sessionId,
      remark: userMark,
    };
  },
);

export const forwardOut = createAsyncThunk(
  'sessions/forwardOut',
  ({ servingNumber, sessionId }: { servingNumber: string; sessionId: string }) =>
    tccc.Call.inviteCall({ sessionId, phoneNumber: servingNumber }),
);

export const cancelForwardOut = createAsyncThunk(
  'sessions/cancelForwardOut',
  ({ servingNumber, sessionId }: { servingNumber: string; sessionId: string }) =>
    tccc.Call.cancelInviteCall({ sessionId, phoneNumber: servingNumber }),
);
export const leaveForwardOut = createAsyncThunk(
  'sessions/leaveForwardOut',
  async ({ sessionId }: { sessionId: string }, { getState }) => {
    const store = getState() as RootState;
    const session = selectBySessionId(store, sessionId);
    if (!session) {
      const message = `sessionId: ${sessionId} does not exist`;
      debugLogRequest(message);
      throw new Error(`sessionId: ${sessionId} does not exist`);
    }
    await tccc.Call.leaveCall({ sessionId });
    const duration = Math.floor(session.timestamp ? Date.now() / 1000 - session.timestamp : 0);
    sdkMessage.postEventMessage(
      sdkMessage.events.sessionEnded,
      transformCallInfoToSdk(
        {
          ...session,
          closeBy: 'seat',
          duration,
        },
        true,
      ),
    );
    return {
      sessionId,
    };
  },
);

export const restoreForwardOut = createAsyncThunk(
  'sessions/restoreForwardOut',
  ({ sessionId }: { sessionId: string }) => tccc.Call.restoreCall({ sessionId }),
);

export const triggerReceiveKey = createAsyncThunk(
  'sessions/triggerReceiveKey',
  ({
    ivrId,
    sessionId,
    clientData,
    param,
  }: {
    ivrId: string;
    sessionId: string;
    clientData?: string;
    param?: string;
  }) => tccc.Call.triggerReceiveKey({ sessionId, ivrId, clientData, param }),
);

export const triggerSelfService = createAsyncThunk(
  'sessions/triggerSelfService',
  ({
    selfId,
    sessionId,
    clientData,
    param,
  }: {
    selfId: number;
    sessionId: string;
    clientData?: string;
    param?: string;
  }) => tccc.Call.triggerSelfService({ sessionId, selfId, clientData, param }),
);

export const callHold = createAsyncThunk('sessions/callHold', ({ sessionId }: { sessionId: string }) =>
  tccc.Call.hold({ sessionId }),
);

export const cancelCallHold = createAsyncThunk('sessions/cancelCallHold', ({ sessionId }: { sessionId: string }) =>
  tccc.Call.unHold({ sessionId }),
);

export const startCall = createAsyncThunk(
  'sessions/startCall',
  async ({
    phoneNumber,
    phoneDesc,
    servingNum,
    sdkCall,
    ...callerArgs
  }: {
    phoneNumber: string;
    phoneDesc?: string;
    servingNum?: string;
    sdkCall?: boolean;
    uui?: string;
    skillGroupId?: string;
    callerPhoneNumber?: string;
    servingNumberGroupIds?: string[];
  }) => {
    try {
      await tccc.Devices.checkDevices();
    } catch (e) {
      debugLogRequest(`checkDevices Error: ${(e as Error).message}`);
      throw e;
    }
    const {
      sessionId,
      remark,
      calleeLocation,
      calleePhoneNumber,
      callerPhoneNumber,
      protectedCallee,
      protectedCaller,
      aiEnabled,
      serverType,
    } = await tccc.Call.startOutboundCall({
      phoneNumber,
      callerPhoneNumber: servingNum,
      remark: phoneDesc,
      ...callerArgs,
    }).then(unwrapResult);
    const session: Session = {
      sessionId,
      remark,
      calleeLocation,
      calleePhoneNumber,
      callerPhoneNumber,
      protectedCallee,
      protectedCaller,
      aiEnabled,
      status: '150',
      type: 'phone',
      direction: Direction.callOut,
      serverType,
    };
    const response: Session = {
      ...session,
      sdkCall,
    };
    return {
      ...response,
      timestamp: Math.floor(Date.now() / 1000),
    };
  },
);

export const dialBack = createAsyncThunk(
  'sessions/dialBack',
  async ({ sessionId: targetSessionId, phoneDesc }: { sessionId: string; phoneDesc?: string }) => {
    try {
      await tccc.Devices.checkDevices();
    } catch (e) {
      throw new Error(deviceErrorInfo(e, t('麦克风')));
    }
    const {
      sessionId,
      remark,
      callerPhoneNumber,
      calleePhoneNumber,
      protectedCaller,
      protectedCallee,
      aiEnabled,
      serverType,
    } = await tccc.Call.dialBack({ sessionId: targetSessionId, remark: phoneDesc }).then(unwrapResult);
    const session: Session = {
      sessionId,
      remark,
      callerPhoneNumber,
      calleePhoneNumber,
      protectedCaller,
      protectedCallee,
      aiEnabled,
      status: '150',
      type: 'dialBack',
      direction: Direction.callOut,
      serverType,
    };
    return {
      ...session,
      timestamp: Math.floor(Date.now() / 1000),
    };
  },
);

// 服务端静音
export const mute = createAsyncThunk('sessions/mute', async (params: { sessionId: string; memberId?: string }) =>
  tccc.Call.mute(params),
);
export const unmute = createAsyncThunk('sessions/unmute', async (params: { sessionId: string; memberId?: string }) =>
  tccc.Call.unmute(params),
);
