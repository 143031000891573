import { ReactChild } from 'react';

import every from 'lodash/every';
import some from 'lodash/some';

import { useSelector } from 'react-redux';

import type { SeatTypeRelatedFeatures } from 'src/config/seatTypeToFeatureToggles';
import store from 'src/store';

function matchFeatureToggle(
  props: {
    featureToggle?: Partial<SeatTypeRelatedFeatures> | undefined;
    matchCondition?: 'some' | 'every' | undefined;
  },
  featureFlags: SeatTypeRelatedFeatures,
) {
  const matchCondition = props.matchCondition || 'some';
  const matchFuction = matchCondition === 'some' ? some : every;
  const match = props.featureToggle
    ? matchFuction(Object.entries(props.featureToggle), ([key, value]) => {
        if (featureFlags[key as keyof SeatTypeRelatedFeatures] === value) {
          return true;
        }
        return false;
      })
    : true;
  return match;
}

export const UnderFeatureToggle = (props: {
  featureToggle?: Partial<SeatTypeRelatedFeatures>;
  children: ReactChild | undefined | false;
  matchCondition?: 'some' | 'every';
}) => {
  const featureFlags = useSelector((s) => s.appSettings.featureFlag);
  const match = matchFeatureToggle(props, featureFlags);
  if (!match) {
    return null;
  }
  return <>{props.children}</>;
};

export const useFeatureToggle = (featureToggle: Partial<SeatTypeRelatedFeatures>) => {
  const featureFlags = useSelector((s) => s.appSettings.featureFlag);
  const match = matchFeatureToggle({ featureToggle }, featureFlags);
  return match;
};

export const checkFeatureEnabled = (featureToggle: Partial<SeatTypeRelatedFeatures>) => {
  const state = store.getState();
  const featureFlags = state.appSettings.featureFlag;
  const match = matchFeatureToggle({ featureToggle }, featureFlags);
  return match;
};
