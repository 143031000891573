import { ButtonBase, SvgIcon, styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const Button = styled(ButtonBase)({
  display: 'flex',
  flexDirection: 'column',
  color: '#fff',
  '& span': {
    marginTop: 8,
  },
});

const Svg = styled(SvgIcon)({
  width: 48,
  height: 48,
});
export const MicButton = (props: { open: boolean; onClick: React.MouseEventHandler }) => {
  const { t } = useTranslation();
  return (
    <Button disableRipple onClick={props.onClick}>
      <Svg width="48" height="48" viewBox="0 0 48 48" fill="none">
        {props.open ? (
          <>
            <g filter="url(#filter0_b_10856_5836)">
              <rect width="48" height="48" rx="24" fill="white" />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.4588 22.5883C19.4588 25.1867 21.4609 27.2942 23.9294 27.2942C26.3979 27.2942 28.4 25.1867 28.4 22.5883V17.8824C28.4 15.284 26.3979 13.1765 23.9294 13.1765C21.4609 13.1765 19.4588 15.284 19.4588 17.8824V22.5883ZM30.0235 22.6493C30.0235 22.2647 30.3295 21.953 30.7059 21.953C31.0822 21.953 31.3882 22.2647 31.3882 22.6493L31.3868 22.7986C31.313 26.6784 28.3702 29.855 24.6353 30.2138V32.7059H27.851C28.2839 32.7059 28.6353 33.0222 28.6353 33.4118C28.6353 33.8015 28.2839 34.1177 27.851 34.1177H20.0078C19.5749 34.1177 19.2235 33.8015 19.2235 33.4118C19.2235 33.0222 19.5749 32.7059 20.0078 32.7059H23.2235V30.2138C19.4408 29.8504 16.4706 26.5967 16.4706 22.6493C16.4706 22.2647 16.7766 21.953 17.1529 21.953C17.5293 21.953 17.8353 22.2647 17.8353 22.6493C17.8353 26.0707 20.5689 28.8549 23.9294 28.8549C27.2899 28.8549 30.0235 26.0707 30.0235 22.6493Z"
              fill="#333333"
            />
            <defs>
              <filter
                id="filter0_b_10856_5836"
                x="-92.9849"
                y="-92.9849"
                width="233.97"
                height="233.97"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="46.4924" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_10856_5836" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_10856_5836" result="shape" />
              </filter>
            </defs>
          </>
        ) : (
          <>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_b_10856_6177)">
                <rect width="48" height="48" rx="24" fill="white" fill-opacity="0.2" />
              </g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.9863 26.8273C20.4908 26.0661 19.4589 24.4537 19.4589 22.5883V17.8824C19.4589 15.284 21.461 13.1765 23.9295 13.1765C26.398 13.1765 28.4001 15.284 28.4001 17.8824V20.4135L21.9863 26.8273ZM20.8256 27.9881C19.0367 26.9055 17.8354 24.9174 17.8354 22.6493C17.8354 22.2647 17.5294 21.953 17.1531 21.953C16.7767 21.953 16.4707 22.2647 16.4707 22.6493C16.4707 25.2949 17.8049 27.6289 19.8237 28.99L20.8256 27.9881ZM21.1215 29.6886L22.2075 28.6027C22.7538 28.7668 23.3317 28.8549 23.9295 28.8549C27.29 28.8549 30.0236 26.0707 30.0236 22.6493C30.0236 22.2647 30.3297 21.953 30.706 21.953C31.0823 21.953 31.3884 22.2647 31.3884 22.6493L31.3869 22.7986C31.3132 26.6784 28.3703 29.855 24.6354 30.2138V32.7059H27.8511C28.284 32.7059 28.6354 33.0222 28.6354 33.4118C28.6354 33.8015 28.284 34.1177 27.8511 34.1177H20.008C19.575 34.1177 19.2236 33.8015 19.2236 33.4118C19.2236 33.0222 19.575 32.7059 20.008 32.7059H23.2236V30.2138C22.4868 30.143 21.7808 29.9625 21.1215 29.6886ZM23.5342 27.276L28.4001 22.4101V22.5883C28.4001 25.1867 26.398 27.2942 23.9295 27.2942C23.7963 27.2942 23.6644 27.288 23.5342 27.276Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.6166 17.3854C29.8923 17.1097 30.3392 17.1097 30.6149 17.3854V17.3854C30.8905 17.661 30.8905 18.108 30.6149 18.3836L17.6374 31.3611C17.3617 31.6368 16.9148 31.6368 16.6391 31.3611V31.3611C16.3634 31.0855 16.3634 30.6385 16.6391 30.3629L29.6166 17.3854Z"
                fill="white"
              />
              <defs>
                <filter
                  id="filter0_b_10856_6177"
                  x="-92.9849"
                  y="-92.9849"
                  width="233.97"
                  height="233.97"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImage" stdDeviation="46.4924" />
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_10856_6177" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_10856_6177" result="shape" />
                </filter>
              </defs>
            </svg>
          </>
        )}
      </Svg>
      <span>{props.open ? t('关闭麦克风') : t('开启麦克风')}</span>
    </Button>
  );
};
