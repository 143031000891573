import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStylesFacebook = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  top: {
    color: '#fff',
  },
  bottom: {
    color: '#006EFF',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
});

function FacebookProgress(props) {
  const classes = useStylesFacebook();
  const style = {};

  if (props.color) {
    style.color = props.color;
  }
  return (
    <div className={classes.root}>
      <CircularProgress variant="determinate" value={100} className={classes.top} {...props} />
      <CircularProgress variant="indeterminate" disableShrink className={classes.bottom} style={style} {...props} />
    </div>
  );
}

export default FacebookProgress;
