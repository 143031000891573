import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserStatusEnum } from 'tccc-utils';

import { UserStatusMap } from 'src/constants/agent';
import i18n from 'src/i18n';

import logger from 'src/utils/logger';

import tccc from 'src/utils/tccc';

import { hideConfirmDialog } from '../../components/universal/ConfirmDialogTips/ConfirmDialogTips';
import { debugLogRequest } from '../../services';
import { RootState } from '../index';
import { changeStatus, clearAgentState } from '../slices/agent';

import { loginInstance } from '../slices/app.thunk';

import { clearIMState } from './im';
import { loading, TIPS_LOADING } from './loading';

const { t } = i18n;

export const offline = createAsyncThunk('offline', async (params, { dispatch }) => {
  dispatch(clearAgentState());
  dispatch(clearIMState());
  try {
    tccc.Agent.offline();
  } catch (e) {
    debugLogRequest('tccc sdk logout failed', (e as Error)?.message);
  }
  debugLogRequest('用户切换下线状态');
  return dispatch(
    changeStatus({
      status: UserStatusEnum.offline,
    }),
  );
});

export const online = createAsyncThunk('online', async (params, { getState, dispatch }) => {
  const {
    userInfo: { sdkAppId, userId },
    agent: { status, connectionState },
  } = getState() as RootState;
  if (status !== UserStatusMap.offline && connectionState === 'connected') {
    logger.warn('用户已经上线， 无需再次上线');
    return;
  }
  if (window.location.search.includes('fromSdk')) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('fromSdk');
    const newURL = new URL(window.location.href);
    newURL.search = searchParams.toString();
    window.location.replace(newURL);
    return;
  }
  hideConfirmDialog();
  dispatch(loading(true, t('上线中'), TIPS_LOADING));
  return dispatch(
    loginInstance({
      sdkAppId,
      userId,
    }),
  )
    .unwrap()
    .then((res) => {
      dispatch(loading(false, '', TIPS_LOADING));
      return res;
    })
    .catch((error) => {
      console.error('error', error);
      throw error;
    });
});
