import { createReducer, createAction } from '@reduxjs/toolkit';

export type Direction = 'IN' | 'OUT';
type Word = {
  word: string;
  start_time: number;
  end_time: number;
  stable_flag: 0 | 1;
};
export type AsrResult = {
  slice_type: 0 | 1 | 2;
  index: number;
  start_time: number;
  end_time: number;
  voice_text_str: string;
  word_size: number;
  word_list?: Word[];
};
export type Asr = {
  id: number;
  isRecognizing: boolean;
  text: string;
  direction: Direction;
};

export const updateRecognizedMessages = createAction<any>('updateRecognizedMessages');

const initialState: Record<string, Asr[]> = {};

export const asrReducer = createReducer(initialState, (builder) => {
  builder.addCase(updateRecognizedMessages, (state, action) => {
    state[action.payload.sessionId] = action.payload.result;
  });
});
