import { useCallback } from 'react';

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import { goldlog } from 'src/utils/goldlog';
import sessionManage from 'src/utils/sessionManage';

import { CheckDevicesSvg } from '../Assets/CheckDevices.svg';
import { SwitchAccountSvg } from '../Assets/SwitchAccount.svg';
const LogInteraction = goldlog.of('SEAT').forType('INTERACTION');

const useStyles = makeStyles((theme) => ({
  link: {
    marginRight: 32,
    fontSize: 14,
    fontFamily: theme.base.fontFamily.Regular,
    color: '#fff',
    opacity: 0.5,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function OnlineTopBar() {
  const classes = useStyles();
  const { t } = useTranslation();
  function unLine() {
    sessionManage.loginOut();
  }

  const handleCheckDeviceOnClick = useCallback((e) => {
    e.preventDefault();
    LogInteraction.doing('CLICK_JUMP').with(t('跳转设备检测'));
    window.open('/helper');
  }, []);
  return (
    <>
      <div className={classes.icon}>
        <CheckDevicesSvg />
      </div>
      <a className={classes.link} href={''} onClick={handleCheckDeviceOnClick}>
        {t('设备检测')}
      </a>
      <div className={classes.icon}>
        <SwitchAccountSvg />
      </div>
      <a
        className={classes.link}
        href={''}
        onClick={(e) => {
          e.preventDefault();
          unLine();
        }}
      >
        {t('切换账号')}
      </a>
    </>
  );
}

export default OnlineTopBar;
