import { styled, SvgIcon } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 8,
  height: 8,
});
export function DisabledSvg() {
  return (
    <Svg viewBox="0 0 8 8">
      <path fill="#E54545" d="M4 0A4 4 0 1 0 4 8A4 4 0 1 0 4 0Z" />
      <rect x="2" y="3.5" width="4" height="1" rx=".5" fill="#fff" />
    </Svg>
  );
}
