import { formatTel as commonFormatTel } from 'tccc-utils';

/**
 *  格式化显示号码
 * @param telStr{String|Number}
 * @param splitText{String}
 * @return {String}
 */
export function formatTel(telStr: string, splitText = ' ', useMask = false) {
  return commonFormatTel(telStr, useMask, splitText);
}

export function reverseFormatTel(telStr: string) {
  if (!telStr) {
    return '';
  }
  if (telStr.startsWith('0086')) {
    return telStr.replace(/\s+/g, '');
  }
  return `0086${telStr.replace(/\s+/g, '')}`;
}
