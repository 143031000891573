export const SETSYSTEMSETTINGS = 'SETSYSTEMSETTINGS';
export const ADDCRMCOMPONENT = 'ADDCRMCOMPONENT';
export const SETCRMTABINDEX = 'SETCRMTABINDEX';
export const DELCRMCOMPONENT = 'DELCRMCOMPONENT';
export function setSystemSettings(payload) {
  return {
    type: SETSYSTEMSETTINGS,
    payload,
  };
}

export function addCRMComponent(payload) {
  return {
    type: ADDCRMCOMPONENT,
    payload,
  };
}

export function setCRMTabIndex(payload) {
  return {
    type: SETCRMTABINDEX,
    payload,
  };
}

export function delCRMComponent(payload) {
  return {
    type: DELCRMCOMPONENT,
    payload,
  };
}
