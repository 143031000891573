import { ButtonBase, SvgIcon, styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const Button = styled(ButtonBase)({
  display: 'flex',
  flexDirection: 'column',
  color: '#fff',
  '& span': {
    marginTop: 8,
  },
});

const Svg = styled(SvgIcon)({
  width: 48,
  height: 48,
});
export const CameraButton = (props: { open: boolean; onClick: React.MouseEventHandler }) => {
  const { t } = useTranslation();
  return (
    <Button disableRipple onClick={props.onClick}>
      <Svg width="48" height="48" viewBox="0 0 48 48" fill="none">
        {props.open ? (
          <>
            <g filter="url(#filter0_b_10856_5852)">
              <rect width="48" height="48" rx="24" fill="white" />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.1415 16.9412C14.3161 16.9412 13.6471 17.6102 13.6471 18.4356V29.5644C13.6471 30.3897 14.3161 31.0588 15.1415 31.0588H27.6821C28.5074 31.0588 29.1765 30.3897 29.1765 29.5644V18.4356C29.1765 17.6102 28.5074 16.9412 27.6821 16.9412H15.1415ZM16.2353 18.8235C15.8455 18.8235 15.5294 19.1395 15.5294 19.5294C15.5294 19.9192 15.8455 20.2353 16.2353 20.2353H18.5882C18.9781 20.2353 19.2941 19.9192 19.2941 19.5294C19.2941 19.1395 18.9781 18.8235 18.5882 18.8235H16.2353ZM35.0735 19.4791C35.0735 18.996 34.5477 18.6963 34.1321 18.9426L30.4608 21.118L30.3382 21.1906V21.3331V26.4919V26.6294L30.4544 26.703L34.1161 29.024C34.5313 29.2872 35.0735 28.9889 35.0735 28.4973V19.4791ZM34.3869 19.3728C34.4693 19.324 34.5735 19.3834 34.5735 19.4791V28.4973C34.5735 28.5947 34.4661 28.6539 34.3838 28.6017L30.8382 26.3543V21.4755L34.3869 19.3728Z"
              fill="#333333"
            />
            <defs>
              <filter
                id="filter0_b_10856_5852"
                x="-90.2895"
                y="-90.2895"
                width="228.579"
                height="228.579"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="45.1448" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_10856_5852" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_10856_5852" result="shape" />
              </filter>
            </defs>
          </>
        ) : (
          <>
            <g filter="url(#filter0_b_10856_6573)">
              <rect width="48" height="48" rx="24" fill="white" fill-opacity="0.2" />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.647 18.4506C13.647 17.617 14.3228 16.9412 15.1565 16.9412H27.6669C28.2911 16.9412 28.8267 17.32 29.0566 17.8603L15.858 31.0588H15.1565C14.3228 31.0588 13.647 30.383 13.647 29.5493V18.4506ZM17.8546 31.0588H27.6669C28.5006 31.0588 29.1764 30.383 29.1764 29.5493V19.737L17.8546 31.0588ZM15.5293 19.5294C15.5293 19.1395 15.8454 18.8235 16.2352 18.8235H18.5881C18.978 18.8235 19.294 19.1395 19.294 19.5294C19.294 19.9192 18.978 20.2353 18.5881 20.2353H16.2352C15.8454 20.2353 15.5293 19.9192 15.5293 19.5294ZM34.1263 18.946C34.5445 18.6982 35.0734 18.9997 35.0734 19.4858V28.4905C35.0734 28.985 34.5279 29.2851 34.1102 29.0203L30.4543 26.703L30.3382 26.6294V26.4919V21.3331V21.1906L30.4607 21.118L34.1263 18.946ZM34.5734 19.4858C34.5734 19.3871 34.466 19.3259 34.3811 19.3762L30.8382 21.4755V26.3543L34.3779 28.598C34.4627 28.6518 34.5734 28.5909 34.5734 28.4905V19.4858Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.9302 16.1043C29.2031 15.8313 29.6505 15.8363 29.9235 16.1092C30.1964 16.3822 30.2013 16.8296 29.9284 17.1025L14.6117 32.4193C14.3388 32.6922 13.8913 32.6872 13.6184 32.4143C13.3455 32.1414 13.3405 31.6939 13.6134 31.421L28.9302 16.1043Z"
              fill="white"
            />
            <defs>
              <filter
                id="filter0_b_10856_6573"
                x="-91.2016"
                y="-91.2016"
                width="230.403"
                height="230.403"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="45.6008" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_10856_6573" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_10856_6573" result="shape" />
              </filter>
            </defs>
          </>
        )}
      </Svg>
      <span>{props.open ? t('关闭摄像头') : t('开启摄像头')}</span>
    </Button>
  );
};
