import { makeStyles } from '@material-ui/core';
import { Button, Form, Input, Text } from '@tencent/tea-component';
import { useField, useForm } from 'react-final-form-hooks';
import { useTranslation } from 'react-i18next';
import { getTeaFormStatus as getStatus } from 'tccc-utils';

export interface FormProps {
  extensionId: string;
  password: string;
}

const useStyles = makeStyles(() => ({
  formAction: { width: '100%' },
}));

interface ExtensionLoginFormProps {
  onSubmit: (values: FormProps) => Promise<void>;
  onSuccess: (values: FormProps) => void;
  onError: (value: any) => void;
  onClose: () => void;
}

const reg = /^[1-8][0-9]{3,5}$/;

export const ExtensionLoginForm = ({ onSubmit, onSuccess, onError, onClose }: ExtensionLoginFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const _handleSubmit = async (values: FormProps) => {
    try {
      await onSubmit(values);
      onSuccess(values);
    } catch (e) {
      onError({ error: e, params: values });
    }
  };
  const validate = ({ extensionId, password }: FormProps) => {
    const errors: any = {};
    if (!extensionId) {
      errors.extensionId = t('分机号必填');
    }
    if (extensionId && !extensionId.match(reg)) {
      errors.extensionId = t('分机号格式错误');
    }
    if (!password) {
      errors.password = t('话机名称不能为空');
    }

    return errors;
  };
  const { form, handleSubmit, submitting, validating } = useForm({
    onSubmit: _handleSubmit,
    validate,
    initialValues: { extensionId: '' },
  });

  const extensionIdField = useField('extensionId', form);
  const passwordField = useField('password', form);

  const { status, message } = getStatus(extensionIdField.meta, validating) || {};
  return (
    <form onSubmit={handleSubmit}>
      <Form>
        <Form.Item
          required
          label={t('分机号')}
          status={status}
          message={
            <>
              {status === 'error' && <Text parent={'p'}>{message}</Text>}
              <Text theme={'label'}>{t('请输入1到8开头，4到6位数字的分机号')}</Text>
            </>
          }
        >
          <Input size={'full'} {...extensionIdField.input} autoComplete="off" />
        </Form.Item>
        <Form.Item required label={t('密码')} {...getStatus(passwordField.meta, validating)}>
          <Input.Password rules={false} size={'full'} {...passwordField.input} autoComplete="new-password" />
        </Form.Item>
      </Form>
      <Form.Action className={classes.formAction}>
        <Button type={'primary'} htmlType={'submit'} disabled={submitting} loading={submitting}>
          {t('登录')}
        </Button>
        <Button
          disabled={submitting}
          onClick={(e) => {
            e?.stopPropagation();
            onClose();
          }}
        >
          {t('取消')}
        </Button>
      </Form.Action>
    </form>
  );
};
