import { isRejected } from '@reduxjs/toolkit';
import { message } from '@tencent/tea-component';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getServerTypeName } from 'src/components/SessionStatusDisplay/SessionStatusDisplay';
import { Timing } from 'src/components/Timing/Timing';

import type { RootState } from 'src/store';
import { Direction, ReceiveIvrStatus, Session } from 'src/store/slices/sessions';
import { callHold, cancelCallHold, updateReceiveStatus } from 'src/store/slices/sessions/pstn';

import { enablePhoneNumReflect } from 'src/utils/checkIsPhoneNumReflectEnabled';

import { changeMicStatus, exitMonitorRTC } from '../../../store/slices/sessions/rtc';
import { endSession } from '../../../store/slices/sessions/sessions.thunk';
import { formatTel } from '../../../utils/formatTel';

type PhoneType = 'monitor' | 'phone' | 'dialBack' | 'internal' | 'voip';

export function useCallOut({ callInfo }: { callInfo?: Session }) {
  const { t } = useTranslation();
  const {
    type: callOutType,
    status,
    sessionId,
    receiveStatus,
    holdStatus,
    direction,
    callerPhoneNumber,
    calleePhoneNumber,
    micStatus,
    protectedCallee,
    protectedCaller,
  } = callInfo || {};
  const { hideCalloutNumber, hideCallinNumber, numberReflectMode } = useSelector(
    (store: RootState) => store.appSettings,
  );
  const hideCallNumber = direction === Direction.callIn ? hideCallinNumber : hideCalloutNumber;
  const phoneNumber = direction === Direction.callIn ? callerPhoneNumber : calleePhoneNumber;
  const protectedPhoneNumber = direction === Direction.callIn ? protectedCaller : protectedCallee;
  const dispatch = useDispatch();

  const throttleEndSession = throttle(
    async () => {
      if (!sessionId) return;
      if (callOutType === 'monitor') {
        dispatch(exitMonitorRTC());
        return;
      }
      const res = await dispatch(endSession({ closeBy: 'seat', sessionId }));
      if (isRejected(res)) {
        message.error({
          content: t('挂断异常，请立刻重试，{{0}}', { 0: res.error.message }),
        });
      }
    },
    3000,
    { leading: true, trailing: false },
  );

  const toggleHold = async () => {
    if (!sessionId) return;
    const res = await dispatch(holdStatus ? cancelCallHold({ sessionId }) : callHold({ sessionId }));
    if (isRejected(res)) {
      message.error({
        content: t('{{0}}操作失败，{{1}}', { 0: holdStatus ? t('通话取回') : t('通话保持'), 1: res.error.message }),
      });
    }
  };

  const toggleMicStatus = () => {
    if (!sessionId) return;
    dispatch(changeMicStatus({ sessionId, micStatus: micStatus === 'off' ? 'on' : 'off' }));
  };

  const getCallOutDisplayText = () => {
    if (!callInfo || !callOutType) return '';
    if (callOutType === 'internal') {
      return callInfo.userId;
    }
    if (callOutType === 'voip') {
      return callInfo.remark || callInfo.caller;
    }
    if (['phone', 'dialBack'].includes(callOutType)) {
      if (enablePhoneNumReflect(numberReflectMode)) return protectedPhoneNumber;
      return callInfo.remark || formatTel(phoneNumber || '', '', hideCallNumber);
    }
    if (callOutType === 'monitor') {
      return callInfo.remark;
    }
    return callInfo.userId || formatTel(phoneNumber || '', ' ', hideCallNumber) || t('网站用户');
  };

  const toggleReceiveFlag = () => {
    let status: ReceiveIvrStatus = '';
    if (receiveStatus === '') {
      status = 'pending';
    } else if (receiveStatus === 'pending') {
      status = '';
    } else if (receiveStatus === 'ok') {
      status = '';
    }
    if (!sessionId) return;
    dispatch(updateReceiveStatus({ sessionId, receiveStatus: status }));
  };

  const getCallTipsForPhone = (prefix: string, callInfo?: Session) => {
    if (callInfo?.direction === 0) {
      return t('{{0}}接入中', { 0: prefix });
    }
    if (callInfo?.serverType === 'staffSeat') {
      return (
        <>
          {`${t('呼叫中')}`}
          (<Timing startTime={callInfo.timestamp} />)
        </>
      );
    }
    return t('({{0}}呼出中)', { 0: prefix || t('电话') });
  };

  const getCallStatusText = () => {
    const prefix = callInfo?.serverType ? getServerTypeName(callInfo.serverType) : '';
    const callStatusTextMap: Record<PhoneType, Record<'150' | '200', string | JSX.Element>> = {
      monitor: {
        150: t('准备监听中'),
        200: t('通话监听中'),
      },
      phone: {
        150: getCallTipsForPhone(prefix, callInfo),
        200: t('正在通话中'),
      },
      dialBack: {
        150: t('{{0}}呼出中', { 0: prefix || t('回拨') }),
        200: t('{{0}}通话中', { 0: prefix || t('电话') }),
      },
      internal: {
        150: callInfo?.direction === 0 ? t('接入中') : t('{{0}}呼出中', { 0: prefix || t('内线') }),
        200: t('{{0}}通话中', { 0: prefix || t('内线') }),
      },
      voip: {
        150: t('音频接入中'),
        200: t('音频通话中'),
      },
    };
    if (!callOutType) return '';
    if (status === '150' || status === '200') {
      return callStatusTextMap[callOutType as PhoneType][status] || '';
    }
    return '';
  };
  const endCallTextMap: Record<PhoneType, string> = {
    phone: t('挂断'),
    monitor: t('退出监听'),
    internal: t('挂断'),
    dialBack: t('挂断'),
    voip: t('挂断'),
  };
  const endCallText = callOutType ? endCallTextMap[callOutType as PhoneType] : '';
  return {
    endCallFunc: throttleEndSession,
    endCallText,
    canCall: status === '400',
    callDisplayText: getCallOutDisplayText(),
    callingStatusText: getCallStatusText(),
    toggleHold,
    holdStatus,
    receiveStatus,
    toggleReceiveFlag,
    toggleMicStatus,
    numberReflectMode,
  };
}
