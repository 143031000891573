import { SvgIcon, styled } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 18,
  height: 18,
  '& path:not(:last-child)': {
    stroke: (props) => (props.active ? '#006eff' : '#666666'),
  },
  '& path:last-child': {
    fill: (props) => (props.active ? '#006eff' : '#666666'),
  },
  '& rect': {
    fill: (props) => (props.active ? '#006eff' : '#666666'),
  },
  '&:hover': {
    '& rect': {
      fill: '#006eff',
    },
    '& path:not(:last-child)': {
      stroke: '#006eff',
    },
    '& path:last-child': {
      fill: '#006eff',
    },
  },
});
export function TransferSvg(props) {
  return (
    <Svg viewBox={'0 0 18 18'} fontSize={'inherit'} color={'inherit'} {...props}>
      <path
        d="M12.9747 16.6102L12.8983 16.6202C11.2166 16.6906 8.8088 15.2543 6.45831 12.7735L5.58882 11.8596C3.23834 9.37879 1.90066 6.86789 2.00576 5.13034C2.0822 3.90502 3.69697 2.72992 4.67156 2.62948C5.1493 2.57926 5.34995 2.82031 5.41683 2.96092C6.05701 3.93515 6.89783 5.66265 6.95516 6.30545V6.35566L6.94561 6.39584C6.85006 6.74737 6.59208 6.93819 6.37232 7.09889C6.07612 7.31985 5.90413 7.46046 5.91369 7.81199C5.91369 7.92247 6.04745 8.40456 7.60489 10.0718L8.26417 10.7749C9.81205 12.3718 10.2707 12.5124 10.3758 12.5224C10.7198 12.5425 10.8631 12.3718 11.0828 12.0604C11.2453 11.8294 11.4364 11.5683 11.7803 11.4779L11.8281 11.4679L11.8759 11.4779C12.497 11.5583 14.1499 12.4622 15.1054 13.1552C15.2392 13.2355 15.4876 13.4766 15.3634 14.0792C15.1628 15.0233 14.0831 16.4997 12.9747 16.6102Z"
        stroke="#666666"
        stroke-width="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3774 1.15929L16.8547 2.77876C17.0484 2.9823 17.0484 3.32743 16.8547 3.53982L15.3774 5.15929C15.1837 5.37168 14.8769 5.37168 14.6831 5.15929C14.4894 4.9469 14.4894 4.61062 14.6831 4.39823L15.3209 3.69912H12.6327C12.3582 3.69912 12.1403 3.9469 12.1403 4.23894C12.1403 4.53982 12.3663 4.77876 12.6327 4.77876H12.8184C13.6337 4.77876 14.2876 5.49557 14.2876 6.38938C14.2876 7.28319 13.6256 8 12.8184 8H9.49243C9.21796 8 9 7.76106 9 7.46018C9 7.15929 9.22603 6.92035 9.49243 6.92035H12.8184C13.0928 6.92035 13.3108 6.67257 13.3108 6.38053C13.3108 6.07965 13.0848 5.84071 12.8184 5.84071H12.6327C11.8174 5.84071 11.1635 5.12389 11.1635 4.23009C11.1635 3.33628 11.8254 2.61947 12.6327 2.61947H15.3209L14.6831 1.92035C14.4894 1.70796 14.4894 1.37168 14.6831 1.15929C14.8769 0.946903 15.1837 0.946903 15.3774 1.15929Z"
        fill="#666666"
      />
    </Svg>
  );
}
