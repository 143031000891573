import * as t from 'io-ts';

import { numberFromString } from 'src/utils/codecs';

export const List = t.type({
  menuId: t.string,
  menuName: t.string,
  lastUpdateTime: t.number,
  menuContent: t.string,
  menuSendRuleFlag: t.string,
});

export const imAPIs = {
  '/tcccadmin/satisfaction/getList': {
    Input: t.type({
      pageSize: numberFromString,
      pageNum: numberFromString,
    }),
    Output: t.type({
      list: t.union([t.array(List), t.null]),
      total: numberFromString,
    }),
  },
  '/tcccadmin/satisfaction/add': {
    Input: t.type({
      menuName: t.string,
      menuContent: t.string,
      menuSendRuleFlag: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/satisfaction/update': {
    Input: t.type({
      menuId: t.string,
      menuName: t.string,
      menuContent: t.string,
      menuSendRuleFlag: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/satisfaction/del': {
    Input: t.type({
      menuId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/im/getIMUserProfile': {
    Input: t.type({
      UserIDs: t.array(t.string),
    }),
    Output: t.type({
      UserProfileItems: t.array(
        t.type({
          UserID: t.string,
          ErrorCode: t.number,
          Nick: t.string,
        }),
      ),
    }),
  },
};
