import React from 'react';

import { Badge, makeStyles, ButtonBase, Tooltip, CircularProgress } from '@material-ui/core';
import { message } from '@tencent/tea-component';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch, RootState } from 'src/store';
import { updateAssignAccept } from 'src/store/slices/app.thunk';

import { UnderFeatureToggle } from '../UnderFeatureToggle/UnderFeatureToggle';

import { CallSvg } from './CallSvg';
import { CheckedSvg } from './CheckedSvg';
import { DisabledSvg } from './DisabledSvg';
import { IMSvg } from './IMSvg';

const useStyles = makeStyles<{}, { fromSdk?: boolean }>({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '4px 0',
    flexDirection: (props) => (props.fromSdk ? 'column' : 'row'),
  },
  iconButton: {
    height: 24,
    width: 24,
    minWidth: 24,
    fontSize: 24,
    padding: 8,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#006eff',
    marginTop: (props) => (props.fromSdk ? 24 : 0),
    marginLeft: (props) => (props.fromSdk ? 0 : 16),
  },
  disabledIcon: {
    background: '#999',
  },
  iconButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  font: {
    fontSize: 12,
    marginTop: 4,
    marginLeft: (props) => (props.fromSdk ? 0 : 6),
    height: 22,
    color: '#27C565',
  },
  disabledFont: {
    color: '#999',
  },
  checked: {
    color: '#0abf5b',
  },
  disabled: {
    color: '#e54545',
  },
  call: {
    background: '#006eff',
  },
  badge: {
    right: '-36%',
    bottom: '-28%',
  },
  circular: {
    color: '#fff',
  },
});

export function AssignState(props: { fromSdk?: boolean }) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { assignCall, assignIM, existCall, existIM, IM } = useSelector((e: RootState) => e.agent);
  const isDemoAccount = useSelector((e: RootState) => e.userInfo.isDemoAccount);
  const [loading, setLoading] = React.useState<'im' | 'call'>();
  const dispatch = useAppDispatch();

  if (isDemoAccount) {
    return null;
  }
  const update = async (
    params:
      | {
          assignCall: boolean;
        }
      | {
          assignIM: boolean;
        },
  ) => {
    if (loading) return;
    if ('assignCall' in params) {
      if (!assignIM && !params.assignCall) {
        return message.error({
          content: t('请至少选择一种分配方式'),
        });
      }
      setLoading('call');
      await dispatch(updateAssignAccept({ assignCall: params.assignCall }));
      setLoading(undefined);
    } else {
      if (!assignCall && !params.assignIM) {
        return message.error({
          content: t('请至少选择一种分配方式'),
        });
      }
      setLoading('im');
      await dispatch(updateAssignAccept({ assignIM: params.assignIM }));
      setLoading(undefined);
    }
  };
  const callTipTitle = () => {
    if (loading === 'call') {
      if (assignCall) {
        return t('关闭中');
      }
      return t('开启中');
    }
    if (assignCall) {
      return t('关闭后，将不会分配新的电话类型会话');
    }
    return t('开启后，将分配电话类型会话');
  };
  const imTipsTitle = () => {
    if (loading === 'im') {
      if (assignIM) {
        return t('关闭中');
      }
      return t('开启中');
    }
    if (assignIM) {
      return t('关闭后，将不会分配新的在线类型会话');
    }
    return t('开启后，将分配在线类型会话');
  };
  /*
   * 分配开关的显示逻辑：
   * 0. 开关一旦显示，就不允许全部关闭
   * 1. 存在多种渠道时，必须显示开关
   * 2. 只存在一种渠道时，不需要显示开关
   * 3. 不存在渠道时(初始化/全部技能组都删掉了)，不需要显示开关
   */
  if (assignCall && assignIM && (!existCall || !existIM)) return null;
  return (
    <div className={classes.wrapper}>
      <UnderFeatureToggle featureToggle={{ phoneCallEnabled: true }}>
        <Tooltip title={callTipTitle()}>
          <ButtonBase
            className={classnames(classes.iconButton, {
              [classes.disabledIcon]: !assignCall && !loading,
            })}
            disabled={loading === 'im'}
            onClick={() => update({ assignCall: !assignCall })}
          >
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              classes={{
                badge: classes.badge,
              }}
              badgeContent={loading !== 'call' && (assignCall ? <CheckedSvg /> : <DisabledSvg />)}
              overlap="circular"
            >
              {loading === 'call' ? (
                <CircularProgress
                  classes={{
                    root: classes.circular,
                  }}
                  color="inherit"
                  size={20}
                />
              ) : (
                <CallSvg />
              )}
            </Badge>
          </ButtonBase>
        </Tooltip>
      </UnderFeatureToggle>
      <UnderFeatureToggle featureToggle={{ imEnabled: true }}>
        <Tooltip title={imTipsTitle()}>
          <ButtonBase
            className={classnames(classes.iconButton, {
              [classes.disabledIcon]: !assignIM && !loading,
            })}
            disabled={loading === 'call'}
            onClick={() => update({ assignIM: !assignIM })}
          >
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              classes={{
                badge: classes.badge,
              }}
              overlap="circular"
              badgeContent={loading !== 'im' && (assignIM ? <CheckedSvg /> : <DisabledSvg />)}
            >
              {loading === 'im' ? (
                <CircularProgress
                  classes={{
                    root: classes.circular,
                  }}
                  color="inherit"
                  size={20}
                />
              ) : (
                <IMSvg />
              )}
            </Badge>
          </ButtonBase>
        </Tooltip>
      </UnderFeatureToggle>
      <div
        className={classnames(classes.font, {
          [classes.disabledFont]: !assignIM,
        })}
      >
        {IM.total > 0 ? `${IM.used}/${IM.total}` : ''}
      </div>
    </div>
  );
}
