import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useStyles from 'src/components/IM/Message/Emojis/Emojis.style.js';

import { updateReplyInfo } from 'src/store/slices/tim.thunk';
import { getReplyMessage } from 'src/utils/message';

import { ImReplyWrapper, ImReplyContent, ImReplyFrom, ImReplyMessage, ImReplyCloseIcon } from './imReply.style';
export const ImReply = (props) => {
  const emojisClasses = useStyles();
  const { className, message, hideClose, from } = props;
  const dispatch = useDispatch();
  const parsedMessage = getReplyMessage(message, emojisClasses);
  const sessionId = useSelector((state) => state.userInfo.currentUser.sessionId);

  const handleImReplyCloseOnClick = useCallback(() => {
    dispatch(
      updateReplyInfo({
        sessionId,
        replyInfo: {
          replying: false,
        },
      }),
    );
  }, [dispatch, sessionId]);

  return (
    <ImReplyWrapper className={className} style={{ wordBreak: 'break-all' }}>
      <ImReplyContent>
        <ImReplyFrom>{from}</ImReplyFrom>: <ImReplyMessage>{parsedMessage}</ImReplyMessage>
      </ImReplyContent>
      {!hideClose && <ImReplyCloseIcon onClick={handleImReplyCloseOnClick} />}
    </ImReplyWrapper>
  );
};
