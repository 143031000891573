import * as t from 'io-ts';

import { nullableArray } from 'src/utils/codecs';

export const crmAPIs = {
  // 新版接口
  '/tcccadmin/crm/addClientProfileUDF': {
    Input: t.type({
      name: t.string,
      type: t.union([t.literal(1), t.literal(2), t.literal(3)]),
      options: nullableArray(t.string),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/updateClientProfileUDF': {
    Input: t.type({
      id: t.number,
      name: t.string,
      type: t.union([t.literal(1), t.literal(2), t.literal(3)]),
      options: nullableArray(t.string),
    }),
    Output: t.type({}),
  },
  // 获取客户自定义字段配置
  '/tcccadmin/crm/getClientProfileUDFConfig': {
    Input: t.type({}),
    Output: t.type({
      order: nullableArray(t.number),
      fields: nullableArray(
        t.type({
          id: t.number,
          name: t.string,
          type: t.number,
          options: nullableArray(t.string),
        }),
      ),
    }),
  },
  '/tcccadmin/crm/deleteClientProfileUDF': {
    Input: t.type({
      id: t.number,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/updateClientProfileUDFOrder': {
    Input: t.type({
      order: t.array(t.number),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/getClientProfile': {
    Input: t.partial({
      profileId: t.number,
      clientUserId: t.string,
      mobile: t.string,
      sourceType: t.number,
    }),
    Output: t.type({
      profileId: t.number,
      name: t.union([t.undefined, t.string]),
      mobile: t.string,
      sourceType: nullableArray(t.number),
      servedCnt: t.number,
      lastServedTime: t.number,
      location: t.string,
      udfData: nullableArray(
        t.type({
          id: t.number,
          value: nullableArray(t.string),
        }),
      ),
    }),
  },
  '/tcccadmin/crm/getClientProfileForStaff': {
    Input: t.partial({
      profileId: t.number,
      clientUserId: t.string,
      mobile: t.string,
      sourceType: t.number,
      sessionId: t.string,
    }),
    Output: t.type({
      profileId: t.number,
      name: t.union([t.undefined, t.string]),
      mobile: t.string,
      sourceType: nullableArray(t.number),
      servedCnt: t.number,
      lastServedTime: t.number,
      location: t.string,
      udfData: nullableArray(
        t.type({
          id: t.number,
          value: nullableArray(t.string),
        }),
      ),
    }),
  },
  '/tcccadmin/crm/getClientProfileList': {
    Input: t.intersection([
      t.type({
        pageSize: t.number,
        pageNumber: t.number,
      }),
      t.partial({
        fuzzingKeyWord: t.string,
        sourceType: t.number,
        nameKeyWord: t.string,
        mobileKeyWord: t.string,
        formTime: t.number,
        toTime: t.number,
      }),
    ]),
    Output: t.type({
      total: t.number,
      data: nullableArray(
        t.type({
          clientUserId: t.string,
          lastServedTime: t.number,
          location: t.string,
          mobile: t.string,
          name: t.string,
          profileId: t.number,
          remark: t.string,
          servedCnt: t.number,
          sourceType: nullableArray(t.number),
          udfData: nullableArray(
            t.type({
              id: t.number,
              value: nullableArray(t.string),
            }),
          ),
        }),
      ),
    }),
  },
  '/tcccadmin/crm/addClientProfile': {
    Input: t.partial({
      clientUserId: t.string,
      name: t.string,
      mobile: t.string,
      sessionId: t.string,
      sourceType: t.number,
      udfData: t.array(
        t.type({
          id: t.number,
          value: t.string,
        }),
      ),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/mergeClientProfile': {
    Input: t.partial({
      clientUserId: t.string,
      name: t.string,
      mobile: t.string,
      sourceType: t.array(t.number),
      tags: t.array(t.number),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/updateClientProfile': {
    Input: t.partial({
      profileId: t.number,
      clientUserId: t.string,
      sessionId: t.string,
      name: t.string,
      mobile: t.string,
      sourceType: t.number,
      udfData: t.array(
        t.type({
          id: t.number,
          value: t.array(t.string),
        }),
      ),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/delClientProfile': {
    Input: t.partial({
      profileId: t.number,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/addQAType': {
    Input: t.type({
      name: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/updateQAType': {
    Input: t.type({
      id: t.number,
      name: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/delQAType': {
    Input: t.type({
      id: t.number,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/getQATypeList': {
    Input: t.type({
      pageNumber: t.number,
      pageSize: t.number,
    }),
    Output: t.type({
      total: t.number,
      data: nullableArray(
        t.type({
          id: t.number,
          name: t.string,
        }),
      ),
    }),
  },
  '/tcccadmin/crm/addBriefSummary': {
    Input: t.type({
      sessionId: t.string,
      qaTypeId: t.number,
      detail: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/updateBriefSummary': {
    Input: t.type({
      id: t.number,
      sessionId: t.string,
      qaTypeId: t.number,
      detail: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/delBriefSummary': {
    Input: t.type({
      id: t.number,
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/crm/getBriefSummary': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({
      data: nullableArray(
        t.type({
          id: t.number,
          sessionId: t.string,
          qaTypeId: t.number,
          detail: t.string,
          modifiedTime: t.number,
        }),
      ),
    }),
  },
  '/tcccadmin/crm/getBriefSummaryForStaff': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({
      data: nullableArray(
        t.type({
          id: t.number,
          sessionId: t.string,
          qaTypeId: t.number,
          detail: t.string,
          modifiedTime: t.number,
        }),
      ),
    }),
  },
  '/tcccadmin/crm/getBriefSummaryListAggregateBySession': {
    Input: t.partial({
      fromTime: t.number,
      toTime: t.number,
      pageSize: t.number,
      pageNumber: t.number,
      mobileKeyWord: t.string,
      qaTypes: nullableArray(t.number),
    }),
    Output: t.type({
      data: nullableArray(
        t.type({
          sessionId: t.string,
          serveTime: t.number,
          mobile: t.string,
          qaTypeIds: nullableArray(t.number),
          seat: t.string,
          duration: t.number,
          source: t.number,
        }),
      ),
      total: t.number,
    }),
  },
  '/tcccadmin/crm/exportBriefSummaryListAggregateBySession': {
    Input: t.partial({
      fromTime: t.number,
      toTime: t.number,
      mobileKeyWord: t.string,
      qaTypes: nullableArray(t.number),
    }),
    Output: t.type({
      total: t.number,
      // FIXME: 字段
      data: nullableArray(t.type({})),
    }),
  },
  '/tcccadmin/crm/getCDRWithBriefSummary': {
    Input: t.intersection([
      t.partial({
        clientUserId: t.string,
        mobile: t.string,
        profileId: t.number,
      }),
      t.type({
        pageNum: t.number,
        pageSize: t.number,
      }),
    ]),
    Output: t.type({
      total: t.number,
      data: nullableArray(
        t.type({
          sessionId: t.string,
          sourceType: t.number,
          sourceTypeDesc: t.string,
          timestamp: t.number,
          mobile: t.string,
          duration: t.number,
          staffName: t.string,
          userId: t.string,
          summaries: nullableArray(
            t.type({
              id: t.number,
              qaTypeId: t.number,
              detail: t.string,
              modifiedTime: t.number,
            }),
          ),
        }),
      ),
    }),
  },
  '/tcccadmin/crm/getCDRWithBriefSummaryForStaff': {
    Input: t.intersection([
      t.partial({
        clientUserId: t.string,
        mobile: t.string,
        profileId: t.number,
      }),
      t.type({
        sessionId: t.string,
        pageNum: t.number,
        pageSize: t.number,
      }),
    ]),
    Output: t.type({
      total: t.number,
      data: nullableArray(
        t.type({
          sessionId: t.string,
          sourceType: t.number,
          sourceTypeDesc: t.string,
          timestamp: t.number,
          mobile: t.string,
          duration: t.number,
          staffName: t.string,
          userId: t.string,
          summaries: nullableArray(
            t.type({
              id: t.number,
              qaTypeId: t.number,
              detail: t.string,
              modifiedTime: t.number,
            }),
          ),
        }),
      ),
    }),
  },
  '/tcccadmin/crm/exportClientProfileList': {
    Input: t.partial({
      fuzzingKeyWord: t.string,
      sourceType: nullableArray(t.number),
      tagKeyWord: t.string,
      formTime: t.number,
      toTime: t.number,
      nameKeyWord: t.string,
      mobileKeyWord: t.string,
      tags: nullableArray(t.number),
    }),
    Output: t.type({
      total: t.number,
      data: nullableArray(
        t.type({
          profileId: t.number,
          clientUserId: t.string,
          name: t.string,
          mobile: t.string,
          remark: t.string,
          location: t.string,
          sourceType: nullableArray(t.number),
          tags: nullableArray(t.number),
        }),
      ),
    }),
  },
};
