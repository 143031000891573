import { createAsyncThunk, isRejected } from '@reduxjs/toolkit';
import { unwrapResult } from 'tccc-sdk';

import sdkMessage, { transformCallInfoToSdk } from 'src/agentSdkIframe/sdkMessage';
import sessionEnded from 'src/assets/audio/sessionEnded.mp3';
import { t } from 'src/i18n';
import { debugLogRequest } from 'src/services';
import { judgeInWhitelistByKey, request } from 'src/services/httpClient';
import type { RootState } from 'src/store';
import { selectAllSession, selectBySessionId } from 'src/store';
import { endIMSession } from 'src/store/slices/sessions/im';
import type { Session, SessionStatus } from 'src/store/slices/sessions/types';
import { CloseBy, Direction, isPhoneLikeType } from 'src/store/slices/sessions/types';
import Notification from 'src/utils/browserNotification';
import logger from 'src/utils/logger';
import { timer } from 'src/utils/timing';

import tccc from '../../../utils/tccc';
import { endMedia, getMessageList, setMessageRead } from '../tim.thunk';

import { PhoneEndStatus } from '.';

/**
 * 前端计算真实挂断类型，目前只有主动挂断场景需要修改为209
 */
export const getHangupType = (session: Session, hangupType: PhoneEndStatus, closeBy: CloseBy) => {
  if (closeBy === 'timer' || closeBy === 'system') {
    return 1;
  }
  if (closeBy === 'seat' && ['100', '150'].includes(session.status)) {
    return 209;
  }
  return hangupType;
};

const playSessionEndAudio = async (sdkAppId: string) => {
  const shouldPlay = await judgeInWhitelistByKey('addSessionEndedAudioWhitelist', sdkAppId);
  if (!shouldPlay) {
    return;
  }
  const sessionEndedAudio = new Audio(sessionEnded);
  sessionEndedAudio.volume = 0.4;
  const promise = sessionEndedAudio.play();
  if (promise !== undefined) {
    promise.catch((error) => {
      logger.error('playSessionEndAudio auto play failed', error);
    });
  }
};

export const transferSession = createAsyncThunk(
  'sessions/transferSession',
  async (
    {
      skillGroupId,
      sessionId,
      userId,
      phone,
      allowQueue,
    }: { userId?: string; skillGroupId?: string; sessionId: string; phone?: string; allowQueue?: boolean },
    { getState },
  ) => {
    const store = getState() as RootState;
    const session = selectBySessionId(store, sessionId);
    if (!session) {
      throw new Error(t('转接失败, sessionId: {{sessionId}} 不存在', { sessionId }));
    }
    if (isPhoneLikeType(session.type)) {
      if (userId) {
        await tccc.Call.transfer({ sessionId, userId, allowQueue });
      } else if (skillGroupId) {
        await tccc.Call.transfer({ sessionId, skillGroupId, allowQueue });
      } else if (phone) {
        await tccc.Call.transfer({ sessionId, phone, allowQueue });
      } else {
        throw new Error('userId or skillGroupId must not be empty');
      }
    } else if (session.type === 'im') {
      if (userId) {
        await tccc.Chat.transfer({ sessionId, userId });
      } else if (skillGroupId) {
        await tccc.Chat.transfer({ sessionId, skillGroupId });
      } else {
        throw new Error('userId or skillGroupId must not be empty');
      }
    } else if (session.type === 'video') {
      if (userId) {
        await tccc.Video.transfer({ sessionId, userId });
      } else if (skillGroupId) {
        await tccc.Video.transfer({ sessionId, skillGroupId });
      } else {
        throw new Error('userId or skillGroupId must not be empty');
      }
    } else {
      throw new Error(t('不能转接该类型的会话'));
    }
    sdkMessage.postEventMessage(sdkMessage.events.transfer, {
      staff: { userId: store.userInfo.userId },
      attendee: userId ? { userId, sessionId } : {},
      skillGroupId: skillGroupId || '',
      userId: userId || '',
      sessionId,
    });
    sdkMessage.postEventMessage(sdkMessage.events.sessionEnded, {
      ...transformCallInfoToSdk({ ...session, closeBy: 'seat' }, true),
      hangupType: '101',
    });
    const now = Math.floor(Date.now() / 1000);
    const duration = session.timestamp ? now - session.timestamp : 0;

    return {
      sessionId: session.sessionId,
      duration,
    };
  },
);

type InviteParams = Parameters<typeof tccc.Call.invite>;
export const sessionInvite = createAsyncThunk('session/invite', (...params: InviteParams) =>
  tccc.Call.invite(...params).then(unwrapResult),
);

const clearSessionTimer = (session: Session) => {
  if (session.timestamp) timer.clear(session.timestamp);
};
/**
 * 通用endSession方法
 * type:
 *    phoneLikeType: 'phone', 'monitor', 'dialBack', 'internal'
 *    IMLikeType: 'im'
 * 1. 校验
 * 2. dispatch对应end
 * 3. 发送sdk消息
 * 4. 清除timer计时器
 * 5. 计算duration
 * 6. 判断是否从列表删除:  接入中、监听
 */
export const endSession = createAsyncThunk(
  'sessions/endSession',
  async (
    {
      sessionId,
      closeBy,
      hangupType = 1,
      mainReason,
      subReason,
      fromEvent,
    }: {
      sessionId: string;
      closeBy: CloseBy;
      hangupType?: PhoneEndStatus;
      mainReason?: string;
      subReason?: string;
      fromEvent?: boolean;
    },
    { getState, rejectWithValue, dispatch },
  ) => {
    debugLogRequest(`endSession, sessionId: ${sessionId}, closeBy:${closeBy}, hangupType: ${hangupType}`);
    const store = getState() as RootState;
    const session = selectBySessionId(store, sessionId);
    if (!session) {
      const message = `sessionId: ${sessionId} does not exist`;
      debugLogRequest(message);
      throw new Error(`sessionId: ${sessionId} does not exist`);
    }
    let res;
    if (isPhoneLikeType(session.type)) {
      if (closeBy === 'seat' && !fromEvent) {
        res = await tccc.Call.end({ sessionId }).then(unwrapResult);
      }
      playSessionEndAudio(store.userInfo.sdkAppId);
    } else if ((session.type === 'im' && !session.media) || session.type === 'video') {
      res = await dispatch(endIMSession({ sessionId, closeBy }));
    } else if (session.type === 'im' && session.media) {
      await dispatch(endMedia({ sessionId }));
      playSessionEndAudio(store.userInfo.sdkAppId);
      return {
        ...session,
        forceDelete: false,
        status: '200' as SessionStatus,
        media: undefined,
        micStatus: 'on',
        disableCamera: false,
      };
    } else {
      const message = `sessionId: ${session} cannot end`;
      debugLogRequest(message);
      return rejectWithValue(message);
    }
    if (isRejected(res)) {
      debugLogRequest(`endSession isrejected${res.payload}`);
      return rejectWithValue(res.payload);
    }
    let duration = Math.floor(session.timestamp ? Date.now() / 1000 - session.timestamp : 0);
    if (session.status !== '200') {
      duration = 0;
    }
    if (session.type && !['phone', 'voip', 'dialBack'].includes(session.type)) {
      sdkMessage.postEventMessage(
        sdkMessage.events.sessionEnded,
        transformCallInfoToSdk(
          {
            ...session,
            closeBy,
            hangupType: getHangupType(session, hangupType, closeBy),
            duration,
            mainReason,
            subReason,
          },
          true,
        ),
      );
    }
    clearSessionTimer(session);
    let forceDelete = false;
    if (
      ((session.status === '100' || session.status === '150') && session.direction === Direction.callIn) ||
      session.type === 'monitor'
    ) {
      forceDelete = true;
    }
    if (session.type === 'phone' && hangupType === 'giveup') {
      forceDelete = false;
    }
    return {
      ...session,
      forceDelete,
      duration,
      status: '400',
      state: 'finished',
      media: undefined,
      hangupSide: closeBy,
      hangupType,
    };
  },
  {
    condition(arg, { getState }) {
      const store = getState() as RootState;
      const session = selectBySessionId(store, arg.sessionId);
      if (session && +session.status > 200) return false;
    },
  },
);

export const deleteSession = createAsyncThunk(
  'sessions/deleteSession',
  async ({ sessionId, forceDelete }: { sessionId: string; forceDelete?: boolean }, { getState }) => {
    if (!sessionId) {
      throw new Error('Expecting string at sessionId but instead got: undefined');
    }
    const store = getState() as RootState;
    const session = selectBySessionId(store, sessionId);
    if (!session) {
      throw new Error(`sessionId:${sessionId} does not exist`);
    }
    if (session.status === '400') {
      await tccc.Call.deleteCall({ sessionId });
    }
    clearSessionTimer(session);
    // 呼入接听前收到挂断信息
    if (session.status === '100' && forceDelete) {
      sdkMessage.postEventMessage(
        sdkMessage.events.sessionEnded,
        transformCallInfoToSdk({
          ...session,
          closeBy: 'client',
        }),
      );
    }
    sdkMessage.postEventMessage(sdkMessage.events.sessionCompleted, transformCallInfoToSdk(session, true, true));
    return {
      sessionId,
    };
  },
);

export const accessSession = createAsyncThunk(
  'sessions/accessSession',
  async ({ sessionId }: { sessionId: string }, { getState, dispatch }) => {
    Notification.clearAll();
    if (!sessionId) {
      throw new Error("Expecting string at sessionId but instead got: undefined'");
    }
    const store = getState() as RootState;
    const session = selectBySessionId(store, sessionId);
    const sessions = selectAllSession(store);
    if (!session) {
      throw new Error(t('接入失败，会话不存在'));
    }
    let aiEnabled = false;
    if (session.type === 'phone') {
      const res = await tccc.Call.accept({ sessionId }).then(unwrapResult);
      aiEnabled = res.aiEnabled;
    } else if (session.type === 'voip') {
      const res = await tccc.Call.accept({ sessionId }).then(unwrapResult);
      aiEnabled = res.aiEnabled;
    } else if (session.type === 'video') {
      await tccc.Video.accept({ sessionId });
    } else if (session.type === 'internal') {
      await tccc.Call.accept({ sessionId });
    } else if (session.type === 'im' && !session.media) {
      await tccc.Chat.seatInChat(session);
      await dispatch(getMessageList(session));
    }
    if (!store.appSettings.imAutoAccept || sessions.length <= 1) {
      dispatch(
        setActiveUser({
          sessionId: session.sessionId,
        }),
      );
    }
    return {
      ...session,
      aiEnabled,
      timestamp: Math.floor(Date.now() / 1000),
    };
  },
  {
    condition: ({ sessionId }, { getState }) => {
      const store = getState() as RootState;
      const session = selectBySessionId(store, sessionId);
      if (session && session.status === '150') {
        debugLogRequest(`sessionId: ${sessionId}, status: ${session.status}, condition rejected`);
        return false;
      }
    },
  },
);

// 回访im渠道的用户
// ClientUserIDs是联系哪些用户
// channelAgentID通过哪个虚拟号发起会话
// 后台暂时是调用callin事件，后续可能会修改成主动插入会话列表
export const followUpIMSession = createAsyncThunk(
  'TIM/followUpIMSession',
  async (params: { channelAgentID: string; ClientUserID: string; isFromRecord: boolean }) => {
    const { channelAgentID, ClientUserID, isFromRecord } = params;
    const failedText = 'follow up im session failed';
    if (!channelAgentID && !ClientUserID) {
      throw new Error('Expecting value at channelAgentID or ClientUserIDs but instead got: emptyString');
    }
    // let followUpSessions = [];
    try {
      await request('/ccc/im/startCallOut', {
        channelAgentID,
        ClientUserID,
        isFromRecord,
      });
    } catch (e) {
      logger.error(`${failedText}`, e);
      throw e;
    }
    // // setmessageread
    // return followUpSessions;
  },
);

export const addToBlackList = createAsyncThunk(
  'sessions/addToBlackList',
  async ({ sessionId }: { sessionId: string }, { rejectWithValue }) => {
    if (!sessionId) {
      return rejectWithValue('addToBlackList failed, because session is empty');
    }
    try {
      await request('/ccc/black/addBlackUser', {
        sessionId,
      });
    } catch (e: any) {
      if (e?.errorCode === '-4013') {
        return rejectWithValue(`sessionId:${sessionId} is already blacklisted`);
      }
      return rejectWithValue(`addToBlackList failed, ${e?.msg}`);
    }
    return {
      sessionId,
    };
  },
);

export const setActiveUser = createAsyncThunk(
  'setActiveUser',
  async ({ sessionId }: { sessionId: string }, { getState, dispatch }) => {
    const store = getState() as RootState;
    const { currentUser } = store.userInfo;
    if (currentUser.sessionId === sessionId) return;
    const targetCallInfo = selectBySessionId(store, sessionId);
    const targetCallInfoCount = targetCallInfo?.count ? targetCallInfo.count : 0;
    if (targetCallInfo && targetCallInfoCount > 0) {
      if (targetCallInfo.type === 'im') {
        await dispatch(setMessageRead({ sessionId }));
      }
      return {
        sessionId,
      };
    }
    const { innerConversationList } = store.tim;
    const innerConv = innerConversationList.find((item) => item.sessionId === sessionId);
    const innerConvCount = innerConv?.count ? innerConv.count : 0;
    if (innerConv && innerConvCount) {
      await dispatch(setMessageRead({ sessionId }));
      return {
        sessionId,
      };
    }
    return {
      sessionId,
    };
  },
);

export const unmuteVideo = createAsyncThunk('unmuteVideo', async ({ sessionId }: { sessionId: string }) =>
  tccc.Video.unmuteVideo({ sessionId }).then(unwrapResult),
);
export const muteVideo = createAsyncThunk('muteVideo', async ({ sessionId }: { sessionId: string }) =>
  tccc.Video.muteVideo({ sessionId }).then(unwrapResult),
);
