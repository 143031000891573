import { createAction } from '@reduxjs/toolkit';

import type { MicStatus, ServerType, Session, SessionMember } from '.';

export const phoneCallOutAnswer = createAction<{ sessionId: string; isHost?: boolean }>('sessions/phoneCallOutAnswer');
export const phoneCallInAnswer = createAction<{
  sessionId: string;
  serverType?: ServerType;
  playAudio?: boolean;
  isHost?: boolean;
}>('sessions/phoneCallInAnswer');
export const addSession = createAction<Session>('sessions/addSession');
export const upsertSession = createAction<Session>('sessions/upsertSession');
export const updateSession = createAction<{ id: string; changes: Partial<Omit<Session, 'sessionId'>> }>(
  'sessions/updateSession',
);
export const updateMember = createAction<{ sessionId: string; changes?: SessionMember; isHost?: boolean }>(
  'sessions/updateMember',
);

export const changeMicStatusAction = createAction<{ sessionId: string; micStatus: MicStatus }>(
  'sessions/changMuteStatus',
);
