import { styled, SvgIcon } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 16,
  height: 16,
});
export function CheckDevicesSvg(props) {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <path opacity=".1" d="M0 0h16v16H0z" />
        <circle stroke="#666" cx="8" cy="8" r="7.5" />
        <path stroke="#666" strokeLinecap="round" d="M3 8h2l2-4 3 7 1-3h2" />
      </g>
    </Svg>
  );
}
