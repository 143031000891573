import { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Button } from '@tencent/tea-component';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { GET_MOBILE_CAPTCHA_ID } from 'tccc-env/src/url';

import { verify } from 'src/utils/dynamicLoadCaptcha';

import { CaptchaModule } from '../../services/httpAPIs/login';
import { request } from '../../services/httpClient';
import { validatePhone } from '../../utils/validate';

import { setSession } from './captchaSessionMange';

const useStyles = makeStyles({
  disabled: {
    opacity: 0.5,
    cursor: 'no-drop',
  },
  button: {
    width: 120,
    color: '#006EFF !important',
  },
});

function VerifyCode(props: VerifyCodePropTypes) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [time, setTime] = useState(0);
  const [nowTime, setNowTime] = useState(0);
  useEffect(() => {
    const verifyTime = localStorage.getItem(`${props.mobile}verifyCode`);
    if (verifyTime) {
      setTime(Number(verifyTime));
      setNowTime(Date.now());
    }
  }, [props.mobile]);
  const disabled = !validatePhone(props.mobile);

  useEffect(() => {
    if (hasGetCode()) {
      setTimeout(() => {
        const nTime = Date.now();
        setNowTime(nTime);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowTime, time]);

  function hasGetCode() {
    return nowTime - time <= 60 * 1000 && time !== 0 && nowTime !== 0;
  }

  async function getCode() {
    if (disabled) return;
    const res = await verify(GET_MOBILE_CAPTCHA_ID());
    if (!res) return;
    request('/tccclogin/captcha/getCaptcha', {
      mobile: props.mobile,
      module: props.module,
      captcha: {
        ticket: res.ticket!!,
        randstr: res.randstr!!,
        appid: res.appid,
        errorCode: res.errorCode,
        errorMessage: res.errorMessage,
      },
    }).then((msg) => {
      const t = Date.now();
      localStorage.setItem(`${props.mobile}verifyCode`, t.toString());
      setTime(t);
      setNowTime(t);
      setSession(props.mobile, msg.sessionId);
    });
  }

  return hasGetCode() ? (
    <Button type={'text'} disabled className={classnames(classes.button, classes.disabled)}>
      {t('再次获取验证码')}（{props.timeCount - Math.floor((nowTime - time) / 1000)})
    </Button>
  ) : (
    <Button
      type={'link'}
      htmlType={'button'}
      disabled={disabled}
      className={classnames(classes.button, {
        [classes.disabled]: disabled,
      })}
      onClick={getCode}
    >
      {t('获取验证码')}
    </Button>
  );
}

VerifyCode.defaultProps = {
  timeCount: 60,
  module: 'login',
};

interface VerifyCodePropTypes {
  mobile: string;
  timeCount: number;
  module: CaptchaModule;
}

export default VerifyCode;
