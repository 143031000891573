import { useEffect, useState } from 'react';

import { ClickAwayListener, makeStyles, InputBase, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { Session } from 'src/store/slices/sessions';
import { updatePhoneRemark } from 'src/store/slices/sessions/pstn';

import { MicStatus } from '../components/MicStatus';

import { UserIcon } from './UserIcon';
const useStyles = makeStyles<{}, { show: boolean }>({
  root: {
    position: 'absolute',
    zIndex: 2,
    margin: '14px 20px',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 4,
  },
  wrapper: {
    maxWidth: 280,
    padding: '4px 7px',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  nickname: {
    lineHeight: '24px',
    color: '#fff',
    fontSize: 14,
    fontWeight: 500,
    userSelect: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&::after': {
      visibility: (props) => (props.show ? 'visible' : 'hidden'),
      content: '""',
      width: 0,
      height: 0,
      left: 5,
      top: 20,
      position: 'absolute',
      border: '8px solid transparent',
      borderLeftWidth: 10,
      borderRightWidth: 10,
      borderBottomColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 1,
    },
  },
  icon: {
    marginLeft: 6,
  },
  remoteUserInfo: {
    position: 'absolute',
    top: 44,
    left: 0,
    padding: 12,
    width: 280,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    userSelect: 'none',
  },
  remoteUserInfoHeader: {
    fontSize: 15,
    fontWeight: 500,
    color: 'rgb(253, 253, 253)',
  },
  userInfoItem: {
    marginTop: 16,
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: 12,
  },
  userInfoValue: {
    marginTop: 4,
    fontSize: 16,
    color: 'rgba(255, 255, 255, 0.8)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  userRemark: {
    marginTop: 4,
    width: '100%',
    height: 28,
    display: 'flex',
    borderRadius: 4,
    border: 'none',
    backgroundColor: '#000',
    color: '#fff',
    caretColor: '#006eff',
  },
});
export const RemoteUserInfo = ({ callInfo }: { callInfo: Session }) => {
  const { t } = useTranslation();
  const [infoVisible, setInfoVisible] = useState(false);
  const [remarkValue, setRemarkValue] = useState(callInfo.remark);
  const classes = useStyles({ show: infoVisible });
  const dispatch = useDispatch();
  useEffect(() => {
    setRemarkValue(callInfo.remark);
  }, [callInfo.remark]);
  return (
    <ClickAwayListener onClickAway={() => setInfoVisible(false)}>
      <div className={classes.root}>
        <div className={classes.wrapper} onClick={() => setInfoVisible((visible) => !visible)}>
          <div className={classes.nickname}>{callInfo.remark || callInfo.nickname}</div>
          <div className={classes.iconWrapper}>
            <UserIcon className={classes.icon} />
          </div>
          <MicStatus
            darkMode
            side="remote"
            className={classes.icon}
            sessionId={callInfo.sessionId}
            disabled={callInfo.micStatus === 'off' || callInfo.holdStatus}
          />
        </div>

        {infoVisible && (
          <div className={classes.remoteUserInfo}>
            <div className={classes.remoteUserInfoHeader}>{t('客户信息')}</div>
            <div className={classes.userInfoItem}>
              {t('微信昵称')}
              <div className={classes.userInfoValue}>{callInfo.nickname}</div>
            </div>
            <div className={classes.userInfoItem}>
              {t('备注')}
              <InputBase
                className={classes.userRemark}
                value={remarkValue}
                onChange={(e) => setRemarkValue(e.target.value)}
                onKeyPress={(e) => {
                  e.code === 'Enter' &&
                    dispatch(updatePhoneRemark({ sessionId: callInfo.sessionId, userMark: remarkValue || '' }));
                }}
                placeholder={t('请输入')}
                endAdornment={
                  <Button
                    variant="text"
                    disabled={callInfo.remark === remarkValue}
                    onClick={() =>
                      dispatch(updatePhoneRemark({ sessionId: callInfo.sessionId, userMark: remarkValue || '' }))
                    }
                  >
                    {t('确认')}
                  </Button>
                }
              />
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
