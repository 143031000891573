import { SvgIcon, styled } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 18,
  height: 18,
  '&:hover': {
    '& path': {
      fill: '#006eff',
    },
    '& rect': {
      fill: '#006eff',
    },
  },
});
export function ImgSvg(props) {
  return (
    <Svg viewBox={'0 0 18 18'} fontSize={'inherit'} color={'inherit'} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3.25C18 2.00736 16.9926 1 15.75 1H2.25C1.00736 1 0 2.00736 0 3.25V14.75C0 15.9926 1.00736 17 2.25 17H15.75C16.9926 17 18 15.9926 18 14.75V3.25ZM2.25 2.5H15.75L15.8518 2.50685C16.2178 2.55651 16.5 2.8703 16.5 3.25V14.75L16.4932 14.8518C16.4435 15.2178 16.1297 15.5 15.75 15.5H2.25L2.14823 15.4932C1.78215 15.4435 1.5 15.1297 1.5 14.75V3.25L1.50685 3.14823C1.55651 2.78215 1.8703 2.5 2.25 2.5ZM3.1875 5.625C3.1875 4.27881 4.27881 3.1875 5.625 3.1875C6.97119 3.1875 8.0625 4.27881 8.0625 5.625C8.0625 6.97119 6.97119 8.0625 5.625 8.0625C4.27881 8.0625 3.1875 6.97119 3.1875 5.625ZM6.5625 5.625C6.5625 5.10723 6.14277 4.6875 5.625 4.6875C5.10723 4.6875 4.6875 5.10723 4.6875 5.625C4.6875 6.14277 5.10723 6.5625 5.625 6.5625C6.14277 6.5625 6.5625 6.14277 6.5625 5.625ZM15.0493 6.75526C14.7479 6.47112 14.2732 6.48512 13.9891 6.78651L8.965 12.114L6.90714 10.1997L6.81103 10.1209C6.44498 9.85798 5.93794 9.87864 5.59358 10.1861L2.87553 12.6126L2.79826 12.6924C2.56415 12.9732 2.56474 13.3906 2.81551 13.6715L2.89539 13.7488C3.1762 13.9829 3.59357 13.9823 3.87447 13.7315L6.235 11.623L8.32539 13.5688L8.41965 13.6462C8.81072 13.9287 9.35966 13.8839 9.69908 13.5238L15.0805 7.81546L15.1506 7.72924C15.3597 7.42933 15.3233 7.01356 15.0493 6.75526Z"
        fill="#666666"
      />
    </Svg>
  );
}
