import { forwardRef, useImperativeHandle, useState, cloneElement } from 'react';

import { makeStyles } from '@material-ui/core';
import map from 'lodash/map';
import PropTypes from 'prop-types';

import validate from 'src/utils/validate';

import style from './DSForm.style';

const useStyle = makeStyles(style);

const DSForm = forwardRef((props, ref) => {
  const { model, rules, children, onChange } = props;
  const classes = useStyle();
  const [errormsg, setErrorMsg] = useState({});
  useImperativeHandle(ref, () => ({
    validate: validateForm,
  }));

  function validateForm(data) {
    const validData = validate(rules)(data || model);
    setErrorMsg(validData.message);
    return validData;
  }
  const child = map(Array.isArray(children) ? children : [children], (item, key) =>
    cloneElement(item, {
      onBlur: (name) => {
        const valid = validate(rules);
        const validData = valid({
          [name]: model[name],
        });
        if (!validData.isValid) {
          setErrorMsg(Object.assign({}, errormsg, validData.message));
        } else {
          delete errormsg[name];
          setErrorMsg({ ...errormsg });
        }
      },
      onChange: (value, name) => {
        if (errormsg[name]) {
          const valid = validate(rules);
          const validData = valid({
            [name]: value[name],
          });
          if (!validData.isValid) {
            setErrorMsg(Object.assign({}, errormsg, validData.message));
          } else {
            delete errormsg[name];
            setErrorMsg({ ...errormsg });
          }
        }
        onChange?.(value);
      },
      key,
      model,
      rules,
      errormsg,
    }),
  );
  return <form className={classes.root}>{child}</form>;
});

DSForm.propTypes = {
  model: PropTypes.object,
  rules: PropTypes.object,
  formItem: PropTypes.array,
  children: PropTypes.array,
  onChange: PropTypes.func,
};

export default DSForm;
