import { useEffect, useMemo, useState } from 'react';

import { IconButton, FormControl } from '@material-ui/core';
import { SkipNext, SkipPrevious, NavigateBefore, NavigateNext } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DSSelect from '../DSFormControls/DSSelect/DSSelect';

import style from './DSPagination.style';

function DSPagination({ classes, count, page, countPerPage, countPerPageOptions, onChange }) {
  const [countPerPageState, setCountPerPageState] = useState(Number(countPerPage));
  const [pageState, setPageState] = useState(Number(page || 0));
  const [countState, setCountState] = useState(Number(count || 0));
  const pageCount = useMemo(() => Math.ceil(countState / countPerPageState), [countState, countPerPageState]);
  const { t } = useTranslation();
  const pageSelectOptions = useMemo(() => {
    const options = {};
    Array.from(Array(pageCount).keys()).forEach((i) => {
      options[i + 1] = `${i + 1} / ${pageCount}`;
    });
    return options;
  }, [pageCount]);

  useEffect(() => setPageState(Number(page || 0)), [page]);
  useEffect(() => setCountState(Number(count || 0)), [count]);

  useEffect(() => {
    onChange?.({
      page: typeof page === 'string' ? String(pageState) : pageState,
      countPerPage: typeof countPerPage === 'string' ? String(countPerPageState) : countPerPageState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState, countPerPageState]);

  return (
    <div className={classes.root}>
      <div className={classes.countWrapper}>
        {t('共')}
        {countState}
        {t('项')}
      </div>
      <DSSelect
        className={classes.select}
        label={t('每页显示行')}
        value={countPerPageState}
        options={countPerPageOptions}
        onChange={(value) => {
          setCountPerPageState(value);
          setPageState(0);
        }}
      />
      <div className={classes.buttonsWrapper}>
        <FormControl className={classes.formControl}>
          <IconButton disabled={pageState === 0 || Number(count) === 0} onClick={() => setPageState(0)}>
            <SkipPrevious />
          </IconButton>
          <IconButton disabled={pageState === 0 || Number(count) === 0} onClick={() => setPageState(pageState - 1)}>
            <NavigateBefore />
          </IconButton>
        </FormControl>
        <DSSelect
          className={classNames({
            [classes.select]: true,
            [classes.pageSelect]: true,
          })}
          value={pageState + 1}
          options={pageSelectOptions}
          disabled={Object.keys(pageSelectOptions).length < 2}
          onChange={(value) => setPageState(value - 1)}
        />
        <FormControl className={classes.formControl}>
          <IconButton
            disabled={pageState === pageCount - 1 || Number(count) === 0}
            onClick={() => setPageState(pageState + 1)}
          >
            <NavigateNext />
          </IconButton>
          <IconButton
            disabled={pageState === pageCount - 1 || Number(count) === 0}
            onClick={() => setPageState(pageCount - 1)}
          >
            <SkipNext />
          </IconButton>
        </FormControl>
      </div>
    </div>
  );
}

DSPagination.defaultProps = {
  countPerPageOptions: [5, 10, 15],
};

DSPagination.propTypes = {
  classes: PropTypes.object,
  countPerPageOptions: PropTypes.array,
  onChange: PropTypes.func,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  countPerPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
export default withStyles(style)(DSPagination);
