/* eslint-disable no-param-reassign */
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import lodashPick from 'lodash/pick';

import { isInternational as isInternationalUtils } from 'tccc-utils';

import { UAParser } from 'ua-parser-js';

import { SUPPORTED_BROWSERS } from 'src/constants/userAgent';

import i18n from 'src/i18n';

import BoxRef from 'src/utils/BoxRef';

import store, { selectBySessionId } from '../store';
import { loading, TIPS_ERROR, TIPS_SUCCESS } from '../store/actions/loading';

import { RequestError } from './requestError';

const { t } = i18n;
/**
 *
 * @param {object} obj 接收对象
 * @param {string|string[]} key 接收需要获取的字段名
 * @param {function} handle(value, path, obj) 用于处理每一项的函数
 * @param {any} args 其他任意参数
 * @returns [any]
 */
export function pick(obj, key, handle = (value) => value, ...args) {
  if (typeof obj !== 'object' || obj === null) {
    return [];
  }
  if (Object.prototype.toString.call(key) !== '[object Array]' && typeof key !== 'string') {
    throw new TypeError(`${key}must be a string or a string[]!`);
  }
  let items = key;
  if (typeof key === 'string') {
    items = [key];
  }
  if (typeof handle === 'function') {
    return items.map((path) =>
      // eslint-disable-next-line no-undefined
      handle(get(obj, path, undefined), path, obj, ...args),
    );
  }
  return lodashPick(obj, key);
}

export function showError(error, isNotPrefix) {
  const newError = new RequestError('', {});
  const options = {};
  if (isNotPrefix !== undefined) {
    if (typeof isNotPrefix === 'boolean') {
      Object.assign(options, {
        isNotPrefix,
      });
    } else if (typeof isNotPrefix === 'object') {
      newError.code = isNotPrefix.code;
      newError.url = isNotPrefix.url;
    }
  }

  if (typeof error === 'object') {
    if (error.errorCode && error.errorCode !== 0 && (error.msg || error.message)) {
      newError.message = error.msg || error.message;
      newError.code = error.errorCode;
    } else {
      newError.message = JSON.stringify(error);
    }
  } else if (typeof error === 'string') {
    newError.message = error;
  }
  if (!options.isNotPrefix) {
    newError.message = t('错误：{{0}}', { 0: newError.message });
  }
  store.dispatch(loading(true, newError, TIPS_ERROR));
  setTimeout(() => {
    store.dispatch(loading(false, newError, TIPS_ERROR));
  }, 5000);
}

export function showSuccess(msg) {
  store.dispatch(loading(true, msg, TIPS_SUCCESS));
  setTimeout(() => {
    store.dispatch(loading(false, msg, TIPS_SUCCESS));
  }, 3000);
}

export function getCallInfo(sessionId) {
  return selectBySessionId(store.getState(), sessionId);
}

export function getQuery(history) {
  return history.location.search ? Object.fromEntries(new URLSearchParams(history.location.search).entries()) : {};
}
/**
 * 秒转化为时长
 * @param {number} time
 * @returns
 */
export function getTime(time) {
  const d = parseInt(isNaN(time) ? 0 : time, 10);
  let hours = Math.floor(d / 3600);

  let minutes = Math.floor((d - hours * 3600) / 60);
  let seconds = d - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
}
export function validateIvrNode(nodeType, nodeData) {
  const errorMessage = { labels: [], names: [] };
  const validateOBJ = {
    'skill-group': () => {
      if (!nodeData['entry-id']) {
        errorMessage.names.push(nodeData.name);
        errorMessage.labels.push(t('节点"{{0}}"没有选择技能组', { 0: nodeData.label }));
      }
    },
    'out-bound': () => {
      if (!nodeData.caller) {
        errorMessage.names.push(nodeData.name);
        errorMessage.labels.push(t('节点"{{0}}"没有选择转外线主叫号码', { 0: nodeData.label }));
      }
    },
  };
  if (validateOBJ[nodeType]) {
    validateOBJ[nodeType].call(this);
  }
  return errorMessage;
}
export function validateIvrData(data) {
  return Object.entries(data).reduce(
    (pre, [key, value]) => {
      if (key === 'end') return pre;
      value.forEach((data) => {
        const erroNodeArry = validateIvrNode(key, data);
        if (erroNodeArry.labels.length > 0) {
          erroNodeArry.labels.forEach((itemLabels, index) => {
            pre.names.push(erroNodeArry.names[index]);
            pre.labels.push(itemLabels);
          });
        }
        if (data.entries) {
          data.entries.forEach((item) => {
            if (!item.next) {
              pre.names.push(item.name);
              pre.labels.push(t('节点"{{0}}"没有连接到下一个节点', { 0: item.label }));
            }
          });
        } else {
          if (!data.next) {
            pre.names.push(data.name);
            pre.labels.push(t('节点"{{0}}"没有连接到下一个节点', { 0: data.label }));
          }
        }
      });
      return pre;
    },
    { labels: [], names: [] },
  );
}

export function toTime(time) {
  let s = time % 60;
  let h = Math.floor(time / 60);
  if (s < 10) s = `0${s}`;
  if (h < 10) h = `0${h}`;
  return `${h}:${s}`;
}

export function getKeys(value, fn) {
  return Array.isArray(value)
    ? () => {
        const keys = value.map((item) => item.key);
        fn(keys);
      }
    : fn;
}

export function isTestEnv() {
  return process.env.NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'test';
}

export function isDev() {
  return process.env.NODE_ENV === 'development';
}

export function isHotUpdate() {
  return module?.hot?.status && module.hot.status() === 'apply' && isDev();
}

export function messageBoxScrollToBottom() {
  setTimeout(() => {
    const messageBox = BoxRef.getRef();
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight;
    }
  });
}

export function toFixed(number, precision = 2) {
  return number.toFixed(precision);
}
export function getSize(size) {
  if (size > 1024) {
    if (size / 1024 > 1024) {
      return `${toFixed(size / 1024 / 1024)} Mb`;
    }
    return `${toFixed(size / 1024)} Kb`;
  }
  return `${toFixed(size)}B`;
}

export function filterUserId(id) {
  return id.replace(/^GROUP|^C2C/, '');
}

export function setRef(ref, value) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}

export function toFirstUpperCase(str) {
  return str.replace(/( |^)[a-z]/g, (L) => L.toUpperCase()); // 执行代码
}

export function queryObject2String(o) {
  const str = [];
  Object.keys(o).forEach((p) => {
    if (Object.prototype.hasOwnProperty.call(o, p)) {
      str.push(
        `${encodeURIComponent(p)}=${encodeURIComponent(typeof o[p] === 'object' ? JSON.stringify(o[p]) : o[p])}`,
      );
    }
  });
  return str.join('&');
}

export function queryString2Object(s) {
  const str = s.split('&');
  const o = {};
  str.forEach((p) => {
    const partials = p.split('=');
    // eslint-disable-next-line prefer-destructuring
    o[partials[0]] = partials[1];
  });
  return o;
}

export function leadDebounce(fn, time) {
  return debounce(fn, time, {
    leading: true,
    trailing: false,
  });
}

export function awaitTime(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export function imSenderFocused() {
  document.querySelector('#im-send-input-box').focus();
}

// 根据数据格式、数据内容下载文件
export function downloadFileByData(fileName, data, dataType) {
  const blob = new Blob([data], { type: dataType ? dataType : 'text/json' });
  const mouseEvents = document.createEvent('MouseEvents');
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  a.dataset.downloadurl = [dataType ? dataType : 'text/json', a.download, a.href].join(':');
  mouseEvents.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  a.dispatchEvent(mouseEvents);
  a.remove();
}
// 判断是否debug mode，url包含test或者isdebug为debug mode。
export function isDebugMode() {
  const url = window.location.href.toLowerCase();
  return url.includes('localhost') || url.includes('test') || url.includes('isdebug') || isTestEnv();
}

export function getDemoSDKAppId() {
  const { location } = window;
  if (location.hostname.includes('test') || location.hostname.includes('localhost')) {
    return '1400670064';
  }
  return '1400678275';
}

export function isInternational() {
  return isInternationalUtils();
}

export function isDeskSeat(seatType, imAppType) {
  try {
    if (seatType && seatType === 4 && imAppType && imAppType === 1) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}

export const checkIsDeskAuthing = () => {
  const { search, pathname } = window.location;
  const searchParams = new URLSearchParams(search);
  const loginToken = searchParams.get('login_token');
  const source = searchParams.get('source');
  return pathname === '/login' && loginToken && source === 'desk_console';
};

export const checkIsSDKAuthing = () => {
  const { search, pathname } = window.location;
  const searchParams = new URLSearchParams(search);
  const loginToken = searchParams.get('login_token');
  const source = searchParams.get('source');
  // SDK跳转管理端暂时没有指定 source
  return pathname === '/login' && loginToken && !source;
};

export const getUserAgent = () => {
  const parse = new UAParser();
  return parse.getResult();
};

export const checkIsBrowserSupported = () => {
  // 浏览器兼容性提示
  const ua = getUserAgent();
  const { browser } = ua;
  const { name, version } = browser;
  const major = parseInt(version, 10);
  return SUPPORTED_BROWSERS[name] !== undefined && major >= SUPPORTED_BROWSERS[name];
};
