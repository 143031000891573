import { Checkbox, DatePicker, Radio, SelectMultiple, Table, Tree } from '@tencent/tea-component';
import { createUseStyles } from 'react-jss';
import styled from 'styled-components';

const { RangePicker } = DatePicker;

/**
 * Centralized styled Radio
 * Radio label 文本对齐居中
 */
export const StyledRadio = styled(Radio)`
  & .tea-radio-wrap {
    top: 2px;
  }
`;

/**
 * Centralized styled RangePicker
 * 日期选择器基础宽度调整、时间选择器基础宽度调整
 */
export const StyledRangePicker = styled(RangePicker)`
  & .tea-input {
    padding: 0 7px;
  }
  & .tea-datetimepicker__input {
    width: 308px !important;
  }
`;

/**
 * Centralized styled Checkbox
 * Checkbox label 文本对齐居中
 */
export const StyledCheckbox = styled(Checkbox)`
  & input.tea-checkbox {
    margin-top: 2px;
  }
`;

/**
 * Centralized styled Tree
 * Tree label 文本对齐居中，dropdown tree switcher 对齐居中
 */
export const StyledTree = styled(Tree)`
  & input.tea-checkbox {
    margin-top: 3px;
  }
  & a.tea-tree__switcher {
    padding: 9px 0;
  }
`;

/**
 * Centralized styled SelectMultiple
 * SelectMultiple 下拉菜单文本对齐
 */
export const StyledSelectMultiple = ({ ...props }: { [key: string]: any }) => {
  const useStyles = createUseStyles({
    box: {
      '& input.tea-checkbox': {
        marginTop: 3,
      },
    },
  });
  const classes = useStyles();

  return (
    <SelectMultiple {...props} boxClassName={classes.box}>
      {props.children}
    </SelectMultiple>
  );
};

/**
 * Centralized styled Table
 * Table Radio/Select 文本对齐，空表时表头下沿重复的 border 去掉
 */
export const StyledTable = ({ ...props }: { [key: string]: any }) => {
  const useStyles = createUseStyles({
    row: {
      '& span.tea-radio-wrap': {
        marginTop: 4,
      },
      '& input.tea-checkbox': {
        marginTop: 3,
      },
    },
    bodyBottom: {
      '& div.tea-table__body': {
        borderBottom: 0,
      },
    },
  });
  const classes = useStyles();

  return (
    <Table
      {...props}
      columns={props.columns}
      className={`${classes.row} ${props.records.length === 0 ? classes.bodyBottom : ''}`}
    />
  );
};
StyledTable.addons = Table.addons;
StyledTable.ActionPanel = Table.ActionPanel;
