/* eslint-disable import/no-anonymous-default-export */
export default () => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
  countWrapper: {
    marginRight: 'auto',
  },
  buttonsWrapper: {
    display: 'flex',
    marginLeft: 10,
  },
  formControl: {
    flexDirection: 'row',
    verticalAlign: 'baseline',
    '& .MuiIconButton-root': {
      padding: 0,
      width: 30,
      height: 30,
      border: '1px solid #ddd',
      borderRadius: 0,
      borderRight: '0',
      '&:last-child': {
        borderRight: '1px solid #ddd',
      },
    },
  },
  select: {
    '& .MuiInputBase-root': {
      width: 'auto',
      minWidth: 0,
    },
  },
  pageSelect: {
    '& .MuiInputBase-root': {
      borderRight: 0,
      borderLeft: 0,
    },
  },
});
