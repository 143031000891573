import { mergeAttributes } from '@tiptap/core';
import Image from '@tiptap/extension-image';

export default Image.extend({
  name: 'customImage',

  addAttributes() {
    return {
      ...Image.config.addAttributes(),
      class: {
        default: 'image',
        rendered: false,
      },
    };
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ tr, commands }) => {
          if (tr.selection?.node?.type?.name === 'customImage') {
            return commands.updateAttributes('customImage', options);
          }
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    HTMLAttributes.class = `customImage${node.attrs.class}`;
    return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },
});
