import { SvgIcon, styled } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 18,
  height: 18,
  '&:hover': {
    '& path': {
      stroke: '#006eff',
    },
  },
});
export function SmileSvg(props) {
  return (
    <Svg viewBox="0 0 56 56" fontSize={'inherit'} color={'inherit'} {...props}>
      <g transform="translate(-554 -5.853448)" fill="none" fillRule="evenodd">
        <path
          d="M16,33 C17.466529,38.1911324 22.2950601,42 28.0254837,42 C33.6911935,42 38.475266,38.2766739 40,33.1753384"
          stroke="#666666"
          stroke-width="4"
          stroke-linecap="round"
          transform="translate(554 5.853448)"
        />
        <g fill="#666666">
          <path
            d="M2.96644295 0A2.96644295 3 0 1 0 2.96644295 6A2.96644295 3 0 1 0 2.96644295 0Z"
            transform="translate(569 23.853448)"
          />
          <path
            d="M23.033557 0A2.96644295 3 0 1 0 23.033557 6A2.96644295 3 0 1 0 23.033557 0Z"
            transform="translate(569 23.853448)"
          />
        </g>
        <path
          stroke="#666666"
          stroke-width="4"
          d="M28 1.5A26.5 26.5 0 1 0 28 54.5A26.5 26.5 0 1 0 28 1.5Z"
          transform="translate(554 5.853448)"
        />
      </g>
    </Svg>
  );
}
