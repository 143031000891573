// 聊天窗原始宽度
export const MESSAGE_WIDTH = 672;
// 聊天窗最小宽度
export const MIN_MESSAGE_WIDTH = 350;
// 用户列表原始宽度
export const LIST_WIDTH = 230;
// 用户列表最小宽度
export const MIN_LIST_WIDTH = 126;
// CRM 最小宽度
export const MIN_CRM_WIDTH = 240;
// 聊天窗原始宽度与最小宽度差值
export const OFFSET_MESSAGE_WIDTH = MESSAGE_WIDTH - MIN_MESSAGE_WIDTH;
// 隐藏时间时宽度
export const HIDE_LIST_TIME_WIDTH = 238;
// 时间div 原始宽度
export const LIST_CHILDREN_WIDTH = 80;
// 时间div 缩小时宽度
export const HIDE_LIST_CHILDREN_WIDTH = 32;
