/* eslint-disable import/no-anonymous-default-export */
export default function () {
  return {
    root: {
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 2,
    },
    video: {
      maxWidth: 'min(calc(100vw - 180px),300px)',
      maxHeight: 'min(calc(100vw - 180px), 300px)',
      borderRadius: '8px',
    },
  };
}
