import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles<{}, { length: number }>({
  actionButtonGroup: {
    width: '100%',
    height: 42,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'hidden',
    marginTop: '5px 16px 0 16px',
    '& > button': {
      boxSizing: 'border-box',
      flexBasis: (props) => (props.length > 4 ? '23%' : `${100 / props.length - 3}%`),
      margin: '0 5px 10px',
      minWidth: 'auto',
    },
    '& > button:nth-child(4n)': {
      marginRight: 0,
    },
    '& > button:nth-child(4n+1)': {
      marginLeft: 0,
    },
  },
  footer: {
    width: '100%',
    height: 36,
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    fontFamily: 'PingFangSc-Regular',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  opText: {
    cursor: 'pointer',
  },
});
export const ActionButtonGroup: React.FC<{ className?: string }> = ({ className, children }) => {
  const childLen = (React.Children.toArray(children) as React.ReactElement[]).length;
  const { t } = useTranslation();
  const [toggleFlag, setToggleFlag] = useState(true);
  const classes = useStyles({ length: childLen });
  return (
    <>
      <div
        className={`${className ? className : ''} ${classes.actionButtonGroup}`}
        style={toggleFlag ? { height: 42 } : { height: Math.ceil(childLen / 4) * 42 }}
      >
        {children}
      </div>
      {childLen > 4 && (
        <div className={classes.footer}>
          <span className={classes.opText} onClick={() => setToggleFlag((flag) => !flag)}>
            {toggleFlag ? t('展开') : t('收起')}
          </span>
        </div>
      )}
    </>
  );
};
