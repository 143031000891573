/* eslint-disable i18n/no-chinese-character */
import moment from 'moment';

import logger from 'src/utils/logger';

type ActionType =
  | 'SWITCH_RADIO'
  | 'SWITCH_SWITCH'
  | 'SWITCH_TAB'
  | 'CLICK_ADD'
  | 'CLICK_BIND'
  | 'CLICK_CANCEL'
  | 'CLICK_CHECK'
  | 'CLICK_CLEAR'
  | 'CLICK_CONFIRM'
  | 'CLICK_COPY'
  | 'CLICK_EDIT'
  | 'CLICK_EXPORT'
  | 'CLICK_JUMP'
  | 'CLICK_PLAY'
  | 'CLICK_PREVIEW'
  | 'CLICK_REDIRECT'
  | 'CLICK_REFRESH'
  | 'CLICK_RENAME'
  | 'CLICK_REMOVE'
  | 'CLICK_RESET'
  | 'CLICK_SEARCH'
  | 'CLICK_TRIGGER'
  | 'CLICK_TOGGLE'
  | 'CLICK_UNBIND'
  | 'CLICK_UNFOLD'
  | 'LOAD_SEARCH'
  | 'SET_STATUS'
  | 'NULL';

const ACTION_MAP: Record<ActionType, string> = {
  SWITCH_RADIO: '按钮切换',
  SWITCH_SWITCH: '开关切换',
  SWITCH_TAB: '切换标签页',
  CLICK_ADD: '点击添加',
  CLICK_BIND: '点击绑定',
  CLICK_CANCEL: '点击取消',
  CLICK_CHECK: '切换点击勾选',
  CLICK_CLEAR: '点击清除',
  CLICK_CONFIRM: '点击确认',
  CLICK_COPY: '点击复制',
  CLICK_EDIT: '点击编辑/修改',
  CLICK_EXPORT: '点击导出/下载',
  CLICK_JUMP: '点击跳转',
  CLICK_PLAY: '点击播放',
  CLICK_PREVIEW: '点击预览',
  CLICK_REDIRECT: '点击重定向',
  CLICK_REFRESH: '点击刷新',
  CLICK_RENAME: '点击重命名',
  CLICK_REMOVE: '点击删除',
  CLICK_RESET: '点击重置',
  CLICK_SEARCH: '点击搜索',
  CLICK_TOGGLE: '点击切换',
  CLICK_TRIGGER: '点击触发',
  CLICK_UNBIND: '点击解绑',
  CLICK_UNFOLD: '点击展开',
  LOAD_SEARCH: '加载查询',
  SET_STATUS: '切换状态',
  NULL: '空操作',
};

type PagePathType =
  | 'DATA-OVERVIEW'
  | 'SERVICES-RECORDS'
  | 'REALTIME-MONITOR'
  | 'IM/SKILL-GROUP-MANAGEMENT'
  | 'IM/WORKFLOW-MANAGEMENT'
  | 'IM/RATING'
  | 'IM/CHANNEL-MANAGEMENT'
  | 'IM/SETTINGS'
  | 'IM/ROBOT'
  | 'TELEPHONE/SKILL-GROUP-MANAGEMENT'
  | 'TELEPHONE/IVR-MANAGEMENT'
  | 'TELEPHONE/USER-SATISFACTION'
  | 'TELEPHONE/TELEPHONE-MANAGEMENT'
  | 'TELEPHONE/TELEPHONE-SETTINGS'
  | 'TELEPHONE/INPUT-CALLBACK'
  | 'TELEPHONE/AUDIO-MANAGEMENT'
  | 'AUDIO/SKILL-GROUP'
  | 'AUDIO/IVR-MANAGEMENT'
  | 'AUDIO/AUDIO-MANAGEMENT'
  | 'AUDIO/CHANNEL-MANAGEMENT'
  | 'AUDIO/AUDIO-SETTINGS'
  | 'GENERAL-SETTINGS/THIRD-PARTY-SYSTEM-SETTINGS'
  | 'GENERAL-SETTINGS/QUICK-REPLY-SETTINGS'
  | 'GENERAL-SETTINGS/BLACKLIST'
  | 'GENERAL-SETTINGS/BASIC-SETTINGS'
  | 'SEAT-MANAGEMENT';

const PAGE_PATH_MAP: Record<PagePathType, string> = {
  'DATA-OVERVIEW': '数据分析',
  'SERVICES-RECORDS': '服务记录',
  'REALTIME-MONITOR': '实时监控',
  'IM/SKILL-GROUP-MANAGEMENT': '在线客服-技能组管理',
  'IM/WORKFLOW-MANAGEMENT': '在线客服-会话服务流管理',
  'IM/RATING': '在线客服-满意度评价',
  'IM/CHANNEL-MANAGEMENT': '在线客服-渠道管理',
  'IM/SETTINGS': '在线客服-设置',
  'IM/ROBOT': '在线客服-文本机器人',
  'TELEPHONE/SKILL-GROUP-MANAGEMENT': '电话客服-技能组管理',
  'TELEPHONE/IVR-MANAGEMENT': '电话客服-IVR 管理',
  'TELEPHONE/USER-SATISFACTION': '电话客服-满意度评价',
  'TELEPHONE/TELEPHONE-MANAGEMENT': '电话客服-号码管理',
  'TELEPHONE/TELEPHONE-SETTINGS': '电话客服-客服设置',
  'TELEPHONE/INPUT-CALLBACK': '电话客服-收号设置',
  'TELEPHONE/AUDIO-MANAGEMENT': '电话客服-放音文件管理',
  'AUDIO/SKILL-GROUP': '音频客服-技能组管理',
  'AUDIO/IVR-MANAGEMENT': '音频客服-IVR 管理',
  'AUDIO/AUDIO-MANAGEMENT': '音频客服-放音文件管理',
  'AUDIO/CHANNEL-MANAGEMENT': '音频客服-渠道管理',
  'AUDIO/AUDIO-SETTINGS': '音频客服-客服设置',
  'GENERAL-SETTINGS/THIRD-PARTY-SYSTEM-SETTINGS': '通用设置-来电弹屏',
  'GENERAL-SETTINGS/QUICK-REPLY-SETTINGS': '通用设置-快捷回复',
  'GENERAL-SETTINGS/BLACKLIST': '通用设置-黑名单管理',
  'GENERAL-SETTINGS/BASIC-SETTINGS': '通用设置-通用设置',
  'SEAT-MANAGEMENT': '客服管理',
};

type EndType = 'MANAGE' | 'SEAT' | 'SDK' | 'SDK-UI';

const END_MAP: Record<EndType, string> = {
  MANAGE: '管理端',
  SEAT: '座席端',
  SDK: 'SDK-API',
  'SDK-UI': 'SDK',
};

type LogType = 'EXPOSE' | 'INTERACTION' | 'API';

const LOG_TYPE_MAP: Record<string, string> = {
  EXPOSE: '曝光埋点',
  INTERACTION: '交互埋点',
  API: '接口错误埋点',
};

const formatTime = () => moment.unix(Date.now() / 1000).format('YYYY-MM-DD#HH:mm:ss.SSS');

const formatMsg = (msg: string) => {
  const [end, pagePath, type, action] = msg.split('.');
  return `${END_MAP[end as EndType]}#${PAGE_PATH_MAP[pagePath as PagePathType]}#${LOG_TYPE_MAP[type as LogType]}#${
    ACTION_MAP[action as ActionType]
  }`;
};

// 【端】.【页面】.【类型】.【动作】
export const goldlog = {
  of: (end: EndType) => {
    const pagePath = window.location.pathname?.split('/')?.splice(3, 2)?.join('/')?.toLocaleUpperCase() as PagePathType;
    const sdkAppId = window.location.pathname?.split('/')?.splice(2, 1)?.join('');
    return {
      forType: (type: LogType) => ({
        doing: (action: ActionType = 'NULL') => ({
          with: (data = {}, msg = '') => {
            let actionName = msg;
            let params = { timestamp: formatTime(), sdkAppId };
            if (typeof data === 'string') {
              actionName = data;
            } else {
              params = { ...data, ...params };
            }
            if (type === 'EXPOSE') {
              logger.report({
                msg: actionName || formatMsg(`${end}.${pagePath}.${type}.NULL`),
                ext1: JSON.stringify(params),
                ext2: `${end}.${pagePath}.${type}.NULL`,
              });
              return;
            }
            if (type === 'API') {
              actionName = '接口错误';
            }
            logger.report({
              msg: actionName || formatMsg(`${end}.${pagePath}.${type}.${action}`),
              ext1: JSON.stringify(params),
              ext2: `${end}.${pagePath}.${type}.${action}`,
            });
          },
        }),
      }),
    };
  },
};
