import { useState } from 'react';

import { makeStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { StaffList } from '../../../components/StaffList/StaffList';

const useStyles = makeStyles({
  internalCall: {
    padding: '20px 10px 0 10px',
  },
  buttons: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  callButton: {
    width: 140,
    height: 32,
    borderRadius: 17,
    margin: '0 auto',
  },
});

interface InternalCallProps {
  handleCallout: (userId: string) => void;
}

export const InternalCall: React.FC<InternalCallProps> = ({ handleCallout }) => {
  const classes = useStyles();
  const [selectedUserId, setSelectedUserId] = useState('');
  const { t } = useTranslation();
  return (
    <div className={classes.internalCall}>
      <StaffList onSelected={(userId) => setSelectedUserId(userId)} value={selectedUserId} />
      <div className={classes.buttons}>
        <Button
          className={classes.callButton}
          variant={'contained'}
          color={'primary'}
          onClick={() => handleCallout(selectedUserId)}
          disabled={!selectedUserId}
        >
          {t('在线呼叫')}
        </Button>
      </div>
    </div>
  );
};
