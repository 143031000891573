import { ReactNode, useMemo } from 'react';

import { makeStyles, CircularProgress, List, ListItem, withStyles, Typography } from '@material-ui/core';
import ListSubheader from '@material-ui/core/ListSubheader';

interface option {
  value: any;
  label: ReactNode;
  extraFields?: any;
}

type CollapseListProps<T extends string = string> =
  | {
      options: option[];
      button?: boolean;
      loading?: boolean;
      value: string;
      onSelected?: (value: string, index: number) => void;
      visible?: boolean;
      label?: ReactNode;
      title?: ReactNode;
      emptyTips?: ReactNode;
    }
  | {
      options: (option & { groupKey: T })[];
      groups: Record<T, string>;
      button?: boolean;
      loading?: boolean;
      value: string;
      onSelected?: (value: string, index: number) => void;
      visible?: boolean;
      label?: ReactNode;
      title?: ReactNode;
      emptyTips?: ReactNode;
    };

const StyledListItem = withStyles({
  selected: {
    backgroundColor: '#000 !important',
  },
})(ListItem);

const useStyles = makeStyles({
  listContainer: {
    width: '100%',
    maxWidth: '381px',
    border: '1px solid rgba(0,0,0,0.15)',
    borderRadius: '5px',
    fontSize: 14,
  },
  title: {
    padding: '5px 0px 5px 16px',
    borderBottom: '1px solid rgba(0,0,0,0.15)',
    color: 'white',
    opacity: 0.6,
  },
  foldWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    paddingBottom: 10,
    paddingTop: 10,
  },
  listLabel: {
    color: 'white',
    opacity: 0.6,
    fontSize: 14,
  },
  listRoot: {
    width: '100%',
    maxWidth: '381px',
    maxHeight: 310,
    overflow: 'auto',
    background: '#282828',
    cursor: 'pointer',

    '&:hover': {
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.3)',
        backgroundColor: '#d1d1d1',
      },
    },
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&:-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      background: 'rgba(0,0,0,0.1)',
      boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.3)',
    },
    '&::-webkit-scrollbar-thumb:window-inactive': {
      background: 'rgba(255,0,0,0.4)',
    },
  },
  listItem: {
    color: 'white',
    opacity: 0.6,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export const CollapseList: React.FC<CollapseListProps> = (props) => {
  const { loading, value: currentValue, onSelected, visible = true, label, title, emptyTips } = props;
  const classes = useStyles();
  const getFreeNumberStyle = (key: string, extraFields: { freeStaffCount: number }) => {
    if (key !== 'freeStaffCount' || extraFields.freeStaffCount === 0) {
      return { color: '#666666' };
    }
    return { color: 'cyan', fontWeight: 800 };
  };
  const Items = useMemo(() => {
    if (props.options?.length === 0 && !loading) {
      return (
        <StyledListItem button disabled className={classes.listItem}>
          {emptyTips}
        </StyledListItem>
      );
    }
    if ('groups' in props) {
      return (
        <>
          {Object.entries(props.groups).map(([key, groupLabel]) => {
            const items = props.options.filter((i) => i.groupKey === key);
            if (items?.length)
              return (
                <li>
                  <ul>
                    <ListSubheader style={{ backgroundColor: 'white' }}>{groupLabel}</ListSubheader>
                    {items.map(({ value, label }, index) => (
                      <StyledListItem
                        button
                        selected={value === currentValue}
                        className={classes.listItem}
                        onClick={() => onSelected?.(value, index)}
                      >
                        {label}
                      </StyledListItem>
                    ))}
                  </ul>
                </li>
              );
            return null;
          })}
        </>
      );
    }
    return (
      <>
        {props.options?.map(({ value, label, extraFields }, index) => (
          <StyledListItem
            button
            selected={value === currentValue}
            className={classes.listItem}
            onClick={() => onSelected?.(value, index)}
          >
            <div className={classes.itemContainer}>
              {label}
              {Object.keys(extraFields).map((key) => (
                <div style={getFreeNumberStyle(key, extraFields)}>{extraFields[key]}</div>
              ))}
            </div>
          </StyledListItem>
        ))}
      </>
    );
  }, [classes.itemContainer, classes.listItem, currentValue, emptyTips, loading, onSelected, props]);
  if (!visible) return null;
  return (
    <>
      <div className={classes.foldWrapper}>
        <Typography noWrap color="primary" variant="caption" className={classes.listLabel}>
          {label}
        </Typography>
      </div>

      <div className={classes.listContainer}>
        {!('groups' in props) && title && <div className={classes.title}>{title}</div>}
        <div className={classes.listRoot}>
          <List>{loading ? <CircularProgress /> : Items}</List>
        </div>
      </div>
    </>
  );
};
