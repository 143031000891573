import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const RobotTextElementWrapper = styled.div`
  padding: 9px 12px;
  border-radius: 4px;
  font-size: 12px;
  color: #151d32;
  margin: 0;
  line-height: 18px;
  white-space: pre-wrap;
`;

export const RobotText = styled.div`
  overflow: hidden;
  white-space: pre-wrap;
`;

export const RobotTextToobar = styled.div`
  border-top: 1px dashed hsla(0, 0%, 55.3%, 0.3);
  text-align: right;
  padding-top: 10px;
  margin-top: 10px;
`;

export const RobotTextFeedback = styled(Button)`
  && {
    font-size: 12px;
    padding: 0;
    height: 12px;
    line-height: 12px;
    color: #00a4ff;
    &:hover {
      background: none;
    }
    svg {
      font-size: 14px !important;
    }
  }
`;

export const RobotCardElementWrapper = styled.div`
  padding: 12px;
  min-width: 394px;
  @media (max-width: 641px) {
    min-width: auto;
  }
`;

export const RobotCardTitle = styled.h3`
  padding-bottom: 12px;
  color: #333;
  font-size: 14px;
  font-weight: 600;
`;

export const RobotCardContent = styled.div``;

export const ContentList = styled.ul`
  & > li {
    margin: 8px 0;
  }
`;

export const ContentListItem = styled.a`
  cursor: pointer;
  line-height: 14px;
  font-size: 14px;
`;
