import { SvgIcon, styled } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 18,
  height: 18,
  '&:hover': {
    '& path': {
      stroke: '#006eff',
    },
  },
});
export function QuickReplySvg(props) {
  return (
    <Svg viewBox={'0 0 18 18'} fontSize={'inherit'} color={'inherit'} {...props}>
      <path
        d="M3.05904 13.8518L3.23394 13.75H3.43631H13.25C14.0784 13.75 14.75 13.0784 14.75 12.25V3.75C14.75 2.92157 14.0784 2.25 13.25 2.25H2.25C1.42157 2.25 0.75 2.92157 0.75 3.75V14.7609C0.75 14.9538 0.959091 15.074 1.12576 14.977L3.05904 13.8518Z"
        stroke="#666666"
        stroke-width="1.5"
      />
      <path
        d="M8.38721 5L5.5083 8.02106L10.1024 8.09308L6.81525 11.3697"
        stroke="#666666"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
}
