import { getOrElseW, left } from 'fp-ts/es6/Either';
import { flow } from 'fp-ts/es6/function';

import * as t from 'io-ts';
import reporter from 'io-ts-reporters';
import { nullable } from 'io-ts/Type';

type Code = 400;
export class ParseParamsError extends Error {
  code: Code;
  value: any;
  constructor(message: string, span?: any) {
    super();
    this.code = 400;
    this.name = 'INVALID_PARAMS_ERROR';
    this.message = message;
    if (span) {
      span.recordException(this);
      span.setAttributes({
        'error.name': this.name,
        'error.code': this.code,
        'error.message': this.message,
      });
    }
  }
}

export const numberFromString = t.union([t.number, t.string]).pipe(
  new t.Type<number, string, string | number>(
    'NumberFromString',
    t.number.is,
    (i, c) => {
      const n = Number(i);
      if (isNaN(n) && typeof i !== 'number') {
        return t.failure(i, c, 'Can’t convert to number');
      }
      return t.success(n);
    },
    String,
  ),
);
export const stringFromNumber = t.union([t.number, t.string]).pipe(
  new t.Type<string, number, string | number>(
    'stringFromNumber',
    t.string.is,
    (i) => {
      const n = String(i);
      return t.success(n);
    },
    Number,
  ),
);

export const booleanFromString0or1 = t.keyof({ '0': undefined, '1': undefined }).pipe(
  new t.Type<boolean, '0' | '1', '0' | '1'>(
    'booleanFromString0or1',
    t.boolean.is,
    (i) => t.success(i === '1'),
    (b) => (b ? '1' : '0'),
  ),
);

export const booleanFromNumber0or1 = t.union([t.literal(1), t.literal(0)]).pipe(
  new t.Type<boolean, 0 | 1, 0 | 1>(
    'booleanFromNumber0or1',
    t.boolean.is,
    (i) => t.success(i === 1),
    (b) => (b ? 1 : 0),
  ),
);

export const booleanFromString = t.keyof({ true: undefined, false: undefined }).pipe(
  new t.Type<boolean, 'true' | 'false', 'true' | 'false'>(
    'booleanFromString0or1',
    t.boolean.is,
    (i) => t.success(i === 'true'),
    (b) => (b ? 'true' : 'false'),
  ),
);
/**
 * 1: true
 * 2: false
 */
export const booleanFromString1or2 = t.keyof({ '1': undefined, '2': undefined }).pipe(
  new t.Type<boolean, '1' | '2', '1' | '2'>(
    'booleanFromString1or2',
    t.boolean.is,
    (i) => t.success(i === '1'),
    (b) => (b ? '1' : '2'),
  ),
);

export const nullableArray = <C extends t.Mixed>(c: C) =>
  nullable(t.array(c)).pipe(
    new t.Type<Array<t.TypeOf<C>>, Array<t.OutputOf<C>> | null, Array<t.InputOf<C>> | null>(
      'NullableArray',
      t.array(c).is,
      (i) => {
        if (i === null) {
          return t.success([]);
        }
        return t.success(i);
      },
      t.identity,
    ),
  ) as t.Type<Array<t.TypeOf<C>>, Array<t.OutputOf<C>>, unknown>;

export class StringEnumType<A> extends t.Type<A> {
  public readonly _tag: 'StringEnumType' = 'StringEnumType';
}

export const stringEnum = <E extends string>(e: { [key: string]: E }, name = 'StringEnum'): StringEnumType<E> => {
  const is = (u: unknown): u is E => Object.keys(e).some((k) => e[k] === u);
  return new StringEnumType<E>(name, is, (u, c) => (is(u) ? t.success(u) : t.failure(u, c)), t.identity);
};

export class EnumType<A> extends t.Type<A> {
  public readonly _tag: 'EnumType' = 'EnumType';
  public enumObject!: object;
  public constructor(e: object, name?: string) {
    super(
      name || 'enum',
      (u): u is A => {
        if (!Object.values(this.enumObject).some((v) => v === u)) {
          return false;
        }
        // enum reverse mapping check
        if (typeof (this.enumObject as any)[u as string] === 'number') {
          return false;
        }

        return true;
      },
      (u, c) => (this.is(u) ? t.success(u) : t.failure(u, c)),
      t.identity,
    );
    this.enumObject = e;
  }
}

export const createEnumType = <T>(e: object, name?: string) => new EnumType<T>(e, name);

export function literalTransfrom<K extends keyof any & string, V extends keyof any>(of: Record<K, V>) {
  const keys = Object.keys(of);
  const values = Object.values(of);
  const reverseTransform = Object.fromEntries(Object.entries(of).map(([key, value]) => [value, key]));
  return new t.Type<V, string, K>(
    'literalTransform',
    (e: unknown): e is V => values.includes(e),
    (i, c) => (keys.includes(i) ? t.success(of[i]) : t.failure(i, c, `not a value for keys ${Object.keys(of).join()}`)),
    (a) => reverseTransform[a],
  );
}

interface MillionSecondsUNIXTime {
  readonly MillionSecondsUNIXTime: unique symbol;
}
export const unixMsFromUnixSecondsString = numberFromString.pipe(
  new t.Type<t.Branded<number, MillionSecondsUNIXTime>, number, number>(
    'unixMsFromUnixSecondsString',
    (n: unknown): n is t.Branded<number, MillionSecondsUNIXTime> => t.number.is(n) && n >= 946684800000,
    (n: number, c) =>
      n > 946684800000
        ? t.failure(n, c, 'the number is to large not looks like a unix seconds')
        : t.success((n * 1000) as t.Branded<number, MillionSecondsUNIXTime>),
    (n) => n / 1000,
  ),
);
export const getOrPromiseReject = <I, A>(decoder: t.Decoder<I, A>, s?: any) =>
  flow(
    decoder.decode,
    getOrElseW<t.Errors, Promise<never>>((l: t.Errors) => {
      const error = new ParseParamsError(`parse failed: ${reporter.report(left(l))}`, s);
      s?.end();
      return Promise.reject(error);
    }),
  );

export interface NonEmptyStringBrand {
  readonly NonEmptyString: unique symbol;
}

export type NonEmptyStringC = t.Type<t.Branded<string, NonEmptyStringBrand>, string, unknown>;

export const NonEmptyString: NonEmptyStringC = t.brand(
  t.string,
  (s): s is t.Branded<string, NonEmptyStringBrand> => s.length > 0,
  'NonEmptyString',
);

export const getDecodedResultOrReportError = getOrElseW<t.Errors, Promise<never>>((l: t.Errors) => Promise.reject(l));
