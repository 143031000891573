import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export default function FullscreenLoading({ text }: { text: string }) {
  return (
    <StyledContainer>
      <CircularProgress size={20} />
      <span style={{ marginLeft: 4 }}>{text}</span>
    </StyledContainer>
  );
}
