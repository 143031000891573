import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

import { ImReply } from 'src/components/IM/im-reply/ImReply';

import { ImReplyWrapper } from '../../im-reply/imReply.style';

export const ImReplyInSender = styled(ImReply)`
  box-sizing: border-box;
  font-size: 14px;
  &${ImReplyWrapper} {
    padding: 0;
  }
`;

export default makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    width: '100%',
    boxSizing: 'border-box',
    border: 'none',
    resize: 'none',
    padding: '16px 10px',
    position: 'relative',
    // '& img': {
    //   width: 'auto',
    //   height: 'auto',
    //   maxWidth: '100px',
    //   maxHeight: '120px',
    // },
  },
  quickReplyContent: {
    fontFamily: theme.base.fontFamily.Regular,
    fontSize: 13,
    color: '#000',
    marginLeft: '10px',
  },
  quickReplyActive: {
    margin: 0,
    background: '#eee',
    height: 34,
    boxSizing: 'border-box',
    padding: '0 18px',
  },
  qulityList: {
    width: '450px',
    position: 'fixed !important',
    top: 0,
    left: 0,
    background: '#fff',
    padding: '0 !important',
    margin: '0 !important',
    cursor: 'pointer',
    zIndex: 1,
    boxShadow: '0 4px 10px 0 rgba(169,169,169,0.50);',
  },
  quickReplyText: {
    padding: 0,
    margin: 0,
    fontFamily: theme.base.fontFamily.Medium,
    fontSize: 13,
    color: '#000',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  item: {
    padding: '0 18px',
    margin: 0,
    height: 34,
    boxSizing: 'border-box',
    '&:hover': {
      background: '#ccc',
    },
  },
  textarea: {
    width: '100%',
    border: 'none',
    resize: 'none',
    minHeight: '30px',
    fontSize: 12,
    background: 'inherit',
    height: 'auto',
    overflow: 'auto',
    wordBreak: 'break-all',
    color: '#2B2B2B',
    fontFamily: theme.base.fontFamily.Regular,
    '&:empty:before': {
      color: '#ccc',
      content: 'attr(placeholder)',
    },
  },
  sendBtn: {
    height: 34,
    bottom: '20px',
    right: '20px',
    position: 'absolute',
    borderRadius: '17px',
    background: '#006EFF',
    color: '#fff',
    fontSize: 12,
    fontWeight: 500,
  },
  toolbar: {
    height: '18px',
    marginBottom: 8,
    color: 'black',
    display: 'flex',
    alignItems: 'center',
  },
  imgItem: {
    height: 32,
    lineHeight: '32px',
    width: 32,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    verticalAlign: 'middle',
  },
  imgNode: {
    width: 64,
    height: 64,
  },
  funcIcon: {
    color: '#fff',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  transfer: {
    marginLeft: 'auto',
  },
  iconButton: {
    marginRight: 16,
  },
  lightningIcon: {
    transform: 'scale(1.1)',
  },
}));
