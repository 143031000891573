import { useEffect, useRef, useCallback, useState, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { updateCRMMaximized, updateMinify } from 'src/store/slices/appSettings';
import { isDeskSeat } from 'src/utils';

import { MIN_MESSAGE_WIDTH, MIN_LIST_WIDTH, OFFSET_MESSAGE_WIDTH, HIDE_LIST_TIME_WIDTH } from './constant';
import useDraggable from './useDraggable';
function Events() {
  this.events = {};
  this.on = (name, cb) => {
    this.events[name] = this.events[name] || [];
    this.events[name].push(cb);
  };
  this.emit = (name, ...args) => {
    const srcEvents = this.events[name] || [];
    srcEvents.forEach((func) => {
      // func.apply(null, args)
      func(...args);
    });
  };
  this.off = () => {
    this.events = {};
  };
}

const events = new Events();

export const layoutEvents = events;

export default function useLayouts() {
  const listRef = useRef();
  const messageRef = useRef();
  const crmRef = useRef();
  const zoomInIconRef = useRef();
  const zoomOutRef = useRef();
  const splitLineRef = useRef();
  const dragModalRef = useRef();
  const collapseRef = useRef();
  const expandRef = useRef();
  const crmActionRef = useRef();
  const dispatch = useDispatch();
  const CRMMaximized = useSelector((s) => s.appSettings.CRMMaximized);
  const appSettings = useSelector((state) => state.appSettings);
  const isDesk = useMemo(
    () => isDeskSeat(appSettings?.seatType, appSettings?.imAppType),
    [appSettings?.imAppType, appSettings?.seatType],
  );
  const MIN_CRM_WIDTH = isDesk ? 240 : 500;
  const dragRef = useDraggable(crmActionRef, crmRef, {
    mouseMove() {
      crmActionRef.current && (crmActionRef.current.style.zIndex = 10);
      dragModalRef.current && (dragModalRef.current.style.display = 'block');
    },
    mouseUp() {
      crmActionRef.current && (crmActionRef.current.style.zIndex = 1);
      dragModalRef.current && (dragModalRef.current.style.display = 'none');
    },
  });

  const [messageDOMRect, setMessageDOMRect] = useState(new DOMRect());
  const [listDOMRect, setListDOMRect] = useState(new DOMRect());
  const [crmDOMRect, setCrmDOMRect] = useState(new DOMRect());
  const [lastX, setLastX] = useState(0);
  const [clientWidth] = useState(document.documentElement.clientWidth);

  useEffect(() => {
    if (CRMMaximized) {
      messageRef.current && (messageRef.current.style.display = 'none');
      listRef.current && (listRef.current.style.display = 'none');
      zoomOutRef.current && (zoomOutRef.current.style.display = 'block');
      zoomInIconRef.current && (zoomInIconRef.current.style.display = 'none');
      splitLineRef.current && (splitLineRef.current.style.display = 'none');
      crmActionRef.current && (crmActionRef.current.style.left = '');
    } else {
      messageRef.current && (messageRef.current.style.display = 'flex');
      listRef.current && (listRef.current.style.display = 'flex');
      zoomOutRef.current && (zoomOutRef.current.style.display = 'none');
      zoomInIconRef.current && (zoomInIconRef.current.style.display = 'block');
      splitLineRef.current && (splitLineRef.current.style.display = 'block');
      crmActionRef.current && (crmActionRef.current.style.left = '');
    }
  }, [CRMMaximized]);
  // 全屏
  const zoomIn = () => {
    dispatch(updateCRMMaximized(true));
  };
  // 全屏恢复
  const zoomOut = () => {
    dispatch(updateCRMMaximized(false));
  };

  // 隐藏tab
  const collapse = useCallback(() => {
    const tcccCRMBox = document.querySelector('#tcccCRMBox');
    const CRMComponent = document.querySelector('#CRMComponent');
    const CRMTabs = document.querySelector('#CRMTabs');
    if (CRMTabs) {
      CRMTabs.style.display = 'none';
    }
    if (tcccCRMBox?.style?.visibility === 'visible') {
      tcccCRMBox.style.height = '100%';
    }
    if (CRMComponent?.style?.visibility === 'visible') {
      CRMComponent.style.height = '100%';
    }
    collapseRef.current.style.display = 'none';
    expandRef.current.style.display = 'block';
    dragRef.current.style.visibility = 'visible';
    // crmActionRef.current.style.background = '#fff';
    // crmActionRef.current.style.boxShadow = '0 2px 8px 0 rgba(0,0,0,0.08)';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 显示tab
  const expand = useCallback(() => {
    const tcccCRMBox = document.querySelector('#tcccCRMBox');
    const CRMComponent = document.querySelector('#CRMComponent');
    const crmTabls = document.querySelector('#CRMTabs');
    if (crmTabls) {
      crmTabls.style.display = 'flex';
    }
    if (tcccCRMBox?.style?.visibility === 'visible') {
      tcccCRMBox.style.height = 'calc(100% - 55px)';
    }
    if (CRMComponent?.style?.visibility === 'visible') {
      CRMComponent.style.height = 'calc(100% - 55px)';
    }
    collapseRef.current.style.display = 'block';
    expandRef.current.style.display = 'none';
    dragRef.current.style.visibility = 'hidden';
    crmActionRef.current.style.top = '45px';
    crmActionRef.current.style.right = '16px';
    crmActionRef.current.style.left = '';
    // crmActionRef.current.style.background = '';
    // crmActionRef.current.style.boxShadow = '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const invalidate = useCallback(() => {
    splitLineRef.current.style.transform = `translateX(${
      listRef.current.getBoundingClientRect().width + messageRef.current.getBoundingClientRect().width
    }px)`;
  }, []);

  const adjust = useCallback(
    (event) => {
      const mouseDownPosition = getPosition(event);

      const startAdjust = (event) => {
        const mouseMovePosition = getPosition(event);
        const moveX = mouseMovePosition.mouseX - mouseDownPosition.diffX - mouseDownPosition.x;
        const diffX = moveX + lastX;
        if (messageDOMRect.width + diffX < MIN_MESSAGE_WIDTH) {
          // 1. list最小
          if (listDOMRect.width + diffX + OFFSET_MESSAGE_WIDTH < MIN_LIST_WIDTH) {
            listRef.current.style.width = `${MIN_LIST_WIDTH}px`;
          } else {
            // 2. message最小
            messageRef.current.style.width = `${MIN_MESSAGE_WIDTH}px`;
            listRef.current.style.width = `${listDOMRect.width + diffX + OFFSET_MESSAGE_WIDTH}px`;
            splitLineRef.current.style.transform = `translateX(${
              listDOMRect.width + diffX + OFFSET_MESSAGE_WIDTH + MIN_MESSAGE_WIDTH
            }px)`;
          }
          // list缩小时,小于x值隐藏时间
          if (listDOMRect.width + diffX + OFFSET_MESSAGE_WIDTH < HIDE_LIST_TIME_WIDTH) {
            dispatch(updateMinify(true));
          } else {
            dispatch(updateMinify(false));
          }
        }
        // CRM最小
        else if (crmDOMRect.width - diffX < MIN_CRM_WIDTH) {
          crmRef.current.style.width = `${MIN_CRM_WIDTH}px`;
          messageRef.current.style.width = `${clientWidth - listDOMRect.width - MIN_CRM_WIDTH}px`;
        } else {
          splitLineRef.current.style.transform = `translateX(${mouseMovePosition.mouseX}px)`;
          messageRef.current.style.width = `${messageDOMRect.width + diffX}px`;
          listRef.current.style.width = '240px';
          dispatch(updateMinify(false));
        }
      };
      const endAdjust = () => {
        const endX = splitLineRef.current.getBoundingClientRect().left - mouseDownPosition.diffX - mouseDownPosition.x;
        setLastX((x) => x + endX);
        dragModalRef.current.style.display = 'none';
        splitLineRef.current.style.transform = `translateX(${
          listRef.current.getBoundingClientRect().width + messageRef.current.getBoundingClientRect().width
        }px)`;
        window.removeEventListener('mousemove', startAdjust);
        window.removeEventListener('mouseup', endAdjust);
      };

      dragModalRef.current.style.display = 'block';
      window.addEventListener('mousemove', startAdjust);
      window.addEventListener('mouseup', endAdjust);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageDOMRect, listDOMRect, crmDOMRect, lastX],
  );

  const getPosition = useCallback((e) => {
    const elem = e.target;
    const DOMRect = elem.getBoundingClientRect();
    return {
      x: DOMRect.left,
      mouseX: e.pageX,
      diffX: e.pageX - DOMRect.left,
    };
  }, []);

  // 初始化拖拽分割线
  useEffect(() => {
    const X = listRef.current?.getBoundingClientRect().width + messageRef.current?.getBoundingClientRect().width;
    if (splitLineRef.current) {
      splitLineRef.current.style.transform = `translateX(${X}px)`;
    }
  }, []);

  useEffect(
    () => {
      zoomInIconRef.current?.addEventListener('click', zoomIn, false);
      zoomOutRef.current?.addEventListener('click', zoomOut, false);
      splitLineRef.current?.addEventListener('mousedown', adjust);

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        zoomInIconRef.current?.removeEventListener('click', zoomIn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        zoomOutRef.current?.removeEventListener('click', zoomOut);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        splitLineRef.current?.removeEventListener('mousedown', adjust);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageDOMRect, listDOMRect, crmDOMRect, lastX],
  );

  useEffect(() => {
    collapseRef?.current?.addEventListener('click', collapse);
    expandRef?.current?.addEventListener('click', expand);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      collapseRef.current?.removeEventListener('click', collapse);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      expandRef.current?.removeEventListener('click', expand);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMessageDOMRect(messageRef.current?.getBoundingClientRect());
    setListDOMRect(listRef.current?.getBoundingClientRect());
    setCrmDOMRect(crmRef.current?.getBoundingClientRect());
  }, []);

  useEffect(
    () => () => {
      events.off();
    },
    [],
  );

  return {
    ref: {
      listRef,
      messageRef,
      crmRef,
      zoomInIconRef,
      zoomOutRef,
      splitLineRef,
      dragModalRef,
      collapseRef,
      expandRef,
      dragRef,
      crmActionRef,
    },
    zoomIn,
    zoomOut,
    invalidate,
  };
}
