import { makeStyles } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const MessageActions = styled.div`
  border: 1px solid #d1d1d6;
  border-radius: 2px;
  background: #fff;
  display: none;
  cursor: pointer;
  ${(props) =>
    props.flow === 'out'
      ? css`
          margin-right: 8px;
        `
      : css`
          margin-left: 8px;
        `}
`;

export default makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #E5E5E5',
    overflowY: 'auto',
    background: 'inherit',
    paddingTop: 10,
    boxSizing: 'border-box',
    flex: '2 1',
  },
  historyMessage: {
    overflow: 'hidden',
    height: '100%',
    boxSizing: 'border-box',
    flex: '2 1',
    background: '#fff',
  },
  historyMessageInner: {
    padding: '10px 0 60px',
  },
  showMore: {
    color: '#409EFF',
    alignSelf: 'center',
    cursor: 'pointer',
    textAlign: 'center',
  },
  notMessage: {
    color: '#ccc',
    alignSelf: 'center',
    textAlign: 'center',
  },
  messageFlow: {
    marginTop: 20,
    '&:hover .message-actions': {
      display: 'flex',
    },
  },
  message_in: {
    alignSelf: 'flex-start',
    textAlign: 'left',
    marginLeft: 20,
    marginRight: 25,
  },
  message_out: {
    alignSelf: 'flex-end',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 20,
    marginLeft: 25,
  },
  message_info: {
    alignSelf: 'center',
    textAlign: 'left',
    color: 'rgb(153, 153, 153)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    marginBottom: '5px',
    color: '#8A8F97',
    fontSize: 12,
  },
  messageItem: {
    display: 'flex',
    alignItems: 'center',
  },
  infoMessage: {
    height: 17,
    lineHeight: '17px',
    textAlign: 'center',
    color: '#999',
    width: '100%',
    fontSize: 12,
    fontFamily: theme.base.fontFamily.Regular,
    margin: '20px 0',
  },
  notSupportMessage: {
    textAlign: 'left',
    display: 'inline',
    padding: 0,
    margin: 0,
    whiteSpace: 'pre-wrap',
    fontSize: 12,
    color: '#333',
  },
  notSupportMessage_in: {
    background: '#fff',
    padding: '9px 10px',
    display: 'inline-block',
    borderRadius: 2,
  },
  notSupportMessage_out: {
    background: '#B2D3FF',
    padding: '9px 10px',
    display: 'inline-block',
    marginLeft: '10px',
    borderRadius: 2,
  },
  listWrapper: {
    minHeight: '100%',
    position: 'relative',
    paddingBottom: '50px',
  },
}));
