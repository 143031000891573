import { useState, useCallback, useRef, useEffect } from 'react';

import { message } from '@tencent/tea-component';

import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import { t } from 'src/i18n';
import { debugLogRequest } from 'src/services';
import { SessionStatus } from 'src/store/slices/sessions';

import tccc from '../../../utils/tccc';
import { Input } from '../components/Input';

interface DtmfInputProps {
  sessionId: string;
  status: SessionStatus;
  sendDtmfList: string[];
  setSendDtmfList: React.Dispatch<React.SetStateAction<string[]>>;
}

export class DtmfConsumer {
  public sessionId: string;
  dtmfList: string[];
  dtmfLoading: boolean;
  cb: (dtmf: string) => void;
  errorCb: (errorText: string) => void;
  constructor(sessionId: string) {
    this.dtmfLoading = false;
    this.dtmfList = [];
    this.sessionId = sessionId;
    this.cb = () => {};
    this.errorCb = () => {};
  }

  start() {
    if (this.dtmfLoading) return;
    this.sendNextDtmf().then((r) => r);
  }

  onSendDtmf(cb: (dtmf: string) => void) {
    this.cb = cb;
  }

  onError(cb: (errorText: string) => void) {
    this.errorCb = cb;
  }

  async sendDtmf(dtmf: string) {
    try {
      await tccc.Call.sendDigits({
        digits: dtmf,
        sessionId: this.sessionId,
      });
      debugLogRequest(`sendDtmf: ${dtmf} success`);
      this.cb(dtmf);
    } catch (e) {
      debugLogRequest(`sendDtmf: ${dtmf} error`);
      this.errorCb?.(t('发送按键{{dtmf}}错误，已停止发送，请重新输入', { dtmf }));
      message.error({
        content: t('发送按键{{dtmf}}错误，已停止发送，请重新输入', { dtmf }),
      });
      this.dtmfList = [];
    }
  }

  async sendNextDtmf() {
    this.dtmfLoading = true;
    if (this.dtmfList.length > 0) {
      const keyPress = this.dtmfList.shift();
      if (keyPress) {
        await this.sendDtmf(keyPress);
        await this.sendNextDtmf();
      }
    } else {
      this.dtmfLoading = false;
    }
  }
}

let sendDtmfQueue: string[] = [];

export const DtmfInput: React.FC<DtmfInputProps> = (props) => {
  const { sessionId, sendDtmfList, setSendDtmfList } = props;
  const [dtmf, setDtmf] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [dtmfList, setDtmfList] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const sendDtmf = useCallback(() => {
    setLoading(true);
    if (sendDtmfQueue.length > 0) {
      const keyPress = sendDtmfQueue.shift();
      if (keyPress) {
        tccc.Call.sendDigits({
          digits: keyPress,
          sessionId,
        })
          .then(() => {
            setLoading(false);
            setSendDtmfList(keyPress.split(''));
            debugLogRequest(`sendDtmf: ${keyPress} success`);
            sendDtmf();
          })
          .catch(() => {
            setLoading(false);
            sendDtmfQueue = []; // 如果发送失败 后续无需发送
            debugLogRequest(`sendDtmf: ${keyPress} error`);
            message.error({
              content: t('发送按键{{keyPress}}错误，已停止发送，请重新输入', { keyPress }),
            });
            setError(t('发送按键{{keyPress}}错误，已停止发送，请重新输入', { keyPress }));
          });
      }
    } else {
      setLoading(false);
    }
  }, [sessionId, setSendDtmfList]);

  const handleSend = useCallback(
    debounce(async (keyPress: string) => {
      /**
       *  sendDtmf接口响应时间如果超过了1.5s，会导致 dtmfList没有及时清空，
       *  下次请求时会包含上一次的keyPress，所以一进debounce就先清空一次dtmfList
       */
      setDtmfList([]);
      const { length } = sendDtmfQueue;
      sendDtmfQueue.push(keyPress);
      if (length <= 0 && !loading) {
        sendDtmf();
      }
    }, 1500),
    [],
  );

  const onDtmfChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value.replace(/[^\d#*]/g, '');
    setDtmf(value);
  };

  const onDtmfKeyPress = useCallback(async (e: React.KeyboardEvent<HTMLDivElement>) => {
    const keyPress = e.key.replace(/[^\d#*]/g, '');
    if (keyPress === '') {
      return;
    }
    setDtmfList((list) => [...list, keyPress]);
  }, []);

  const getLabel = () => {
    if (loading) {
      return t('按键发送中：{{0}}', { 0: sendDtmfList.join(' -> ') });
    }
    if (props.sendDtmfList.length !== 0) {
      return t('已发送按键：{{0}}', { 0: props.sendDtmfList.join(' -> ') });
    }
    return t('请输入分机号');
  };

  useEffect(() => {
    const timer = setInterval(() => {
      inputRef.current?.focus?.();
    }, 1500);
    return () => {
      clearInterval(timer);
      sendDtmfQueue = [];
    };
  }, []);

  useEffect(() => {
    if (dtmfList.length > 0) {
      handleSend(dtmfList.join(''));
    }
  }, [dtmfList, handleSend]);

  return (
    <Input
      autoFocus
      value={dtmf}
      inputRef={inputRef}
      onChange={onDtmfChange}
      onClear={() => setDtmf('')}
      onKeyPress={onDtmfKeyPress}
      onPaste={(e) => e.preventDefault()}
      placeholder={t('在此输入分机号')}
      label={getLabel()}
      errorText={error}
    />
  );
};
