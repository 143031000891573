import { SvgIcon, styled } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 18,
  height: 18,
  '&:hover': {
    '& path': {
      stroke: (props) => (props.disabled ? '#dedede' : '#006eff'),
    },
  },
});
export function StarSvg(props) {
  return (
    <Svg viewBox="0 0 18 18" fontSize={'inherit'} color={'inherit'} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8629 1.86541C10.6936 1.60502 10.4716 1.38301 10.2113 1.21369C9.18236 0.544632 7.8059 0.836336 7.13684 1.86523L5.29675 4.69497L1.91228 5.73952C1.69186 5.80755 1.48338 5.90948 1.29433 6.04166C0.288475 6.74488 0.0431477 8.13036 0.746373 9.13622L2.82012 12.1024L2.82012 15.7792C2.82012 16.0487 2.86916 16.316 2.96484 16.568C3.40051 17.7154 4.68382 18.2923 5.83119 17.8566L9.00002 16.6534L12.1676 17.8564C12.4196 17.9521 12.6869 18.0012 12.9565 18.0012C14.1838 18.0012 15.1788 17.0062 15.1788 15.7789V12.1024L17.2526 9.13766C17.3849 8.94849 17.487 8.73985 17.555 8.51925C17.917 7.34654 17.2597 6.10245 16.087 5.74052L12.7033 4.6962L10.8629 1.86541ZM8.4591 2.59775C8.6922 2.3339 9.08955 2.27352 9.39354 2.4712L9.47383 2.53177C9.52436 2.57572 9.56865 2.62659 9.60533 2.68301L11.4457 5.51379L11.5335 5.63513C11.7204 5.86801 11.9734 6.04076 12.2609 6.12949L15.6446 7.17381L15.7363 7.20901C16.0591 7.35866 16.2296 7.72752 16.1218 8.0769C16.0996 8.14859 16.0665 8.2164 16.0235 8.27788L13.9496 11.2426L13.8683 11.3724C13.7444 11.5949 13.6788 11.8461 13.6788 12.1024V15.7789L13.6722 15.8769C13.6243 16.2295 13.3222 16.5012 12.9565 16.5012C12.8689 16.5012 12.782 16.4852 12.7001 16.4541L9.53258 15.2511L9.38357 15.2033C9.082 15.1235 8.7616 15.1394 8.46755 15.2511L5.29871 16.4543L5.20475 16.483C4.85822 16.5634 4.49695 16.3773 4.36715 16.0355C4.33605 15.9536 4.32012 15.8668 4.32012 15.7792L4.32012 12.1024L4.3123 11.9495C4.28635 11.6962 4.19627 11.4529 4.04946 11.2429L1.97572 8.27674L1.92099 8.18478C1.76371 7.8698 1.85662 7.47878 2.15381 7.27101C2.21525 7.22805 2.28301 7.19492 2.35464 7.17281L5.73911 6.12826L5.87982 6.07701C6.15491 5.96096 6.39026 5.76489 6.55426 5.51269L8.39435 2.68295L8.4591 2.59775Z"
        fill="#666666"
      />
    </Svg>
  );
}
