import { ReactRenderer } from '@tiptap/react';
import memoize from 'lodash/memoize';
import tippy from 'tippy.js';

import { request } from 'src/services/httpClient';

import QuickReplyList from './QuickReplyList';

const getQuickReplyList = memoize(async () => {
  const quickReplayRes = await request('/tcccadmin/app/getCommKV', {
    key: 'quickReply',
  });
  setTimeout(() => {
    getQuickReplyList.cache.clear();
  }, 5 * 30 * 1000);
  return JSON.parse(quickReplayRes?.value || '[]');
});
function getLatelyQuickReply() {
  let latelyQuickReply = localStorage.getItem('latelyQuickReply');
  if (latelyQuickReply) {
    latelyQuickReply = JSON.parse(latelyQuickReply);
  } else {
    latelyQuickReply = [];
  }
  return latelyQuickReply;
}

const suggestionConfig = {
  items: async ({ query }) => {
    const quickReplyList = await getQuickReplyList();
    const latelyQuickReply = getLatelyQuickReply();

    if (query) {
      return [
        ...quickReplyList.filter(
          (item) =>
            item.content.toLowerCase().includes(query.toLowerCase()) ||
            item.title.toLowerCase().includes(query.toLowerCase()),
        ),
      ].slice(0, 5);
    }

    return [
      ...latelyQuickReply.map((lq) => quickReplyList.find((q) => q.id === lq)).filter(Boolean),
      ...quickReplyList.filter(
        (item) =>
          !latelyQuickReply.includes(item.id) &&
          (item.content.toLowerCase().includes(query.toLowerCase()) ||
            item.title.toLowerCase().includes(query.toLowerCase())),
      ),
    ].slice(0, 5);
  },
  render: () => {
    let component;
    let popup;

    return {
      onStart: (props) => {
        component = new ReactRenderer(QuickReplyList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }
        if (!popup && component.element) {
          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          });
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          if (popup) {
            popup[0].destroy();
          }
          component.destroy();
          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        if (popup) {
          popup[0].destroy();
        }
        popup = undefined;
        component.destroy();
      },
    };
  },
};
export default suggestionConfig;
