import { useEffect, useLayoutEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { CheckCircle, Error as ErrorIcon, Warning } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import sdkMessage from 'src/agentSdkIframe/sdkMessage';
import styles from 'src/components/BaseDialog/BaseDialog.style';
import { useCountdownTime } from 'src/utils/timing';
const useStyle = makeStyles(styles);
const events = {};
const id = Date.now();

function ConfirmDialogTips() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const defaultConfig = {
    title: t('提示'),
    content: '',
    enterText: t('确定'),
    cancelText: t('取消'),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onEnter: () => {},
    onCancel: () => {
      events.hide();
    },
  };
  const [config, setConfig] = useState(defaultConfig);
  const classes = useStyle();
  const { from, countdown, onCountDownZero } = config.countDownConfig || {};
  const countTime = useCountdownTime(from, countdown);
  useLayoutEffect(() => {
    events.show = (option) => {
      setOpen(true);
      setConfig(Object.assign({}, defaultConfig, option));
    };
    events.hide = () => {
      setOpen(false);
    };
  }, [config]);
  useEffect(() => {
    sdkMessage.syncData('dialogState', {
      open,
    });
  }, [open, config.content]);
  useEffect(() => {
    if (countdown && onCountDownZero) {
      if (countTime === 0) {
        onCountDownZero();
      }
    }
  }, [countdown, countTime, onCountDownZero]);
  return (
    <Dialog
      className={classes.root}
      classes={{
        paper: classes.paper,
      }}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby={`confirmation-dialog-title${id}`}
      open={countdown ? countTime && open : open}
    >
      <DialogTitle id={`confirmation-dialog-title${id}`}>
        {config.icon === 'success' && <CheckCircle className={classes.titleIcon} style={{ color: '#19C4A0' }} />}
        {config.icon === 'warning' && <Warning className={classes.titleIcon} style={{ color: '#FDB728' }} />}
        {config.icon === 'error' && <ErrorIcon className={classes.titleIcon} style={{ color: '#E54545' }} />}
        {config.title}
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        {typeof config.content === 'string'
          ? config.content.split('\n').map((item, index) => (
              <div style={{ lineHeight: 1.75 }} key={index}>
                {item}
              </div>
            ))
          : config.content}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {config.enterText && (
          <Button
            onClick={config.onEnter}
            color="inherit"
            style={{
              backgroundColor: '#006eff',
              marginLeft: 20,
              color: '#fff',
            }}
            variant="contained"
            className={classes.actionsButton}
          >
            {config.enterText}
            {countdown ? `(${countTime}s)` : ''}
          </Button>
        )}
        {config.cancelText && (
          <Button
            onClick={config.onCancel}
            color="inherit"
            style={{
              backgroundColor: '#fff',
              color: '#000',
            }}
            variant="contained"
            className={classes.actionsButton}
          >
            {config.cancelText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export function showConfirmDialog(config) {
  events.show(config);
}

export function hideConfirmDialog() {
  events.hide();
}

export default ConfirmDialogTips;
