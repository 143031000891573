import { useEffect, useState, useMemo } from 'react';

import { Table, Justify, PagingQuery, SearchBox, Button } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';

import { SkillGroup } from 'src/services/httpAPIs/admin';

import { useRequest } from 'src/services/httpClient';

interface SkillGroupListProps {
  skillGroupType?: 0 | 1 | 2 | 3 | 4;
  selectedId?: string;
  onSelected?: (skillGroupId: string, record: SkillGroup) => void;
  emptyText?: string;
}

export const SkillGroupList = ({ skillGroupType, selectedId, onSelected, emptyText }: SkillGroupListProps) => {
  const { t } = useTranslation();
  const { radioable, autotip, pageable } = Table.addons;
  const [searchText, setSearchText] = useState('');
  const [pageInfo, setPageInfo] = useState<PagingQuery>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [selectedKey, setSelectedKey] = useState<string | undefined>();
  const {
    loading,
    data: { skillGroupList = [] },
    refresh,
  } = useRequest('/tcccadmin/tech/getSkillGroupList', {
    skillGroupType,
    freeOnly: true,
  });
  const filteredSkillGroupList = useMemo(
    () => (searchText ? skillGroupList.filter((item) => item.skillGroupName.match(searchText)) : skillGroupList),
    [searchText, skillGroupList],
  );
  const columns = [
    {
      key: 'skillGroupName',
      header: t('技能组名称'),
    },
    {
      key: 'ringAll',
      header: t('振铃方式'),
      render: (record: SkillGroup) => (record.ringAll ? t('同时振铃') : t('顺序振铃')),
    },
    {
      key: 'freeStaffCount',
      header: t('空闲座席数'),
    },
  ];

  useEffect(() => {
    setSelectedKey(selectedId);
  }, [selectedId]);

  return (
    <div style={{ margin: '20px 20px 0 20px' }}>
      <Table.ActionPanel>
        <Justify
          right={
            <>
              <SearchBox
                placeholder={t('请输入技能组名称')}
                onSearch={(value) => setSearchText(value || '')}
                onClear={() => setSearchText('')}
              />
              <Button icon="refresh" onClick={refresh} />
            </>
          }
        />
      </Table.ActionPanel>
      <Table
        columns={columns}
        recordKey={'skillGroupId'}
        records={filteredSkillGroupList}
        addons={[
          autotip({
            isLoading: loading,
            emptyText: emptyText || t('暂无数据'),
          }),
          pageable({
            pageSize: pageInfo.pageSize,
            pageIndex: pageInfo.pageIndex,
            pageSizeOptions: [5, 10, 20],
            onPagingChange: ({ pageIndex, pageSize }) => {
              setPageInfo({
                pageIndex,
                pageSize,
              });
            },
          }),
          radioable({
            value: selectedKey,
            onChange: (key, context) => {
              console.log(key, context);
              setSelectedKey(key);
              onSelected?.(key, context.record);
            },
            rowSelect: true,
          }),
        ]}
      />
    </div>
  );
};
