import { styled, SvgIcon } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 8,
  height: 8,
});
export function CheckedSvg() {
  return (
    <Svg viewBox="0 0 8 8">
      <path fill="#27C565" d="M4 0A4 4 0 1 0 4 8A4 4 0 1 0 4 0Z" />
      <path d="M2 4L3.5 5.5L6 3" stroke="#fff" fill="#fff" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
}
