import { useCallback } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useStyles from './LocationElemnet.style';

function LocationElement({ data, index }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { payload } = data;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onHandleMap = useCallback(() => {
    window.open(
      `https://map.qq.com/?type=marker&isopeninfowin=1&markertype=1&pointx=${payload.longitude}&pointy=${payload.latitude}&name=${payload.description}`,
      '_blank',
    );
  });
  return (
    <div onClick={onHandleMap} className={classes.root} key={data.ID + index}>
      <p className={classes.title}>
        {t('位置消息：')}
        {payload.description}
      </p>
    </div>
  );
}

LocationElement.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default LocationElement;
