import { useState } from 'react';

import { makeStyles, Button, IconButton, Card, CardHeader, Avatar, CardContent } from '@material-ui/core';
import { isRejected } from '@reduxjs/toolkit';
import { Copy, Form, Input, InputAdornment, Popover } from '@tencent/tea-component';
import cs from 'classnames';
import { unix } from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BlackListIcon from 'src/assets/images/chat-setting.png';
import { ChannelSource } from 'src/components/chat/ChannelSource/ChannelSource';
import CustomPopper from 'src/components/CustomPopper/CustomPopper';
import { Timing } from 'src/components/Timing/Timing';
import { leadDebounce, showError } from 'src/utils';
import enums from 'src/utils/enums';

import { deleteSession, endSession } from '../../../store/slices/sessions/sessions.thunk';
import BlackList from '../../BlackList/BlackList';

import { Duration } from '../../Duration';

import style from './IMHeader.style';

const useStyles = makeStyles(style);
const NameCardItem = ({ label, text }) => (
  <Form.Item label={label}>
    <InputAdornment appearance="pure" after={<Copy text={text} />}>
      <Input disabled value={text} />
    </InputAdornment>
  </Form.Item>
);
export default function IMHeader(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const imBlackUserEnabled = useSelector((s) => s.appSettings.imBlackUserEnable);
  const [isEndding, setIsEndding] = useState(false);
  const { info, quickReply } = props;
  const dispatch = useDispatch();
  function handleFinish() {
    if (isEndding) {
      return showError(t(`请不要重复结束会话`));
    }
    setIsEndding(true);
    dispatch(
      endSession({
        sessionId: info.sessionId,
        closeBy: 'seat',
      }),
    ).then((res) => {
      if (isRejected(res)) {
        showError(t('结束失败：{{0}}', { 0: res.payload || res.error.message }));
      }
      setIsEndding(false);
      props.closeQuickReply(false);
    });
  }

  function handleCompleted() {
    dispatch(
      deleteSession({
        sessionId: info.sessionId,
      }),
    );
  }

  const getPopper = () => {
    if (info.isBlackUser) {
      return (
        <Button className={classes.blackAdd} variant={'text'} color={'inherit'} disabled>
          {t('已加入黑名单')}
        </Button>
      );
    }
    return (
      <BlackList sessionId={info.sessionId}>
        <Button className={classes.blackAdd} variant={'text'} color={'inherit'}>
          {t('添加至黑名单')}
        </Button>
      </BlackList>
    );
  };

  const getIMHeaderButtons = () => {
    if (info.status === '200') {
      return (
        <Button
          color={'secondary'}
          variant={'outlined'}
          onClick={leadDebounce(handleFinish, 300)}
          className={cs(classes.btnItem, classes.finishBtn)}
        >
          {t('结束会话')}
        </Button>
      );
    }
    if (info.status === '400') {
      const hasPopper = info.status === '400' && info.peerSource !== '4' && info.mode !== '2' && imBlackUserEnabled;
      return (
        <>
          <Button
            variant={'outlined'}
            color={'primary'}
            onClick={leadDebounce(handleCompleted, 300)}
            className={cs(classes.btnItem, classes.completeBtn)}
          >
            {t('完成服务')}
          </Button>
          {hasPopper && (
            <CustomPopper placement={'bottom-end'} renderPopper={getPopper()}>
              <IconButton
                style={{
                  marginLeft: 5,
                }}
                size={'small'}
                disableElevation
                disableRipple
                disableFocusRipple
                disableTouchRipple
              >
                <img src={BlackListIcon} alt="" />
              </IconButton>
            </CustomPopper>
          )}
        </>
      );
    }
    return '';
  };

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <Popover
          placement="bottom-right"
          trigger={'hover'}
          overlay={
            <Card>
              <CardHeader
                title={info?.clientData?.nickName || info.nickname}
                avatar={<Avatar src={info?.clientData?.avatarUrl || info.avatar}></Avatar>}
              ></CardHeader>
              <CardContent style={{ width: '380px' }}>
                <Form>
                  <NameCardItem label="ID" text={info?.clientData?.uid || info.userId} />
                  <NameCardItem
                    label={t('渠道')}
                    text={info?.clientData?.peerSource || enums.peerSource[info.peerSource] || info.channelName}
                  />
                  {info?.clientData &&
                    Object.entries(info.clientData).map(([key, value]) => {
                      if (!['nickName', 'uid', 'peerSource'].includes(key)) {
                        return <NameCardItem label={key.startsWith('extra_') ? key.substring(6) : key} text={value} />;
                      }
                      return null;
                    })}
                </Form>
              </CardContent>
            </Card>
          }
        >
          <div className={classes.nickName}>{info?.clientData?.nickName || info.nickname || t('访客')}</div>
        </Popover>
        <div className={classes.extra}>
          {info.status === '200' && info.timestamp && (
            <div className={classes.timing}>
              {t('开始时间')}:{unix(info.timestamp).format('HH:mm:ss')}
            </div>
          )}
          <div className={classes.timing}>
            {t('服务时长')}:&nbsp;
            {info.status === '200' && info.timestamp ? (
              <Timing startTime={info.timestamp} />
            ) : (
              <Duration seconds={info.duration} />
            )}
          </div>
          <span className={classes.source}>
            {t('来源')}:&nbsp;
            <ChannelSource
              sourceNumber={info.peerSource}
              sourceOverride={info?.clientData?.peerSource}
              channelName={info.channelName}
            />
          </span>
          {info?.clientData?.uid && !quickReply && (
            <span style={{ marginLeft: '20px' }}>UID：{info.clientData.uid}</span>
          )}
        </div>
      </div>
      <div className={classes.btnBox}>{getIMHeaderButtons()}</div>
    </div>
  );
}

IMHeader.propTypes = {
  info: PropTypes.object,
  onQuitRoom: PropTypes.func,
  quickReply: PropTypes.bool,
};
