import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { t } from 'src/i18n';

import { RequestError } from '../../utils/requestError';

import style from './Tips.style';

function Tips(props) {
  const { classes, type, text, open } = props;
  const typeObj = {
    TIPS_LOADING: <CircularProgress className={classes.loading} size={24} />,
    TIPS_SUCCESS: <CheckIcon className={classes.success} />,
    TIPS_ERROR: <ErrorOutline className={classes.error} />,
  };

  let displayText = text;

  if (displayText instanceof RequestError) {
    const errorCode = [displayText.code, displayText.url].filter(Boolean).join(',');
    displayText = (
      <p style={{ display: 'flex' }}>
        {displayText.message}
        {errorCode && <span style={{ fontSize: 12, color: '#ecbaba' }}>[{errorCode}]</span>}
      </p>
    );
  } else if (displayText instanceof Error) {
    displayText = displayText.message;
  }

  return (
    <Snackbar className={classes.snackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open}>
      <SnackbarContent
        className={classes.snackbarContent}
        message={
          <div className={classes.message}>
            {typeObj[type]}
            <pre style={{ color: type === 'TIPS_ERROR' ? 'red' : '' }} className={classes.text}>
              {displayText}
            </pre>
          </div>
        }
      />
    </Snackbar>
  );
}

Tips.defaultProps = {
  type: 'TIPS_LOADING',
  text: t('加载中'),
  open: false,
};
Tips.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.oneOf(['TIPS_LOADING', 'TIPS_SUCCESS', 'TIPS_ERROR']),
  text: PropTypes.string,
  open: PropTypes.bool,
};
export default connect(
  (state) => ({
    ...state.tipsData,
  }),
  null,
)(withStyles(style)(Tips));
