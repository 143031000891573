import { t } from 'src/i18n';
import services from 'src/services';
import { goldlog } from 'src/utils/goldlog';

export function modifyData(newDataHandler) {
  const api = '/tcccadmin/app/getCommKV';
  const params = {
    key: 'quickReply',
  };
  goldlog.of('MANAGE').forType('INTERACTION').doing('LOAD_SEARCH').with({ api, params }, t('加载配置信息'));
  return services[api](params).then(({ value }) => {
    const newVal = newDataHandler(value ? JSON.parse(value) : []);
    const setApi = '/tcccadmin/app/setCommKV';
    const setParams = {
      key: 'quickReply',
      value: JSON.stringify(newVal),
    };
    const logParams = { api: setApi, params: setParams };
    goldlog.of('MANAGE').forType('INTERACTION').doing('CLICK_EDIT').with(logParams, t('点击编辑快捷回复信息'));
    return Promise.all([newVal, services[setApi](setParams)])
      .then((res) => res[0])
      .catch((error) => {
        goldlog.of('MANAGE').forType('API').doing('CLICK_EDIT').with({ api: setApi, params: setParams, error });
      });
  });
}
