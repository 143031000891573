import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
const initialState: UAParser.IResult = {
  ua: '',
  browser: {
    name: '',
    version: '',
    major: '',
  },
  device: {
    model: '',
    type: '',
    vendor: '',
  },
  engine: {
    name: '',
    version: '',
  },
  os: {
    name: '',
    version: '',
  },
  cpu: {
    architecture: '',
  },
};

const userAgentSlice = createSlice({
  name: 'userAgent',
  initialState,
  reducers: {
    updateUserAgent(state, action: PayloadAction<UAParser.IResult>) {
      return action.payload;
    },
  },
});

export const { updateUserAgent } = userAgentSlice.actions;
export default userAgentSlice.reducer;
