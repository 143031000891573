import styled from 'styled-components';

export const Loader = styled.div`

  @keyframes loader-default {
    to {
      transform: rotate(360deg);
    }
  }
    box-sizing: border-box;
    display: inline-flex;
    position: relative;
    font-size: 1em;
    width: 1.8em;
    height: 1.8em;
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;

    &::before,
    &::after {
      box-sizing: border-box;
      display: block;
      width: 1.8em;
      height: 1.8em;
      margin: 0 0 0 (math.div(1.8, 2) * -1); // Center horizontally
    }

    // Background circle
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      border-radius: 50%;
      border-color:  #c6e3fa 
      border-style: solid;
      border-width: 1.8em;
      //box-shadow: 0 0 2px  #c6e3fa ;
    }

    // Animated "circle"
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      animation: loader-default 0.6s linear;
      animation-iteration-count: infinite;
      border-radius: 50%;
      border-color: #6ea9d7 transparent transparent transparent;
      border-style: solid;
      border-width: 0.2em;
      box-shadow: 0 0 0 transparent;
    }

    & .loader-content {
      width: auto;
      height: auto;
      padding-top: calc(1.8em + 0.8em);
    }

`;
