import { cloneElement } from 'react';

import { FormControl, InputLabel, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './DSFormControl.style';
// 添加formControl 组件
const useStyles = makeStyles(styles);
function DSFormControl(props) {
  const { name, label, children, onChange, model, rules, errormsg, onBlur } = props;
  const rule = rules[name];
  const classes = { ...useStyles(), ...props.classes };
  const Child =
    typeof children.type === 'string'
      ? children
      : cloneElement(children, {
          error: !!errormsg[name],
          onBlur: () => {
            onBlur?.(name);
          },
          onChange: (e) => {
            onChange?.(
              {
                ...model,
                [name]: e.currentTarget.value,
              },
              name,
            );
          },
        });
  return (
    <FormControl className={classes.root}>
      {label && (
        <InputLabel className={classes.inputLabel} shrink={false}>
          {label}
          {rule?.find((item) => item.type === 'required') && <span className={classes.errorText}>*</span>}
        </InputLabel>
      )}
      <div className={classes.content}>
        {Child}
        <div className={classes.errorText}>{errormsg?.[name] ? errormsg[name] : ''}</div>
      </div>
    </FormControl>
  );
}

DSFormControl.defaultProps = {
  classes: {},
};

DSFormControl.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.element.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  model: PropTypes.object,
  rules: PropTypes.object,
  errormsg: PropTypes.object,
  classes: PropTypes.object,
};

export default DSFormControl;
