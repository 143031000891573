import { useCallback, useState, useMemo, useEffect } from 'react';

import { makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { IVR_TYPE } from 'src/constants/manage';
import { SelfServiceSource, SelfServiceStatus } from 'src/services/httpAPIs/types';
import { request } from 'src/services/httpClient';
import { triggerReceiveKey, triggerSelfService } from 'src/store/slices/sessions/pstn';
import { goldlog } from 'src/utils/goldlog';

import { ReceiveIvrStatus } from '../../../store/slices/sessions';
import { ActionButtonGroup } from '../components/ActionButtonGroup';
import { RoundedButton } from '../components/Button';
import { CollapseList } from '../components/CollapseList';
const LogInteraction = goldlog.of('SEAT').forType('INTERACTION');

const fetchInputCallback = (pageNum = 0) =>
  request('/tcccadmin/ivr/getIvrList', {
    pageNum,
    pageSize: 100,
    type: IVR_TYPE.inputCallback,
  }).then((res) => ({
    total: res.total,
    list: res.ivrList,
  }));
const fetchSelfService =
  (source: SelfServiceSource) =>
  (pageNum = 0) =>
    request('/tcccadmin/selfservice/getList', {
      pageNum,
      pageSize: 100,
      status: SelfServiceStatus.on,
      source,
    }).then((res) => ({
      total: res.total,
      list: res.selfServiceList,
    }));

const fetchAll = <T extends object>(
  fetchByPage: (pageNum: number) => Promise<{ total: number | string; list: Array<T> }>,
) =>
  fetchByPage(0).then(
    ({ total, list }) => {
      if (+total < 100) {
        return list;
      }
      return Promise.all([
        Promise.resolve(list),
        ...Array(Math.ceil(+total / 100) - 1)
          .fill(0)
          // eslint-disable-next-line @typescript-eslint/naming-convention
          .map((_, i) => fetchByPage(i + 1).then((res) => res.list)),
      ]).then((dataArray) => dataArray.flatMap((m) => m));
    },
    (err) => Promise.reject(err),
  );
const fetchAllInputCallbacksAndSelfServices = async (source: SelfServiceSource) =>
  Promise.all([
    source === SelfServiceSource.audioSelfService ? Promise.resolve([]) : fetchAll(fetchInputCallback),
    fetchAll(fetchSelfService(source)),
  ]);

interface ReceiveIvrProps {
  toggleReceiveFlag: () => void;
  receiveStatus?: ReceiveIvrStatus;
  sessionId: string;
  source: SelfServiceSource;
}

const useStyles = makeStyles({
  buttonGroups: {
    marginTop: 10,
  },
  receiveStatus: {
    height: 28,
    width: '100%',
    padding: '10px 0',
    fontSize: 18,
    opacity: 0.6,
    color: '#fff',
    boxSizing: 'content-box',
    textAlign: 'left',
  },
});

export const ReceiveIvr = (props: ReceiveIvrProps) => {
  const { toggleReceiveFlag, receiveStatus, sessionId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selfServiceList, setSelfServiceList] = useState<
    [Array<{ ivrId: string; ivrName: string }>, Array<{ name: string; id: number }>]
  >([[], []]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSS, setCurrentSS] = useState<{ name: string; id: number } | { ivrId: string; ivrName: string }>();
  const receiveStatusMap = useMemo<Record<'ok' | 'timeout' | 'error' | 'waiting', string>>(
    () => ({
      ok: t('调用成功'),
      timeout: t('调用超时'),
      error: t('调用失败'),
      waiting: t('正在调用自助服务中'),
    }),
    [],
  );

  const getServiceList = useCallback(() => {
    setLoading(true);
    fetchAllInputCallbacksAndSelfServices(props.source).then((services) => {
      setSelfServiceList(services);
      setLoading(false);
    });
  }, [props.source]);
  useEffect(() => {
    getServiceList();
  }, [getServiceList]);

  if (receiveStatus === 'pending') {
    return (
      <>
        <CollapseList
          label={t('请选择自助服务')}
          emptyTips={t('无自助服务可用，请联系管理员配置')}
          value={currentSS as any}
          onSelected={(value) => setCurrentSS(value as any)}
          options={[
            ...selfServiceList[0].map((item) => ({ label: item.ivrName, value: item, groupKey: 'input-callback' })),
            ...selfServiceList[1].map((item) => ({ label: item.name, value: item, groupKey: 'teleSelfService' })),
          ]}
          loading={loading}
          groups={{
            'input-callback': t('自助收号'),
            teleSelfService: t('自助服务'),
          }}
          title={t('自助服务')}
        />
        <ActionButtonGroup className={classes.buttonGroups}>
          {(selfServiceList?.[0]?.length !== 0 || selfServiceList?.[1]?.length !== 0) && (
            <RoundedButton
              variant={'contained'}
              color={'primary'}
              disabled={!currentSS}
              onClick={() => {
                LogInteraction.doing('CLICK_TRIGGER').with(t('点击自助服务按钮'));
                if ('ivrId' in currentSS!) dispatch(triggerReceiveKey({ sessionId, ivrId: currentSS.ivrId }));
                else if ('id' in currentSS!) dispatch(triggerSelfService({ sessionId, selfId: currentSS.id! }));
              }}
            >
              {t('自助服务')}
            </RoundedButton>
          )}
          <RoundedButton variant={'outlined'} color={'default'} onClick={toggleReceiveFlag}>
            {t('返回')}
          </RoundedButton>
        </ActionButtonGroup>
      </>
    );
  }
  if (receiveStatus) {
    return (
      <>
        <div className={classes.receiveStatus}>{receiveStatusMap[receiveStatus]}</div>
        {receiveStatus !== 'waiting' && (
          <ActionButtonGroup className={classes.buttonGroups}>
            {(receiveStatus === 'error' || receiveStatus === 'timeout') && (
              <RoundedButton
                variant={'contained'}
                color={'primary'}
                disabled={!!currentSS}
                onClick={() => {
                  if ('ivrId' in currentSS!) dispatch(triggerReceiveKey({ sessionId, ivrId: currentSS.ivrId }));
                  else if ('id' in currentSS!) dispatch(triggerSelfService({ sessionId, selfId: currentSS.id! }));
                }}
              >
                {t('再次调用')}
              </RoundedButton>
            )}
            <RoundedButton variant={'outlined'} color={'default'} onClick={toggleReceiveFlag}>
              {t('返回通话')}
            </RoundedButton>
          </ActionButtonGroup>
        )}
      </>
    );
  }
  return null;
};
