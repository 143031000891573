import { withStyles, Button } from '@material-ui/core';

export const RoundedButton = withStyles({
  root: {
    height: 36,
    minWidth: 90,
    borderRadius: 20,
    fontSize: 14,
    color: '#FFF',
    padding: '4px 10px',
  },
  primary: {
    background: '#006EFF',
  },
  secondary: {
    background: '#E54545',
  },
  outlined: {
    borderColor: '#fff',
  },
  disabled: {
    color: 'rgba(255, 255, 255, 0.3) !important',
    background: 'rgba(255, 255, 255, 0.12) !important',
  },
  contained: {
    border: '1px solid #fff',
  },
  containedPrimary: {
    border: 'none',
  },
  containedSecondary: {
    border: 'none',
  },
})(Button);

export const HangUp = (props) => (
  <RoundedButton
    disableElevation
    disableFocusRipple
    disableRipple
    variant={'contained'}
    color={'secondary'}
    {...props}
  />
);
