const getStyles = function (theme) {
  return {
    root: {
      backgroundColor: 'white',
      padding: '14px 20px',
      display: 'flex',
      borderBottom: '1px solid #E5E5E5',
      justifyContent: 'space-between',
      maxWidth: '100%',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexGrow: 1,
      maxWidth: '100%',
      minWidth: 0,
    },
    nickName: {
      height: 20,
      fontSize: 14,
      fontFamily: theme.base.fontFamily.Medium,
      color: '#333333',
      display: 'block',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'break-all',
    },
    extra: {
      display: 'flex',
      marginTop: 3,
      alignItems: 'center',
      fontSize: 12,
      fontFamily: theme.base.fontFamily.Regular,
      color: '#999',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      gap: 12,
      lineClamp: '1',
    },
    source: {
      display: 'flex',
      minWidth: 0,
      lineHeight: '20px',
    },
    btnItem: {
      height: 36,
      padding: '8px 24px',
      borderRadius: 20,
      fontFamily: theme.base.fontFamily.Regular,
    },
    finishBtn: {
      border: '1px solid #E54545',
      color: '#E54545',
      '&:hover': {
        border: '1px solid #E54545',
      },
    },
    completeBtn: {
      color: theme.base.color.primary,
    },
    followUpBtn: {
      color: theme.base.color.primary,
      marginRight: 10,
    },
    blackUser: {
      marginRight: '20px',
      color: '#666',
    },
    btnBox: {
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
    },
    blackAdd: {
      width: 95,
      height: 45,
    },
    corpName: {
      color: '#006EFF',
    },
    timing: {
      display: 'inline-block',
      minWidth: 105,
    },
  };
};
export default getStyles;
