import * as React from 'react';

import { makeStyles } from '@material-ui/styles';
import { NavMenu } from '@tencent/tea-component';

const useStyles = makeStyles(() => ({
  topBar: {
    height: 56,
    background: '#2B303B',
  },
}));

interface TopBarProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export const TopBar: React.FC<TopBarProps> = (props) => {
  const classes = useStyles();
  return <NavMenu className={classes.topBar} left={props.left} right={props.right} />;
};
