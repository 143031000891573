import uniq from 'lodash/uniq';

import { APIs } from './httpClient';
export const apis = uniq([
  '/ccc/debug/seatLog',
  '/ccc/sendsms',
  '/tcccadmin/app/getWXAuthInfo',
  '/tcccadmin/app/getAppInfo',
  '/tcccadmin/app/getCommKV',
  '/tcccadmin/app/setCommKV',
  '/tcccadmin/app/bindWxIvr',
  '/tcccadmin/staff/updateGroupBatch',
  '/tcccadmin/staff/delRestReason',
  '/tcccadmin/staff/setPriority',
  '/tccclogin/login/checkLogin',
  '/tccclogin/login/loginByPass',
  '/tccclogin/login/loginInstance',
  '/tccclogin/account/bindMobile',
  '/tccclogin/captcha/getCaptcha',
  '/tccclogin/oauth/login',
  '/wxwork/getPreAuthCode',
  '/wxwork/setAuthInfo',
  '/wxwork/getAuthInfo',
  '/wxwork/setSelfAppInfo',
  ...Object.keys(APIs),
]);
