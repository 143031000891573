import { Fragment, useCallback } from 'react';

import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  TableFooter,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import each from 'lodash/each';
import map from 'lodash/map';
import PropTypes from 'prop-types';
// @material-ui/core components
// core components

import { useTranslation } from 'react-i18next';

import DSCircularProgress from 'src/components/DSCircularProgress/DSCircularProgress';
import DSPagination from 'src/components/DSPagination/DSPagination';
import { t } from 'src/i18n';
import { pick } from 'src/utils';

import style from './DSTable.style';

const useStyles = makeStyles(style);

function TableCellContent(props) {
  const { content } = props;
  if (content && content.type === 'actions') {
    return (
      <Fragment>
        {map(content.data, (item, index) => (
          <Button key={index} color="primary" variant="text" {...item}>
            {item.text}
          </Button>
        ))}
      </Fragment>
    );
  }
  if (Array.isArray(content)) {
    if (!content.length) {
      return '-';
    }
    if (content.every((item) => typeof item === 'string')) {
      return <Fragment>{content.join('、') || '-'}</Fragment>;
    }
    return <Fragment>{content || null}</Fragment>;
  }
  return <Fragment>{content || '-'}</Fragment>;
}

TableCellContent.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.object,
    }),
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.node,
  ]),
};

function DSTable({
  tableFooter,
  align,
  alignSpecial,
  loading,
  error,
  rowHover,
  tablePagination,
  paginationProps,
  tableDataParser,
  stickyHeader,
  onChecked,
  showTableBody,
  emptyText,
  cellProps,
  size,
  padding,
  showTableHead,
  ...other
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  let { tableHead } = other;
  const { tableData, rowEvents, order, checkKey, checkedValue } = other;
  const tableHeadKeys = Object.keys(tableHead);
  tableHead = typeof tableHead === 'object' ? Object.values(tableHead) : tableHead;
  const theme = useTheme();

  function handleCheck(event) {
    let checkData = [...checkedValue];
    const { value } = event.currentTarget;
    if (!checkData.includes(value)) {
      checkData.push(value);
    } else {
      checkData = checkData.filter((item) => item !== value);
    }
    onChecked?.(checkData);
  }

  function handleCheckAll(event) {
    const { checked } = event.currentTarget;
    let data = [];
    if (checked) {
      data = tableData.map((item) => item[checkKey]);
    }
    onChecked?.(data);
  }

  const renderTableBody = useCallback(() => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={tableHead.length} align="center">
            <div className={classes.progressContainer}>
              <DSCircularProgress size={16} />
              <div style={{ marginLeft: 9 }}>{t('加载中')}</div>
            </div>
          </TableCell>
        </TableRow>
      );
    }
    if (error) {
      return (
        <TableRow>
          <TableCell colSpan={tableHead.length} align="center">
            <div className={classes.progressContainer}>
              <Typography color="error">{error}</Typography>
            </div>
          </TableCell>
        </TableRow>
      );
    }
    if (showTableBody && tableData && tableData.length > 0) {
      return tableData.map((data, key) => {
        const prop = Array.isArray(data) ? data : pick(data, tableHeadKeys, tableDataParser, key);
        const rowEventsMap = {};
        each(rowEvents, (handler, type) => {
          rowEventsMap[type] = handler.bind(null, prop, key);
        });
        return (
          <TableRow {...rowEventsMap} hover={rowHover} key={['table-body', key].join('-')}>
            {checkKey ? (
              <TableCell>
                <Checkbox
                  onChange={handleCheck}
                  checked={checkedValue.includes(data[checkKey])}
                  color="primary"
                  value={data[checkKey]}
                />
              </TableCell>
            ) : null}
            {map(prop, (value, key) => (
              <TableCell key={key} align={alignSpecial[key] || align || 'center'} {...cellProps[tableHeadKeys[key]]}>
                <TableCellContent content={value} />
              </TableCell>
            ))}
          </TableRow>
        );
      });
    }
    if (!showTableBody) {
      return null;
    }
    return (
      <TableRow>
        <TableCell colSpan={tableHead.length + (checkKey ? 1 : 0)} align="center">
          <div className={classes.progressContainer}>{emptyText}</div>
        </TableCell>
      </TableRow>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableHead,
    tableData,
    loading,
    error,
    alignSpecial,
    align,
    rowEvents,
    rowHover,
    classes.progressContainer,
    theme.overrides.MuiTableCell.root.height,
  ]);

  return (
    <Table stickyHeader={stickyHeader} size={size}>
      {showTableHead && (
        <TableHead>
          <TableRow>
            {checkKey ? (
              <TableCell>
                <Checkbox
                  onChange={handleCheckAll}
                  checked={tableData?.length === checkedValue?.length}
                  color="primary"
                />
              </TableCell>
            ) : null}
            {tableHead.map((prop, key) => {
              let title;
              if (typeof prop === 'string') {
                title = prop;
              } else {
                title = prop.title;
              }
              return (
                <TableCell style={prop.style || {}} align={align || 'center'} key={key} sortDirection={order || false}>
                  {title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
      )}
      <TableBody>{renderTableBody()}</TableBody>
      {paginationProps && (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={tableHead.length}>
              <DSPagination {...paginationProps} />
            </TableCell>
          </TableRow>
        </TableFooter>
      )}
      {tableFooter ? (
        <TableFooter>
          <TableRow>
            {tableFooter.map((prop, key) => (
              <TableCell key={key} align={alignSpecial[key] || align || 'left'} colSpan={tableHead.length}>
                {prop}
              </TableCell>
            ))}
          </TableRow>
        </TableFooter>
      ) : null}
    </Table>
  );
}

DSTable.defaultProps = {
  tableHeaderColor: 'gray',
  tableHead: [],
  tableData: [],
  tableFooter: [],
  alignSpecial: {},
  loading: false,
  pagination: {},
  stickyHeader: false,
  showTableBody: true,
  showTableHead: true,
  emptyText: t('暂无数据'),
  cellProps: {},
  size: 'medium',
  padding: 'default',
};

DSTable.propTypes = {
  tableHead: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]),
  tableData: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.array), PropTypes.arrayOf(PropTypes.object)]),
  tableDataParser: PropTypes.func,
  rowHover: PropTypes.bool,
  rowEvents: PropTypes.object,
  tableFooter: PropTypes.array,
  align: PropTypes.string,
  alignSpecial: PropTypes.object,
  loading: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  error: PropTypes.string,
  tablePagination: PropTypes.node,
  checkedValue: PropTypes.array,
  checkKey: PropTypes.string,
  onChecked: PropTypes.func,
  paginationProps: PropTypes.shape({
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    countPerPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
  }),
  showTableBody: PropTypes.bool,
  showTableHead: PropTypes.bool,
  emptyText: PropTypes.string,
  cellProps: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium']),
  padding: PropTypes.oneOf(['default', 'checkbox', 'none']),
};

export default DSTable;
