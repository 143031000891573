import store from 'src/store';
// import { updateRecognizedMessages } from 'src/store/actions/agent';
import { updateRecognizedMessages } from 'src/store/slices/asr';

// key: roomId + userId
export const asrParserMap = new Map<string, AsrParser>();
enum Direction {
  IN = 'IN',
  OUT = 'OUT',
}
export class AsrParser {
  recognizingVoices: any[];
  recognizedVoices: any[];
  sessionId: string;
  constructor({ sessionId }: { sessionId: string }) {
    this.recognizingVoices = [];
    this.recognizedVoices = [];
    this.sessionId = sessionId;
    asrParserMap.set(sessionId, this);
  }

  handleRecognizeMessages = (msgBody: any) => {
    if (!msgBody.voice_text_str) {
      return;
    }
    const msgUniqId = msgBody.index + 1;
    const foundIndex = this.recognizingVoices.findIndex((msg) => msg.id === msgUniqId);
    const role = msgBody.flow === 'IN' ? 0 : 1;
    const text = msgBody.voice_text_str;
    const isRecognizeCompleted = msgBody.slice_type === 2;
    if (isRecognizeCompleted) {
      // 更新识别中及已识别列表
      this.recognizingVoices.splice(foundIndex, 1);
      this.recognizedVoices.push({
        text,
        role,
      });
    } else if (!~foundIndex) {
      // 首次发现
      this.recognizingVoices.push({
        id: msgUniqId,
        text,
        role,
      });
    } else {
      // 已入列
      this.recognizingVoices[foundIndex] = {
        ...this.recognizingVoices[foundIndex],
        text,
      };
    }
    const newMsgs = [...this.recognizedVoices, ...this.recognizingVoices].map((rv) => ({
      sessionId: this.sessionId,
      isRecognizing: !!rv.id,
      text: rv.text,
      direction: rv.role === 0 ? Direction.IN : (Direction.OUT as Direction),
    }));
    store.dispatch(updateRecognizedMessages({ sessionId: this.sessionId, result: newMsgs }));
  };
}
