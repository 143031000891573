import i18n from 'src/i18n';

export const DEFAULT_RATING_LEVEL = 5;
export const DEFAULT_EFFECTIVE_HOUR = 12;
export const MAX_EFFECTIVE_HOUR = 24;
export const MIN_EFFECTIVE_HOUR = 1;
export const RATING_TYPE = {
  STAR: 1,
  NUMBNER: 2,
};

export const SKILL_GROUP_TYPE = {
  // 电话
  TELEPHONE: 0,
  // IM
  IM: 1,
  // 音频
  AUDIO: 3,
  // 视频
  VIDEO: 4,
};

export enum SKILL_GROUP_TYPE_ENUM {
  // 电话
  TELEPHONE = 0,
  // IM
  IM = 1,
  // 音频
  AUDIO = 3,

  // 视频
  VIDEO = 4,
}

export enum SEAT_TYPE_ENUM {
  ADVANCED = 1,
  NORMAL = 2,
  IM_AGENT = 3,
  IM_DESKKIT = 4,
  NEW_STANDARD = 5,
  IVI_STANDARD = 6,
  IVI_PREMIUM = 7,
}
const { t } = i18n;
export const PROTOCOLS = [
  {
    label: t('隐私协议'),
    link: 'https://tccc.qcloud.com/assets/doc/Privacy-Protection-Guidelines.html',
  },
  {
    label: t('用户协议'),
    link: 'https://tccc.qcloud.com/assets/doc/User-Agreement.html',
  },
];

export enum IVR_TYPE {
  telephone = '0',
  im = '1',
  customerSatisfaction = '3',
  inputCallback = '4',
  audio = '5',
  teleAutoOutbound = '6',
  teleSelfService = '7',
  audioSelfService = '8',
  teleCallOut = '9',
  telePredictiveCallOut = '10',
}

export enum IVR_STATUS {
  IvrPubStateOK = '1',
  IvrPubStatePending = '2',
  IvrPubStateAssociated = '3',
  IvrPubStateRejected = '4',
}

export interface RoleItem {
  roleId: string;
  roleName: string;
  memberNumber: number;
}

export enum ERROR_CODES {
  // 需要白名单（出现在全部都需要白名单的情况）
  NEED_WHITELIST = '-4534',
  // 许可不足（只有够用或者不够用，没有部分够用的情况）
  LACK_OF_LICENCE = '-2032',
  // 座席套餐包失效
  SEAT_PKG_INVALID = '-2034',
  // 座席数量不足（老版）
  LACK_OF_SEAT = '-2027',
  // TOKEN 过期
  TOKEN_EXPIRED = '-2014',
}
