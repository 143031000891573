import { useState, useEffect, useRef, useCallback } from 'react';
import { clearWorkerInterval, workerInterval } from './timer';

interface CountdownProps {
  interval: number;
}
export function useCountdown(endTime = new Date(), options?: CountdownProps) {
  const { interval } = options || {
    interval: 1000,
  };
  const [count, setCount] = useState(() => {
    const offset = endTime.getTime() - Date.now();
    const count = offset / 1000;
    return Math.floor(count);
  });
  const timer = useRef<ReturnType<typeof workerInterval>>();
  useEffect(() => {
    timer.current = workerInterval(() => {
      if (count > 0) {
        setCount((count) => Math.floor(count - interval / 1000));
      } else if (timer.current) {
        clearWorkerInterval(timer.current);
        timer.current = undefined;
      }
    }, interval);
    return () => {
      if (timer.current) {
        clearWorkerInterval(timer.current);
        timer.current = undefined;
      }
    };
  }, [interval, count]);

  return count;
}

/**
 * 倒计时hooks
 * @param seconds 秒
 * @returns 当前计数
 */
export function useTimeout(seconds: number) {
  const count = useCountdown(new Date(Date.now() + seconds * 1000));
  return count;
}

/**
 * 计数hooks
 * @param value 初始值
 * @returns
 */
type UseCounterProps = number | (() => number);
export function useCounter(value: UseCounterProps) {
  const [counter, setCounter] = useState(value);
  const [pause, setPause] = useState(false);
  const timer = useRef<ReturnType<typeof workerInterval>>();

  const stop = useCallback(() => {
    setPause(true);
  }, []);
  useEffect(() => {
    if (!pause) {
      timer.current = workerInterval(() => {
        setCounter((counter) => counter + 1);
      }, 1000);
    } else if (timer.current) {
      clearWorkerInterval(timer.current);
    }
    return () => {
      if (timer.current) {
        clearWorkerInterval(timer.current);
      }
    };
  }, [pause]);

  return {
    counter,
    stop,
  };
}

function prepand(value: number) {
  if (value < 10) {
    return `0${value}`;
  }
  return `${value}`;
}
function secondsToHHmmss(value: number) {
  const sec = value;
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;
  return `${prepand(hours)}:${prepand(minutes)}:${prepand(seconds)}`;
}

/**
 *
 * @param value 初始时间, Unix 时间戳 (秒）
 * @returns
 */
export function useFormattedCounter(value = Math.floor(Date.now() / 1000)) {
  const { counter, stop } = useCounter(() => {
    const now = Math.floor(Date.now() / 1000);
    if (now < value) {
      return 0;
    }
    return now - value;
  });
  return {
    counter: secondsToHHmmss(counter),
    stop,
  };
}
