import { UserStatusType, LogicStatusType } from 'tccc-utils';

export const UserStatusMap: Record<UserStatusType, UserStatusType> = {
  offline: 'offline',
  free: 'free',
  rest: 'rest',
  notReady: 'notReady',
  busy: 'busy',
  countdown: 'countdown',
  arrange: 'arrange',
};

export const AgentLogicStatus: Record<LogicStatusType, LogicStatusType> = {
  ready: 'ready',
  notReady: 'notReady',
  rest: 'rest',
  afterCallWork: 'afterCallWork',
  offline: 'offline',
};
