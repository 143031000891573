import { TIPS_SUCCESS, TIPS_LOADING, TIPS_ERROR } from '../actions/loading';
const defaultState = {
  open: false,
  text: '',
  type: TIPS_LOADING,
};
const tipsReducers = function (srcState, action) {
  const state = srcState || defaultState;
  switch (action.type) {
    case TIPS_SUCCESS:
    case TIPS_LOADING:
    case TIPS_ERROR:
      return action.state;
    default:
      return state;
  }
};
export default tipsReducers;
