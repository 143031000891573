import { makeStyles } from '@material-ui/styles';
import { Text } from '@tencent/tea-component';
import isUndefined from 'lodash/isUndefined';

import i18n from 'src/i18n';
import { StaffStatus } from 'src/services/httpAPIs/admin';

const { t } = i18n;

export const EXTENSION_STATUS_MAP: Record<StaffStatus, string> = {
  0: t('离线'),
  100: t('示闲'),
  200: t('忙碌'),
  300: t('小休'),
  400: t('话后整理'),
  500: t('话后整理'),
  700: t('示忙'),
};

export const EXTENSION_STATUS_COLOR_MAP: Record<StaffStatus, string> = {
  0: '#ADADAD',
  100: '#0abf5b',
  200: '#F6CA53',
  300: '#006EFF',
  400: '#AD73EF',
  500: '#AD73EF',
  700: '#FC4F05',
};

interface ExtensionStatusProps {
  status?: StaffStatus;
  registerStatus?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  statusIcon: {
    display: 'block',
    width: '7px',
    height: '7px',
    borderRadius: '7px',
  },
});

export const ExtensionStatusText = ({ status, registerStatus }: ExtensionStatusProps) => {
  if (isUndefined(status) || !registerStatus) {
    return <Text theme={'strong'}>-</Text>;
  }

  return <Text style={{ color: `${EXTENSION_STATUS_COLOR_MAP[status]}` }}>{EXTENSION_STATUS_MAP[status]}</Text>;
};

export const ExtensionStatus = ({ status, registerStatus, style = {}, className = '' }: ExtensionStatusProps) => {
  const classes = useStyles();

  if (isUndefined(status) || !registerStatus) {
    return (
      <Text theme={'strong'} className={className} style={style}>
        -
      </Text>
    );
  }

  return (
    <div className={`${classes.container} ${className}`} style={style}>
      <span className={classes.statusIcon} style={{ background: EXTENSION_STATUS_COLOR_MAP[status] }}></span>
      <Text theme={'strong'}>{EXTENSION_STATUS_MAP[status]}</Text>
    </div>
  );
};
