/*
 *  @method validateEmail
 *  @param {String} email
 *  @return {Boolean}
 * */
/* eslint-disable */
function validateEmail(email) {
  let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

function validatePassword(password) {
  let re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^()])[A-Za-z\d@$!%*#?&^()]{8,20}$/
  return re.test(password)
}

function validatePhone(value) {
  let re = /^[1]([3-9])[0-9]{9}$/
  return re.test(value)
}

function validateURL(str) {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

/* eslint-enable */
const validateObject = {
  email: validateEmail,
  psw: validatePassword,
  required(value) {
    // eslint-disable-next-line no-undefined
    return value !== '' && value !== undefined;
  },
  boolean(value) {
    return value;
  },
  phone: validatePhone,
  code(value) {
    const re = /^\d{6}$/;
    return re.test(value);
  },
};

export default function validate(option) {
  return function (data) {
    const msg = {};
    const error = Object.keys(data).reduce((pre, key) => {
      if (!option[key]) return pre;
      for (const item of option[key]) {
        if (validateObject[item.type] && !validateObject[item.type](data[key])) {
          // eslint-disable-next-line no-param-reassign
          pre[key] = item.message;
          break;
        }
        if (item.validate) {
          const validData = item.validate(data);
          if (validData.valid === false) {
            // eslint-disable-next-line no-param-reassign
            pre[key] = validData.message;
          }
          break;
        }
      }
      return pre;
    }, {});
    msg.isValid = Object.keys(error).length === 0;
    msg.message = error;
    return msg;
  };
}

export { validateEmail, validatePassword, validatePhone, validateObject, validateURL };
