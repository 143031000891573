import { Editor, Extension } from '@tiptap/react';
import SuggestionExtension from '@tiptap/suggestion';

import type { Range } from '@tiptap/core';

function setLatelyQuickReply(id: string) {
  let latelyQuickReply: string[] = [];
  const latelyQuickReplyStr = localStorage.getItem('latelyQuickReply');
  if (latelyQuickReply) {
    latelyQuickReply = JSON.parse(latelyQuickReplyStr || '[]');
  }
  const index = latelyQuickReply.indexOf(id);
  if (index !== -1) {
    latelyQuickReply.splice(index, 1);
  }
  latelyQuickReply.push(id);
  if (latelyQuickReply.length > 5) {
    latelyQuickReply.shift();
  }
  localStorage.setItem('latelyQuickReply', JSON.stringify(latelyQuickReply));
}
export const QuickReplyExtension: Extension = Extension.create({
  name: 'quickReplay',
  addOptions() {
    return {
      suggestion: {
        char: '#',
        allowedPrefixes: null,
        command: ({
          editor,
          range,
          props,
        }: {
          editor: Editor;
          range: Range;
          props: { content: string; id: string };
        }) => {
          editor.chain().focus().deleteRange(range).insertContent(props.content).run();
          setLatelyQuickReply(props.id);
        },
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      SuggestionExtension({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});
