/* eslint-disable import/no-anonymous-default-export */
export default function () {
  return {
    errorIcon: {
      fontSize: '18px',
      color: 'red',
    },
    content: {
      padding: 10,
    },
  };
}
