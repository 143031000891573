import { GET_CAPTCHA_URL } from 'tccc-env/src/url';

export function dynamicLoadCaptcha() {
  return new Promise((resolve, reject) => {
    const captcha = document.getElementById('TCaptcha');
    if (!captcha) {
      const script = document.createElement('script');
      script.src = GET_CAPTCHA_URL();
      script.id = 'TCaptcha';
      script.addEventListener('load', resolve);
      script.addEventListener('error', reject);
      document.body.appendChild(script);
    } else {
      resolve(undefined);
    }
  });
}

export function verify(appId: string): Promise<TencentCaptchaResult | undefined> {
  return dynamicLoadCaptcha()
    .then(
      () =>
        new Promise<TencentCaptchaResult | undefined>((resolve, reject) => {
          new TencentCaptcha(appId, (res) => {
            switch (res.ret) {
              case 0:
                resolve(res);
                return;
              case 2:
                resolve(undefined);
                return;
              default:
                reject(`verify failed, ret: ${JSON.stringify(res)}`);
                return;
            }
          }).show();
        }),
    )
    .catch((error) => {
      if (error instanceof Error) {
        return generateFallbackCaptchaResult(appId, 1005, error.message);
      }
      if (error?.target?.id === 'TCaptcha') {
        return generateFallbackCaptchaResult(appId, 1001, 'jsload_error');
      }
      return generateFallbackCaptchaResult(appId, 1005, error?.toString() || 'unknown_error');
    });
}

export function generateFallbackCaptchaResult(
  appId: string,
  errorCode: number,
  errorMessage?: string,
): TencentCaptchaResult {
  return {
    ret: 0,
    appid: appId,
    randstr: `@${Math.random().toString(36).substr(2)}`,
    ticket: `terror_1001_${appId}_${Math.floor(new Date().getTime() / 1000)}`,
    errorCode,
    errorMessage,
  };
}
