import React from 'react';

import { styled } from '@material-ui/core';
import * as DOMPurify from 'dompurify';
import { marked } from 'marked';

type UserCustomElementProps = {
  data: {
    content: string;
  };
};

const RichText = styled('div')({
  '& img': {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
    marginTop: '6px',
  },
});

export const ImRobotElement: React.FC<UserCustomElementProps> = (props) => {
  const htmlString = marked(props?.data?.content);
  const cleanContent = DOMPurify.sanitize(htmlString);
  return <RichText dangerouslySetInnerHTML={{ __html: cleanContent }} />;
};
