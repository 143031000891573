import { ImCenterWrapper, ImCenterContent } from './ImCenter.style';

export const ImCenter = (props) => {
  const { content } = props;
  return (
    <ImCenterWrapper>
      <ImCenterContent>{content}</ImCenterContent>
    </ImCenterWrapper>
  );
};
