import get from 'lodash/get';

import { ROBOT_COMMAND, ROBOT_MESSAGE_TYPE } from 'src/constants/im';

import { RobotCardElement } from './RobotCardElement';
import { RobotTextElement } from './RobotTextElement';

export function RobotElement(props) {
  const { data } = props;
  const robot = get(data, 'content', {});
  if (robot.command === ROBOT_COMMAND.SHOW_DIALOG) {
    switch (robot.contentType) {
      case ROBOT_MESSAGE_TYPE.SIMPLE_TEXT:
      case ROBOT_MESSAGE_TYPE.RICH_TEXT:
      case ROBOT_MESSAGE_TYPE.MULTI_LINE_TEXT:
        return <RobotTextElement data={robot} />;
      case ROBOT_MESSAGE_TYPE.QUESTION_LIST:
        return <RobotCardElement data={robot} />;
      default:
        return null;
    }
  }
  return null;
}
