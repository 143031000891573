import styled, { css } from 'styled-components';

import { TIMSDK as TIM } from 'tccc-sdk';

import { TextElementWrapper } from '../../im-elements/text-element/TextElement.style';

interface FlowProps {
  flow?: string;
  renderBackground?: boolean;
  location?: string;
  messageType?: string;
}

export const MessageTime = styled.time`
  display: none;
  font-size: 12px;
  color: #151d32;
`;

export const ImAsideContentActions = styled.div<FlowProps>`
  border: 1px solid #d1d1d6;
  border-radius: 2px;
  background: #fff;
  display: none;
  cursor: pointer;
  ${(props) =>
    props.flow !== 'in'
      ? css`
          margin-right: 8px;
        `
      : css`
          margin-left: 8px;
        `}
`;

export const ImAsideWrapper = styled.div<FlowProps>`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  :hover ${MessageTime} {
    display: unset;
  }
  ${(props) =>
    props.flow === 'out' &&
    css`
      align-items: flex-end;
      ${ImAsideContent} {
        background: ${props.renderBackground ? '#dceafd' : 'white'};
        border-top-right-radius: ${props.messageType !== TIM.TYPES.MSG_IMAGE ? '0' : '8px'};
      }
      ${TextElementWrapper}::selection {
        color: black;
        background-color: white;
      }
    `}
  ${(props) =>
    props.flow === 'in' &&
    css`
      align-items: flex-start;
      ${ImAsideContent} {
        background: ${props.renderBackground ? '#fbfbfb' : 'white'};
        border-top-left-radius: ${props.messageType !== TIM.TYPES.MSG_IMAGE ? '0' : '8px'};
      }
    `}
  ${(props) =>
    props.flow === 'out-others' &&
    css`
      align-items: flex-end;
      ${ImAsideContent} {
        background: ${props.renderBackground ? '#f9f9f9' : 'white'};
        border-top-right-radius: ${props.messageType !== TIM.TYPES.MSG_IMAGE ? '0' : '8px'};
      }
      ${ImAsideContent} {
      }
    `}
`;

export const ImAsideHeader = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  color: #030303;
  opacity: 0.4;
  margin: 0 42px;
  margin-bottom: 4px;
`;

export const ImAsideBody = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 100%;
`;

export const ImAsideContent = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
`;

export const ImAsideStatus = styled.div``;

export const Avatar = styled.div<FlowProps>`
  transform: translateY(-21px);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  & img {
    width: 34px;
    height: 34px;
  }
  align-self: flex-start;
  ${(props) =>
    props.location === 'left' &&
    css`
      justify-content: flex-start;
      order: 0;
    `}
  ${(props) =>
    props.location === 'right' &&
    css`
      justify-content: flex-start;
      order: 4;
    `};
`;
