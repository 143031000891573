const getStyles = {
  snackbar: {
    top: 14,
  },
  snackbarContent: {
    backgroundColor: '#fff',
    minWidth: '200px !important',
  },
  loading: {
    color: '#9c27b0',
  },
  success: {
    color: 'rgba(15,175,150, 0.8)',
    fontSize: 30,
  },
  error: {
    color: 'red',
    fontSize: 30,
  },
  message: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    paddingLeft: 30,
    color: '#000',
  },
};
export default getStyles;
