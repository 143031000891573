import styled from 'styled-components';

export const TypingTipWrapper = styled.div`
  position: absolute;
  left: 20px;
  bottom: 10px;
`;
export const TypingTipText = styled.span`
  color: #999;
  font-size: 12px;
`;
