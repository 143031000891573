import { useState, useEffect } from 'react';

import { makeStyles, ListItem, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Timing } from 'src/components/Timing/Timing';

import { useCallOut } from '../../../views/Agent/CallOut/useCallOut';
import { Header } from '../components/Header';

import { Body } from './Body';
const useStyles = makeStyles({
  itemRoot: {
    width: 415,
    borderRadius: 4,
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
  },
  callPhoneInfo: {
    margin: '10px 0',
    display: 'inline-block',
    alignSelf: 'flex-start',
  },
  moreAction: {
    display: 'inline-block',
    marginLeft: 8,
    cursor: 'pointer',
  },
});
const useTypographyStyles = makeStyles((theme) => ({
  subtitle1: {
    height: 18,
    lineHeight: '18px',
    fontFamily: theme.base.fontFamily.Regular,
    fontWeight: 500,
    fontSize: 12,
    color: '#fff',
    marginRight: 4,
  },
  subtitle2: {
    height: 18,
    lineHeight: '18px',
    fontFamily: theme.base.fontFamily.Regular,
    fontSize: 12,
    color: '#fff',
  },
  body1: {
    fontFamily: theme.base.fontFamily.Regular,
    fontSize: 18,
    color: 'rgba(255, 255, 255, 0.8)',
    borderBox: '1px solid rgba(0,0,0,0.3)',
  },
}));

export function CallOutTips(props) {
  const history = useHistory();
  const classes = useStyles();
  const CRMMaximized = useSelector((s) => s.appSettings.CRMMaximized);
  const typographyClasses = useTypographyStyles();
  const { callInfo } = props;
  const { status, timestamp } = callInfo;
  const { callingStatusText, callDisplayText } = useCallOut({
    callInfo: props.callInfo,
  });
  const [dtmfFlag, setDtmfFlag] = useState(false);
  const [sendDtmfList, setSendDtmfList] = useState([]);

  useEffect(() => {
    setDtmfFlag(false);
  }, [status]);

  return (CRMMaximized ||
    (history.location.pathname.indexOf('agent') === -1 && history.location.pathname.indexOf('demo') === -1)) &&
    ['150', '200'].includes(status) ? (
    <ListItem className={classes.itemRoot}>
      <Header
        time={status === '200' && timestamp && <Timing startTime={timestamp} />}
        statusText={callingStatusText}
        callInfo={callInfo}
      />
      <Typography className={classes.callPhoneInfo} classes={typographyClasses} component={'div'} variant={'body1'}>
        {callDisplayText}
      </Typography>
      <Body
        callInfo={callInfo}
        dtmfFlag={dtmfFlag}
        setDtmfFlag={setDtmfFlag}
        sendDtmfList={sendDtmfList}
        setSendDtmfList={setSendDtmfList}
      />
    </ListItem>
  ) : null;
}
