import { useState, useEffect, Fragment } from 'react';

import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { CheckCircle, Error, Warning, Close } from '@material-ui/icons';
import { useTheme, withStyles } from '@material-ui/styles';
import cn from 'classnames';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DSCircularProgress from 'src/components/DSCircularProgress/DSCircularProgress';

import style from './BaseDialog.style';

function renderLoadResult(loadResult, loadMessage, classes) {
  switch (loadResult) {
    case 'success':
      return (
        <Fragment>
          <CheckCircle className={classes.icon} color="primary" />
          {loadMessage}
        </Fragment>
      );
    case 'error':
      return (
        <Fragment>
          <Error className={classes.icon} color="error" />
          {loadMessage}
        </Fragment>
      );
    case 'warn':
      return (
        <Fragment>
          <Warning className={classes.icon} color="warn" />
          {loadMessage}
        </Fragment>
      );
    default:
      return null;
  }
}

function BaseDialog({
  classes,
  open,
  title,
  children,
  actions,
  onClose,
  withCancel,
  loading,
  loadResult,
  loadMessage,
  dialogProps,
  paperClass,
  scrollPaperClass,
}) {
  const theme = useTheme();
  const [withCancelState, setWithCancelState] = useState(withCancel);
  const [openState, setOpenState] = useState(open);
  const [loadResultState, setLoadResultState] = useState(loadResult);
  const [loadMessageState, setLoadMessageState] = useState(loadMessage);
  const { t } = useTranslation();
  useEffect(() => {
    setOpenState(open);
    setWithCancelState(withCancel);
    setLoadResultState(loading ? null : loadResult);
    setLoadMessageState(loading ? null : loadMessage);
  }, [open, withCancel, loadResult, loadMessage, loading]);

  let closeIconSize;
  try {
    closeIconSize = theme.overrides.MuiTypography.h6.fontSize;
  } catch (e) {
    closeIconSize = '12px';
  }

  const handleCancel = (event) => {
    setOpenState(false);
    onClose?.(event);
  };

  const handleCloseDlg = () => {
    onClose?.();
  };

  return (
    <Dialog
      className={classes.root}
      open={openState}
      onClose={handleCloseDlg}
      classes={{
        paper: cn(classes.paper, paperClass),
        scrollPaper: scrollPaperClass,
      }}
      disableBackdropClick={true}
      {...dialogProps}
    >
      {title && (
        <DialogTitle align="left" disableTypography>
          <Typography variant="h6">{title}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCancel}>
            <Close style={{ fontSize: closeIconSize }} />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Fragment>
          {(loading || loadResultState) && (
            <div className={classes.progressContainer}>
              {loadResultState && renderLoadResult(loadResultState, loadMessageState, classes)}
            </div>
          )}
          {actions &&
            map(actions, (handlers, text) => (
              <Button
                className={cn(classes.actionsButton, classes.baseButton)}
                disabled={loading}
                variant="contained"
                key={text}
                color="primary"
                {...handlers}
              >
                {loading ? <DSCircularProgress size={16} /> : text}
              </Button>
            ))}
          {withCancelState ? (
            <Button
              className={cn(classes.actionsButton, classes.baseButton, classes.cancelButton)}
              color="inherit"
              disabled={loading}
              variant="outlined"
              onClick={handleCancel}
            >
              {t('取消')}
            </Button>
          ) : null}
        </Fragment>
      </DialogActions>
    </Dialog>
  );
}

BaseDialog.defaultProps = {
  withCancel: true,
};

BaseDialog.propTypes = {
  theme: PropTypes.object,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  withCancel: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  actions: PropTypes.object,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  loadMessage: PropTypes.string,
  loadResult: PropTypes.oneOf(['success', 'error', 'warn']),
  dialogProps: PropTypes.object,
  paperClass: PropTypes.object,
  scrollPaperClass: PropTypes.object,
};

export default withStyles(style)(BaseDialog);
