const tableStyle = () => ({
  loadCell: {
    width: '100%',
    height: '100%',
  },
  progressContainer: {
    display: 'flex',
    width: '100%',
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default tableStyle;
