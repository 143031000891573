const getStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginBottom: 0,
  },
  card: {
    padding: '30px 40px 26px 40px',
    overflow: 'visible',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 0,
  },
  message: {
    flexBasis: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 16,
    textAlign: 'center',
  },
  link: {
    display: 'inline-block',
    marginTop: '30px',
  },
});

export default getStyles;
