import { useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { updateImageViewer } from 'src/store/actions/im';

import { MessageListScrollContext } from '../../im-list/ImListInner';

const Container = styled.div`
  background-color: white;
`;
const StyledImage = styled.img`
  object-fit: contain;
  max-height: 200px;
  max-width: 300px;
`;

function ImageElement({ data, index }) {
  const { t } = useTranslation();
  const scrollContext = useContext(MessageListScrollContext);

  const dispatch = useDispatch();
  const { url, height } = [2, 1, 0].map((i) => data.payload.imageInfoArray[i]).find((item) => Boolean(item?.url));
  const [lightBoxModalIsOpen, setLightBoxModalOpen] = useState(false);

  function toggleModal() {
    dispatch(
      updateImageViewer({
        visible: true,
        currentImageId: data.ID,
      }),
    );
  }

  function imageOnload() {
    if (!height) {
      scrollContext?.invalidScrollToEnd && scrollContext.invalidScrollToEnd();
    }
  }
  return (
    <Container key={data.ID + index}>
      <StyledImage
        onClick={toggleModal}
        onLoad={imageOnload}
        height={height ? Math.min(height, 300) : undefined}
        src={url}
        alt={t('图片')}
      />
      {lightBoxModalIsOpen && (
        <Lightbox onCloseRequest={() => setLightBoxModalOpen(false)} mainSrc={data.payload.imageInfoArray?.[0]?.url} />
      )}
    </Container>
  );
}

ImageElement.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default ImageElement;
