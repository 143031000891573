import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { request } from 'src/services/httpClient';
import { RootState } from 'src/store';

interface GetStaffList {
  staffList: Array<Staff>;
  total: number;
}

export const useFreeStaffList = (
  params: { pageIndex: number; pageSize: number; currentSearchValue: string },
  isExecuteImmediately?: boolean,
) => {
  const { pageIndex, pageSize, currentSearchValue } = params;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { userId } = useSelector((state: RootState) => state.userInfo);
  const [data, setData] = useState<Partial<GetStaffList>>({});

  const fetchList = useCallback(() => {
    setLoading(true);
    setError(null);
    // 只筛选可转接的空闲座席
    request('/tcccadmin/staff/getStaffList', {
      pageNum: pageIndex - 1,
      pageSize,
      staffName: currentSearchValue,
      isNeedStatus: true,
      status: [100, 700],
    })
      .then((data) => {
        const { staffList = [], total } = data || [];
        const list = staffList
          .filter((item) => item.userId !== userId)
          .sort((a, b) => {
            if (a.status && b.status) {
              return +a.status - +b.status;
            }
            return -1;
          });
        setData({ staffList: list, total });
      })
      .catch((error: any) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentSearchValue, pageIndex, pageSize, userId]);

  useEffect(() => {
    if (isExecuteImmediately) {
      fetchList();
    }
  }, [fetchList, isExecuteImmediately]);

  return {
    loading,
    error,
    data,
    fetchList,
  };
};
