import Aegis from 'aegis-web-sdk';

import { GET_AEGIS_ID, GET_AEGIS_URL } from 'tccc-env/src/url';

const ignoreUrls = [
  /\/ccc\/debug\/seatLog/,
  /\/tcccadmin\/feedbackqueue\/queryQueueCount/,
  /^https:\/\/aegis.qq.com/,
  /\/ccc\/report\/seatConnectionState/,
  /\/ccc\/report\/seatNetworkQuality/,
].filter((i) => i instanceof RegExp);
const reportUrls = [
  /https:\/\/((local|test|pre)\.)?ccclogic\.pstn\.avc\.qcloud.com/,
  /https:\/\/api.ccc.cloud.tencent.(com.)?cn/,
].filter((i) => i instanceof RegExp);

const ignoreErrors = [/ResizeObserver loop limit exceeded/, /script error/i, /<anonymous>/, /chrome-extension/];
export const aegis = new Aegis({
  hostUrl: GET_AEGIS_URL(location.hostname),
  id: GET_AEGIS_ID(location.hostname),
  reportApiSpeed: true, // 接口测速
  reportAssetSpeed: true, // 静态资源测速,
  spa: true,
  beforeRequest(data) {
    if (data?.logType === 'log') {
      if (data?.log?.level === '1024') {
        const ignore = ignoreUrls.some((regexp) => regexp.test(data.msg));
        if (ignore) return false;
        const report = reportUrls.some((regexp) => regexp.test(data.msg));
        return report;
      }
      if (data?.log?.level === '4') {
        const ignore = ignoreErrors.some((regexp) => regexp.test(data.msg));
        if (ignore) return false;
      }
    }
    if (data?.logType === 'speed' && data?.logs?.type === 'fetch' && data?.logs?.url) {
      const ignore = ignoreUrls.some((regexp) => regexp.test(data.logs.url));
      if (ignore) return false;
      const report = reportUrls.some((regexp) => regexp.test(data.logs.url));
      return report;
    }
    return true;
  },
  api: {
    retCodeHandler(data, url, xhr) {
      try {
        // eslint-disable-next-line no-param-reassign
        data = JSON.parse(data);
      } catch (e) {}
      return {
        isErr: typeof data.errorCode === 'string' && data.errorCode !== '0',
        code: xhr.status,
      };
    },
  },
});
