import styled from 'styled-components';

import { Close } from 'src/assets/images/Close.svg';
export const ImReplyWrapper = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  gap: 8px;
  color: #8b8d90;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  background: #fbfbfb;
  justify-content: space-between;
  border-radius: 8px;
  max-width: 75%;
`;

export const ImReplyContent = styled.blockquote`
  font-size: 12px;
  padding: 0 8px;
  color: #444444;
  width: 100%;
  display: inline-block;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 5.4em;
  line-height: 1.8em;
  text-overflow: ellipsis;
`;

export const ImReplyFrom = styled.h5`
  font-size: 12px;
  display: inline;
  margin-bottom: 6px;
`;
export const ImReplyMessage = styled.div`
  width: 100%;
  display: inline;
  img {
    max-width: 30px;
    max-height: 30px;
  }

  & .emoji-wrapper {
    width: 12px;
    height: 12px;
  }
  & .emoji-img {
    transform: scale(0.1875);
  }
`;

export const ImReplyCloseIcon = styled(Close).attrs({
  width: 16,
  height: 16,
})`
  width: 16px;
  height: 16px;
  background-color: #dfdfdf;
  border-radius: 50%;
  fill: #4d4d4d;
  cursor: pointer;
  vertical-align: middle;
`;
