import { InputBase, InputProps, makeStyles, InputAdornment, Typography, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const useStyles = makeStyles({
  inputWrapper: {
    width: '100%',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: '1px solid rgba(0,0,0,0.7)',
  },
  inputAdornment: {
    position: 'absolute',
    width: 24,
    height: 24,
    borderRadius: '50%',
    overflow: 'hidden',
    background: '#f2f2f2',
    top: 8,
    right: 0,
    cursor: 'pointer',
  },
  iconButton: {
    width: 24,
    height: 24,
  },
  clearIcon: {
    width: 24,
    height: 24,
    background: '#F2F2F2',
    color: '#999',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  label: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  error: {
    color: '#e54545',
  },
});
const useInputStyles = makeStyles({
  root: {
    height: 33,
    padding: 0,
    textAlign: 'left',
    color: 'rgba(255,255,255,0.7)',
    border: 'none',
  },
  input: {
    fontSize: 24,
    fontWeight: 500,
    padding: 0,
  },
  disabled: {
    color: 'rgba(255,255,255,0.3)',
    '-webkit-text-fill-color': 'rgba(255,255,255,0.3) !important',
  },
});

const useTypographyStyles = makeStyles({
  subtitle2: {
    lineHeight: '18px',
    padding: '10px 0',
  },
});

export const Input: React.FC<
  InputProps & { label?: React.ReactNode; onClear?: () => void; clearable?: boolean; errorText?: React.ReactNode }
> = (props) => {
  const inputBaseClasses = useInputStyles();
  const typographyClasses = useTypographyStyles();
  const classes = useStyles();
  return (
    <div className={classes.inputWrapper}>
      <Typography
        className={classes.label}
        classes={typographyClasses}
        color={'inherit'}
        component={'div'}
        variant={'subtitle2'}
      >
        {props.label || ''}
      </Typography>
      <InputBase
        fullWidth
        classes={inputBaseClasses}
        value={props.value}
        endAdornment={
          props.clearable && (
            <InputAdornment
              className={classes.inputAdornment}
              position={'end'}
              style={
                !props.value || (typeof props.value === 'string' && props.value.length === 0)
                  ? { display: 'none' }
                  : { display: 'flex' }
              }
            >
              <IconButton className={classes.iconButton} onClick={() => props.onClear?.()}>
                <Clear className={classes.clearIcon} />
              </IconButton>
            </InputAdornment>
          )
        }
        {...props}
      />
      {props.errorText && (
        <Typography className={classes.error} classes={typographyClasses} component={'div'} variant={'subtitle2'}>
          {props.errorText}
        </Typography>
      )}
    </div>
  );
};
