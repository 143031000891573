import { useState } from 'react';

import { makeStyles, Tooltip } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SelfServiceSource } from 'src/services/httpAPIs/types';
import { RootState } from 'src/store';
import { Session } from 'src/store/slices/sessions';

import { useCallOut } from '../../../views/Agent/CallOut/useCallOut';
import { usePhoneState } from '../../../views/Agent/Workbench/Content/usePhoneState';
import { ActionButtonGroup } from '../components/ActionButtonGroup';
import { RoundedButton } from '../components/Button';

import { DtmfInput } from './DtmfInput';
import { ReceiveIvr } from './ReceiveIvr';
import { TransferIvr } from './TransferIvr';
import { TransferSeat } from './TransferSeat/TransferSeat';

interface BodyProps {
  callInfo: Session;
  dtmfFlag: boolean;
  setDtmfFlag: (flag: boolean) => void;
  sendDtmfList: string[];
  setSendDtmfList: React.Dispatch<React.SetStateAction<string[]>>;
}

const useStyles = makeStyles({
  actionButtonGroup: {
    width: '100%',
    marginTop: 10,
  },
  actionButton: {
    marginRight: 10,
  },
});

export const Body: React.FC<BodyProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { callInfo, dtmfFlag, setDtmfFlag } = props;
  const { status: agentStatus } = useSelector((state) => state.agent);

  const { holdStatus, canHold, canTransfer, canReceive, canSendDTMF } = usePhoneState({
    callInfo,
    agentStatus,
  });
  const isAdvanced = useSelector((state: RootState) => state.appSettings.isAdvanced);
  const isDemoAccount = useSelector((state: RootState) => state.userInfo.isDemoAccount);
  const { endCallFunc, endCallText, toggleHold, receiveStatus, toggleReceiveFlag, toggleMicStatus } = useCallOut({
    callInfo,
  });
  const { status, type, ivrSoundPlaying, sessionId, micStatus } = callInfo;
  const isCallOuting = status === '200' && type !== 'monitor';
  const disableAction = holdStatus || micStatus === 'off';

  const [transfer, setTransfer] = useState(false);
  const [isSeatListVisible, setIsSeatListVisible] = useState(false);

  if (receiveStatus) {
    // @ts-ignore
    return (
      <ReceiveIvr
        sessionId={sessionId}
        source={type === 'voip' ? SelfServiceSource.audioSelfService : SelfServiceSource.teleSelfService}
        toggleReceiveFlag={toggleReceiveFlag}
        receiveStatus={receiveStatus}
      />
    );
  }
  if (transfer) {
    return <TransferIvr toggleTransferFlag={setTransfer} sessionId={sessionId} />;
  }

  if (isSeatListVisible) {
    return <TransferSeat sessionId={sessionId} onCancel={() => setIsSeatListVisible(false)} />;
  }

  if (dtmfFlag) {
    return (
      <>
        <DtmfInput
          sessionId={sessionId}
          status={status}
          sendDtmfList={props.sendDtmfList}
          setSendDtmfList={props.setSendDtmfList}
        />
        <ActionButtonGroup className={classes.actionButtonGroup}>
          {isCallOuting && (
            <RoundedButton
              className={classes.actionButton}
              variant={'outlined'}
              color={'default'}
              onClick={() => {
                setDtmfFlag(false);
              }}
            >
              {t('返回通话')}
            </RoundedButton>
          )}
        </ActionButtonGroup>
      </>
    );
  }

  return (
    <ActionButtonGroup className={classes.actionButtonGroup}>
      <RoundedButton
        disableElevation
        disableFocusRipple
        disableRipple
        size="small"
        variant={'contained'}
        color={'secondary'}
        className={classes.actionButton}
        onClick={endCallFunc}
      >
        {endCallText}
      </RoundedButton>
      {canHold && (
        <Tooltip
          title={isDemoAccount ? t('体验账号无法使用该功能') : t('对自己静音，不影响收听对方声音，为对方播放等待音')}
          enterDelay={800}
        >
          <RoundedButton
            className={classes.actionButton}
            variant={'contained'}
            color={'primary'}
            disabled={ivrSoundPlaying || micStatus === 'off'}
            onClick={toggleHold}
          >
            {holdStatus ? t('通话取回') : t('通话保持')}
          </RoundedButton>
        </Tooltip>
      )}
      {isCallOuting && (
        <Tooltip
          title={isDemoAccount ? t('体验账号无法使用该功能') : t('对自己静音，不影响收听对方声音')}
          enterDelay={800}
        >
          <RoundedButton
            className={classes.actionButton}
            variant={'contained'}
            color={'primary'}
            disabled={isDemoAccount ? true : ivrSoundPlaying || holdStatus}
            onClick={toggleMicStatus}
          >
            {micStatus === 'off' ? t('取消静音') : t('静音')}
          </RoundedButton>
        </Tooltip>
      )}
      {canTransfer && (
        <Tooltip
          title={isDemoAccount ? t('体验账号无法使用该功能') : t('点击后可跳转选择目标技能组并转接走')}
          enterDelay={800}
        >
          <RoundedButton
            className={classes.actionButton}
            variant={'contained'}
            color={'primary'}
            disabled={isDemoAccount ? true : ivrSoundPlaying || disableAction}
            onClick={() => setTransfer(true)}
          >
            {t('转技能组')}
          </RoundedButton>
        </Tooltip>
      )}
      {canTransfer && (
        <Tooltip
          title={isDemoAccount ? t('体验账号无法使用该功能') : t('点击后可搜索选择目标座席并转接走')}
          enterDelay={800}
        >
          <RoundedButton
            className={classes.actionButton}
            variant={'contained'}
            color={'primary'}
            disabled={isDemoAccount ? true : ivrSoundPlaying || disableAction}
            onClick={() => setIsSeatListVisible(true)}
          >
            {t('转座席')}
          </RoundedButton>
        </Tooltip>
      )}
      {canReceive && (
        <Tooltip
          title={isDemoAccount ? t('体验账号无法使用该功能') : t('点击后可跳转选择预置的自助服务')}
          enterDelay={800}
        >
          <RoundedButton
            className={classes.actionButton}
            variant={'contained'}
            color={'primary'}
            disabled={isDemoAccount || ivrSoundPlaying || disableAction || !isAdvanced}
            onClick={toggleReceiveFlag}
          >
            {t('自助服务')}
          </RoundedButton>
        </Tooltip>
      )}
      {canSendDTMF && !dtmfFlag && (
        <Tooltip
          title={isDemoAccount ? t('体验账号无法使用该功能') : t('点击后可输入分机号并自动发送')}
          enterDelay={800}
        >
          <RoundedButton
            className={classes.actionButton}
            style={{ fontSize: '13px' }}
            variant={'contained'}
            color={'primary'}
            disabled={isDemoAccount ? true : ivrSoundPlaying || disableAction}
            onClick={() => setDtmfFlag(true)}
          >
            {t('输入分机号')}
          </RoundedButton>
        </Tooltip>
      )}
    </ActionButtonGroup>
  );
};
