/* eslint-disable @typescript-eslint/member-ordering */
import mitt from 'mitt';
// @ts-ignore
window.mitt = mitt;
// @ts-ignore
if (!window.IframeCommunication) {
  const IframeCommunication = (function () {
    class IframeCommunication {
      private isParent;
      private targetWindow;
      private from;
      private to;
      private emitter;
      constructor(params: { isParent: any; targetWindow: any; from: any; to: any }) {
        this.isParent = params.isParent;
        this.targetWindow = params.targetWindow;
        this.from = params.from;
        this.to = params.to;
        // @ts-ignore
        this.emitter = window.mitt()!;
        this.initEvents();
      }
      public receiveMessage = (event: { data: string }) => {
        try {
          const info = JSON.parse(event.data);
          if (info.from && info.to) {
            this.emitter.emit(info.cmd, info);
          }
        } catch (e) {}
      };

      public sendMessage = (cmd: string, params: any) => {
        const sendMsg = {
          cmd,
          from: this.from,
          to: this.to,
          params,
        };
        if (this.targetWindow) {
          this.targetWindow.postMessage(JSON.stringify(sendMsg), '*');
        }
      };

      public initEvents = () => {
        window.addEventListener('message', this.receiveMessage);
      };

      public on = (eventName: string, func: (data: any) => void) => {
        this.emitter.on(eventName, func);
      };
      public destroy = () => {
        window.removeEventListener('message', this.receiveMessage);
      };
    }
    return IframeCommunication;
  })();
  // @ts-ignore
  window.IframeCommunication = IframeCommunication;
}

// @ts-ignore
const communication = new window.IframeCommunication({
  isParent: false,
  from: 'tcccContent',
  to: 'experience-center',
  targetWindow: window.top,
});

export function sendMessageToIframe(msgObj: { type?: string; data?: Object }) {
  communication.sendMessage('notify-sidebar', {
    content: msgObj,
  });
}

export function onReceiveIframeEvent(eventName: string, func: Function) {
  communication.on(eventName, func);
}
