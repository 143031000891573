const getStyles = {
  paper: {
    width: 'auto',
    maxHeight: '800px',
    flex: 1,
    minWidth: 'max(calc(100vw - 200px), 600px)',
  },
  scrollPaper: {
    flexDirection: 'column',
  },
};

export default getStyles;
