import React, { useState } from 'react';

import { styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { unwrapResult } from 'tccc-sdk';

import { RootState } from 'src/store';

import tccc from 'src/utils/tccc';

import { decodeText } from './decodeText';

type TextElementProps = {
  data: { payload: { text: string }; flow: string; from: string };
};

const TextContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '12px',
  '& .text-box': {
    display: 'inline-block',
    wordBreak: 'break-all',
    whiteSpace: 'pre-line',
  },
  '& .text-img': {
    width: '20px',
    height: '20px',
    display: 'inline-block',
  },
});

const WhiteBlock = styled('div')({
  height: '4px',
  background: 'white',
});

const TranslateButton = styled('div')({
  fontSize: '12px',
  color: '#006EFF',
  cursor: 'pointer',
  padding: '0 12px 0 12px',
});

const TranslateContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '12px',
  background: '#f2f7ff',
  overflow: 'auto',
  wordBreak: 'break-all',
  whiteSpace: 'pre-line',
});

const translateText = async (sourceLanguage: string, targetLanguage: string, sourceTextList: string[]) => {
  const translateRes = await tccc.Chat.translateText({
    sourceLanguage,
    targetLanguage,
    sourceTextList,
  }).then(unwrapResult);
  return translateRes;
};

export const TextElement: React.FC<TextElementProps> = (props) => {
  const { sdkAppId } = useSelector((state: RootState) => state.userInfo);
  const [translatStatus, setTranslatStatus] = useState('close');
  const [translateMemoey, setTranslateMemoey] = useState('');
  const [translatText, setTranslatText] = useState('');
  const { t } = useTranslation();
  const renderDom = decodeText(props?.data?.payload);
  const whiteList = ['1600037108', '1600045926', '20009044', '20011559'];

  const translateClick = (status: string) => {
    switch (status) {
      case 'close': {
        translate();
        break;
      }
      case 'finished': {
        setTranslatStatus('close');
        break;
      }
      case 'error': {
        translate();
        break;
      }
    }
  };

  const translate = async () => {
    try {
      if (props.data.flow === 'in' && props.data.from !== 'administrator') {
        if (translateMemoey !== '') {
          setTranslatText(translateMemoey);
          setTranslatStatus('finished');
          return;
        }
        setTranslatStatus('translating');
        const translateRes = await translateText('auto', 'zh', [props?.data?.payload.text]);
        if (translateRes?.[0].length) {
          setTranslatText(translateRes[0]);
          setTranslateMemoey(translateRes[0]);
        }
        setTranslatStatus('finished');
      }
    } catch (e) {
      console.log(e);
      setTranslatStatus('error');
    }
  };

  return (
    <>
      <TextContainer>
        {renderDom.map((item, index) => {
          if (item.name === 'text') {
            return (
              <div className="text-box" key={index}>
                {item.text}
              </div>
            );
          }
          if (item.name === 'img') {
            return <img className="text-img" key={index} src={item.src} alt="" />;
          }
          return null;
        })}
      </TextContainer>
      {props?.data?.flow === 'in' && props?.data?.from !== 'administrator' && whiteList.includes(sdkAppId) && (
        <TranslateButton
          onClick={() => {
            translateClick(translatStatus);
          }}
        >
          {translatStatus === 'close' && t('翻译')}
          {translatStatus === 'translating' && ''}
          {translatStatus === 'finished' && t('收起翻译')}
          {translatStatus === 'error' && t('重新翻译')}
        </TranslateButton>
      )}
      {['translating', 'finished', 'error'].includes(translatStatus) && (
        <>
          <WhiteBlock></WhiteBlock>
          <TranslateContainer>
            {translatStatus === 'translating' && t('翻译中')}
            {translatStatus === 'finished' && translatText}
            {translatStatus === 'error' && t('翻译失败')}
          </TranslateContainer>
        </>
      )}
    </>
  );
};
