import React, { useEffect, useMemo } from 'react';

import { Card, CardContent, CardHeader, CardActions, SvgIcon, ButtonBase, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Timing } from 'src/components/Timing/Timing';
import { RootState, selectAllSession } from 'src/store';
import { changeMicStatus } from 'src/store/slices/sessions/rtc';
import { endSession, muteVideo, unmuteVideo } from 'src/store/slices/sessions/sessions.thunk';

import tccc from '../../../../../utils/tccc';
import { WifiStatus } from '../components/WifiStatus';

import { CameraButton } from './CameraButton';
import { MicButton } from './MicButton';
import seatSvg from './seat.svg';
import { StreamInfo } from './StreamInfo';
import userSvg from './user.svg';

const useCardStyles = makeStyles({
  root: {
    position: 'absolute',
    width: 760,
    background: 'rgba(0, 0, 0, 0.8)',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(54.3656px)',
    zIndex: 10,
    '@media (min-height: 900px)': {
      width: 460,
    },
  },
});
const useCardTitleStyles = makeStyles({
  root: {
    padding: 20,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    color: '#FDFDFD',
    display: 'flex',
    alignItems: 'center',
  },
});

const useStyles = makeStyles({
  floatVideoCard: {
    position: 'absolute',
  },
  cardContent: {
    padding: '16px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '@media (min-height: 900px)': {
      flexDirection: 'column',
    },
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: 12,
    fontWeight: 400,
    color: '#FFF',
    paddingBottom: 20,
  },
  icon: {
    width: 48,
    height: 48,
    marginBottom: 8,
  },
  localStream: {
    position: 'relative',
    background: '#1D1D1D',
    minHeight: 200,
    flexBasis: '50%',
    '@media (min-height: 900px)': {
      flexBasis: 'auto',
      marginTop: 8,
    },
  },
  remoteStream: {
    position: 'relative',
    height: 318,
    background: '#1D1D1D',
    flexBasis: '50%',
    '@media (min-height: 900px)': {
      flexBasis: 'auto',
    },
  },
  remoteStreamContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '50%',
  },
  localStreamContainer: {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    backgroundImage: `url(${require('./local-background.jpg')})`,
    backgroundSize: 'cover',
    zIndex: -1,
  },
  muteContainer: {
    zIndex: 1,
  },
  remoteAvatar: {
    width: 112,
    height: 112,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  network: {
    marginLeft: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
const useButtonStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
  },
});

export const FloatVideoCard = () => {
  const { t } = useTranslation();
  const sessions = useSelector(selectAllSession);
  const { staffName } = useSelector((state: RootState) => state.userInfo);
  const dispatch = useDispatch();
  const cardClasses = useCardStyles();
  const cardTitleClasses = useCardTitleStyles();
  const buttonClasses = useButtonStyles();
  const classes = useStyles();
  const hasVideoCall = useMemo(
    () => sessions.find((session) => session.type === 'video' && session.status !== '400'),
    [sessions],
  );
  const hasVideoMedia = useMemo(
    () =>
      sessions.find(
        (session) =>
          session.type === 'im' &&
          session.status === '200' &&
          (session.media?.status === '200' || session.media?.status === '150') &&
          session.media?.type === 'video',
      ),
    [sessions],
  );
  const endMedia = () => {
    if (hasVideoMedia) {
      dispatch(endSession({ sessionId: hasVideoMedia.sessionId, closeBy: 'seat' }));
    }
  };
  const changeMic = () => {
    if (hasVideoMedia) {
      dispatch(
        changeMicStatus({
          sessionId: hasVideoMedia.sessionId,
          micStatus: hasVideoMedia.micStatus === 'off' ? 'on' : 'off',
        }),
      );
    }
  };
  const handleCamera = () => {
    if (hasVideoMedia) {
      if (hasVideoMedia.disableCamera) {
        dispatch(unmuteVideo({ sessionId: hasVideoMedia.sessionId }));
      } else {
        dispatch(muteVideo({ sessionId: hasVideoMedia.sessionId }));
      }
    }
  };

  useEffect(() => {
    if (hasVideoMedia) {
      tccc.Video.play({ sessionId: hasVideoMedia.sessionId, id: 'remoteVideo' }).catch(console.error);
      tccc.Video.play({ sessionId: hasVideoMedia.sessionId, id: 'mainVideo', local: true }).catch(console.error);
    }
  }, [hasVideoMedia]);

  if (hasVideoCall) return null;
  return (
    <Draggable bounds={'html'}>
      <Card classes={cardClasses} style={{ visibility: hasVideoMedia ? 'visible' : 'hidden' }}>
        <CardHeader
          classes={cardTitleClasses}
          title={
            <>
              {hasVideoMedia?.media?.status === '200' ? t('通话中  ') : t('等待接听中  ')}
              {hasVideoMedia?.media?.startTime && <Timing startTime={hasVideoMedia.media.startTime} />}
              <div className={classes.network}>
                <WifiStatus />
              </div>
            </>
          }
        />
        <CardContent classes={{ root: classes.cardContent }}>
          <div className={classes.remoteStream} id="remoteVideo">
            <div className={classes.remoteStreamContainer}>
              <Avatar className={classes.remoteAvatar} src={userSvg}></Avatar>
            </div>
            <StreamInfo
              name={hasVideoMedia?.nickname || t('访客')}
              side="client"
              sessionId={hasVideoMedia?.sessionId || ''}
              micStatus={hasVideoMedia?.micStatus === 'off' || hasVideoMedia?.holdStatus}
            />
          </div>
          <div className={classes.localStream} id="mainVideo">
            <div
              className={classnames(classes.remoteStreamContainer, classes.localStreamContainer, {
                [classes.muteContainer]: hasVideoMedia?.disableCamera,
              })}
            >
              <Avatar className={classes.remoteAvatar} src={seatSvg}></Avatar>
            </div>
            <StreamInfo name={staffName} side="seat" sessionId={hasVideoMedia?.sessionId || ''} />
          </div>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <ButtonBase disableRipple classes={buttonClasses} onClick={endMedia}>
            <SvgIcon width="48" height="48" viewBox="0 0 48 48" fill="none" className={classes.icon}>
              <g filter="url(#filter0_b_11056_8)">
                <rect x="0.23999" width="47.52" height="48" rx="23.76" fill="#FA5151" />
              </g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.1818 18.9202C32.2775 18.9023 37.4137 21.9831 37.3839 24.2225C37.3831 26.1421 37.887 29.0066 33.9765 28.5574C30.0659 28.1082 30.3118 26.6685 30.3415 24.6617C30.3565 23.2802 27.1673 23.0052 24.1802 22.9919C21.1785 23.0223 18.0036 23.2853 18.0175 24.6668C18.0167 26.6736 18.247 28.1277 14.3938 28.551C10.454 28.9743 10.9313 26.1094 10.961 24.2189C10.9474 21.994 16.1149 18.9381 24.1818 18.9202Z"
                fill="white"
              />
              <defs>
                <filter
                  id="filter0_b_11056_8"
                  x="-10.3968"
                  y="-10.6368"
                  width="68.7935"
                  height="69.2735"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImage" stdDeviation="5.31838" />
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11056_8" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_11056_8" result="shape" />
                </filter>
              </defs>
            </SvgIcon>
            {t('挂断')}
          </ButtonBase>
          <MicButton open={hasVideoMedia?.micStatus !== 'off'} onClick={changeMic} />
          <CameraButton open={!hasVideoMedia?.disableCamera} onClick={handleCamera} />
        </CardActions>
      </Card>
    </Draggable>
  );
};
