import React from 'react';

import { styled } from '@material-ui/core';

type OrderElementProps = {
  guide?: string;
  name?: string;
  desc?: string;
  pic?: string;
  customField?: { name: string; value: string; valueColor: string; customValue: string }[];
};

const OrderContainer = styled('div')({
  maxWidth: '400px',
  width: '400px',
  backgroundColor: 'white',
  border: '1px solid #999999',
  borderRadius: '10px',
  paddingBottom: '10px',
  paddingTop: '10px',
});

const OrderInfoContainer = styled('div')({
  display: 'flex',
  background: '#f9f9f9',
  padding: '10px',
});

const GuideText = styled('div')({
  fontSize: '18px',
  color: 'black',
  fontWeight: 'bold',
  wordBreak: 'break-all',
  padding: '10px',
});

const OrderName = styled('div')({
  fontSize: '16px',
  color: 'black',
  fontWeight: 'bold',
  wordBreak: 'break-all',
});

const OrderDesc = styled('div')({
  fontSize: '14px',
  color: '#999999',
  fontWeight: 'bold',
  wordBreak: 'break-all',
});

const CustomFieldContainer = styled('div')({
  // 暂时不需要滚动
  // maxHeight: '100px',
  // overflow: 'auto',
});

const CustomFieldItem = styled('div')({
  display: 'flex',
  padding: '10px',
});

export const OrderLement: React.FC<OrderElementProps> = ({ guide, name, desc, pic, customField }) => (
  <OrderContainer>
    {guide && <GuideText>{guide?.toString()}</GuideText>}
    <OrderInfoContainer>
      <img src={pic} style={{ width: '60px', height: '60px', objectFit: 'fill' }} />
      <div style={{ marginLeft: '5px' }}>
        <OrderName>{name?.toString()}</OrderName>
        <OrderDesc>{desc?.toString()}</OrderDesc>
      </div>
    </OrderInfoContainer>
    <CustomFieldContainer>
      {Array.isArray(customField) &&
        customField?.map((item) => (
          <CustomFieldItem style={{ display: 'flex', padding: '10px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold', wordBreak: 'keep-all' }}>{item?.name?.toString()}</div>
            <div
              style={{
                color: item?.valueColor,
                wordBreak: 'break-all',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              {item?.value?.toString()}
            </div>
          </CustomFieldItem>
        ))}
    </CustomFieldContainer>
  </OrderContainer>
);
