import * as t from 'io-ts';

import { getSession } from 'src/components/VerifyCode/captchaSessionMange';
import services from 'src/services';
import {
  booleanFromString,
  booleanFromString0or1,
  nullableArray,
  numberFromString,
  getDecodedResultOrReportError,
} from 'src/utils/codecs';

import { request } from '../httpClient';

export const captchaType = t.intersection([
  t.type({
    ticket: t.string,
    randstr: t.string,
    appid: t.string,
  }),
  t.partial({
    errorCode: t.number,
    errorMessage: t.string,
  }),
]);
export const loginInstanceResponseC = t.intersection([
  t.type({
    appInfo: t.partial({
      callTimeOut: numberFromString,
      endCountDown: numberFromString,
      imEndCountDown: numberFromString,
      imTimeOut: numberFromString,
      smsPrivilege: booleanFromString0or1,
    }),
    staff: t.type({
      email: t.string,
      mobile: t.string,
      nickName: t.string,
      roleId: t.string,
      sdkAppId: t.string,
      skillGroupId: nullableArray(t.string),
      staffName: t.string,
      staffNo: t.string,
    }),
  }),
  t.partial({
    isBindMobile: t.union([booleanFromString, t.boolean]),
  }),
]);

const commonLoginResponseC = t.type({
  errorCode: t.string,
});

const loginResponseC = t.intersection([
  commonLoginResponseC,
  t.type({
    isBindMobile: booleanFromString,
  }),
]);

export const loginByPass = ({
  userid,
  passwd,
  captcha,
}: {
  userid: string;
  passwd: string;
  captcha: t.TypeOf<typeof captchaType>;
}) =>
  request('/tccclogin/login/loginByPass', {
    staff: {
      userid,
      passwd,
    },
    captcha,
  }).then((rawResponse: any) => getDecodedResultOrReportError(loginResponseC.decode(rawResponse)));

export const loginByCaptcha = async ({ mobile, captchaCode }: { mobile: string; captchaCode: string }) => {
  const rawResponse = await services['/tccclogin/login/loginByCaptcha']({
    mobile,
    captcha: {
      value: captchaCode,
      sessionId: getSession(mobile),
      module: 'login',
    },
  });
  return getDecodedResultOrReportError(commonLoginResponseC.decode(rawResponse));
};

export const verifyMobile = async ({
  mobile,
  captchaCode,
  module,
}: {
  mobile: string;
  captchaCode: string;
  module: 'changeMobile' | 'login';
}) => {
  const rawResponse = await services['/tccclogin/login/loginByCaptcha']({
    mobile,
    captcha: {
      value: captchaCode,
      sessionId: getSession(mobile),
      module,
    },
  });
  return getDecodedResultOrReportError(commonLoginResponseC.decode(rawResponse));
};

export const loginAPIs = {
  '/tccclogin/login/loginByPass': {
    Input: t.type({
      staff: t.type({
        userid: t.string,
        passwd: t.string,
      }),
      captcha: captchaType,
    }),
    Output: t.intersection([
      commonLoginResponseC,
      t.type({
        isBindMobile: booleanFromString,
      }),
    ]),
  },
  '/tccclogin/login/loginByCaptcha': {
    Input: t.type({
      mobile: t.string,
      captcha: t.type({
        value: t.string,
        sessionId: t.string,
        module: t.union([
          t.literal('login'),
          t.literal('bindMobile'),
          t.literal('changePass'),
          t.literal('changeMobile'),
          t.literal('loginDemo'),
        ]),
      }),
    }),
    Output: commonLoginResponseC,
  },
  '/tccclogin/login/checkLogin': {
    Input: t.type({}),
    Output: t.intersection([
      loginResponseC,
      t.type({
        userId: t.string,
      }),
    ]),
  },
  '/tccclogin/captcha/getCaptcha': {
    Input: t.type({
      module: t.union([
        t.literal('login'),
        t.literal('bindMobile'),
        t.literal('changePass'),
        t.literal('changeMobile'),
        t.literal('loginDemo'),
      ]),
      mobile: t.string,
      captcha: captchaType,
    }),
    Output: t.type({
      sessionId: t.string,
    }),
  },
  '/tccclogin/login/loginInstance': {
    Input: t.type({
      staff: t.type({
        sdkAppId: t.string,
      }),
      sdkAppId: t.string,
      userId: t.string,
    }),
    Output: loginInstanceResponseC,
  },
  '/tccclogin/login/checkSDKLoginToken': {
    Input: t.type({
      staff: t.type({
        userid: t.string,
      }),
      sdkAppId: t.string,
      token: t.string,
    }),
    Output: loginInstanceResponseC,
  },
  '/tccclogin/demo/createSDKLoginTokenByCaptcha': {
    Input: t.type({
      mobile: t.string,
      captcha: t.type({
        module: t.literal('loginDemo'),
        sessionId: t.string,
        value: t.string,
      }),
    }),
    Output: t.type({
      Token: t.string,
      SdkURL: t.string,
      ExpiredTime: t.number,
      ServingNum: t.string,
      WebAppId: t.string,
    }),
  },
  '/tccclogin/login/checkInstanceLogin': {
    Input: t.type({}),
    Output: t.type({
      expireTime: t.string,
      userId: t.string,
      sdkAppId: t.number,
    }),
  },
  '/tccclogin/account/changePass': {
    Input: t.type({
      newPasswd: t.string,
      emails: nullableArray(t.string),
      isInstance: t.boolean,
    }),
    Output: t.type({}),
  },
};

export type CaptchaModule = t.TypeOf<typeof loginAPIs['/tccclogin/captcha/getCaptcha']['Input']>['module'];
