import { useCallback, useEffect, useState } from 'react';

import { AxiosRequestConfig } from 'axios';
import * as t from 'io-ts';
import { useSelector } from 'react-redux';
import { ulid } from 'ulid';

import { showConfirmDialog } from 'src/components/universal/ConfirmDialogTips/ConfirmDialogTips';
import { ERROR_CODES } from 'src/constants/manage';
import i18n from 'src/i18n';

import { RootState } from 'src/store';

import { aegis } from '../aegis';
import { showError } from '../utils';

import { getOrPromiseReject } from '../utils/codecs';
import logger from '../utils/logger';
import { RequestError } from '../utils/requestError';
import sessionManage from '../utils/sessionManage';

import { axiosInstance } from './axios';
import { adminAPIs } from './httpAPIs/admin';
import { cccAPIs } from './httpAPIs/ccc';
import { crmAPIs } from './httpAPIs/crm';
import { imAPIs } from './httpAPIs/im';
import { loginAPIs } from './httpAPIs/login';
import { pstnAPIs } from './httpAPIs/pstn';
import { recordAPIs } from './httpAPIs/records';
import { seatApis } from './httpAPIs/seatInfo';
import throwErrorCodeApi from './throwErrorCodeApi';

import { debugLogRequest } from './index';

export const APIs = {
  ...pstnAPIs,
  ...seatApis,
  ...adminAPIs,
  ...imAPIs,
  ...cccAPIs,
  ...recordAPIs,
  ...loginAPIs,
  ...crmAPIs,
  '/ccc/debug/seatLog': {
    Input: t.unknown,
    Output: t.type({}),
    StoreInjectionGetter: undefined,
  },
};

export function request<T extends keyof typeof APIs>(
  url: T,
  data: Omit<t.TypeOf<typeof APIs[T]['Input']>, 'sdkAppId'> & { sdkAppId?: string; nonce?: string; requestId?: string },
  config?: AxiosRequestConfig,
): Promise<t.TypeOf<typeof APIs[T]['Output']>> {
  const axiosConfig = config || {};
  const id = ulid();
  const { cancelToken } = axiosConfig;

  let params = {
    clientType: '1',
    nonce: id,
    requestId: id,
    timestamp: String(Date.now()),
    // @ts-ignore
    ...APIs[url].Input.encode(data),
  };
  // 上传
  if (axiosConfig.headers?.['Content-Type'] === 'multipart/form-data') {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });
    params = formData;
  }

  return axiosInstance
    .request({
      method: 'post',
      url,
      cancelToken,
      data: params,
      ...axiosConfig,
    })
    .then((response) => {
      if (!response.data && response.status === 200) {
        console.warn('Empty response.data!');
        debugLogRequest('Empty response.data found for url: ', url);
        return response.data;
      }
      if (Number(response.data?.errorCode) === 0) {
        return Promise.resolve(getOrPromiseReject(APIs[url].Output)(response.data)).catch(() => {
          throw new RequestError(i18n.t('返回值不符合预期，请联系TCCC'), { url: url.split('/').pop() });
        });
      }
      if (response.data?.errorCode === ERROR_CODES.TOKEN_EXPIRED) {
        showConfirmDialog({
          content: i18n.t('登录已过期或在其他地方登录，请重新登录'),
          onEnter() {
            sessionManage.loginOut();
          },
          countDownConfig: {
            from: Date.now(),
            countdown: 5,
          },
          cancelText: '',
        });
        return response.data;
      }
      if (response.data?.errorCode === '-403' && url.includes('getAsrWebsocket')) {
        // 未开通Tencent AI/账号不存在的用户，获取不到tencent websocket 的 seatUrl/clientUrl，这里屏蔽掉，但是要加上日志
        debugLogRequest(`[接口请求成功但是报错, 错误信息: ${response.data.msg}]`);
        return response.data;
      }
      if (throwErrorCodeApi.includes(url)) {
        throw response.data;
      }
      throw new RequestError(response.data.msg, { code: response.data.errorCode, url: url.split('/').pop() });
    })
    .catch((error) => {
      aegis.report(`url: ${url}, nonce:${id}, message: ${error}`);
      if (!error) {
        logger.error(i18n.t('[{{0}}未知请求错误]', { 0: url }));
        showError(i18n.t('网络错误请重试'));
        throw error;
      }
      if (!['/ccc/debug/seatLog'].includes(url)) {
        debugLogRequest(`[${url}请求失败, nonce: ${id}, ${error?.message}, ${error?.errorCode}, ${error?.msg}]`);
      }
      if (error?.__CANCEL__) {
        logger.warn(error);
        return;
      }
      if (throwErrorCodeApi.includes(url)) {
        throw error;
      }
      if (error?.response?.status && typeof error.response.status === 'number' && error.response.status !== 200) {
        showError(i18n.t('HTTP请求错误，{{0}}', { 0: error.response.data }), {
          code: error.response.status,
          url: error.url,
        });
      } else if (error.code === 'ECONNABORTED') {
        showError(i18n.t('请求超时，请稍后再试'));
      } else if (error && error.message === 'Network Error') {
        showError(i18n.t('网络错误请重试'));
      } else {
        showError(error.message || error, { code: error.code, url: error.url });
      }
      throw error;
    });
}

export function useRequest<T extends keyof typeof APIs>(
  url: T,
  data: Omit<t.TypeOf<typeof APIs[T]['Input']>, 'sdkAppId'> & { sdkAppId?: string; nonce?: string; requestId?: string },
  deps?: any,
  toggle = true,
  config?: AxiosRequestConfig,
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [responseData, setResponseData] = useState<t.TypeOf<typeof APIs[T]['Output']>>({});

  const fetchData = useCallback(() => {
    setError(null);
    setLoading(true);
    request(url, data, config)
      .then((data) => {
        setResponseData(data);
      })
      .catch((error) => setError(error))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || [])]);

  useEffect(() => {
    if (toggle) {
      fetchData();
    }
  }, [fetchData, toggle]);

  return {
    loading,
    error,
    data: responseData,
    refresh: fetchData,
  };
}

export function useSkillGroupListLimitBySkillGroupScope(options = {}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [responseData, setResponseData] = useState<
    t.TypeOf<typeof APIs['/tcccadmin/tech/getSkillGroupList']['Output']>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  >({} as t.TypeOf<typeof APIs['/tcccadmin/tech/getSkillGroupList']['Output']>);
  const allowedSkillGroup = useSelector<RootState>((s) => s.userInfo.skillGroupScope) as undefined | number[];

  const fetchData = useCallback(() => {
    setError(null);
    setLoading(true);
    request('/tcccadmin/tech/getSkillGroupList', options)
      .then((data) => {
        if (allowedSkillGroup === undefined || allowedSkillGroup === null) {
          setResponseData(data);
        } else {
          setResponseData({
            ...data,
            skillGroupList: data.skillGroupList.filter((s) => allowedSkillGroup.includes(Number(s.skillGroupId))),
          });
        }
      })
      .catch((error) => setError(error))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedSkillGroup]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    error,
    data: responseData,
    refresh: fetchData,
  };
}

export const getWhitelistByKey = async (key: string, sdkAppId: string | number) => {
  const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json;charset=UTF-8',
  };
  try {
    const res = await fetch(`/tcccbff/fetch/${key}/${sdkAppId}`, { headers });
    const { errCode, data } = await res.json();
    if (errCode !== 0) {
      logger.error(`Fetch rainbow config for ${key} failed due to logic: ${errCode}`);
      return null;
    }
    return data;
  } catch (error: any) {
    logger.error(`Fetch rainbow config for ${key} failed due to unknown reason: ${error.message}`);
    return null;
  }
};

export const judgeInWhitelistByKey = async (key: string, sdkAppId: string | number) => {
  const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json;charset=UTF-8',
  };
  try {
    const res = await fetch(`/tcccbff/judge/${key}/${sdkAppId}`, { headers });
    const { errCode, data } = await res.json();
    if (errCode !== 0) {
      logger.error(`Judge rainbow config for ${key} of ${sdkAppId} failed due to logic: ${errCode}`);
      return false;
    }
    return data || false;
  } catch (error: any) {
    logger.error(`Judge Rainbow Config for ${key} of ${sdkAppId} failed due to unknown reason: ${error.message}`);
    return false;
  }
};
