import EventEmitter from 'eventemitter3';
import * as workerTimers from 'worker-timers';

type Key = number | string | Symbol;

const Self = (() => {
  if (typeof globalThis !== undefined) {
    return globalThis as unknown as Window;
  }
  return window;
})();

export class Timer {
  timer?: number | void;
  onceEvents: Map<string, number>;
  onEvents: Map<string, [endTime: number, delay: number]>;
  emitter: EventEmitter;
  offEmitter: EventEmitter;
  constructor() {
    this.onceEvents = new Map();
    this.onEvents = new Map();
    this.emitter = new EventEmitter();
    this.offEmitter = new EventEmitter();
  }

  loop(): number | void {
    const current = performance.now();
    this.onceEvents.forEach((value, key) => {
      if (value < current) {
        this.emitter.emit(key);
        this.onceEvents.delete(key);
      }
    });
    this.onEvents.forEach(([emitTime, delay], key) => {
      if (emitTime < current) {
        this.emitter.emit(key);
        const nextEmitTime = performance.now() + delay;
        this.onEvents.set(key, [nextEmitTime, delay]);
      }
    });
    if (this.onceEvents.size > 0 || this.onEvents.size > 0) {
      return workerTimers.setTimeout(() => this.loop(), 200);
    }
    if (this.timer) {
      try {
        workerTimers.clearTimeout(this.timer);
      } catch (e) {
        console.warn(e);
      }
    }
    this.timer = undefined;
  }

  /**
   *
   * @param key
   * @param callback
   * @param delay 毫秒
   * @param reject 取消时回调
   */
  once(key: Key, callback: (...args: any[]) => any, delay: number, reject?: (...args: any[]) => any) {
    const now = performance.now();
    const emitTime = now + delay;
    this.onceEvents.set(`${key}`, emitTime);
    this.emitter.once(`${key}`, callback);
    if (reject) {
      this.offEmitter.once(`${key}`, reject);
    }
    if (!this.timer) {
      this.timer = this.loop();
    }
  }

  /**
   *
   * @param key
   * @param callback
   * @param delay 毫秒
   */
  on(key: Key, callback: (...args: any[]) => any, delay: number) {
    const now = performance.now();
    const emitTime = now + delay;
    this.onEvents.set(`${key}`, [emitTime, delay]);
    this.emitter.on(`${key}`, callback);
    if (!this.timer) {
      this.timer = this.loop();
    }
  }

  off(key: Key): any[] | null | void {
    const strKey = `${key}`;
    if (this.onceEvents.has(strKey)) {
      this.onceEvents.delete(strKey);
      this.offEmitter.emit(strKey);
    }
    if (this.onEvents.has(strKey)) {
      this.onEvents.delete(strKey);
    }
    this.emitter.off(strKey);
  }

  clear() {
    this.onceEvents.clear();
    this.onEvents.clear();
    this.emitter.removeAllListeners();
    this.offEmitter.removeAllListeners();
    if (this.timer) {
      try {
        workerTimers.clearTimeout(this.timer);
        this.timer = undefined;
      } catch (e) {
        console.warn(e);
      }
    }
  }
}

const requestAnimFrame = (function () {
  return Self.requestAnimationFrame;
})();

export const requestInterval = function (fn: Function, delay = 0) {
  let start = new Date().getTime();
  const handle: { value?: number } = {};
  function loop() {
    handle.value = requestAnimFrame(loop);
    const current = new Date().getTime();
    const delta = current - start;
    if (delta >= delay) {
      fn.call(null);
      start = new Date().getTime();
    }
  }
  handle.value = requestAnimFrame(loop);
  return handle;
};
export const clearRequestInterval = (handle: ReturnType<typeof requestInterval>) => {
  if (handle.value) {
    cancelAnimationFrame(handle.value);
  }
};

export const requestTimeout = function (fn: Function, delay = 0) {
  const start = new Date().getTime();
  const handle: { value?: number } = {};
  function loop() {
    const current = new Date().getTime();
    const delta = current - start;
    delta >= delay ? fn.call(null) : (handle.value = requestAnimFrame(loop));
  }
  handle.value = requestAnimFrame(loop);
  return handle;
};

export const clearRequestTimeout = function (handle: ReturnType<typeof requestTimeout>) {
  if (handle.value) {
    Self.cancelAnimationFrame(handle.value);
  }
};

export const workerInterval = workerTimers.setInterval;
export const workerTimeout = workerTimers.setTimeout;
export const clearWorkerInterval = (params: Parameters<typeof workerTimers.clearInterval>[0]) => {
  try {
    workerTimers.clearInterval(params);
  } catch (e) {
    console.warn('clearWorkerInterval failed', e);
  }
};
export const clearWorkerTimeout = (params: Parameters<typeof workerTimers.clearTimeout>[0]) => {
  try {
    workerTimers.clearTimeout(params);
  } catch (e) {
    console.warn('clearWorkerTimeout failed', e);
  }
};

export const timer = new Timer();
