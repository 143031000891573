import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';

import { request } from 'src/services/httpClient';

export enum stopNumberEnum {
  RUN_OUT = 2,
  DISABLE = 5,
}

interface LineUnavailableWarningState {
  unavailableAsrRealtimePkgWarning: boolean;
  unavailableAsrOfflinePkgWarning: boolean;
  runOutNumbers: string[];
  disableNumbers: string[];
  unavailableLandLineCallOutPkgWarning: boolean;
  unavailable400CallInPkgWarning: boolean;
  unavailableSeatPkgWarning: boolean;
  unavailableSeatLicenseWarning: boolean;
}
const initialState: LineUnavailableWarningState = {
  unavailableAsrRealtimePkgWarning: false,
  unavailableAsrOfflinePkgWarning: false,
  runOutNumbers: [],
  disableNumbers: [],
  unavailableLandLineCallOutPkgWarning: false,
  unavailable400CallInPkgWarning: false,
  unavailableSeatPkgWarning: false,
  unavailableSeatLicenseWarning: false,
};

export const getLineUnavailableWarning = createAsyncThunk('getLineUnavailableWarning', async () => {
  try {
    const res = await request('/tcccadmin/num/getLineUnavailableWarning', {});

    const stopNumberData = groupBy(
      (res?.stopNumberList || []).filter((v) => [stopNumberEnum.RUN_OUT, stopNumberEnum.DISABLE].includes(v.stopType)),
      'stopType',
    );

    const runOutNumbers = stopNumberData?.[stopNumberEnum.RUN_OUT]?.map((item) => item.servingNum) || [];
    const disableNumbers = stopNumberData?.[stopNumberEnum.DISABLE]?.map((item) => item.servingNum) || [];

    return {
      unavailableAsrRealtimePkgWarning: !!res?.unavailableAsrRealtimePkgWarning,
      unavailableAsrOfflinePkgWarning: !!res?.unavailableAsrOfflinePkgWarning,
      runOutNumbers,
      disableNumbers,
      unavailableLandLineCallOutPkgWarning: !!res?.unavailableLandLineCallOutPkgWarning,
      unavailable400CallInPkgWarning: !!res?.unavailable400CallInPkgWarning,
      unavailableSeatPkgWarning: !!res?.unavailableSeatPkgWarning,
      unavailableSeatLicenseWarning: !!res?.unavailableSeatLicenseWarning,
    };
  } catch {
    return initialState;
  }
});

const lineUnavailableWarningSlice = createSlice({
  initialState,
  name: 'lineUnavailableWarning',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLineUnavailableWarning.fulfilled, (state, action) => action.payload);
  },
});

export const lineUnavailableWarning = lineUnavailableWarningSlice.reducer;
