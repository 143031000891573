import { useEffect, useState, useCallback } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './emoji.css';
import { SmileSvg } from '../../Icons/SmileSvg';

import { emojiPlusName, emojiPlusMap, DEFAULT_BASIC_EMOJI_URL } from './default-emoji';

import useStyles, { emojiFiles } from './Emojis.style';

export default function Emojis(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const documentClick = useCallback((e) => {
    if (e.target.className.baseVal?.includes('emojiTarget')) return;
    if (e.target.className?.includes?.('emojiTarget')) return;
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  // eslint-disable-next-line no-undefined
  const id = open ? 'simple-popper' : undefined;

  const chooseEmoji = (emoji) => {
    setAnchorEl(null);
    props.onChooseEmoji(emoji);
  };

  useEffect(() => {
    document.addEventListener('click', documentClick);
    return function () {
      document.removeEventListener('click', documentClick);
    };
  }, [documentClick]);
  return (
    <>
      <div
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{
          __html: emojiFiles.map((file) => `<link href="${file}" as="image" rel="preload" />`).join('\r\n'),
        }}
      />
      <Tooltip title={t('发送表情')}>
        <IconButton size="small" className={classes.iconButton} onMouseDown={preventDefault} onClick={handleClick}>
          <SmileSvg
            className={cn('emojiTarget', classes.smileIcon, {
              [classes.openSmileIcon]: open,
            })}
          />
        </IconButton>
      </Tooltip>
      <Popper
        style={{ zIndex: props.zIndex || 10 }}
        keepMounted={true}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="top-start"
        modifiers={{ offset: { enabled: true, offset: '0 32px' } }}
      >
        <Paper>
          <div style={{ display: open ? 'flex' : 'none' }} onMouseDown={preventDefault} className={classes.emojis}>
            {emojiPlusName.map((emojiName) => (
              <li
                className={classes.imgItem}
                key={emojiName}
                onClick={(e) => {
                  chooseEmoji({
                    url: DEFAULT_BASIC_EMOJI_URL + emojiPlusMap[emojiName],
                    name: emojiName,
                  });
                  e.stopPropagation();
                }}
              >
                <img
                  style={{ width: '30px' }}
                  src={DEFAULT_BASIC_EMOJI_URL + emojiPlusMap[emojiName]}
                  alt={emojiName}
                />
              </li>
            ))}
          </div>
        </Paper>
      </Popper>
    </>
  );
}

Emojis.propTypes = {
  onChooseEmoji: PropTypes.func,
  zIndex: PropTypes.number,
};
