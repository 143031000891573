import { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Card } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';

import Footer from 'src/components/Footer/Footer';
import Header from 'src/components/Header/Header';

import { ChangeInstancePassword } from './ChangeInstancePassword';
import { LoginByMobile } from './LoginByMobile';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

/**
 * 首页使用手机号重置密码流程
 * 1. 手机号登录
 * 2. 选择实例登录
 * 3. 重置密码
 */
export const ResetPassword = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLogin, setIsLogin] = useState(false);
  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.content}>
        {!isLogin ? (
          <LoginByMobile loginCallback={() => setIsLogin(true)} />
        ) : (
          <Card style={{ padding: '80px 120px' }}>
            <Card.Body title={t('重置密码')}>
              <ChangeInstancePassword />
            </Card.Body>
          </Card>
        )}
      </div>
      <Footer />
    </div>
  );
};
