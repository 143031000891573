import { styled, SvgIcon } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 24,
  height: 24,
  '& rect': {
    stroke: '#FFF',
    fill: (props) => (props.active ? '#FFF' : ''),
  },
  '& g': {
    opacity: (props) => (props.active ? 1 : 0.7),
  },
  '&:hover': {
    '& rect': {
      stroke: '#FFF',
      fill: (props) => (props.active ? '' : '#FFF'),
    },
    '& g': {
      opacity: (props) => (props.active ? 0.7 : 1),
    },
  },
});
export function ManageSvg(props) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" opacity=".7">
        <rect x="2.5" y="2.5" width="8" height="8" rx="1" />
        <rect x="2.5" y="13.5" width="8" height="8" rx="1" />
        <rect x="13.5" y="2.5" width="8" height="8" rx="1" />
        <rect x="13.5" y="13.5" width="8" height="8" rx="1" />
      </g>
    </Svg>
  );
}
