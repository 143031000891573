/* eslint-disable import/no-anonymous-default-export */
import DSFormControlsStyle from '../DSFormControls.style';

export default () => ({
  ...DSFormControlsStyle(),
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  menuItem: {
    fontSize: 14,
  },
});
