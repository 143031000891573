import { useMemo, useState, useEffect, useRef } from 'react';

const HEADER_HEIGHT = 53;
const MINI_HEADER_HEIGHT = 32;
const BOTTOM_PADDING = 28;
const MIN_BOTTOM_PADDING = 8;
const MINI_SCREEN_BOUNDING = 672;
const MINI_BOTTOM_BTN_HEIGHT = 96;
const BOTTOM_BTN_HEIGHT = 170;
const LAYOUT_WIDTH = 500;
export const useVideoCardObserver = () => {
  const [miniScreen, setMiniScreen] = useState(false);
  const [layout, setLayout] = useState<'vertical' | 'horizontal'>('horizontal');
  const [remoteStreamHeight, setRemoteStreamHeight] = useState(500);
  const [headerHeight, setHeaderHeight] = useState(HEADER_HEIGHT);
  const [bottomPadding, setBottomPadding] = useState(28);
  const videoCardRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const videObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          entries.forEach((entry) => {
            const { target } = entry;
            let curMiniScreen = miniScreen;
            if (
              (!curMiniScreen && target.clientHeight <= MINI_SCREEN_BOUNDING) ||
              (!curMiniScreen && target.clientWidth <= LAYOUT_WIDTH)
            ) {
              curMiniScreen = true;
            }
            if (curMiniScreen && target.clientHeight > MINI_SCREEN_BOUNDING && target.clientWidth > LAYOUT_WIDTH) {
              curMiniScreen = false;
            }
            if (curMiniScreen !== miniScreen) {
              setMiniScreen(curMiniScreen);
            }
            let bottomHeight = curMiniScreen ? MINI_BOTTOM_BTN_HEIGHT : BOTTOM_BTN_HEIGHT;
            if (bottomRef.current) {
              bottomHeight = bottomRef.current.clientHeight;
            }
            const newHeaderHeight = target.clientHeight > 560 ? HEADER_HEIGHT : MINI_HEADER_HEIGHT;
            const newBottomPadding = target.clientHeight > 510 ? BOTTOM_PADDING : MIN_BOTTOM_PADDING;
            if ((target.clientHeight - newHeaderHeight - bottomHeight) * 2 < target.clientWidth * 3) {
              setLayout('horizontal');
            } else {
              setLayout('vertical');
            }
            if (headerHeight !== newHeaderHeight) {
              setHeaderHeight(newHeaderHeight);
            }
            if (bottomPadding !== newBottomPadding) {
              setBottomPadding(newBottomPadding);
            }
            setRemoteStreamHeight(target.clientHeight - bottomHeight - newHeaderHeight);
          });
        });
      }),
    [miniScreen, headerHeight, bottomPadding],
  );
  useEffect(() => {
    if (videoCardRef.current) {
      videObserver.observe(videoCardRef.current);
    }
    return () => {
      videObserver.disconnect();
    };
  }, [videObserver]);

  return {
    miniScreen,
    layout,
    remoteStreamHeight,
    headerHeight,
    bottomPadding,
    videoCardRef,
    bottomRef,
  };
};
