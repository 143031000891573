import { makeStyles } from '@material-ui/core';

import ExpandHover from 'src/assets/images/collapse-hover.png';
import Expand from 'src/assets/images/collapse.png';
import DragHover from 'src/assets/images/drag-hover.png';
import Drag from 'src/assets/images/drag.png';
import CollapseHover from 'src/assets/images/expand-hover.png';
import Collapse from 'src/assets/images/expand.png';
import ZoomInHover from 'src/assets/images/zoomin-hover.png';
import ZoomIn from 'src/assets/images/zoomin.png';
import ZoomOutHover from 'src/assets/images/zoomout-hover.png';
import ZoomOut from 'src/assets/images/zoomout.png';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    height: '100%',
    // height: (props) => (props.hideHeader ? '100%' : 'calc(100% - 56px)'),
    overflow: 'hidden',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    position: 'relative',
    display: 'flex',
  },
  contentInExperienceCenter: {
    height: 712,
    width: 905,
  },
  callCard: {
    width: 332,
    display: 'block',
  },
  userList: {
    width: 234,
    minWidth: 234,
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: '#FFFFFF',
    borderLeft: '1px solid #f0f0f0',
    borderRight: '1px solid #f0f0f0',
  },
  expMessage: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: '#FFFFFF',
    borderLeft: '1px solid #f0f0f0',
  },
  nullMessage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    color: 'rgba(51,51,51,1)',
  },
  nullTitle: {
    paddingTop: 12,
    userSelect: 'none',
    fontSize: 14,
    fontFamily: theme.base.fontFamily.Regular,
    color: '#666666',
  },
  nullCRM: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    color: 'rgba(51,51,51,1)',
    userSelect: 'none',
  },
  nullImg: {
    userSelect: 'none',
  },
  CRMWrapper: {
    flex: 1,
    minWidth: (props) => (props.isSaasDemoPage ? 400 : null),
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
    position: 'relative',
  },
  crmAction: {
    position: 'absolute',
    top: 45,
    right: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 50,
    height: 30,
    paddingRight: 5,
    background: 'rgb(255, 255, 255)',
  },
  crmIcon: {
    width: 36,
    height: 36,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '16',
    backgroundPosition: 'center',
  },
  zoomIn: {
    backgroundImage: `url(${ZoomIn})`,
    '&:hover': {
      backgroundImage: `url(${ZoomInHover})`,
    },
  },
  zoomOut: {
    backgroundImage: `url(${ZoomOut})`,
    '&:hover': {
      backgroundImage: `url(${ZoomOutHover})`,
    },
  },
  expand: {
    display: 'none',
    backgroundImage: `url(${Expand})`,
    '&:hover': {
      backgroundImage: `url(${ExpandHover})`,
    },
  },
  collapse: {
    backgroundImage: `url(${Collapse})`,
    '&:hover': {
      backgroundImage: `url(${CollapseHover})`,
    },
  },
  drag: {
    width: 18,
    height: 36,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${Drag})`,
    '&:hover': {
      cursor: 'move',
      backgroundImage: `url(${DragHover})`,
    },
  },
  splitLine: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: 8,
    height: 'calc(100vh - 50px)',
    background: 'transparent',
    cursor: 'ew-resize',
  },
  dragModal: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 9,
    width: '100%',
    height: '100%',
    display: 'none',
  },
  service: {
    height: 50,
    lineHeight: '50px',
    width: '100%',
    borderTop: '1px solid',
    paddingLeft: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    fontSize: 12,
    color: 'black',
    borderColor: '#e6e9ee',
  },
  showRecordIcon: {
    userSelect: 'none',
  },
  showRecord: {
    paddingLeft: 4,
    userSelect: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
