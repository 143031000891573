import { createAsyncThunk } from '@reduxjs/toolkit';

import { unwrapResult } from 'tccc-sdk';

import i18n from 'src/i18n';
import { RootState, selectAllSession, selectBySessionId } from 'src/store';
import { Session, SessionStatus, SessionType, MicStatus } from 'src/store/slices/sessions';
import tccc from 'src/utils/tccc';

import { debugLogRequest } from '../../../services';

enum Direction {
  callIn,
  callOut,
}

export const monitorRTC = createAsyncThunk(
  'sessions/monitorRTC',
  async ({ sessionId: targetSessionId, phoneDesc }: { sessionId: string; phoneDesc?: string }) => {
    if (!targetSessionId) throw new Error(i18n.t('sessionId不能为空'));
    const { sessionId } = await tccc.Call.monitorCall({ sessionId: targetSessionId }).then(unwrapResult);
    const session = {
      sessionId,
      direction: Direction.callOut,
      remark: phoneDesc || (targetSessionId ? i18n.t('座席 {{0}}的会话', { 0: targetSessionId }) : undefined),
      status: '200',
      startTime: Math.floor(Date.now() / 1000),
      type: 'monitor',
    };
    return session;
  },
);
export const exitMonitorRTC = createAsyncThunk('sessions/exitMonitor', async (_, { getState }) => {
  const store = getState() as RootState;
  const session = selectAllSession(store).find((item) => item.type === 'monitor' && item.status === '200');
  if (!session) {
    throw new Error(`exitMonitorRTC failed`);
  }
  try {
    await tccc.Call.exitMonitor({ sessionId: session.sessionId });
  } catch (ex) {
    throw ex;
  }
  const duration = Math.floor(session.timestamp ? Date.now() / 1000 - session.timestamp : 0);
  return {
    ...session,
    forceDelete: true,
    duration,
    status: '400',
    state: 'finished',
    media: undefined,
    hangupSide: 'seat',
    hangupType: 'giveup',
  };
});

export const startCallInternal = createAsyncThunk(
  'sessions/startCallInternal',
  async ({ calleeUserId }: { calleeUserId: string }, { getState }) => {
    const { userId } = (getState() as RootState).userInfo;
    if (calleeUserId === userId) {
      throw new Error(i18n.t('不允许呼叫自己，请选择其他座席'));
    }
    try {
      await tccc.Devices.checkDevices();
    } catch (e) {
      debugLogRequest(`checkDevices Error: ${(e as Error).message}`);
      throw e;
    }

    const res = await tccc.Call.startInternalCall({
      calleeUserId,
    }).then(unwrapResult);
    const { sessionId, serverType } = res;
    const session: Session = {
      sessionId,
      userId: calleeUserId,
      direction: Direction.callOut,
      type: 'internal' as SessionType,
      status: '100' as SessionStatus,
      timestamp: Math.floor(Date.now() / 1000),
      serverType,
    };
    return session;
  },
);
/**
 * 本地静音
 */
export const changeMicStatus = createAsyncThunk(
  'sessions/micStatusChanged',
  async (
    { sessionId, micStatus, memberId }: { sessionId: string; micStatus: MicStatus; memberId?: string },
    { getState },
  ) => {
    const session = selectBySessionId(getState() as RootState, sessionId);
    if (!session) {
      throw new Error(i18n.t('会话不存在'));
    }
    try {
      if (session.type === 'video' || session.type === 'im') {
        if (micStatus === 'on') {
          await tccc.Video.unmuteAudio({ sessionId });
        } else {
          await tccc.Video.muteAudio({ sessionId });
        }
      } else {
        if (micStatus === 'on') {
          await tccc.Call.unmute({ sessionId, memberId });
        } else {
          await tccc.Call.mute({ sessionId, memberId });
        }
      }
    } catch (e) {
      throw e;
    }
    return {
      sessionId,
      memberId,
      micStatus,
    };
  },
);
