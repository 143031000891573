/* eslint-disable @typescript-eslint/no-require-imports */
import { downloadImage } from 'src/utils/downloadImage';

import { ReactImageViewer } from './imageView.style';
export function ImageViewer(props) {
  const { activeIndex, visible, images, onMaskClick, onClose, onCurrentImageChange } = props;
  return (
    <ReactImageViewer
      activeIndex={activeIndex || 0}
      visible={visible}
      images={images}
      onChange={onCurrentImageChange}
      onMaskClick={() => {
        onMaskClick?.();
      }}
      onClose={() => onClose?.()}
      customToolbar={(toolbars) =>
        ['prev', 'zoomIn', 'zoomOut', 'rotateRight', 'downloadImage', 'next'].map((name) =>
          Object.assign(
            {},
            toolbars.find(({ key }) => key === name) || {},
            name === 'downloadImage'
              ? {
                  onClick: (image) => {
                    downloadImage(image.downloadUrl, image.alt);
                  },
                }
              : {},
            {
              render: (
                <i>
                  <img alt="name" src={require(`src/assets/images/image-viewer-${name}.svg`)} />
                </i>
              ),
            },
          ),
        )
      }
    />
  );
}
