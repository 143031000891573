import { Fragment } from 'react';

import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CloseIcon from 'src/components/CCCIcon/ccc/close-primary.svg';
import MuteIcon from 'src/components/CCCIcon/ccc/mute-audio.svg';
import MutedIcon from 'src/components/CCCIcon/ccc/muted-audio.svg';
import { Timing } from 'src/components/Timing/Timing';
import { Direction, Session } from 'src/store/slices/sessions';
import { endSession, accessSession } from 'src/store/slices/sessions/sessions.thunk';

import { changeMicStatus } from '../../../store/slices/sessions/rtc';

import style from './MediaBar.style';

const useStyles = makeStyles(style);

export default function MediaBar({ callInfo }: { callInfo: Session }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mute = callInfo.micStatus === 'off';

  const handleEnterRoom = function () {
    dispatch(accessSession(callInfo));
  };
  const handleQuitRoom = function () {
    dispatch(endSession({ sessionId: callInfo.sessionId, closeBy: 'seat' }));
  };

  const emuns = {
    callStart: {
      video: t('请求视频通话'),
      audio: t('请求音频通话'),
    },
    calling: {
      video: t('视频通话中...'),
      audio: t('语音通话中...'),
    },
    callEnd: {
      video: t('视频通话结束'),
      audio: t('语音通话结束'),
    },
  };
  function onHandleMuteAudio() {
    dispatch(changeMicStatus({ sessionId: callInfo.sessionId, micStatus: mute ? 'on' : 'off' }));
  }

  if (!callInfo.media) return null;
  return (
    <div className={cs(classes.root, { [classes.ringingStatus]: callInfo.media.direction === Direction.callIn })}>
      {callInfo.media.status === '150' && (
        <>
          {t('等待接听中')}
          <span onClick={handleQuitRoom}>{t('取消')}</span>
        </>
      )}
      {callInfo.media.status === '100' && (
        <>
          {emuns.callStart[callInfo.media.type]} <span onClick={handleEnterRoom}>{t('接入')}</span>
        </>
      )}
      {callInfo.media.status === '200' && (
        <>
          <div>
            {emuns.calling[callInfo.media.type]}{' '}
            {callInfo.media?.startTime && <Timing startTime={callInfo.media.startTime} />}
            <span onClick={handleQuitRoom}>{t('挂断')}</span>
          </div>
          {callInfo.media.type !== 'video' && (
            <div className={classes.operation}>
              <Tooltip title={mute ? t('取消静音') : t('静音')}>
                <img src={mute ? MutedIcon : MuteIcon} alt="" onClick={onHandleMuteAudio} />
              </Tooltip>
              <Tooltip title={t('挂断')}>
                <img src={CloseIcon} alt="" onClick={handleQuitRoom} />
              </Tooltip>
            </div>
          )}
        </>
      )}
      {callInfo.media.status === '400' && <Fragment>{emuns.callEnd[callInfo.media.type]}</Fragment>}
    </div>
  );
}
