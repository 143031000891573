const getStyles = function (theme) {
  return {
    card: {
      width: '100%',
      height: '100%',
      display: 'block',
      textAlign: 'center',
      boxSizing: 'border-content',
      background: '#fff',
    },
    transferHeader: {
      height: 44,
      borderBottom: '1px solid #f7f7f7',
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      width: 200,
      height: 44,
      borderRadius: 22,
      fontweight: 500,
      fontSize: 14,
      lineHeight: '22px',
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '10px 20px 20px 20px',
    },
    selectTip: {
      margin: '20px 20px 0 20px',
      textAlign: 'left',
      color: '#333',
    },
    groupLoading: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttons2: {
      paddingTop: 30,
      borderTop: '1px solid #f7f7f7',
    },
    gdiv: {
      flexBasis: '18%',
      width: 118,
      height: 80,
      textAlign: 'center',
      margin: '0 4px 10px 4px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      borderRadius: 4,
      cursor: 'pointer',
      background: '#f2f2f2',
      fontSize: 12,
      color: '#333',
      paddingTop: 2,
      fontFamily: theme.base.fontFamily.Regular,
      '&:last-child::after': {
        content: '\\00b0',
        flex: 'auto',
      },
    },
    gdivSelected: {
      fontFamily: theme.base.fontFamily.Medium,
      color: theme.base.color.primary,
      background: 'rgba(0, 110, 255, 0.1)',
      border: '1px solid #006EFF',
    },
    gdivTips: {
      marginTop: 3,
      marginLeft: 6,
      borderRadius: 8,
      width: 8,
      height: 8,
      background: '#00BF62',
    },
    gdivName: {
      marginTop: 5,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '22px',
      textAlign: 'center',
      color: '#000000',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    gdivfreeStaffCount: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '22px',
      textAlign: 'center',
      color: '#00BF62',
    },
    emptyTips: {
      textAlign: 'center',
      margin: '20px 20px 0 20px',
    },
  };
};
export default getStyles;
