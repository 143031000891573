import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { debugLogRequest } from 'src/services';
import { request } from 'src/services/httpClient';

interface BeginnerGuideState {
  showGuide: boolean;
  currentType: string;
  currentStep: number;
  remindFlag: boolean;
  tipsInfo: { [k: string]: boolean };
}
const initialState: BeginnerGuideState = {
  showGuide: false,
  currentType: '',
  currentStep: 0,
  remindFlag: false,
  tipsInfo: {},
};

export const updateBeginnerGuideState = createAsyncThunk(
  'updateBeginnerGuideState',
  async (payload: { showGuide?: boolean; remindFlag?: boolean }, { getState }) => {
    const state = getState() as any;
    const {
      userInfo: { userId },
      beginnerGuide: { showGuide, remindFlag },
    } = state;
    const key = `beginnerGuide-${userId}`;
    await request('/tcccadmin/app/setCommKV', { key, value: JSON.stringify({ showGuide, remindFlag, ...payload }) });
    return payload;
  },
);

export const fetchBeginnerGuideState = createAsyncThunk('fetchBeginnerGuideState', async (payload, { getState }) => {
  const { userId } = (getState() as any).userInfo;
  const key = `beginnerGuide-${userId}`;
  try {
    const { value } = await request('/tcccadmin/app/getCommKV', { key });
    if (value) {
      const parseValue = (JSON.parse(value) as BeginnerGuideState) || {};
      return parseValue;
    }
    return { showGuide: true, remindFlag: true };
  } catch (e: any) {
    debugLogRequest(`get beginner guide state error: ${e.message}`);
  }
  return {};
});

const updateSuccess = (state: BeginnerGuideState, { payload }: { payload: Partial<BeginnerGuideState> }) => ({
  ...state,
  ...payload,
});

const beginnerGuideSlice = createSlice({
  initialState,
  name: 'beginnerGuide',
  reducers: {
    updateCurrentInfo(state, { payload }: { payload: { currentType?: string; currentStep?: number } }) {
      return { ...state, ...payload };
    },
    updateTipsInfo(state, { payload: { key, tipsFlag } }: { payload: { key: string; tipsFlag: boolean } }) {
      state.tipsInfo[key] = tipsFlag;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateBeginnerGuideState.fulfilled, updateSuccess)
      .addCase(fetchBeginnerGuideState.fulfilled, updateSuccess);
  },
});

export const { updateCurrentInfo, updateTipsInfo } = beginnerGuideSlice.actions;
export const beginnerGuide = beginnerGuideSlice.reducer;
