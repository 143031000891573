import { createInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from 'tccc-i18n/translations/en-US.json';
import zh from 'tccc-i18n/translations/zh-CN.json';
import { i18nLanguage } from 'tccc-utils';

const resources = {
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
};

// nsSeparator默认为:,会导致tts:xxxx形式的字符串用t包裹转换后丢失tts:,这里传入一个比较奇怪的字符串来作为nsSeparator
const i18nInstance = createInstance({ nsSeparator: '@@@' });

i18nInstance
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'zh',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    lng: i18nLanguage.language,
  });

declare global {
  interface Window {
    i18n: any;
  }
}

window.i18n = i18nInstance;
export const { t } = i18nInstance;
export default i18nInstance;
