import { styled, SvgIcon } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 16,
  height: 16,
  '& path': {
    fill: '#FFF',
  },
});
export function UserIcon(props: { className?: string; style?: React.CSSProperties }) {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99962 1C5.8902 1 4.18018 2.71002 4.18018 4.81944C4.18018 6.92886 5.8902 8.63889 7.99962 8.63889H4.88867C3.23182 8.63889 1.88867 9.98203 1.88867 11.6389V14.25C1.88867 14.5261 2.11253 14.75 2.38867 14.75H13.6109C13.887 14.75 14.1109 14.5261 14.1109 14.25V11.6389C14.1109 9.98203 12.7677 8.63889 11.1109 8.63889H7.99962C10.109 8.63889 11.8191 6.92886 11.8191 4.81944C11.8191 2.71002 10.109 1 7.99962 1Z"
      />
    </Svg>
  );
}
