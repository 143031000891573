import { makeStyles } from '@material-ui/core';

import RecordIcon2 from 'src/assets/images/record-46f56aa17d514b39fef774977d86eb5df07b838f.svg';
import RecordIcon from 'src/assets/images/record-a-85e4f3d721839aaab06eb52ffc70d42c92dd9a11.svg';
import WorkbenchImg2 from 'src/assets/images/work-51540cf9cb054b819f6eb56ddd732ffdda58003d.svg';
import WorkbenchImg from 'src/assets/images/work-a-1cccd322f4ba6078fffb424d3408cfcd6505ef9b.svg';

export const useStyles = makeStyles(() => ({
  logo: {
    marginLeft: 6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  calloutWrapper: {
    position: 'relative',
    marginLeft: 6,
  },
  callout: {
    position: 'absolute',
    top: 54,
    left: 8,
    background: '#fff',
    zIndex: 5,
  },
  calloutArrow: {
    width: 0,
    height: 0,
    left: 6,
    top: -16,
    position: 'absolute',
    border: '8px solid transparent',
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderBottomColor: '#fff',
    zIndex: 1,
  },
  callOutSvg: {
    '& div': {
      color: '#fff',
      fontSize: 12,
      paddingLeft: 8,
      opacity: (props) => (props.popup ? 1 : 0.7),
    },
    '& path': {
      stroke: '#FFF',
      fill: (props) => (props.popup ? '#FFF' : ''),
    },
    '& g': {
      opacity: (props) => (props.popup ? '1' : '.7'),
    },
    '&:hover': {
      '& path': {
        fill: '#FFF',
      },
      '& g': {
        opacity: 1,
      },
      '& div': {
        opacity: 1,
      },
    },
  },
  workbenchSvg: {
    opacity: (props) => (props.currentView === 'agent' || props.currentView === 'demo' ? 1 : 0.7),
    '& div': {
      color: '#fff',
      fontSize: 12,
      paddingLeft: 8,
      opacity: (props) => (props.currentView === 'agent' || props.currentView === 'demo' ? 1 : 0.7),
      '&:hover': {
        opacity: 1,
      },
    },
    '& path': {
      fill: (props) => (props.currentView === 'agent' || props.currentView === 'demo' ? '#fff' : '#BFC0C3'),
    },
    '&:hover': {
      opacity: 1,
      '& path': {
        fill: '#fff',
      },
      '& div': {
        opacity: 1,
      },
      '& .WorkbenchImg': {
        background: `url(${WorkbenchImg})`,
      },
    },
    '& .WorkbenchImg': {
      width: 24,
      height: 24,
      background: (props) =>
        props.currentView === 'agent' || props.currentView === 'demo'
          ? `url(${WorkbenchImg})`
          : `url(${WorkbenchImg2})`,
      '&:hover': {
        background: `url(${WorkbenchImg})`,
      },
    },
  },
  manageSvg: {
    opacity: (props) => (props.currentView === 'manage' ? 1 : 0.7),
    '& div': {
      color: '#fff',
      fontSize: 12,
      paddingLeft: 8,
    },
    '&:hover': {
      opacity: 1,
      '& rect': {
        stroke: '#FFF',
        fill: (props) => (props.active ? '' : '#FFF'),
      },
      '& g': {
        opacity: (props) => (props.active ? 0.7 : 1),
      },
    },
  },
  crmSvg: {
    opacity: (props) => (props.currentView === 'crm' ? 1 : 0.7),
    '& div': {
      color: '#fff',
      fontSize: 12,
      paddingLeft: 8,
    },
    '&:hover': {
      opacity: 1,
      '& rect': {
        stroke: '#FFF',
        fill: (props) => (props.active ? '' : '#FFF'),
      },
      '& g': {
        opacity: (props) => (props.active ? 0.7 : 1),
      },
    },
  },
  recordSvg: {
    opacity: (props) => (props.currentView === 'record' ? 1 : 0.7),
    '& div': {
      color: '#fff',
      fontSize: 12,
      paddingLeft: 8,
    },
    '&:hover': {
      opacity: 1,
      '& .recordImg': {
        background: `url(${RecordIcon2})`,
      },
    },
    '& .recordImg': {
      width: 24,
      height: 24,
      background: (props) => (props.currentView === 'record' ? `url(${RecordIcon})` : `url(${RecordIcon2})`),
      '&:hover': {
        background: `url(${RecordIcon2})`,
      },
    },
  },
  line: {
    margin: '0 20px',
    width: 1,
    height: 15,
    background: '#BFC0C3',
  },
  disabled: {
    cursor: 'not-allowed !important',
    opacity: '0.5 !important',
  },
}));
