import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

export const TransferText = (props: { loading: boolean; type: 'transfer' | 'invite' }) => {
  const { t } = useTranslation();
  const buttonText = useMemo(() => {
    if (props.loading) {
      if (props.type === 'transfer') {
        return t('转接中');
      }
      return t('咨询中');
    }
    if (props.type === 'transfer') {
      return t('开始转接');
    }
    return t('开始咨询');
  }, [props.loading, props.type, t]);

  return <>{buttonText}</>;
};
