import axios, { AxiosInstance } from 'axios';

import Cookies from 'js-cookie';
import { i18nLanguage } from './I18nLanguage';

const setCsrfToken = (token: string, domain: string) => {
  Cookies.set('csrf-token2', token, {
    domain,
    sameSite: 'none',
    secure: true,
    expires: 1,
  });
};

const Self = (() => {
  if (typeof globalThis !== undefined) {
    return globalThis;
  }
  if (typeof window !== undefined) {
    return window;
  }
})();

export const getCsrfToken = () => {
  let token = Cookies.get('csrf-token2');
  if (!token) {
    token = `${Math.random()}`;
    const hostname = Self?.location?.hostname;
    const prodDomain = [
      'ccc.cloud.tencent.cn',
      'ccc.cloud.tencent.com.cn',
      'tccc.qcloud.com',
      'connect.tencentcloud.com',
    ];
    if (process.env.REACT_APP_NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
      if (hostname === 'connect.tencentcloud.com') {
        setCsrfToken(token, hostname);
      } else {
        setCsrfToken(token, 'qcloud.com');
      }
    } else if (hostname && prodDomain.includes(hostname)) {
      setCsrfToken(token, hostname);
    } else {
      setCsrfToken(token, 'qcloud.com');
    }
  }
  return token;
};

axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.Language = i18nLanguage.backendLanguage;

const onRejected = (axiosInstance: AxiosInstance, err?: any) => {
  if (axios.isAxiosError(err) && err.code === axios.AxiosError.ERR_NETWORK) {
    // 增加backupURL配置，网络异常时切换
    if (
      'backupURL' in axiosInstance.defaults &&
      'baseURL' in axiosInstance.defaults &&
      // @ts-ignore
      axiosInstance.defaults.backupURL &&
      // @ts-ignore
      axiosInstance.defaults.baseURL
    ) {
      // @ts-ignore
      const { backupURL, baseURL } = axiosInstance.defaults;
      Object.assign(axiosInstance.defaults, {
        baseURL: backupURL,
        backupURL: baseURL,
      });
      // @ts-ignore 已经用备份域名重试了
      if (!err.config || ('retry' in err.config && err.config.retry)) {
        return Promise.reject(err);
      }
      Object.assign(err.config, {
        // @ts-ignore
        baseURL: err.config.backupURL,
        backupURL: err.config.baseURL,
        retry: true,
      });

      // 直接用backup重试一次
      return axiosInstance(err.config);
    }
  }

  return Promise.reject(err);
};

axios.interceptors.request.use((config) => {
  if ('languages' in config) {
    Object.assign(config.data, {
      // @ts-ignore
      languages: config.languages,
    });
  }
  Object.assign(config.headers, {
    language: i18nLanguage.backendLanguage,
    'X-Csrf-Token': getCsrfToken(),
  });
  return config;
});
axios.interceptors.response.use(
  (res) => res,
  (err) => onRejected(axios, err),
);

const csrfInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    Object.assign(config.headers, {
      'X-Csrf-Token': getCsrfToken(),
    });
    return config;
  });
};

const backupUrlInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (res) => res,
    (err) => onRejected(axiosInstance, err),
  );
};

export { axios, backupUrlInterceptor, csrfInterceptor };
