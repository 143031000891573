import {
  RobotCardElementWrapper,
  RobotCardTitle,
  RobotCardContent,
  ContentList,
  ContentListItem,
} from './robotElement.style';

export function RobotCardElement(props) {
  const { data } = props;

  return (
    <RobotCardElementWrapper>
      <RobotCardTitle>{data.content.title}</RobotCardTitle>
      <RobotCardContent>
        <ContentList>
          {data.content.candidate.map((item) => (
            <li key={item.content}>
              <ContentListItem>{item.content}</ContentListItem>
            </li>
          ))}
        </ContentList>
      </RobotCardContent>
    </RobotCardElementWrapper>
  );
}
