import ImageViewer from 'react-viewer';
import styled from 'styled-components';

export const ReactImageViewer = styled(ImageViewer)`
  & .react-viewer-navbar {
    background-color: unset;
  }
  & .react-viewer-list {
    margin: 24px;
    height: 58px;
    overflow-y: hidden;
  }
  & .react-viewer-close {
    margin: 12px;
    border-radius: 0;
  }
  & .react-viewer-footer {
    z-index: 1015 !important;
  }
  & .react-viewer-close > i {
    top: 14px;
    left: 14px;
    color: white;
  }
  & .react-viewer-close::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 64px;
    background-color: rgb(0, 0, 0);
  }
  & .react-viewer-attribute {
    position: fixed;
    top: 24px;
    left: 0;
    right: 80px;
  }
  & .react-viewer-toolbar {
    height: 52px;
  }
  & .react-viewer-btn {
    width: 48px;
    height: 48px;
  }
  & .react-viewer-list > li {
    width: auto;
    height: 58px;
  }
  & .react-viewer-list > li > img {
    object-fit: cover;
    width: auto;
    max-width: 90px;
    height: 58px;
  }
  & .react-viewer-list > li.active > img {
    border: 2px solid #006eff;
  }
  & .react-viewer-list {
    margin-left: 0 !important;
    display: inline-block;
  }
`;
