import { useCallback, useEffect, useMemo, useState } from 'react';

import { Refresh } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Input, InputAdornment, LoadingTip, Select, Text } from '@tencent/tea-component';
import debounce from 'lodash/debounce';
import isUndefined from 'lodash/isUndefined';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';
import unionBy from 'lodash/unionBy';
import { useTranslation } from 'react-i18next';

import { ExtensionStatusText } from 'src/components/ExtensionStatus/ExtensionStatus';
import { Extension } from 'src/services/httpAPIs/admin';
import { request, useRequest } from 'src/services/httpClient';

import { StyledTable } from '../CentralizedComponents';

const { radioable, scrollable, autotip } = StyledTable.addons;

type SearchType = 'EXTENSION_ID' | 'USER_ID' | 'USER_NAME';
interface ExtensionListProps {
  open?: boolean;
  value?: string;
  onSelected: (extension: Extension) => void;
}

const useStyles = makeStyles({
  adornment: {
    flexBasis: '48%',
    display: 'flex',
  },
  searchBox: {
    width: '100%',
  },
  beforeSelect: {
    marginLeft: 10,
    width: 80,
    lineHeight: '17px',
  },
  skillGroupSelect: {
    flexBasis: '48%',
    '& > div': {
      width: '100%',
    },
  },
  refresh: {
    color: '#999',
    fontWeight: 800,
    fontSize: 'medium',
    cursor: 'pointer',
    margin: '2px 0 0 -14px',
    transition: 'transform 0.5s',
    '&:active': {
      transform: 'rotate(90deg)',
    },
  },
  actionPanel: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '15px',
  },
});

function ExtensionList({ value, onSelected, open }: ExtensionListProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  // 搜索
  const [searchType, setSearchType] = useState<SearchType>('EXTENSION_ID');
  const [searchText, setSearchText] = useState('');
  const [selectedSkillGroupId, setSelectedSkillGroupId] = useState('');

  // 话机列表
  const pageSize = useState(10)[0];
  const [pageNum, setPageNum] = useState(0);
  const [allExtensionError, setAllExtensionError] = useState(null);
  const [allExtensionCount, setAllExtensionCount] = useState(0);
  const [allExtensionLoading, setAllExtensionLoading] = useState(true);
  const [allExtensionList, setAllExtensionList] = useState<Extension[]>([]);

  const { data: skillGroupData, loading: skillGroupDataLoading } = useRequest('/tcccadmin/tech/getSkillGroupList', {});

  const skillGroupOption = useMemo(
    () =>
      skillGroupData?.skillGroupList?.map(({ skillGroupId, skillGroupName }) => ({
        value: skillGroupId,
        text: skillGroupName,
      })) || [],
    [skillGroupData?.skillGroupList],
  );

  const skillGroupListMap = useMemo(
    () => keyBy(skillGroupData?.skillGroupList || [], 'skillGroupId'),
    [skillGroupData?.skillGroupList],
  );

  const loading = allExtensionLoading || skillGroupDataLoading;

  const getExtensionList = useCallback(
    debounce(
      async (num = 0, searchText: string, searchType: SearchType, skillGroupId: string) => {
        const extensionId = searchType === 'EXTENSION_ID' ? searchText : '';
        const relation = searchType === 'USER_ID' ? searchText : '';
        const relationName = searchType === 'USER_NAME' ? searchText : '';
        const params = pickBy(
          {
            pageSize,
            skillGroupId,
            relation,
            relationName,
            batchExtensionIds: extensionId ? [extensionId] : [],
            pageNum: num,
            isNeedStatus: true,
          },
          (item) => !isUndefined(item) && item !== '',
        );
        try {
          setAllExtensionError(null);
          setAllExtensionLoading(true);
          const res = await request('/tcccadmin/extension/getList', params);
          setPageNum(num + 1);
          setAllExtensionLoading(false);
          setAllExtensionCount(res.total);
          if (num) {
            setAllExtensionList((extensionList) => unionBy(extensionList, res.extensionList, 'extensionId'));
          } else {
            setAllExtensionList(res.extensionList);
          }
        } catch (e: any) {
          setAllExtensionError(e);
        } finally {
          setAllExtensionLoading(false);
        }
      },
      800,
      { leading: false, trailing: true },
    ),
    [],
  );

  const getSearchExtensionList = useCallback(
    async (search: string, searchType: SearchType, skillGroupId: string) => {
      await getExtensionList(0, search, searchType, skillGroupId);
    },
    [getExtensionList],
  );

  useEffect(() => {
    if (open) {
      getSearchExtensionList(searchText, 'EXTENSION_ID', '');
    }
  }, [getSearchExtensionList, open, searchText]);

  return (
    <>
      <StyledTable.ActionPanel className={classes.actionPanel}>
        <Select
          clearable
          searchable
          matchButtonWidth
          className={classes.skillGroupSelect}
          type={'simulate'}
          appearance={'button'}
          tips={skillGroupDataLoading && <LoadingTip />}
          options={skillGroupOption}
          placeholder={t('请选择技能组')}
          value={selectedSkillGroupId}
          onChange={(value) => {
            setSelectedSkillGroupId(value);
            getSearchExtensionList(searchText, searchType, value);
          }}
        />
        <InputAdornment
          className={classes.adornment}
          before={
            <Select
              className={classes.beforeSelect}
              defaultValue={'userId'}
              value={searchType}
              onChange={(value: SearchType) => {
                setSearchType(value);
                getSearchExtensionList(searchText, value, selectedSkillGroupId);
              }}
              options={[
                { text: t('分机号'), value: 'EXTENSION_ID' },
                { text: t('座席邮箱'), value: 'USER_ID' },
                { text: t('座席姓名'), value: 'USER_NAME' },
              ]}
            />
          }
        >
          <Input
            className={classes.searchBox}
            value={searchText}
            onChange={(value) => {
              setSearchText(value);
              getSearchExtensionList(value, searchType, selectedSkillGroupId);
            }}
          />
        </InputAdornment>
        <div onClick={() => getExtensionList(pageNum, searchText, searchType, selectedSkillGroupId)}>
          <Refresh className={classes.refresh} />
        </div>
      </StyledTable.ActionPanel>
      <StyledTable
        bordered
        records={skillGroupDataLoading ? [] : allExtensionList}
        recordKey={'extensionId'}
        columns={[
          {
            key: 'extensionId',
            header: t('分机号'),
            width: 80,
          },
          {
            key: 'relationName',
            header: t('座席姓名'),
            width: 80,
            render: (item: Extension) => item.relationName || '-',
          },
          {
            key: 'relation',
            header: t('座席邮箱'),
            width: 100,
            render: (item: Extension) => item.relation || '-',
          },
          {
            key: 'skillGroupId',
            header: t('技能组'),
            width: 100,
            render: (item: Extension) => {
              const skillGroupIdList = item.skillGroupId.split(',').filter((id) => skillGroupListMap[id]);
              if (skillGroupIdList.length === 0) return <Text theme={'strong'}>-</Text>;
              return skillGroupIdList.map((id) => skillGroupListMap?.[id]?.skillGroupName || t('未知小组')).join(', ');
            },
          },
          {
            key: 'status',
            header: t('话机状态'),
            width: 80,
            render: (item: Extension) => <ExtensionStatusText status={item?.status} registerStatus={item?.register} />,
          },
          {
            key: 'registerStatus',
            header: t('话机注册状态'),
            width: 80,
            render: (item: Extension) =>
              item.register ? <Text theme={'success'}>{t('已注册')}</Text> : <Text theme={'label'}>{t('未注册')}</Text>,
          },
        ]}
        addons={[
          scrollable({
            maxHeight: 310,
            onScrollBottom() {
              if (pageNum !== 0 && pageNum * pageSize > Number(allExtensionCount)) return;
              getExtensionList(pageNum, searchText, searchType, selectedSkillGroupId);
            },
          }),
          radioable({
            value,
            onChange: (key: string, context: any) => {
              onSelected(context.record);
            },
            rowSelect: true,
          }),
          autotip({
            isLoading: loading,
            isError: !!allExtensionError,
            onRetry: () => getSearchExtensionList(searchText, searchType, selectedSkillGroupId),
          }),
        ]}
      />
    </>
  );
}

export default ExtensionList;
