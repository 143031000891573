import { useCallback, useEffect, useMemo } from 'react';

import { Alert as TeaAlert, Bubble, ExternalLink, Text } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootState } from 'src/store';
import { getLineUnavailableWarning } from 'src/store/slices/lineUnavailableWarning';

const Alert = styled(TeaAlert)`
  margin: 0px;
`;

const StyledWarningWrapper = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
`;

const linkTextStyle = {
  color: '#006EFF',
  textDecoration: 'none',
  margin: '0 4px',
  '&::after': {
    color: '#006EFF',
  },
};

export const LineUnavailableWarningAlert = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const realtimeAsr = useSelector((state: RootState) => state.appSettings.realtimeAsr);
  const {
    unavailable400CallInPkgWarning,
    unavailableAsrOfflinePkgWarning,
    unavailableAsrRealtimePkgWarning,
    unavailableLandLineCallOutPkgWarning,
    disableNumbers,
    runOutNumbers,
  } = useSelector((state: RootState) => state.lineUnavailableWarning);

  const ToolTips = ({ numberList }: { numberList: string[] }) => {
    const showNumbers = numberList.slice(0, 5);
    const hiddenNumbers = numberList.slice(5);
    return (
      <>
        {showNumbers.join('；')}
        {hiddenNumbers.length > 0 && (
          <Bubble
            placement={'bottom'}
            content={hiddenNumbers.map((v) => (
              <div>{v}</div>
            ))}
          >
            <Text theme="primary">
              {t('等')}
              {hiddenNumbers.length}
              {t('个')}
            </Text>
          </Bubble>
        )}
      </>
    );
  };

  const PackageRunoutTips = ({ pkgType }: { pkgType: string }) => (
    <StyledWarningWrapper>
      {t('您的')}
      {pkgType}
      {t('套餐包已用尽，请前往')}
      <ExternalLink style={linkTextStyle} href="https://console.cloud.tencent.com/ccc/package">
        {t('控制台')}
      </ExternalLink>
      {t('购买')}
    </StyledWarningWrapper>
  );

  const NumberDisabledTips = ({ numberList }: { numberList: string[] }) => (
    <StyledWarningWrapper>
      {t('您的号码')}
      <ToolTips numberList={numberList} />
      {t('由于违反')}
      <a
        style={linkTextStyle}
        href="https://cloud.tencent.com/document/product/679/75220"
        target="_blank"
        rel="noreferrer"
      >
        {t('《电话业务接入准则》')}
      </a>
      {t('被停用，如有疑问，请')}
      <ExternalLink
        style={linkTextStyle}
        href="https://tccc.qcloud.com/web/im/index.html#/chat?webAppId=cfa574ab93916be85a9d04f4f192aba4"
      >
        {t('联系申诉')}
      </ExternalLink>
      。
    </StyledWarningWrapper>
  );

  const NumberRunoutTips = ({ numberList }: { numberList: string[] }) => (
    <StyledWarningWrapper>
      {t('您的号码')}
      <ToolTips numberList={numberList} />
      {t('已欠费停用，请前往')}
      <ExternalLink style={linkTextStyle} href="https://console.cloud.tencent.com/account/renewal">
        {t('控制台')}
      </ExternalLink>
      {t('续费。')}
    </StyledWarningWrapper>
  );

  const OfflineAsrRunoutTips = () => (
    <StyledWarningWrapper>
      {t('您的离线语音转文字套餐包已用完，离线语音转文字功能不可用，请前往控制台进行购买。')}
      <ExternalLink style={linkTextStyle} href="https://buy.cloud.tencent.com/ccc_valueadded">
        {t('立即购买')}
      </ExternalLink>
    </StyledWarningWrapper>
  );
  const RealtimeAsrRunoutTips = () => (
    <StyledWarningWrapper>
      {t('实时语音套餐包已用完，实时语音转文字功能及IVR语音导航功能不可用，请前往控制台进行购买。')}
      <ExternalLink style={linkTextStyle} href="https://buy.cloud.tencent.com/ccc_valueadded">
        {t('立即购买')}
      </ExternalLink>
    </StyledWarningWrapper>
  );

  const isEditIvr = window?.location?.pathname?.includes?.('ivr-visible-editor');

  // 套餐包到期 并且 开通实时asr后才展示
  const showUnavailableAsrRealtimePkgWarning = useMemo(
    () => unavailableAsrRealtimePkgWarning && realtimeAsr,
    [realtimeAsr, unavailableAsrRealtimePkgWarning],
  );

  const getData = useCallback(() => dispatch(getLineUnavailableWarning()), [dispatch]);

  useEffect(() => {
    getData();
    const timer = setInterval(() => {
      getData();
    }, 2000 * 60);
    return () => clearInterval(timer);
  }, [getData]);

  // ivr编辑这里absolute、fixed元素太多，不好调整，在这个页面先不展示告警条
  if (
    !(
      unavailable400CallInPkgWarning ||
      unavailableLandLineCallOutPkgWarning ||
      !!disableNumbers.length ||
      !!runOutNumbers.length ||
      unavailableAsrOfflinePkgWarning ||
      showUnavailableAsrRealtimePkgWarning
    ) ||
    isEditIvr
  ) {
    return <></>;
  }

  return (
    <Alert carouselMode type={'warning'}>
      {runOutNumbers.length > 0 && <NumberRunoutTips numberList={runOutNumbers} />}
      {disableNumbers.length > 0 && <NumberDisabledTips numberList={disableNumbers} />}
      {unavailable400CallInPkgWarning && <PackageRunoutTips pkgType={t('400号码呼入')} />}
      {unavailableLandLineCallOutPkgWarning && <PackageRunoutTips pkgType={t('固话号码呼出')} />}
      {unavailableAsrOfflinePkgWarning && <OfflineAsrRunoutTips />}
      {showUnavailableAsrRealtimePkgWarning && <RealtimeAsrRunoutTips />}
    </Alert>
  );
};
