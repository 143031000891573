export const TIPS_ERROR = 'TIPS_ERROR';
export const TIPS_LOADING = 'TIPS_LOADING';
export const TIPS_SUCCESS = 'TIPS_SUCCESS';

export function loading(open, text, type) {
  return {
    type,
    state: {
      open,
      text,
      type,
    },
  };
}
