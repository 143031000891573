/* eslint-disable no-param-reassign */
import { useMemo, useState } from 'react';

import { makeStyles, IconButton, Typography } from '@material-ui/core';
import { isRejected } from '@reduxjs/toolkit';
import { message } from '@tencent/tea-component';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import backArrow from 'src/assets/images/backArrow.svg';
import TransferIcon from 'src/assets/images/transfer-white.svg';
import { hideConfirmDialog, showConfirmDialog } from 'src/components/universal/ConfirmDialogTips/ConfirmDialogTips';

import { sessionInvite, transferSession } from 'src/store/slices/sessions/sessions.thunk';

import { Loading } from '../Loading/Loading';

import CButton from '../Tim/CustomButtons/Button';

import { SkillGroupList } from './components/SkillGroupList';

import { TransferText } from './TransferText';
import style from './UserTransfer.style';

const useStyles = makeStyles(style);

function UserTransfer({ callInfo, onComplete, skillGroupType, hideTopBar, isTransfer }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const typeText = useMemo(() => (isTransfer ? t('转接') : t('咨询')), [isTransfer, t]);
  const [ivrExchanging, updateIvrFlag] = useState(false); // ivr转接查询态
  const [selectIvr, updateIvr] = useState(null); // 选择的Ivr对象
  const dispatch = useDispatch();

  function cancelExchange() {
    //  返回通话面板
    updateIvr(null);
    onComplete?.();
  }
  function startExchange() {
    //  开始转接
    if (!selectIvr) {
      showConfirmDialog({
        content: t('请先选择技能组，再发起转接'),
        cancelText: '',
        onEnter: () => {
          hideConfirmDialog();
          updateIvrFlag(false);
        },
      });
      return;
    }
    updateIvrFlag(true);
    (isTransfer
      ? dispatch(transferSession({ skillGroupId: selectIvr.skillGroupId, sessionId: callInfo.sessionId }))
      : dispatch(
          sessionInvite({
            sessionId: callInfo.sessionId,
            callee: {
              skillGroupId: +selectIvr.skillGroupId,
            },
          }),
        )
    ).then((e) => {
      if (isRejected(e)) {
        message.error({
          content: t('转接失败，{{0}}', { 0: e.error.message }),
        });
      } else {
        onComplete?.();
      }
      updateIvrFlag(false);
    });
  }

  return (
    <div className={classes.card}>
      {!hideTopBar && (
        <div className={classes.transferHeader}>
          <IconButton onClick={cancelExchange}>
            <img src={backArrow} alt={t('返回')} />
          </IconButton>
          <span>{typeText}</span>
        </div>
      )}
      <Typography variant="h5" className={classes.selectTip}>
        {t('请选择需要{{typeText}}的技能组', { typeText })}
      </Typography>
      <SkillGroupList
        skillGroupType={skillGroupType}
        emptyText={t('没有其他空闲技能组可以{{typeText}}，请联系管理员配置', { typeText })}
        onSelected={(skillGroupId, item) => {
          updateIvr(item);
        }}
      />

      <div className={classes.buttons2}>
        <CButton
          onClick={startExchange}
          className={classes.button}
          variant="outlined"
          color="primary"
          startIcon={ivrExchanging ? <Loading size={36} /> : <img src={TransferIcon} alt="" />}
          disabled={ivrExchanging || !selectIvr}
        >
          <TransferText loading={ivrExchanging} type={isTransfer ? 'transfer' : 'invite'} />
        </CButton>
      </div>
    </div>
  );
}

UserTransfer.propTypes = {
  callInfo: PropTypes.object.isRequired,
  onComplete: PropTypes.func,
  onSuccess: PropTypes.string,
  skillGroupType: PropTypes.number,
  hideTopBar: PropTypes.bool,
  isTransfer: PropTypes.bool,
};

export default UserTransfer;
