import { styled, SvgIcon } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 10,
  height: 10,
});
export function CallSvg() {
  return (
    <Svg viewBox="0 0 10 10">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.806 11.6729C9.93645 12.5532 6.68706 11.7072 3.48343 8.51765C0.268364 5.31665 -0.555426 2.06991 0.337013 1.20107C1.09215 0.446546 2.01892 -0.879585 3.3919 0.846671C4.76488 2.57293 4.10127 3.04165 3.30037 3.81903C2.75117 4.35635 3.90677 5.7282 5.08524 6.91715C6.2866 8.09466 7.64815 9.24931 8.1859 8.70057C8.97536 7.91175 9.45591 7.24868 11.1493 8.60911C12.8769 10.0038 11.5611 10.9413 10.806 11.6729V11.6729Z"
        fill="#fff"
      />
    </Svg>
  );
}
