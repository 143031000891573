import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import { Phone } from '@material-ui/icons';
import { isRejected } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { useTimeout } from 'tccc-utils';

import NewUserAudio from 'src/components/Player/NewUserAudio';

import { debugLogRequest } from 'src/services';
import { Session } from 'src/store/slices/sessions';
import { accessSession } from 'src/store/slices/sessions/sessions.thunk';
import { leadDebounce, showError } from 'src/utils';
import { formatTel } from 'src/utils/formatTel';
import { getPhoneSvgStr, getRadioSVgStr, getVideoSvgStr } from 'src/utils/preLoginAction';

import enums from '../../utils/enums';

import style from './MediaCallInTips.style';

const useStyles = makeStyles(style);
// eslint-disable-next-line
export default function MediaCallInTips({ callInfo }: { callInfo: Session }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appSettings = useSelector((state) => state.appSettings);
  const countTime = useTimeout(callInfo.timeout as number);
  const { hideCallinNumber, numberReflectMode } = appSettings;

  const { media } = callInfo;
  const handleEnterRoom = function () {
    debugLogRequest(t('MediaCallInTips 点击接入，sessionId：{{0}}', { 0: callInfo.sessionId }));
    dispatch(accessSession(callInfo))
      // @ts-ignore
      .then((e) => {
        if (isRejected(e)) {
          if (e?.error?.message?.includes('IsSuccHandleService') || e?.error?.message?.includes(t('没有权限'))) {
            showError(t(`接入失败，对方已挂断。`), true);
          } else {
            showError(t('接入失败：{{0}}。请刷新页面重新接入。', { 0: e.error.message }), true);
          }
        }
      })
      .catch((e: Error) => {
        showError(t('接入失败：{{0}}。请刷新页面重新接入。', { 0: e.message }), true);
      });
  };

  const getName = () => {
    if (callInfo.type === 'internal') {
      return callInfo.userId || t('未知');
    }
    if ('voip' === callInfo.type) {
      if (callInfo?.clientData && callInfo?.clientData?.nickName) {
        return callInfo?.clientData?.nickName;
      }
      return callInfo.remark || callInfo.nickname || callInfo.caller;
    }
    if (['voip', 'video', 'audio'].includes(callInfo.type)) {
      return callInfo.remark || callInfo.nickname || callInfo.caller;
    }
    if ([1, 2].includes(numberReflectMode) && callInfo.type === 'phone') {
      return callInfo.protectedCaller;
    }
    if (callInfo.type === 'im' && callInfo.media) {
      return callInfo.remark || callInfo.nickname;
    }
    const phone = callInfo.callerPhoneNumber;

    return callInfo.nickname || (phone && formatTel(phone, undefined, hideCallinNumber)) || t('未知');
  };
  let svgUrl = getRadioSVgStr();
  let autoTransferTime = null;
  if (['phone', 'internal', 'voip', 'video'].includes(callInfo.type)) {
    svgUrl = getPhoneSvgStr();
    autoTransferTime = callInfo.timeout;
  }
  if (media && media.type === 'video') {
    svgUrl = getVideoSvgStr();
    autoTransferTime = callInfo.status === '100' ? appSettings.videoTimeout : null;
  }
  if (media && media.type === 'audio') {
    autoTransferTime = callInfo.status === '100' ? appSettings.audioTimeout : null;
  }
  if (callInfo.serverType && callInfo.serverType !== 'staffSeat') {
    return null;
  }
  return (
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={
        (media && media.status === '100') ||
        (['phone', 'internal', 'voip', 'audio', 'video'].includes(callInfo.type) && callInfo.status === '100')
      }
    >
      <SnackbarContent
        className={classes.snackbarContent}
        message={
          <div className={classes.message}>
            <div className={classes.messageBox}>
              {callInfo.type === 'im' && <Avatar className={classes.callinAvatar} sizes="40px" src={callInfo.avatar} />}
              {['phone', 'internal', 'voip', 'video'].includes(callInfo.type) && (
                <div className={classes.callinAvatar}>
                  <Phone fontSize="large" />
                </div>
              )}
              <div className={classes.info}>
                <div className={classes.nickName}>{getName()}</div>
                {callInfo.remark && <div className={classes.callerRemark}>{callInfo.remark}</div>}
                <div className={classes.callType}>
                  {['phone', 'internal'].includes(callInfo.type) && enums.callInfoText.phone}
                  {['voip'].includes(callInfo.type) && enums.callInfoText.voip}
                  {['video'].includes(callInfo.type) && enums.callInfoText.video}
                  {callInfo.type === 'im' && callInfo.media && enums.callInfoText[callInfo.media.type]}
                </div>
              </div>
            </div>
            <div className={classes.iconBox}>
              {callInfo.status === '100' && countTime ? (
                <CircularProgress
                  variant="indeterminate"
                  {...(autoTransferTime && {
                    variant: 'static',
                    value: (countTime / autoTransferTime) * 100,
                  })}
                  style={{ marginRight: '30px' }}
                ></CircularProgress>
              ) : (
                ''
              )}
              {callInfo.status === '100' && countTime ? <div className={classes.countBox}>{countTime}</div> : ''}
              <div
                // @ts-ignore
                name="btn_media_callin"
                className={classes.answerBtn}
                style={{ border: '1px solid rgb(43, 211, 148)', background: `url(${svgUrl})` }}
                onClick={leadDebounce(handleEnterRoom, 2000)}
              >
                <div className={classes.answerText}>{t('接入')}</div>
                {/* <img src={svgUrl} onError={onLoadImgError} alt="接入" className={classes.btnImg} /> */}
              </div>
            </div>
            <NewUserAudio></NewUserAudio>
            {callInfo.avatar && <img src={callInfo.avatar} className={classes.imgBg} alt="" />}
          </div>
        }
      />
    </Snackbar>
  );
}

MediaCallInTips.propTypes = {
  callInfo: PropTypes.object,
  onEnterRoom: PropTypes.func,
  onQuitRoom: PropTypes.func,
};
