import i18n from 'src/i18n';

import { debugLogRequest } from '../services';

import logger from './logger';

const { t } = i18n;
export const deviceErrorInfo = (e, dName) => {
  /* eslint-disable */
  // NotFoundError	找不到满足请求参数的媒体类型（包括：音频、视频、屏幕分享）。例如：PC 没有摄像头，但是请求浏览器获取视频流，则会报此错误。	建议在通话开始前引导用户检查通话所需的摄像头或麦克风等外设，若没有摄像头且需要进行语音通话，可在 TRTC.createStream({ audio: true, video: false }) 指明仅采集麦克风。
  // NotAllowedError	用户拒绝了当前的浏览器实例的访问音频、视频、屏幕分享请求。	提示用户不授权摄像头/麦克风访问无法进行音视频通话
  // NotReadableError	尽管用户已经授权使用相应的设备，但是由于操作系统上某个硬件、浏览器或者网页层面发生的错误导致设备无法被访问。	根据浏览器的报错信息处理，并提示用户：
  // “暂时无法访问摄像头/麦克风，请确保当前没有其他应用请求访问摄像头/麦克风，并重试”
  // OverConstrainedError	cameraId/microphoneId 参数的值无效	确保 cameraId/microphoneId 传值正确且有效
  // AbortError	由于某些未知原因导致设备无法被使用
  /* eslint-enable */
  const deviceName = dName || t('摄像头/麦克风');
  let errorMessage = '';
  if (!e) {
    return t('未找到 {{0}} 设备', { 0: deviceName });
  }
  switch (e.name) {
    case 'NotFoundError':
      // 提示用户：暂时无法访问摄像头/麦克风，请确保当前没有其他应用请求访问摄像头/麦克风，并重试。
      errorMessage = t('无法获取到相应的{{0}}，请检查设备', { 0: deviceName });
      break;
    case 'NotAllowedError':
      errorMessage = t('用户{{0}}没有授权', { 0: deviceName });
      break;
    case 'NotReadableError':
      errorMessage = t('暂时无法访问{{0}}，请确保当前没有其他应用请求访问{{1}}，并重试', {
        0: deviceName,
        1: deviceName,
      });
      break;
    case 'OverConstrainedError':
      errorMessage = t('没有找到{{0}}，请检查设备', { 0: deviceName });
      break;
    case 'AbortError':
      errorMessage = t('{{0}}获取设备错误，请检查设备', { 0: deviceName });
      break;
    case 'RtcError':
      errorMessage = t('{{0}}设备遇到RTC错误，请检查设备', { 0: deviceName });
      logger.warn(e);
      break;
    case 'SecurityError':
      errorMessage = e.message;
      break;
    default:
      logger.error(e);
      debugLogRequest(`checkDevices default error: ${e.message}`);
      errorMessage = e.message || t('获取设备失败');
      break;
  }
  return errorMessage;
};

export const VIDEO_PROFILE = {
  width: 360, // 视频宽度
  height: 640, // 视频高度
  frameRate: 15, // 帧率
  bitrate: 900, // 比特率 kbps
};

export const MESSAGE = {
  ERROR: {
    PUSH_BEFORE_ENTER_ROOM: t('请先加入房间再点击开始推流！'),
    STOP_PUSH_BEFORE_ENTER_ROOM: t('请先加入房间再停止推流！'),
    LOCAL_STREAM_NOT_PUBLISHED: t('当前尚未发布本地流！'),
    STOP_PUBLISH_LOCAL_STREAM: t('停止发布本地流失败！'),
    LOCAL_STREAM_PUBLISHING: t('当前正在推流！'),
    WEBRTC_NOT_SUPPORTED_BY_BROWSER: t('当前浏览器不支持webRTC'),
    LOCAL_STREAM_PUBLISH_FAILED: t('发布本地流失败！'),
  },
  INFO: {
    STOP_SUCCESS: t('停止发布本地流成功！'),
    LOCAL_STREAM_PUBLISHED: t('发布本地流成功！'),
  },
};

export const STATUS = {
  STREAM: {
    FAILED: -1,
    INITIAL: 0,
    PUBLISHING: 1,
    PUBLISHED: 2,
  },
};
