import { useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { isRejected } from '@reduxjs/toolkit';
import { message } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { request } from 'src/services/httpClient';

import { transferSession } from '../../../store/slices/sessions/sessions.thunk';
import { ActionButtonGroup } from '../components/ActionButtonGroup';
import { RoundedButton } from '../components/Button';
import { CollapseList } from '../components/CollapseList';

interface ReceiveIvrProps {
  toggleTransferFlag: React.Dispatch<React.SetStateAction<boolean>>;
  sessionId: string;
}

const useStyles = makeStyles({
  buttonGroups: {
    marginTop: 10,
  },
  receiveStatus: {
    height: 28,
    width: '100%',
    padding: '10px 0',
    fontSize: 18,
    opacity: 0.6,
    color: '#fff',
    boxSizing: 'content-box',
    textAlign: 'left',
  },
});

export const TransferIvr = (props: ReceiveIvrProps) => {
  const { toggleTransferFlag, sessionId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [skillGroupList, setSkillGroupList] = useState<
    { skillGroupName: string; skillGroupId: string; freeStaffCount: number }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [transferLoading, setTransferLoading] = useState<boolean>(false);
  const [, setError] = useState(false);
  const [currentSkillGroupId, setCurrentSkillGroupId] = useState<string>('');
  const getSkillGroupList = useCallback(async ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
    setLoading(true);
    request('/tcccadmin/tech/getSkillGroupList', {
      pageNum: pageIndex,
      pageSize,
      extraFlag: 'TEL',
      freeOnly: true,
    })
      .then(({ skillGroupList }) => {
        setSkillGroupList(
          skillGroupList.map((item) => ({
            skillGroupName: item.skillGroupName,
            skillGroupId: item.skillGroupId,
            freeStaffCount: item.freeStaffCount,
          })),
        );
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, []);

  const Title = () => (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
      <div>{t('技能组名称')}</div>
      <div>{t('空闲座席数量')}</div>
    </div>
  );

  const startTransfer = useCallback(() => {
    setTransferLoading(true);
    // @ts-ignore
    dispatch(transferSession({ skillGroupId: currentSkillGroupId, sessionId })).then((e) => {
      setTransferLoading(true);
      if (isRejected(e)) {
        message.error({
          content: t('转接失败，{{0}}', { 0: e.error.message }),
        });
      } else {
        message.success({
          content: t('转接成功'),
        });
      }
      setTransferLoading(false);
    });
  }, [currentSkillGroupId, dispatch, sessionId]);
  useEffect(() => {
    getSkillGroupList({ pageIndex: 0, pageSize: 100 });
  }, [getSkillGroupList]);

  return (
    <>
      <CollapseList
        label={t('请选择转接技能组')}
        emptyTips={t('没有其他空闲技能组可以转接，请联系管理员配置')}
        value={currentSkillGroupId}
        onSelected={(value) => setCurrentSkillGroupId(value)}
        options={skillGroupList.map((item) => ({
          label: item.skillGroupName,
          value: item.skillGroupId,
          extraFields: {
            freeStaffCount: item.freeStaffCount,
          },
        }))}
        loading={loading}
        title={<Title />}
      />
      <ActionButtonGroup className={classes.buttonGroups}>
        {skillGroupList?.length !== 0 && (
          <RoundedButton
            variant={'contained'}
            color={'primary'}
            disabled={currentSkillGroupId === '' || transferLoading}
            onClick={startTransfer}
          >
            {transferLoading ? t('转接中') : t('开始转接')}
          </RoundedButton>
        )}
        <RoundedButton variant={'outlined'} color={'default'} onClick={() => toggleTransferFlag(false)}>
          {t('返回')}
        </RoundedButton>
      </ActionButtonGroup>
    </>
  );
};
