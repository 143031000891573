import { createAsyncThunk, createAction, PrepareAction } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import { unwrapResult } from 'tccc-sdk';

import i18n from 'src/i18n';
import { selectBySessionId } from 'src/store';
import type { RootState } from 'src/store';
import { Session } from 'src/store/slices/sessions';
import type { CloseBy, PeerSourceKey } from 'src/store/slices/sessions';
import tccc from 'src/utils/tccc';

import { getMessageList } from '../tim.thunk';

import { upsertSession } from './sessions.action';

enum Direction {
  callIn,
  callOut,
}
export const endIMSession = createAsyncThunk(
  'sessions/endIMSession',
  async ({ sessionId, closeBy }: { sessionId: string; closeBy: CloseBy }, { getState, rejectWithValue }) => {
    const failedText = 'end im session failed';
    if (!sessionId) {
      return rejectWithValue(`${failedText}, sessionId muse be not empty`);
    }
    const store = getState() as RootState;
    const session = selectBySessionId(store, sessionId);
    if (!session) {
      return rejectWithValue(`${failedText}, because sessionId:${sessionId} does not exist`);
    }
    try {
      if (closeBy === 'seat') {
        if (session.type === 'im') {
          await tccc.Chat.endChat({ sessionId });
        } else {
          await tccc.Video.end({ sessionId });
        }
      }
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
    return session;
  },
);
export const updateLastMessage = createAction<
  PrepareAction<{ sessionId: string; lastMessage: any }>,
  'sessions/updateLastMessage'
>('sessions/updateLastMessage', ({ sessionId, lastMessage }: { sessionId: string; lastMessage: object }) => ({
  payload: {
    sessionId,
    lastMessage: {
      ...pick(lastMessage, ['payload', 'type', 'from']),
      time: Date.now() / 1000,
    },
  },
}));

export const accessWaitingSession = createAsyncThunk(
  'sessions/accessWaitingSession',
  async (params: { sessionId: string }, { dispatch }) => {
    const { nickname, userId, peerSource, avatar, sessionId, clientData, channelName } = await tccc.Chat.seatInChat({
      sessionId: params.sessionId,
    }).then(unwrapResult);
    const session: Session = {
      sessionId,
      nickname: nickname || i18n.t('访客_{{0}}', { 0: userId }),
      direction: Direction.callIn,
      timestamp: Math.floor(Date.now() / 1000),
      type: 'im',
      status: '200',
      peerSource: peerSource as PeerSourceKey,
      avatar,
      clientData,
      channelName,
    };
    dispatch(upsertSession(session));
    await dispatch(getMessageList({ sessionId: params.sessionId }));
    return session;
  },
);
