const getStyles = (paletee) => ({
  props: {
    MuiInputLabel: {
      shrink: true, // 增加了placeholder，暂未找到css
      align: 'right',
    },
    MuiButton: {
      disableRipple: true,
      color: 'primary',
    },
    MuiAppBar: {
      color: 'default',
    },
  },
  typography: {
    fontFamily: '"PingFang SC", "Microsoft YaHei", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 12,
  },
  overrides: {
    MuiButton: {
      root: {
        height: 30,
        padding: [[6, 16]],
        fontWeight: 'normal',
      },
      contained: {
        borderRadius: 0,
      },
    },
    MuiPaper: {
      elevation0: {
        border: '1px solid #ddd',
        boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
        borderRadius: 0,
      },
    },
    MuiTableCell: {
      root: {
        height: 42,
        padding: [[14, 20]],
        fontSize: 12,
      },
      head: {
        whiteSpace: 'nowrap',
        lineHeight: 1,
      },
    },
    MuiDialog: {
      paper: {
        width: 420,
        borderRadius: 0,
      },
    },
    MuiDialogTitle: {
      display: 'flex',
      height: 70,
    },
    MuiTypography: {
      body1: {
        fontSize: 12,
      },
      h6: {
        fontSize: 14,
      },
      h5: {
        fontSize: 16,
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: paletee.background.bar,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        opacity: '0.8',
      },
    },
    MuiDialogContent: {
      root: {
        borderTop: 0,
        borderBottom: 0,
      },
    },
    MuiFormControl: {
      root: {
        marginRight: 16,
        marginBottom: 20,
        flexDirection: 'row',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 12,
        marginBottom: 0,
        lineHeight: '30px',
        minWidth: 80,
        margin: '0 10px 0 2px',
      },
      formControl: {
        transform: 'none',
        position: 'relative',
      },
      shrink: {
        transform: 'none',
      },
      animated: {
        transition: 'none',
      },
    },
    MuiInput: {
      underline: {
        '&::after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        border: '1px solid #ddd',
        height: 30,
        width: 180,
      },
      input: {
        padding: [[6, 10]],
        fontSize: 12,
      },
      multiline: {
        height: 'auto',
        padding: '6px 10px',
        '& textarea': {
          height: '100%',
          width: '100%',
        },
      },
    },
    MuiCheckbox: {
      root: {
        '& input': {
          height: '100%',
          width: '100%',
        },
      },
    },
    MuiRadio: {
      root: {
        '& input': {
          height: '100%',
          width: '100%',
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 14,
        fontWeight: 500,
      },
      secondary: {
        fontSize: 12,
      },
    },
    MuiCollapse: {
      entered: {
        background: paletee.background.level2,
      },
    },
  },
});

export default getStyles;
