import { useState, useEffect, useMemo } from 'react';

import { makeStyles } from '@material-ui/core';
import { Form, Input, Button, Select, InputPasswordRule, StatusTip } from '@tencent/tea-component';

import { TypeOf } from 'io-ts';
import uniqBy from 'lodash/uniqBy';
import { useField, useForm, FieldRenderProps } from 'react-final-form-hooks';

import i18n from 'src/i18n';

import { APIs, request } from 'src/services/httpClient';
import { validatePassword } from 'src/utils/validate';

import { showConfirmDialog } from '../../components/universal/ConfirmDialogTips/ConfirmDialogTips';
import { showError } from '../../utils';
import sessionManage from '../../utils/sessionManage';

type Instance = TypeOf<typeof APIs['/tccclogin/login/queryUserInstanceList']['Output']>['userInstanceList'][0] & {
  value: string;
};
type FormValues = {
  instance: string;
  userId: string;
  password: string;
  passwordConfirm: string;
};
const useStyles = makeStyles({
  card: {
    padding: '80px 120px',
  },
  submit: {
    width: 300,
    height: 40,
  },
  action: {
    textAlign: 'center',
  },
});

const { t } = i18n;

const getPasswordRule = (userId: string): InputPasswordRule[] | false => [
  {
    text: t('密码长度在8 ～ 20 位长度之间'),
    validator: (value: string) => value.length >= 8 && value.length <= 20,
  },
  {
    text: t('密码需要同时包含数字，字母以及特殊符号 （！@#¥%^&*（）等非空格）'),
    validator: (value: string) => validatePassword(value),
  },
  {
    text: t('密码不能与您的邮箱地址相同'),
    validator: (value: string) => userId !== value,
  },
];
interface ChangeInstancePasswordProps {
  sdkAppId?: string;
  userId?: string;
  successCallback?: () => void;
}

export const ChangeInstancePassword = (props: ChangeInstancePasswordProps = { sdkAppId: '', userId: '' }) => {
  const classes = useStyles();
  const [instanceList, setInstanceList] = useState<Instance[]>([]);
  const [instanceUserIdList, setInstanceUserIdList] = useState<string[]>([]);
  const canModifyUserId = useMemo(() => !props.sdkAppId || !props.userId, [props.sdkAppId, props.userId]);

  const { form, handleSubmit, valid, submitting } = useForm<FormValues>({
    initialValues: {
      instance: props.sdkAppId,
      userId: props.userId, // userId用作校验密码用，从实例选择里面获取
      password: '',
      passwordConfirm: '',
    },
    validate: (values) => {
      const errors: {
        [key in keyof FormValues]: any;
      } = { instance: undefined, password: undefined, passwordConfirm: undefined, userId: undefined };

      if (!values.userId) {
        errors.instance = t('请选择需要修改密码的邮箱账号');
      }
      if (!values.instance) {
        errors.instance = t('请选择您要修改密码的应用');
      }
      if (!values.password) {
        errors.password = t('请输入密码');
      }
      if (!values.passwordConfirm) {
        errors.passwordConfirm = t('请输入重复密码');
      }
      if (values.password) {
        if (values.password && values.password === values.userId) {
          errors.password = t('密码不能与您的邮箱地址相同');
        }
        if (!validatePassword(values.password)) {
          errors.password = t('密码需要同时包含数字，字母以及特殊符号 （！@#¥%^&*（）等非空格）');
        }
        if (values.password.length < 8 || values.password.length > 20) {
          errors.password = t('密码长度必须在8-20位之间');
        }
        if (values.password !== values.passwordConfirm) {
          errors.passwordConfirm = t('两次输入的密码不一致');
        }
      }
      return errors;
    },
    onSubmit: async (values) => {
      // 先登录实例，然后再重置密码
      try {
        await request('/tccclogin/login/loginInstance', {
          staff: {
            sdkAppId: values.instance,
          },
          sdkAppId: values.instance,
          userId: values.userId,
        });
        await request('/tccclogin/account/changePass', {
          emails: [values.userId], // 后续废弃
          newPasswd: values.password,
          isInstance: true,
        });
        if (typeof props.successCallback === 'function') {
          return props.successCallback();
        }
        showConfirmDialog({
          content: t('修改密码成功，请前往首页登录'),
          cancelText: '',
          onEnter() {
            sessionManage.loginOut();
          },
        });
        return;
      } catch (e) {
        showError((e as any).msg || (e as Error).message);
        return;
      }
    },
  });

  const instance = useField<FormValues['instance'], FormValues>('instance', form);
  const userId = useField<FormValues['userId'], FormValues>('userId', form);
  const password = useField<FormValues['password'], FormValues>('password', form);
  const passwordConfirm = useField<FormValues['passwordConfirm'], FormValues>('passwordConfirm', form);

  const getFieldStatus = (
    fieldProps: FieldRenderProps<
      FormValues['instance'] | FormValues['password'] | FormValues['passwordConfirm'],
      string
    >,
  ) => {
    if (fieldProps.meta.touched && fieldProps.meta.error) {
      return fieldProps.meta.error;
    }
  };

  const passwordRules = useMemo(() => getPasswordRule(userId.input.value), [userId.input.value]);
  const userInstanceList = useMemo(() => {
    if (!userId.input.value) return [];
    return instanceList.filter((item) => item.userId === userId.input.value);
  }, [userId.input.value, instanceList]);

  useEffect(() => {
    request('/tccclogin/login/queryUserInstanceList', {}).then((res) => {
      if (res.userInstanceList && Array.isArray(res.userInstanceList)) {
        setInstanceList(
          res.userInstanceList.map((item) => ({
            ...item,
            value: item.sdkAppId,
          })),
        );
        setInstanceUserIdList(uniqBy(res.userInstanceList, 'userId').map((item) => item.userId));
      }
    });
  }, []);

  return (
    <>
      <Form>
        {canModifyUserId && (
          <>
            <Form.Item
              label={t('邮箱账号')}
              status={getFieldStatus(userId) ? 'error' : undefined}
              message={getFieldStatus(userId)}
            >
              <Select
                searchable
                size={'l'}
                // listWidth={420}
                disabled={!canModifyUserId}
                placeholder={t('请选择需要修改密码的邮箱账号')}
                {...userId.input}
                onChange={(value) => {
                  userId.input.onChange(value);
                  instance.input.onChange('');
                }}
                options={instanceUserIdList.map((item) => ({
                  value: item,
                  text: item,
                }))}
              />
            </Form.Item>
            <Form.Item
              label={t('应用详情')}
              status={getFieldStatus(instance) ? 'error' : undefined}
              message={getFieldStatus(instance)}
            >
              <Select
                searchable
                disabled={!userId.input.value}
                size={'l'}
                // listWidth={420}
                placeholder={
                  userInstanceList.length === 0 ? t('请先选择需要修改密码的邮箱账号') : t('请选择您要修改密码的应用')
                }
                tips={
                  userInstanceList.length === 0 ? (
                    <StatusTip status={'empty'} emptyText={t('当前邮箱账号没有可选择的应用')}></StatusTip>
                  ) : undefined
                }
                {...instance.input}
                options={userInstanceList.map((item) => ({
                  value: item.value,
                  text: `${item.instanceName}(${item.sdkAppId})`,
                }))}
                filter={(value, option) => {
                  if (typeof option.text === 'string') {
                    const content = option.text.toUpperCase();
                    const val = value?.toUpperCase();
                    return content.includes(val);
                  }
                  return option.value?.includes(value);
                }}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          label={t('新密码')}
          status={getFieldStatus(password) ? 'error' : undefined}
          message={getFieldStatus(password)}
        >
          <Input.Password rules={passwordRules} size={'full'} {...password.input} />
        </Form.Item>
        <Form.Item
          label={t('重复密码')}
          status={getFieldStatus(passwordConfirm) ? 'error' : undefined}
          message={getFieldStatus(passwordConfirm)}
        >
          <Input.Password rules={false} size={'full'} {...passwordConfirm.input} />
        </Form.Item>
      </Form>
      <Form.Action className={classes.action}>
        <Button
          type={'primary'}
          loading={submitting}
          htmlType={'button'}
          className={classes.submit}
          onClick={() => handleSubmit()}
          disabled={
            !instance.input.value ||
            !userId.input.value ||
            !password.input.value ||
            !passwordConfirm.input.value ||
            !valid
          }
        >
          {t('修改密码')}
        </Button>
      </Form.Action>
    </>
  );
};
