import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from 'src/store';

import { FeatureTips } from '../FeatureTips/FeatureTips';

export const AsrFeatureTips = ({ children }: { children: React.ReactNode }) => {
  const realtimeAsr = useSelector((state: RootState) => state.appSettings.realtimeAsr);
  const showRealtimeAsrTips = !window.localStorage.getItem('ASR_FEATURE_TIPS');
  const { t } = useTranslation();
  const [showAsrFeatureTips, setShowAsrFeatureTips] = useState(realtimeAsr && showRealtimeAsrTips);
  return (
    <FeatureTips
      visible={showAsrFeatureTips}
      title={t('快拨打电话体验实时语音转文字吧！')}
      content={t('TCCC语音识别新功能上线了，拨打电话即刻体验实时语音转文字新功能。')}
      onOk={() => {
        setShowAsrFeatureTips(false);
        window.localStorage.setItem('ASR_FEATURE_TIPS', 'true');
      }}
    >
      {children}
    </FeatureTips>
  );
};
