import { useEffect, useState } from 'react';

import moment from 'moment';

class Timing {
  constructor() {
    this.timer = null;
    this.callbacks = {};
    this.pauseKeys = {};
    this.events = {
      timed: {},
      cleared: {},
    };
    this.clearTime = {};
  }
  onTimed(key, callBack) {
    this.events.timed[key] = callBack;
  }
  pause(key) {
    this.pauseKeys[key] = true;
  }
  play(key) {
    this.pauseKeys[key] = false;
  }
  emitTimed(key) {
    if (this.clearTime[key]) return;
    if (this.events.timed[key]) {
      this.events.timed[key]();
      delete this.events.timed[key];
    }
  }
  set(key, cb) {
    if (this.callbacks[key]) {
      this.callbacks[key].push(cb);
    } else {
      this.callbacks[key] = [cb];
    }
    if (!this.timer) {
      this.timer = setInterval(() => {
        Object.entries(this.callbacks).forEach(([key, item]) => {
          if (this.pauseKeys[key]) return;
          item.forEach((callback) => {
            typeof callback === 'function' && callback();
          });
        });
      }, 1000);
    }
  }
  getClearTimer(key) {
    if (!this.clearTime[key]) return false;
    return this.clearTime[key];
  }
  onClear(key, cb) {
    this.events.cleared[key] = cb;
  }
  clear(key) {
    if (!this.callbacks[key]) return;
    this.clearTime[key] = getTime(key);
    this.events.cleared[key]?.();
    if (Object.keys(this.callbacks).length === 0) {
      clearInterval(this.timer);
      this.timer = null;
    }
    delete this.callbacks[key];
  }
}
export const timer = new Timing();

export function getTime(startTime, endTime = Date.now()) {
  const time = endTime - startTime;
  const duration = moment.duration(time, 'milliseconds');
  let type = 'mm:ss';
  if (time >= 3600000) {
    type = 'HH:mm:ss';
  }
  return moment.utc(duration.asMilliseconds()).format(type);
}

export function useCountdownTime(startTime, count) {
  const [countTime, setCountTime] = useState(count);
  useEffect(() => {
    if (startTime) {
      const clearTime = timer.getClearTimer(startTime);
      if (!clearTime && count !== 0) {
        const n = Math.ceil((Date.now() - startTime) / 1000);
        setCountTime(count - n);
        timer.set(startTime, () => {
          const n = Math.ceil((Date.now() - startTime) / 1000);
          if (n > count) {
            timer.emitTimed(startTime);
            timer.clear(startTime);
            return;
          }
          setCountTime(count - n);
        });
      }
    }
  }, [startTime, count]);
  if (count === 0) return '';
  return countTime;
}
