import { useEffect, useRef } from 'react';

import logger from 'src/utils/logger';
import { getNewUserAudioStr } from 'src/utils/preLoginAction';
const newUserAudioStr = getNewUserAudioStr();
function NewUserAudio(props: { isZeroVolume?: boolean }) {
  const callInAudio = useRef() as React.MutableRefObject<HTMLAudioElement>;
  useEffect(() => {
    if (callInAudio.current) {
      if (props.isZeroVolume) {
        callInAudio.current.volume = 0.0;
      } else {
        callInAudio.current.volume = 0.5;
      }
      callInAudio.current.addEventListener('ended', () => {
        logger.error('NewUserAudio play end');
      });
      callInAudio.current.addEventListener('emptied', () => {
        logger.error('NewUserAudio play end');
      });
      callInAudio.current.addEventListener('abort', () => {
        logger.error('NewUserAudio play abort');
      });
      // 太频繁了，不做上报
      callInAudio.current.addEventListener('canplay', () => {});
    }
  }, [callInAudio, props.isZeroVolume]);
  return (
    <audio
      preload="auto"
      onError={(e) => {
        logger.error('NewUserAudio play audio error', e);
      }}
      loop
      autoPlay
      src={newUserAudioStr}
      ref={callInAudio}
    />
  );
}
export default NewUserAudio;
