import { message, Modal } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ChangeInstancePassword } from 'src/views/ResetPassword/ChangeInstancePassword';

import { RootState } from '../../../store';

interface ChangePasswordDialogProps {
  open: boolean;
  onClose: () => void;
}
export const ChangePasswordDialog = (props: ChangePasswordDialogProps) => {
  const { t } = useTranslation();
  const { sdkAppId, userId } = useSelector((state: RootState) => state.userInfo);
  if (!props.open) return null;
  return (
    <Modal visible caption={t('修改密码')} onClose={props.onClose}>
      <Modal.Body>
        <ChangeInstancePassword
          sdkAppId={sdkAppId}
          userId={userId}
          successCallback={() => {
            message.success({
              content: t('修改密码成功'),
            });
            props.onClose();
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
