const getStyles = () => ({
  root: {
    flexDirection: 'row',
    verticalAlign: 'baseline',
  },
  inputLabel: {
    position: 'static',
    whiteSpace: 'nowrap',
    width: 80,
    textAlign: 'right',
    marginRight: 10,
  },
  input: {
    height: 'auto',
  },
  helperText: {
    paddingLeft: 90,
    width: '100%',
    minHeight: 30,
  },
  error: {
    borderColor: '#e54545',
  },
  endAdornment: {
    position: 'absolute',
    right: -15,
    height: '100%',
    lineHeight: '34px',
    color: '#e54545',
  },
  labelError: {
    color: '#e54545',
  },
  multiline: {
    padding: '6px 10px',
  },
});

export default getStyles;
