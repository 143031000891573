import { createTheme } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';

import { base } from './base';
import overrides from './overrides';
import partialPalette from './partialPalette/light';
const palette = createPalette(partialPalette);
export const global = createTheme({
  base,
  palette,
  ...overrides(palette),
});
