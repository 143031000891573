/* eslint-disable import/no-anonymous-default-export */
export default function () {
  return {
    countBox: {
      position: 'absolute' as const,
      top: '50px',
      left: '0',
      width: 40,
      textAlign: 'center' as const,
    },
    iconBox: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative' as const,
    },
    snackbar: {
      top: 35,
    },
    messageBox: {
      display: 'flex',
      alignItems: 'center',
    },
    snackbarContent: {
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      padding: '0',
    },
    message: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '505px',
      padding: '10px 30px',
      position: 'relative' as const,
      overflow: 'hidden',
    },
    imgBg: {
      position: 'absolute' as const,
      top: '20px',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      filter: 'blur(25px)',
      zIndex: -1,
    },
    avatar: {
      height: 50,
      width: 50,
    },
    info: {
      marginLeft: 10,
      padding: '5px 0',
    },
    nickName: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '28px',
      paddingBottom: '6px',
      color: '#fff',
      maxWidth: 200,
    },
    callType: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '17px',
      color: '#8A8F97',
    },
    finishBtn: {
      float: 'right' as const,
    },
    callinAvatar: {
      width: 60,
      height: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#000',
      borderRadius: 2,
    },
    answerBtn: {
      height: 116,
      width: 116,
      cursor: 'pointer',
      backgroundSize: '50% 50%',
      backgroundPosition: 'center',
      borderRadius: '50%',
      lineHeight: '116px',
      textAlign: 'center' as const,
    },
    answerText: {
      fontSize: 10,
      fontWeight: 400,
      color: '#FFFFFF',
      fontFamily: 'PingFangSC-Medium, PingFang SC',
      paddingLeft: '5px',
      paddingTop: '13px',
    },
    btnImg: {
      width: '100%',
      height: '100%',
      textAlign: 'center' as const,
      fontSize: 18,
    },
    callerRemark: {
      color: 'white',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '17px',
      paddingBottom: '9px',
    },
  };
}
