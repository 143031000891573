import { storage } from './storage';

type I18nLanguage = 'en' | 'zh';
const BackendLanguageMap = {
  zh: 'zh-CN',
  en: 'en-US',
};

const LookupQuerystring = 'lang';
const getLanguageByQuerystring = () => {
  let found;
  if (typeof window !== 'undefined') {
    let { search } = window.location;
    if (!window.location.search && window.location.hash && window.location.hash.indexOf('?') > -1) {
      search = window.location.hash.substring(window.location.hash.indexOf('?'));
    }
    const query = search.substring(1);
    const params = query.split('&');
    for (const item of params) {
      const pos = item.indexOf('=');
      if (pos > 0) {
        const key = item.substring(0, pos);
        if (key === LookupQuerystring) {
          found = item.substring(pos + 1);
        }
      }
    }
  }
  return found?.substring(0, 2);
};

const getBrowserLanguage = () => {
  const self = (() => {
    if (typeof globalThis !== undefined) {
      return globalThis;
    }
    if (typeof window !== undefined) {
      return window;
    }
  })();

  if (typeof self !== undefined) {
    // @ts-ignore
    const language = self?.navigator?.language || self?.navigator?.userLanguage;
    return language?.substring(0, 2);
  }
};

class I18n {
  language: I18nLanguage;
  constructor() {
    this.language = this.getLanguage();
  }

  getLanguage() {
    const language =
      getLanguageByQuerystring() || (storage.getItem('i18n_language') as I18nLanguage) || getBrowserLanguage();
    if (['zh', 'en'].includes(language)) {
      return language;
    }
    return 'zh';
  }

  get backendLanguage() {
    return BackendLanguageMap[this.language] || BackendLanguageMap.zh;
  }

  setLanguage(language: string, saveStorage = false) {
    if (!['zh', 'en'].includes(language)) return;
    this.language = language as I18nLanguage;
    if (saveStorage) {
      storage.setItem('i18n_language', language);
    }
  }
}

const i18nLanguage = new I18n();
export { i18nLanguage };
