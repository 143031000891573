import { makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import logo2 from 'src/assets/images/tcccLogo2.png';

import style from './Footer.style';
const useStyle = makeStyles(style);
function Footer() {
  const { t } = useTranslation();
  const classes = useStyle();
  return (
    <div className={classes.footerWrapper}>
      <div className={classes.footerBox}>
        <div className={classes.footerContent}>
          <p>
            {t('Copyright © 2013-{{time}} Tencent Cloud. All Rights Reserved. 腾讯云 版权所有', {
              time: new Date().getFullYear(),
            })}
          </p>
        </div>
        <img src={logo2} alt="logo" />
      </div>
    </div>
  );
}

export default Footer;
