import { duration, utc } from 'moment';
import PropTypes from 'prop-types';

export const Duration = ({ seconds }) => {
  const momentDuration = duration(seconds || 0, 'second');
  let type = 'mm:ss';
  if (momentDuration.get('hours') >= 1) {
    type = 'HH:mm:ss';
  }
  return <>{utc(momentDuration.asMilliseconds()).format(type)}</>;
};
Duration.propTypes = {
  seconds: PropTypes.number,
};
