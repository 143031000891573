import React from 'react';

export function Close(props) {
  const { width, height, ...rest } = props;
  return (
    <div {...rest} style={{ display: 'inline-block' }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={width} height={height}>
        <circle cx="8" cy="8" r="8" fill="#CCCCCC" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6943 4.15182C11.5285 3.98603 11.2597 3.98603 11.0939 4.15182L8 7.24574L4.90608 4.15182C4.74029 3.98603 4.47149 3.98603 4.3057 4.15182L4.15183 4.30569C3.98604 4.47148 3.98604 4.74028 4.15183 4.90607L7.24575 7.99999L4.15183 11.0939C3.98604 11.2597 3.98604 11.5285 4.15183 11.6943L4.3057 11.8482C4.47149 12.014 4.74029 12.014 4.90608 11.8482L8 8.75424L11.0939 11.8482C11.2597 12.014 11.5285 12.014 11.6943 11.8482L11.8482 11.6943C12.014 11.5285 12.014 11.2597 11.8482 11.0939L8.75425 7.99999L11.8482 4.90607C12.014 4.74028 12.014 4.47148 11.8482 4.30569L11.6943 4.15182Z"
          fill="#F6F6F6"
        />
      </svg>
    </div>
  );
}
