import { ADDCRMCOMPONENT, SETCRMTABINDEX, SETSYSTEMSETTINGS, DELCRMCOMPONENT } from '../actions/CRM';
import { fetchAppInfo } from '../slices/app.thunk';
const defaultState = {
  systemSettings: [],
  crmComponents: [],
  crmTabIndex: 0,
};
const crmReducers = function (srcState, action) {
  const state = srcState || defaultState;
  switch (action.type) {
    case fetchAppInfo.fulfilled.type: {
      if (action.payload.crmEnabled) {
        return {
          ...state,
          systemSettings: state.systemSettings,
          crmTabIndex: 0,
        };
      }
      return {
        ...state,
        systemSettings: state.systemSettings,
        crmTabIndex: -1,
      };
    }
    case SETSYSTEMSETTINGS: {
      return {
        ...state,
        systemSettings: action.payload.systemSettings,
      };
    }
    case SETCRMTABINDEX:
      return Object.assign({}, state, action.payload);
    case ADDCRMCOMPONENT: {
      let index = state.crmComponents.findIndex((item) => item.key === action.payload.key);
      let newCrmComponents = state.crmComponents;
      if (index === -1) {
        newCrmComponents = [...newCrmComponents, action.payload];
        index = state.systemSettings.length + state.crmComponents.length - 1;
      } else {
        index = state.systemSettings.length + index;
      }
      return {
        ...state,
        crmComponents: newCrmComponents,
        crmTabIndex: index,
      };
    }
    case DELCRMCOMPONENT:
      return {
        ...state,
        crmComponents: state.crmComponents.filter((item) => item.key !== action.payload),
        crmTabIndex: state.crmTabIndex - 1 < 0 ? 0 : state.crmTabIndex - 1,
      };
    default:
      return state;
  }
};
export default crmReducers;
