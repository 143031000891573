import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 140,
    boxSizing: 'border-box',
    padding: 10,
  },
  wrapperItem: {
    padding: 0,
    opacity: '1 !important',
  },
  nickName: {
    height: 17,
    lineHeight: '17px',
    marginBottom: 10,
    fontSize: 12,
    fontFamily: theme?.base.fontFamily.Regular,
    color: '#333',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  statusDot: {
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
  phoneSwitch: {
    lineHeight: '32px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #F7F7F7',
    borderBottom: '1px solid #F7F7F7',
  },
  switch: {
    transform: 'scale(0.8)',
  },
  listItemAction: {
    right: 0,
  },
  listItemActionLeft: {
    right: 20,
  },
  checkIcon: {
    color: '#006EFF',
  },
  arrowIcon: {
    color: '#666666',
  },
  nested: {
    padding: 0,
    paddingLeft: 14,
    opacity: '1 !important',
    background: 'white',
  },
}));
