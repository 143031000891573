// message 是event事件，对于重发之类的要保存原对像
class MessageStore {
  constructor() {
    this.messageList = [];
  }
  add(message) {
    this.messageList.push(message);
  }
  get(userId) {
    return this.messageList.find((item) => item.ID === userId);
  }
}

export default new MessageStore();
