/**
 * Emoji input interface in the chat screen.
 * In respect for the copyright of the emoji design, the Chat Demo/TUIKit project does not include the cutouts of large emoji elements.
 * Please replace them with your own designed or copyrighted emoji packs before the official launch for commercial use.
 * The default small yellow face emoji pack is copyrighted by Tencent Cloud and can be authorized for a fee.
 * If you wish to obtain authorization, please submit a ticket to contact us.
 *
 * submit a ticket url：https://console.tencentcloud.com/workorder/category?level1_id=29&level2_id=40&source=14&data_title=Chat&step=1
 */

export const DEFAULT_BASIC_EMOJI_URL = 'https://web.sdk.qcloud.com/im/assets/emoji-plugin/';

export const emojiPlusMap: Record<string, string> = {
  '[TUIEmoji_Expect]': 'emoji_0@2x.png',
  '[TUIEmoji_Blink]': 'emoji_1@2x.png',
  '[TUIEmoji_Guffaw]': 'emoji_2@2x.png',
  '[TUIEmoji_KindSmile]': 'emoji_3@2x.png',
  '[TUIEmoji_Haha]': 'emoji_4@2x.png',
  '[TUIEmoji_Cheerful]': 'emoji_5@2x.png',
  '[TUIEmoji_Smile]': 'emoji_6@2x.png',
  '[TUIEmoji_Sorrow]': 'emoji_7@2x.png',
  '[TUIEmoji_Speechless]': 'emoji_8@2x.png',
  '[TUIEmoji_Amazed]': 'emoji_9@2x.png',
  '[TUIEmoji_Complacent]': 'emoji_10@2x.png',
  '[TUIEmoji_Lustful]': 'emoji_11@2x.png',
  '[TUIEmoji_Stareyes]': 'emoji_12@2x.png',
  '[TUIEmoji_Giggle]': 'emoji_13@2x.png',
  '[TUIEmoji_Daemon]': 'emoji_14@2x.png',
  '[TUIEmoji_Rage]': 'emoji_15@2x.png',
  '[TUIEmoji_Yawn]': 'emoji_16@2x.png',
  '[TUIEmoji_TearsLaugh]': 'emoji_17@2x.png',
  '[TUIEmoji_Silly]': 'emoji_18@2x.png',
  '[TUIEmoji_Wail]': 'emoji_19@2x.png',
  '[TUIEmoji_Kiss]': 'emoji_20@2x.png',
  '[TUIEmoji_Trapped]': 'emoji_21@2x.png',
  '[TUIEmoji_Fear]': 'emoji_22@2x.png',
  '[TUIEmoji_BareTeeth]': 'emoji_23@2x.png',
  '[TUIEmoji_FlareUp]': 'emoji_24@2x.png',
  '[TUIEmoji_Tact]': 'emoji_25@2x.png',
  '[TUIEmoji_Shit]': 'emoji_26@2x.png',
  '[TUIEmoji_ShutUp]': 'emoji_27@2x.png',
  '[TUIEmoji_Sigh]': 'emoji_28@2x.png',
  '[TUIEmoji_Hehe]': 'emoji_29@2x.png',
  '[TUIEmoji_Silent]': 'emoji_30@2x.png',
  '[TUIEmoji_Skull]': 'emoji_31@2x.png',
  '[TUIEmoji_Mask]': 'emoji_32@2x.png',
  '[TUIEmoji_Beer]': 'emoji_33@2x.png',
  '[TUIEmoji_Cake]': 'emoji_34@2x.png',
  '[TUIEmoji_RedPacket]': 'emoji_35@2x.png',
  '[TUIEmoji_Bombs]': 'emoji_36@2x.png',
  '[TUIEmoji_Ai]': 'emoji_37@2x.png',
  '[TUIEmoji_Celebrate]': 'emoji_38@2x.png',
  '[TUIEmoji_Bless]': 'emoji_39@2x.png',
  '[TUIEmoji_Flower]': 'emoji_40@2x.png',
  '[TUIEmoji_Watermelon]': 'emoji_41@2x.png',
  '[TUIEmoji_Cow]': 'emoji_42@2x.png',
  '[TUIEmoji_Fool]': 'emoji_43@2x.png',
  '[TUIEmoji_Surprised]': 'emoji_44@2x.png',
  '[TUIEmoji_Askance]': 'emoji_45@2x.png',
  '[TUIEmoji_Monster]': 'emoji_46@2x.png',
  '[TUIEmoji_Pig]': 'emoji_47@2x.png',
  '[TUIEmoji_Coffee]': 'emoji_48@2x.png',
  '[TUIEmoji_Ok]': 'emoji_49@2x.png',
  '[TUIEmoji_Heart]': 'emoji_50@2x.png',
  '[TUIEmoji_Sun]': 'emoji_51@2x.png',
  '[TUIEmoji_Moon]': 'emoji_52@2x.png',
  '[TUIEmoji_Star]': 'emoji_53@2x.png',
  '[TUIEmoji_Rich]': 'emoji_54@2x.png',
  '[TUIEmoji_Fortune]': 'emoji_55@2x.png',
  '[TUIEmoji_857]': 'emoji_56@2x.png',
  '[TUIEmoji_666]': 'emoji_57@2x.png',
  '[TUIEmoji_Prohibit]': 'emoji_58@2x.png',
  '[TUIEmoji_Convinced]': 'emoji_59@2x.png',
  '[TUIEmoji_Knife]': 'emoji_60@2x.png',
  '[TUIEmoji_Like]': 'emoji_61@2x.png',
};

export const emojiPlusName: Array<string> = [
  '[TUIEmoji_Expect]',
  '[TUIEmoji_Blink]',
  '[TUIEmoji_Guffaw]',
  '[TUIEmoji_KindSmile]',
  '[TUIEmoji_Haha]',
  '[TUIEmoji_Cheerful]',
  '[TUIEmoji_Smile]',
  '[TUIEmoji_Sorrow]',
  '[TUIEmoji_Speechless]',
  '[TUIEmoji_Amazed]',
  '[TUIEmoji_Complacent]',
  '[TUIEmoji_Lustful]',
  '[TUIEmoji_Stareyes]',
  '[TUIEmoji_Giggle]',
  '[TUIEmoji_Daemon]',
  '[TUIEmoji_Rage]',
  '[TUIEmoji_Yawn]',
  '[TUIEmoji_TearsLaugh]',
  '[TUIEmoji_Silly]',
  '[TUIEmoji_Wail]',
  '[TUIEmoji_Kiss]',
  '[TUIEmoji_Trapped]',
  '[TUIEmoji_Fear]',
  '[TUIEmoji_BareTeeth]',
  '[TUIEmoji_FlareUp]',
  '[TUIEmoji_Tact]',
  '[TUIEmoji_Shit]',
  '[TUIEmoji_ShutUp]',
  '[TUIEmoji_Sigh]',
  '[TUIEmoji_Hehe]',
  '[TUIEmoji_Silent]',
  '[TUIEmoji_Skull]',
  '[TUIEmoji_Mask]',
  '[TUIEmoji_Beer]',
  '[TUIEmoji_Cake]',
  '[TUIEmoji_RedPacket]',
  '[TUIEmoji_Bombs]',
  '[TUIEmoji_Ai]',
  '[TUIEmoji_Celebrate]',
  '[TUIEmoji_Bless]',
  '[TUIEmoji_Flower]',
  '[TUIEmoji_Watermelon]',
  '[TUIEmoji_Cow]',
  '[TUIEmoji_Fool]',
  '[TUIEmoji_Surprised]',
  '[TUIEmoji_Askance]',
  '[TUIEmoji_Monster]',
  '[TUIEmoji_Pig]',
  '[TUIEmoji_Coffee]',
  '[TUIEmoji_Ok]',
  '[TUIEmoji_Heart]',
  '[TUIEmoji_Sun]',
  '[TUIEmoji_Moon]',
  '[TUIEmoji_Star]',
  '[TUIEmoji_Rich]',
  '[TUIEmoji_Fortune]',
  '[TUIEmoji_857]',
  '[TUIEmoji_666]',
  '[TUIEmoji_Prohibit]',
  '[TUIEmoji_Convinced]',
  '[TUIEmoji_Knife]',
  '[TUIEmoji_Like]',
];
