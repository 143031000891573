import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TIMSDK as tim } from 'tccc-sdk';

import { getMessageFrom } from 'src/utils/message';

import { InfoElementWrapper } from './InfoElement.style';

export const RevokeMessageElement = ({ reSend, message, callInfo }) => {
  const { t } = useTranslation();
  const resendMessage = () => {
    switch (message.type) {
      case tim.TYPES.MSG_TEXT:
        reSend(message.payload.text);
        break;
      default:
        break;
    }
  };
  return (
    <InfoElementWrapper>
      {message.flow === 'out' && (
        <span>
          {t('你撤回了一条消息')}{' '}
          {message.type === tim.TYPES.MSG_TEXT && (
            <Button variant="text" onClick={resendMessage}>
              {t('重新编辑')}
            </Button>
          )}
        </span>
      )}
      {message.flow === 'in' && (
        <span>
          {getMessageFrom(message, callInfo)}
          {t('撤回了一条消息')}
        </span>
      )}
    </InfoElementWrapper>
  );
};
