import { ReactNode, SetStateAction, useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Session } from 'src/store/slices/sessions';

import { MicStatus } from '../../../views/Agent/Workbench/Content/components/MicStatus';
import { WifiStatus } from '../../../views/Agent/Workbench/Content/components/WifiStatus';
const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '33px',
    borderBottom: '0.5px solid #333333',
  },
  mic: {
    marginLeft: 12,
  },
});

const useTypographyStyles = makeStyles({
  subtitle1: {
    height: 18,
    lineHeight: '18px',
    fontFamily: 'PingFangSC-Regular !important',
    fontWeight: 500,
    fontSize: 12,
    color: '#fff',
    marginRight: 4,
  },
  subtitle2: {
    height: 18,
    lineHeight: '18px',
    fontFamily: 'PingFangSC-Regular !important',
    fontSize: 12,
    color: '#fff',
  },
});

interface HeaderProps {
  time?: ReactNode;
  statusText?: ReactNode;
  callInfo: Session;
}
export const Header: React.FC<HeaderProps> = ({ time, statusText, callInfo }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const typographyClasses = useTypographyStyles();
  const [networkQuality, setNetworkQuality] = useState(0);
  return (
    <div className={classes.wrapper}>
      {statusText && (
        <Typography classes={typographyClasses} component={'div'} variant={'subtitle1'}>
          {statusText}
        </Typography>
      )}
      {time && (
        <Typography classes={typographyClasses} component={'div'} variant={'subtitle1'}>
          ({time})
        </Typography>
      )}
      <WifiStatus
        onNetworkQuality={(val: SetStateAction<number>) => {
          setNetworkQuality(val);
        }}
      />
      <MicStatus
        darkMode
        className={classes.mic}
        side="local"
        sessionId={callInfo.sessionId}
        disabled={callInfo.micStatus === 'off' || callInfo.holdStatus}
      />
      {networkQuality >= 4 ? (
        <div style={{ paddingLeft: 8, color: 'rgba(255,255,255,0.6)' }}>{t('当前通话你的网络不佳')}</div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
