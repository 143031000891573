/* eslint-disable import/no-anonymous-default-export */
export default () => ({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    verticalAlign: 'baseline',
    alignItems: 'start',
    marginBottom: '0',
  },
  errorTips: {
    margin: 0,
    lineHeight: '14px',
  },
  errorText: {
    height: 30,
    color: '#E54446',
    fontSize: '12px',
  },
  inputLabel: {
    position: 'static',
    whiteSpace: 'nowrap',
    width: 80,
    textAlign: 'left',
    marginRight: 10,
  },
});
