import { useEffect } from 'react';

import { makeStyles, List } from '@material-ui/core';

import { message } from '@tencent/tea-component';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectAllSession } from '../../store';

import { CallOutTips } from './CallOutTips/CallOutTips';
import { MediaTips } from './MediaTips';
const useNotificationListStyles = makeStyles(() => ({
  listRoot: {
    position: 'absolute',
    top: 100,
    right: 80,
    zIndex: 10,
    background: 'rgba(0,0,0,0.80)',
  },
}));
export function NotificationList() {
  const classes = useNotificationListStyles();
  const allSession = useSelector(selectAllSession);
  const { t } = useTranslation();
  const sessions = allSession.filter((item) =>
    ['phone', 'dialBack', 'internal', 'monitor', 'voip'].includes(item.type),
  );
  useEffect(() => {
    function updateOnline() {
      // 目前先在有session list的时候提醒。
      if (allSession && allSession.length > 0 && !navigator.onLine) {
        message.error({
          content: t('您当前网络不可用，请检查网络设备'),
        });
        document.title = t('已离线');
      } else if (allSession && allSession.length > 0 && navigator.onLine) {
        message.success({
          content: t('您的网络已从不可用恢复'),
        });
        document.title = t('联络中心');
      }
    }
    function deviceChanged() {
      if (allSession && allSession.length > 0 && !/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        message.warning({
          content: t('您的设备发生变更，请检查设备是否正常以免影响服务'),
        });
      }
    }
    try {
      navigator.mediaDevices?.addEventListener('devicechange', deviceChanged);
    } catch (ex) {}
    window.addEventListener('online', updateOnline);
    window.addEventListener('offline', updateOnline);
    return () => {
      try {
        navigator.mediaDevices?.removeEventListener('devicechange', deviceChanged);
      } catch (ex) {}
      window.removeEventListener('online', updateOnline);
      window.removeEventListener('offline', updateOnline);
    };
  }, [allSession]);
  const mediaSession = useSelector(selectAllSession).filter(
    (item) =>
      item.status === '200' && item.type === 'im' && item.media?.status === '200' && item.media.type === 'audio',
  );
  return (
    <Draggable bounds={'html'}>
      <List className={classes.listRoot}>
        {sessions.map((item) => (
          <CallOutTips key={item.sessionId} callInfo={item} />
        ))}
        {mediaSession.map((item) => (
          <MediaTips key={item.sessionId} callInfo={item} />
        ))}
      </List>
    </Draggable>
  );
}
