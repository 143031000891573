/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useMemo } from 'react';

import { makeStyles } from '@material-ui/core';
import { Switch } from '@tencent/tea-component';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from 'src/store';
import { updateUserSettings } from 'src/store/slices/app.thunk';
import { goldlog } from 'src/utils/goldlog';

import { ExtensionLogin } from './ExtensionLogin';
const LogInteraction = goldlog.of('SEAT').forType('INTERACTION');

const useStyles = makeStyles({
  switch: {
    margin: 0,
    transform: 'scale(0.8)',
  },
  switchItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

function SwitchItem(props: {
  value: boolean;
  label: string;
  tooltip?: string;
  disabled?: boolean;
  onChange: (value: boolean) => Promise<void>;
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const onChange = useMemo(
    () =>
      debounce(
        async (value: boolean) => {
          setLoading(true);
          await props.onChange(value);
          setLoading(false);
        },
        500,
        {
          leading: true,
          trailing: false,
        },
      ),
    [],
  );
  return (
    <div className={classes.switchItem}>
      <span>{props.label}</span>
      <Switch
        className={classes.switch}
        tooltip={props.tooltip}
        value={props.value}
        loading={loading}
        onChange={onChange}
        disabled={props.disabled}
      />
    </div>
  );
}

type UseMobileAcceptType = 0 | 1 | 2;
export function UseMobileAccept(props: { disabled: boolean }) {
  const dispatch = useAppDispatch();
  const useMobileAcceptType = useSelector((e: RootState) => e.userInfo.useMobileAcceptType);
  const value = useMobileAcceptType === 1 || useMobileAcceptType === 2;
  const { t } = useTranslation();
  const tooltipMap = new Map([
    [0, t('开启手机接听后，座席离线时来电将会呼叫到座席手机，如需开启手机接听功能请联系您的管理员添加号码至白名单')],
    [1, t('手机接听功能已开启，工作台离线时，来电将呼叫至座席手机')],
    [2, t('双呼功能开启时，来电将会同时呼叫工作台和座席手机')],
  ]);
  const onChange = async (value: UseMobileAcceptType) => {
    LogInteraction.doing('SWITCH_SWITCH').with({ target: value }, t('点击切换手机接听'));
    await dispatch(
      updateUserSettings({
        useMobileAcceptType: value,
      }),
    );
  };
  return (
    <>
      <SwitchItem
        disabled={props.disabled}
        value={value}
        label={t('手机接听')}
        tooltip={tooltipMap.get(useMobileAcceptType) || ''}
        onChange={(value) => onChange(value ? 1 : (0 as UseMobileAcceptType))}
      />
      {value && (
        <>
          <select
            onChange={(e) => onChange(+e.target.value as UseMobileAcceptType)}
            value={useMobileAcceptType}
            style={{ fontSize: 12, border: 'none', alignSelf: 'flex-start', width: '100%', padding: '0.5em 0.3em' }}
          >
            <option title={tooltipMap.get(1)} value={1}>
              {t('仅工作台离线时')}
            </option>
            <option title={tooltipMap.get(2)} value={2}>
              {t('始终')}
            </option>
          </select>
        </>
      )}
    </>
  );
}

export function UseMobileCallOut(props: { disabled: boolean }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const useMobileCallOut = useSelector((e: RootState) => e.userInfo.useMobileCallOut);

  const onChange = async (value: boolean) => {
    LogInteraction.doing('SWITCH_SWITCH').with({ target: value }, t('点击切换手机外呼'));
    await dispatch(
      updateUserSettings({
        useMobileCallOut: value,
      }),
    );
  };
  return (
    <SwitchItem
      disabled={props.disabled}
      value={useMobileCallOut}
      label={t('手机外呼')}
      tooltip={
        useMobileCallOut
          ? t('手机外呼功能已开启，在工作台外呼时会转接至手机发起外呼')
          : t('如需开启手机外呼功能，请联系您的管理员添加号码至白名单')
      }
      onChange={onChange}
    />
  );
}

export function UseExtensionCall(props: { disabled: boolean }) {
  const [flag, setFlag] = useState(false);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const handleChange = async (value: boolean) => {
    if (!value) {
      setFlag(false);
      return;
    }
    setVisible(true);
  };

  const handleRefresh = () => {
    setFlag(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <>
      <SwitchItem value={flag} label={t('话机呼入/呼出')} onChange={handleChange} />
      <ExtensionLogin visible={visible} onClose={handleClose} onRefresh={handleRefresh} />
    </>
  );
}
