import jsCookie from 'js-cookie';

import { isInternational } from 'tccc-utils';

import { getDemoSDKAppId, isDebugMode } from 'src/utils';
import { getUrlSearchParam } from 'src/utils/urls';

class sessionManage {
  userId: string;
  sdkAppId: string;
  constructor() {
    this.userId = '';
    this.sdkAppId = '';
  }
  getSessionKey() {
    return jsCookie.get('sessionKey');
  }
  getLoginSessionKey() {
    return jsCookie.get('sessionKey2');
  }
  clearSession() {
    jsCookie.remove('sessionKey');
    jsCookie.remove('sessionKey2');
    jsCookie.remove('sessionKey', { domain: '.qcloud.com', sameSite: 'none', secure: true });
    jsCookie.remove('sessionKey2', { domain: '.qcloud.com', sameSite: 'none', secure: true });
    jsCookie.remove('sessionKey', { domain: '.tccc.qcloud.com', sameSite: 'none', secure: true });
    jsCookie.remove('sessionKey2', { domain: '.tccc.qcloud.com', sameSite: 'none', secure: true });
    jsCookie.remove('sessionKey', { domain: '.connect.tencentcloud.com', sameSite: 'none', secure: true });
    jsCookie.remove('sessionKey2', { domain: '.connect.tencentcloud.com', sameSite: 'none', secure: true });
  }
  isLogin() {
    return this.getSessionKey() || this.getLoginSessionKey();
  }
  reload() {
    if (window.parent && ['/open.html', '/open'].includes(window.location.pathname)) {
      window.location.href = '/open.html';
      return;
    }
    this.clearSession();
    if (window.location.pathname.includes('/demo')) {
      const sdkAppidFromLocalStorage = localStorage.getItem('T3CDemoSdkAppId');
      const sdkAppId_ =
        window.location.pathname.replace('/demo/', '').length > 1
          ? window.location.pathname.replace('/demo/', '')
          : sdkAppidFromLocalStorage;
      window.location.href = `/demo/${sdkAppId_ || getDemoSDKAppId()}${window.location.search || ''}`;
      return;
    }
    const isFromDesk = getUrlSearchParam('source') === 'desk_console';
    // DESK 客服退出登录后，跳转到 DESK 登录页面
    if (isFromDesk) {
      const isIntl = isInternational();
      if (isIntl) {
        return `https://desk.tencentcloud.com/login${window.location.search}`;
      }
      if (isDebugMode()) {
        window.location.href = `https://test-desk.qcloud.com/login${window.location.search}`;
        return;
      }
      window.location.href = `https://desk.qcloud.com/login${window.location.search}`;
      return;
    }
    window.location.href = `/login${window.location.search}`;
  }
  loginOut() {
    this.clearSession();
    this.reload();
  }

  setGlobalUserId(userId: string) {
    this.userId = userId;
  }
  setGlobalSdkAppId(sdkAppId: string) {
    this.sdkAppId = sdkAppId;
  }
  getGlobalUserId() {
    return this.userId;
  }
  getGlobalSdkAppId() {
    return this.sdkAppId;
  }
}

// eslint-disable-next-line new-cap
export default new sessionManage();
