/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/no-var-requires */
import { makeStyles } from '@material-ui/core/styles';

import wxEmojiImage from 'src/assets/images/emoji-sprite.png';

import Emoji2_16Img from 'src/assets/images/wx-new-emojis/2_16.png';
import Emoji2_17Img from 'src/assets/images/wx-new-emojis/2_17.png';
import Emoji666Img from 'src/assets/images/wx-new-emojis/666.png';
import EmojiAddoilImg from 'src/assets/images/wx-new-emojis/Addoil.png';
import EmojiBoringImg from 'src/assets/images/wx-new-emojis/Boring.png';
import EmojiBrokenImg from 'src/assets/images/wx-new-emojis/Broken.png';
import EmojiColdImg from 'src/assets/images/wx-new-emojis/Cold.png';
import EmojiCommandoImg from 'src/assets/images/wx-new-emojis/Commando.gif';
import EmojiDuhImg from 'src/assets/images/wx-new-emojis/Duh.png';
import EmojiFirecrackerImg from 'src/assets/images/wx-new-emojis/Firecracker.png';
import EmojiFireworksImg from 'src/assets/images/wx-new-emojis/Fireworks.png';
import EmojiFlushedImg from 'src/assets/images/wx-new-emojis/Flushed.png';
import EmojiGiftImg from 'src/assets/images/wx-new-emojis/Gift.png';
import EmojiHappyImg from 'src/assets/images/wx-new-emojis/Happy.png';
import EmojiHurtImg from 'src/assets/images/wx-new-emojis/Hurt.png';
import EmojiKeepFightingImg from 'src/assets/images/wx-new-emojis/KeepFighting.png';
import EmojiLetDownImg from 'src/assets/images/wx-new-emojis/Let Down.png';
import EmojiLetMeSeeImg from 'src/assets/images/wx-new-emojis/LetMeSee.png';
import EmojiLolImg from 'src/assets/images/wx-new-emojis/Lol.png';
import EmojiNoProbImg from 'src/assets/images/wx-new-emojis/NoProb.png';
import EmojiPartyImg from 'src/assets/images/wx-new-emojis/Party.png';
import EmojiShockedImg from 'src/assets/images/wx-new-emojis/Shocked.png';
import EmojiSickImg from 'src/assets/images/wx-new-emojis/Sick.png';
import EmojiSighImg from 'src/assets/images/wx-new-emojis/Sigh.png';
import EmojiSlapImg from 'src/assets/images/wx-new-emojis/Slap.png';
import EmojiSocialImg from 'src/assets/images/wx-new-emojis/Social.png';
import EmojiSweatImg from 'src/assets/images/wx-new-emojis/Sweat.png';
import EmojiTerrorImg from 'src/assets/images/wx-new-emojis/Terror.png';
import EmojiWatermelonImg from 'src/assets/images/wx-new-emojis/Watermelon.png';
import EmojiWorshipImg from 'src/assets/images/wx-new-emojis/Worship.png';
import EmojiWowImg from 'src/assets/images/wx-new-emojis/Wow.png';
import EmojiYellowdogImg from 'src/assets/images/wx-new-emojis/Yellowdog.png';

export const emojiFiles = [
  wxEmojiImage,
  Emoji2_16Img,
  Emoji2_17Img,
  Emoji666Img,
  EmojiAddoilImg,
  EmojiBoringImg,
  EmojiBrokenImg,
  EmojiColdImg,
  EmojiCommandoImg,
  EmojiDuhImg,
  EmojiFirecrackerImg,
  EmojiFireworksImg,
  EmojiFlushedImg,
  EmojiGiftImg,
  EmojiHappyImg,
  EmojiHurtImg,
  EmojiKeepFightingImg,
  EmojiLetDownImg,
  EmojiLetMeSeeImg,
  EmojiLolImg,
  EmojiNoProbImg,
  EmojiPartyImg,
  EmojiShockedImg,
  EmojiSickImg,
  EmojiSighImg,
  EmojiSlapImg,
  EmojiSocialImg,
  EmojiSweatImg,
  EmojiTerrorImg,
  EmojiWatermelonImg,
  EmojiWorshipImg,
  EmojiWowImg,
  EmojiYellowdogImg,
];

export default makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  root: {
    zIndex: 10,
  },
  emojis: {
    flex: 1,
    width: 435,
    maxHeight: 350,
    borderBottom: '1px solid #eeeeee',
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    padding: 10,
    cursor: 'pointer',
  },
  emoji: {
    height: 28,
    width: 28,
    padding: '2px 3px 3px 2px',
    boxSizing: 'border-box',
  },
  emojiImg: {
    backgroundImage: `url(${wxEmojiImage})`,
    '&.newWXEmoji,&.im-emojis': {
      width: 64,
      height: 64,
      backgroundSize: '64px 64px',
    },
    '&.smiley_29': {
      backgroundImage: `url(${EmojiCommandoImg})`,
    },
    '&.Gift': {
      backgroundImage: `url(${EmojiGiftImg})`,
    },
    '&.Happy': {
      backgroundImage: `url(${EmojiHappyImg})`,
    },
    '&.Sick': {
      backgroundImage: `url(${EmojiSickImg})`,
    },
    '&.Lol': {
      backgroundImage: `url(${EmojiLolImg})`,
    },
    '&.Flushed': {
      backgroundImage: `url(${EmojiFlushedImg})`,
    },
    '&.Terror': {
      backgroundImage: `url(${EmojiTerrorImg})`,
    },
    '&.LetDown': {
      backgroundImage: `url(${EmojiLetDownImg})`,
    },
    '&.Duh': {
      backgroundImage: `url(${EmojiDuhImg})`,
    },
    '&.Worship': {
      backgroundImage: `url(${EmojiWorshipImg})`,
    },
    '&.Party': {
      backgroundImage: `url(${EmojiPartyImg})`,
    },
    '&.Watermelon': {
      backgroundImage: `url(${EmojiWatermelonImg})`,
    },
    '&.Addoil': {
      backgroundImage: `url(${EmojiAddoilImg})`,
    },
    '&.Sweat': {
      backgroundImage: `url(${EmojiSweatImg})`,
    },
    '&.Shocked': {
      backgroundImage: `url(${EmojiShockedImg})`,
    },
    '&.Cold': {
      backgroundImage: `url(${EmojiColdImg})`,
    },
    '&.Social': {
      backgroundImage: `url(${EmojiSocialImg})`,
    },
    '&.Yellowdog': {
      backgroundImage: `url(${EmojiYellowdogImg})`,
    },
    '&.NoProb': {
      backgroundImage: `url(${EmojiNoProbImg})`,
    },
    '&.Slap': {
      backgroundImage: `url(${EmojiSlapImg})`,
    },
    '&.Wow': {
      backgroundImage: `url(${EmojiWowImg})`,
    },
    '&.e2_16': {
      backgroundImage: `url(${Emoji2_16Img})`,
    },
    '&.e2_17': {
      backgroundImage: `url(${Emoji2_17Img})`,
    },
    '&.KeepFighting': {
      backgroundImage: `url(${EmojiKeepFightingImg})`,
    },
    '&.Boring': {
      backgroundImage: `url(${EmojiBoringImg})`,
    },
    '&.e666': {
      backgroundImage: `url(${Emoji666Img})`,
    },
    '&.LetMeSee': {
      backgroundImage: `url(${EmojiLetMeSeeImg})`,
    },
    '&.Sigh': {
      backgroundImage: `url(${EmojiSighImg})`,
    },
    '&.Hurt': {
      backgroundImage: `url(${EmojiHurtImg})`,
    },
    '&.Broken': {
      backgroundImage: `url(${EmojiBrokenImg})`,
    },
    '&.Fireworks': {
      backgroundImage: `url(${EmojiFireworksImg})`,
    },
    '&.Firecracker': {
      backgroundImage: `url(${EmojiFirecrackerImg})`,
    },
    ...(function () {
      const imEmojis = {};
      for (let i = 0; i <= 141; i++) {
        imEmojis[`&.emoji_${i}`] = {
          backgroundImage: `url(${require(`src/assets/images/emojis/emoji_${i}@2x.png`)})`,
        };
      }
      return imEmojis;
    })(),
  },
  iconButton: {
    marginRight: 16,
  },
  smileIcon: {
    width: 18,
    height: 18,
    color: '#fff',
    '&:hover': {
      stroke: '#006EFF',
      fill: '#006EFF',
      cursor: 'pointer',
      '& path': {
        stroke: '#006EFF',
      },
      '& circle': {
        stroke: '#006EFF',
      },
    },
  },
  openSmileIcon: {
    stroke: '#006EFF',
    fill: '#006EFF',
    cursor: 'pointer',
    '& path': {
      stroke: '#006EFF',
    },
    '& circle': {
      stroke: '#006EFF',
    },
  },
  imgItem: {
    padding: '5px',
    '&::marker': {
      content: 'none',
    },
  },
}));
