import { makeStyles, withStyles } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import i18n from 'src/i18n';

import { StaffStatus } from 'src/services/httpAPIs/admin';

import { useIMHistory } from '../ServiceRecords/HistoryRecord';

import { ImList } from './im-list/ImList';
import style from './IM.style';
import IMHeaderStyle from './IMHeader/IMHeader.style';
import HistoryMessageList from './Message/MessageList/HistoryMessageList';
import MessageSend from './Message/MessageSend/MessageSend';

const useStyles = makeStyles(style);
const useHeaderStyles = makeStyles(IMHeaderStyle);

const StyledHistoryMessageList = withStyles({
  historyMessage: {
    width: '100%',
  },
})(HistoryMessageList);

const { t } = i18n;

function CustomHistoryMessageList({ data }) {
  const { roomId, sdkAppId, userId, uid: sessionId } = data;
  const historyData = useIMHistory({ roomId, sdkAppId, userId, userName: t('用户'), sessionId }, true);
  return <StyledHistoryMessageList {...historyData} />;
}
CustomHistoryMessageList.propTypes = {
  data: PropTypes.shape({
    roomId: PropTypes.string,
    sdkAppId: PropTypes.string,
    userId: PropTypes.string,
  }),
};

const getColor = (status) => {
  switch (status) {
    case StaffStatus.Busy:
    case StaffStatus.NotReady:
      return '#E54545';
    case StaffStatus.Offline:
      return '#B3B3B3';
    default:
      return '#0DBF5B';
  }
};
const getStatusDisplay = (status) => {
  switch (status) {
    case StaffStatus.AfterWork:
    case StaffStatus.AfterWorkCountDown:
    case StaffStatus.Rest:
      return t('小休中');
    case StaffStatus.Busy:
      return t('忙碌中');
    case StaffStatus.Free:
      return t('空闲');
    case StaffStatus.NotReady:
      return t('示忙');
    case StaffStatus.Offline:
      return t('离线');
    default:
      return undefined;
  }
};
export default function Chart() {
  const currentUser = useSelector((state) => state.userInfo.currentUser);
  const callInfo = useSelector((state) =>
    state.tim.innerConversationList.find((item) => item.sessionId === currentUser.sessionId),
  );
  const skillGroups = useSelector((state) => state.appSettings.skillGroupList);
  const classes = useStyles();
  const IMHeaderClasses = useHeaderStyles();

  if (!callInfo) return null;
  return (
    <div className={classes.chart}>
      <div className={classes.conversation}>
        <div className={classes.IM}>
          <div className={IMHeaderClasses.root} style={{ background: 'white' }}>
            <div className={IMHeaderClasses.info}>
              <div className={IMHeaderClasses.nickName}>
                {callInfo.nickname}
                &nbsp;
                <span
                  title={getStatusDisplay(callInfo.status)}
                  style={{
                    height: '8px',
                    display: 'inline-block',
                    padding: 0,
                    minWidth: 8,
                    borderRadius: 4,
                    background: getColor(callInfo.status),
                  }}
                ></span>
              </div>
              <div className={IMHeaderClasses.extra}>
                <span>
                  {t('工号')}
                  {': '}
                  {callInfo.staffNo}
                </span>
                <span>
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{' '}
                  <span>
                    {t('邮箱')}
                    {': '}
                    {callInfo.userId}
                  </span>
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                  <span>
                    {t('技能组')}
                    {': '}
                    {callInfo.skillGroupId
                      ?.split(',')
                      .map(
                        (skillGroupId) =>
                          skillGroups.find((item) => item.skillGroupId === skillGroupId)?.skillGroupName,
                      )
                      .join(', ')}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <ImList />
          <MessageSend callInfo={callInfo} />
        </div>
      </div>
    </div>
  );
}

Chart.propTypes = {};
