import { Button } from '@tencent/tea-component';
import styled from 'styled-components';

export const MessageListWrapper = styled.div`
  box-sizing: border-box;
  flex: 1 1 0%;
  border-bottom: 1px solid #d3d4d8;
`;
export const MessageListBox = styled.div`
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: white;
`;

export const ShowMoreButton = styled(Button)`
  display: block;
  margin: 20px auto;
`;
