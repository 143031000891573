import { useState } from 'react';

import { makeStyles, Button } from '@material-ui/core';

import { Icon, message } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';

import ExtensionList from 'src/components/ExtensionList/ExtensionList';

import { Extension } from 'src/services/httpAPIs/admin';

const useStyles = makeStyles({
  internalCall: {
    padding: '20px 10px 0 10px',
  },
  buttons: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  callButton: {
    width: 140,
    height: 32,
    borderRadius: 17,
    margin: '0 auto',
  },
});

interface ExtensionCallProps {
  isCalling: boolean;
  open: boolean;
  handleCallout: (userId: string, useMobile: boolean) => void;
}

export const ExtensionCall: React.FC<ExtensionCallProps> = ({ handleCallout, isCalling, open }) => {
  const classes = useStyles();
  const [selectedExtension, setSelectedExtension] = useState<Extension | null>(null);
  const { t } = useTranslation();
  const _handleCallOut = () => {
    if (!selectedExtension?.extensionId) {
      return;
    }
    if (!selectedExtension?.register) {
      message.error({ content: t('话机呼叫失败，错误信息：该话机未注册') });
      return;
    }
    handleCallout(selectedExtension.fullExtensionId, false);
  };

  return (
    <div className={classes.internalCall}>
      <ExtensionList open={open} onSelected={setSelectedExtension} value={selectedExtension?.extensionId || ''} />
      <div className={classes.buttons}>
        <Button
          className={classes.callButton}
          variant={'contained'}
          color={'primary'}
          onClick={_handleCallOut}
          disabled={isCalling || !selectedExtension?.extensionId}
        >
          {isCalling ? (
            <>
              <Icon type="loading" />
              <span style={{ paddingLeft: 5 }}>{t('拨打中')}</span>
            </>
          ) : (
            t('拨打话机')
          )}
        </Button>
      </div>
    </div>
  );
};
