import { useMemo } from 'react';

import { Tabs, TabPanel, Layout, Card } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TransferSeat } from 'src/components/Transfer/TransferSeat';
import UserTransfer from 'src/components/Transfer/UserTransfer';
import { isDeskSeat } from 'src/utils';
const { Body, Content } = Layout;

export function ImTransfer(props) {
  const { callInfo, onComplete } = props;
  const { t } = useTranslation();
  const appSettings = useSelector((state) => state.appSettings);

  const isDesk = useMemo(
    () => isDeskSeat(appSettings?.seatType, appSettings?.imAppType),
    [appSettings?.imAppType, appSettings?.seatType],
  );

  const tabs = !isDesk
    ? [
        {
          id: 'skill-group',
          label: t('转技能组'),
        },
        {
          id: 'seat',
          label: t('转座席'),
        },
      ]
    : [
        {
          id: 'seat',
          label: t('转指定客服'),
        },
      ];

  return (
    <Layout>
      <Body>
        <Content>
          <Content.Header title={t('转接')} showBackButton onBackButtonClick={onComplete} />
          <Content.Body>
            <Card>
              <Card.Body>
                <Tabs tabs={tabs}>
                  <TabPanel id="seat">
                    <TransferSeat isTransfer hideTopBar onComplete={onComplete} sessionId={callInfo.sessionId} />
                  </TabPanel>
                  <TabPanel id="skill-group">
                    <UserTransfer
                      hideTopBar
                      skillGroupType={1}
                      isTransfer
                      onComplete={onComplete}
                      callInfo={callInfo}
                    />
                  </TabPanel>
                </Tabs>
              </Card.Body>
            </Card>
          </Content.Body>
        </Content>
      </Body>
    </Layout>
  );
}
