import { SvgIcon, styled } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 18,
  height: 18,
  '&:hover': {
    '& path': {
      stroke: '#006eff',
    },
  },
});
export function FileSvg(props) {
  return (
    <Svg viewBox="0 0 18 18" fontSize={'inherit'} color={'inherit'} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 1C5.19494 1 5.83584 1.37483 6.17654 1.98052L6.46875 2.5H13.4028C14.9696 2.5 16.2784 3.62746 16.3699 5.08647C17.3111 5.35564 18 6.22236 18 7.25V14.75C18 15.9926 16.9926 17 15.75 17H2.25C1.00736 17 0 15.9926 0 14.75V3.25C0 2.00736 1.00736 1 2.25 1H4.5ZM14.8449 5C14.7182 4.47861 14.2036 4.05994 13.5472 4.0059L13.4028 4H7.3125L7.875 5H14.8449ZM4.5 2.5H2.25C1.8703 2.5 1.55651 2.78215 1.50685 3.14823L1.5 3.25V14.75C1.5 15.1297 1.78215 15.4435 2.14823 15.4932L2.25 15.5H15.75C16.1297 15.5 16.4435 15.2178 16.4932 14.8518L16.5 14.75V7.25C16.5 6.8703 16.2178 6.55651 15.8518 6.50685L15.75 6.5H7.875C7.38235 6.5 6.92453 6.25844 6.64575 5.85966L6.56764 5.73539L4.86918 2.71591C4.80916 2.60921 4.70684 2.53505 4.58996 2.50965L4.5 2.5Z"
        fill="#666666"
      />
    </Svg>
  );
}
