import PerfectScrollbar from 'react-perfect-scrollbar';

import QuickReply from 'src/components/QuickReply/QuickReply';

function CRMQuickReply({ className }) {
  return (
    <PerfectScrollbar className={className}>
      <QuickReply />
    </PerfectScrollbar>
  );
}

export default CRMQuickReply;
