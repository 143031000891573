import * as t from 'io-ts';
import { nullableArray } from 'tccc-utils';

const numberList = t.array(
  t.type({
    number: t.string,
    location: t.string,
  }),
);
export type NumberList = t.TypeOf<typeof numberList>;

export const pstnAPIs = {
  '/ccc/pstn/invite': {
    Input: t.intersection([
      t.type({ sessionId: t.string }),
      t.union([t.type({ userId: t.string }), t.type({ skillGroupId: t.string }), t.type({ phoneNumber: t.string })]),
    ]),
    Output: t.type({}),
  },
  '/ccc/pstn/getStaffCanCalloutNumberList': {
    Input: t.type({}),
    Output: t.type({
      numberList,
    }),
  },
  '/ccc/pstn/kick': {
    Input: t.type({
      userId: t.string,
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/transferHost': {
    Input: t.type({
      userId: t.string,
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/pstn/getPSTNActiveSessionList': {
    Input: t.partial({
      callType: t.union([t.literal('callin'), t.literal('callout'), t.literal('voipcallin'), t.literal('videocallin')]),
    }),
    Output: t.type({
      total: t.string,
      sessions: nullableArray(
        t.type({
          callType: t.union([
            t.literal('callin'),
            t.literal('callout'),
            t.literal('voipcallin'),
            t.literal('videocallin'),
          ]),
          callee: t.string,
          caller: t.string,
          roomId: t.string,
          sessionId: t.string,
          sessionStatus: t.string,
          staffNo: t.string,
          staffUserId: t.string,
          startTimestamp: t.string,
          acceptTimestamp: t.string,
          ringTimestamp: t.string,
          protectedCaller: t.union([t.undefined, t.string]),
          protectedCallee: t.union([t.undefined, t.string]),
        }),
      ),
    }),
  },
};
