import { useCallback, useMemo, useState } from 'react';

import { makeStyles, IconButton } from '@material-ui/core';
import { isRejected } from '@reduxjs/toolkit';
import { message } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'src/store';
import { sessionInvite, transferSession } from 'src/store/slices/sessions/sessions.thunk';

import { goldlog } from 'src/utils/goldlog';

import backArrow from '../../assets/images/backArrow.svg';
import TransferIcon from '../../assets/images/transfer-white.svg';
import { Loading } from '../Loading/Loading';

import { StaffList } from '../StaffList/StaffList';
import CButton from '../Tim/CustomButtons/Button';

import { TransferText } from './TransferText';
const LogInteraction = goldlog.of('SEAT').forType('INTERACTION');
const LogAPI = goldlog.of('SEAT').forType('API');

interface TransferSeatProps {
  sessionId: string;
  onComplete: () => void;
  hideTopBar?: boolean;
  isTransfer?: boolean;
}

const useStyles = makeStyles({
  transferWrapper: {
    margin: 40,
    textAlign: 'center',
  },
  button: {
    width: 200,
    height: 44,
    borderRadius: 22,
    margin: '20px auto',
  },
  transferHeader: {
    height: 44,
    borderBottom: '1px solid #f7f7f7',
    display: 'flex',
    alignItems: 'center',
  },
});

export const TransferSeat = (props: TransferSeatProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const { sessionId, hideTopBar } = props;
  const [selectedUserId, setSelectedUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState<Staff[]>([]);
  const typeText = useMemo(() => (props.isTransfer ? t('转接') : t('咨询')), [props.isTransfer, t]);

  const handleTransfer = useCallback(() => {
    setLoading(true);
    const params = {
      sessionId,
      userId: selectedUserId,
    };
    LogInteraction.doing('CLICK_TRIGGER').with({ params }, t('点击开始{{typeText}}按钮', { typeText }));
    (props.isTransfer
      ? dispatch(transferSession(params))
      : dispatch(sessionInvite({ sessionId: params.sessionId, callee: { userId: params.userId } }))
    )
      .then((res) => {
        setLoading(false);
        if (isRejected(res)) {
          return message.error({
            content: t('{{typeText}}失败，{{message}}', { typeText, message: res.error.message || t('请重试') }),
          });
        }
        props.onComplete();
        message.success({
          content: t('{{typeText}}成功', { typeText }),
        });
      })
      .catch((error) => {
        LogAPI.doing('CLICK_TRIGGER').with({ params, error });
        setLoading(false);
      });
  }, [sessionId, selectedUserId, typeText, props, dispatch, t]);

  return (
    <div>
      {!hideTopBar && (
        <div className={classes.transferHeader}>
          <IconButton onClick={props.onComplete}>
            <img src={backArrow} alt={t('返回')} />
          </IconButton>
          <span>{typeText}</span>
        </div>
      )}
      <div className={classes.transferWrapper}>
        <StaffList
          value={selectedUserId}
          onSelected={(value) => setSelectedUserId(value)}
          onChangeStaffList={(staffList) => setStaffList(staffList)}
        />
        {staffList.length !== 0 && (
          <CButton
            className={classes.button}
            variant="outlined"
            color="primary"
            disabled={loading || !selectedUserId}
            startIcon={loading ? <Loading size={36} /> : <img src={TransferIcon} alt="" />}
            onClick={handleTransfer}
          >
            <TransferText loading={loading} type={props.isTransfer ? 'transfer' : 'invite'} />
          </CButton>
        )}
      </div>
    </div>
  );
};
