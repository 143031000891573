export enum SelfServiceType {
  consult = 0,
  transfer = 1,
}
export enum SelfServiceSource {
  teleSelfService = 0,
  audioSelfService = 1,
}
export enum SelfServiceStatus {
  on = 1,
  off = 0,
}
