import { useEffect, useState, useCallback } from 'react';

import { FormControl, InputLabel, Select, MenuItem, Input } from '@material-ui/core/index';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import map from 'lodash/map';
import size from 'lodash/size';
import PropTypes from 'prop-types';

import style from './DSSelect.style';

function DSSelect({
  classes,
  label,
  options,
  optionLabelKey,
  optionKey,
  defaultOptionLabel,
  value,
  onChange,
  input,
  className,
  required,
  error,
  selectProps,
  fullWidth,
  ...restProps
}) {
  const parseValue = useCallback(() => (size(options) > 0 ? value : ''), [value, options]);
  const [valueState, setValueState] = useState(parseValue());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setValueState(parseValue()), [value, options]);

  return (
    <FormControl
      fullWidth={fullWidth}
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
    >
      {label && (
        <InputLabel className={classes.inputLabel} shrink={false} error={error}>
          {label}
        </InputLabel>
      )}
      <Select
        fullWidth={fullWidth}
        className={classNames(classes.input, {
          [classes.error]: error,
        })}
        displayEmpty={true}
        disableUnderline={true}
        value={valueState}
        onChange={(event) => {
          setValueState(event.target.value);
          onChange?.(event.target.value);
        }}
        input={input || <Input />}
        {...selectProps}
        {...restProps}
      >
        {defaultOptionLabel && (
          <MenuItem
            classes={{
              root: classes.menuItem,
            }}
            value=""
          >
            {defaultOptionLabel}
          </MenuItem>
        )}
        {map(options, (label, key) => {
          const text = optionLabelKey ? label[optionLabelKey] : label;
          const getValue = () => {
            if (!Array.isArray(options)) {
              return key;
            }
            if (optionKey) {
              return label[optionKey];
            }
            return label;
          };
          const value = getValue();
          return (
            <MenuItem
              key={value}
              value={value}
              classes={{
                root: classes.menuItem,
              }}
            >
              {text}
            </MenuItem>
          );
        })}
      </Select>
      {required && <div className={classes.endAdornment}>*</div>}
    </FormControl>
  );
}
DSSelect.defaultProps = {
  value: '',
};

DSSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  optionLabelKey: PropTypes.string,
  optionKey: PropTypes.string,
  defaultOptionLabel: PropTypes.string,
  input: PropTypes.node,
  value: PropTypes.any,
  required: PropTypes.bool,
  error: PropTypes.bool,
  selectProps: PropTypes.object,
  fullWidth: PropTypes.bool,
};

export default withStyles(style)(DSSelect);
