import produce from 'immer';

import pick from 'lodash/pick';

import {
  UPDATEMESSAGE,
  UNSHIFtMESSAGE,
  UPDATEMESSAGESTATUS,
  ADDMESSAGE,
  CLEARIMSTATE,
  DELETEMESSAGE,
  SDKMINIMIZECHANGED,
  UPDATE_TYPING_STATE,
  UPDATE_REPLY_INFO,
  imSdkReadyStateChanged,
  syncMessageLists,
} from 'src/store/actions/im';

import { deleteSession } from '../slices/sessions/sessions.thunk';

const defaultState = {
  messageList: {},
  sdkMinimized: false,
  typingStateList: {},
  imReady: 'init',
  replyInfoList: {},
  imageViewer: {
    visible: false,
    currentImageId: '',
  },
};

const imReducers = function (srcState, action) {
  const state = srcState || defaultState;
  const { payload } = action;
  switch (action.type) {
    case UPDATE_REPLY_INFO:
      return {
        ...state,
        replyInfoList: {
          ...state.replyInfoList,
          [payload.userId]: payload.replyInfo,
        },
      };
    case UPDATE_TYPING_STATE:
      return {
        ...state,
        typingStateList: {
          ...state.typingStateList,
          [payload.userId]: payload.state,
        },
      };
    case ADDMESSAGE:
      return {
        ...state,
        messageList: {
          ...state.messageList,
          [payload.userId]: {
            list: payload.message.list,
            nextReqMessageID: payload.message ? payload.message.nextReqMessageID : '',
            isCompleted: payload.message.isCompleted,
          },
        },
      };
    case UPDATEMESSAGE:
      return {
        ...state,
        messageList: {
          ...state.messageList,
          ...Object.entries(payload).reduce((acc, [key, value]) => {
            if (!acc[key]) {
              return acc;
            }
            const { list } = acc[key];
            const ids = list.map((item) => item.ID);
            let lastItemIndex = list.length;
            const updateList = Object.fromEntries(
              value.map((item) => {
                const messageIndex = ids.findIndex((id) => id === item.ID);
                const firstEle = messageIndex !== -1 ? messageIndex : lastItemIndex;
                lastItemIndex = lastItemIndex + 1;
                return [firstEle, item];
              }),
            );
            return {
              ...acc,
              [key]: {
                ...acc[key],
                list: Object.assign([], list, updateList),
              },
            };
          }, state.messageList),
        },
      };
    case DELETEMESSAGE:
      return {
        ...state,
        messageList: {
          ...state.messageList,
          [payload.userId]: {
            ...state.messageList[payload.userId],
            list: state.messageList[payload.userId].list.filter((item) => item.ID !== payload.msgId),
          },
        },
      };
    case deleteSession.type:
      return {
        ...state,
        messageList: Object.fromEntries(Object.entries(state.messageList).filter(([key]) => key !== payload.userId)),
      };
    case UPDATEMESSAGESTATUS:
      return produce((state) => {
        const msgList = state.messageList[payload.key].list;
        const messageToUpdate = msgList.find(
          (msg) => msg.ID === payload.message.id || msg.clientSequence === payload.message.clientSequence,
        );
        if (messageToUpdate) {
          Object.assign(messageToUpdate, pick(payload.message, 'status', 'errorMsg', 'sequence'));
        }
      })(state);
    case UNSHIFtMESSAGE:
      return {
        ...state,
        messageList: {
          [payload.userId]: {
            list: [...payload.message, ...state.messageList[payload.userId].list],
            cache: null,
            isCompleted: payload.isCompleted,
            nextReqMessageID: payload.nextReqMessageID,
          },
        },
      };
    case CLEARIMSTATE:
      return { ...defaultState };
    case SDKMINIMIZECHANGED:
      return { ...state, sdkMinimized: action.payload };
    case syncMessageLists.type: {
      return { ...state, messageList: action.payload };
    }
    case imSdkReadyStateChanged.type: {
      return { ...state, imReady: payload };
    }

    default:
      return state;
  }
};
export default imReducers;
