import { CUSTOM_MESSAGE_SRC } from 'src/constants/im';
import i18n from 'src/i18n';
import store from 'src/store';

import { receiveMessageThunk, sendMessage } from 'src/store/slices/tim.thunk';

import { showError } from 'src/utils';

import tccc from './tccc';

import { filterUserId, messageBoxScrollToBottom } from './index';

const { t } = i18n;
class CRMMessage {
  constructor() {
    this.isCreated = false;
    this.evnents = {
      callOut: null,
    };
  }
  create() {
    if (this.isCreated) return;
    this.isCreated = true;
    window.addEventListener(
      'message',
      (message) => {
        const data = message.data.payload;
        data && this[data.event] && this[data.event](data.message);
      },
      false,
    );
  }
  textMessage(message) {
    const currentUser = store.getState().userInfo.currentUser.userId;
    tccc.Chat.sendTextMessage({
      to: filterUserId(currentUser),
      content: message,
    }).then(() => {
      messageBoxScrollToBottom();
    });
  }
  onCallOut(cb) {
    this.evnents.callOut = cb;
  }
  callOut(obj) {
    if (store.getState().agent.status === '200') {
      showError(t('当前座席忙，请稍后尝试重新拨打'));
      return;
    }
    const message = {};
    if (typeof obj === 'number' || typeof obj === 'string') {
      message.phoneNumber = obj;
      message.phoneDesc = null;
    } else if (typeof obj === 'object') {
      message.phoneNumber = obj.phoneNumber;
      message.phoneDesc = obj.desc;
    }
    message.sdkCall = true;
    this.evnents.callOut?.(message);
  }
  async sendCard(obj) {
    const { currentUser } = store.getState().userInfo;
    if (!currentUser) {
      showError(t('当前没有选择会话，无法发送卡片'));
      return;
    }
    const { userId, sessionId } = currentUser;
    const message = await tccc.Chat.createCustomMessage({
      to: filterUserId(userId),
      sessionId,
      payload: {
        data: JSON.stringify({
          src: CUSTOM_MESSAGE_SRC.CustomCard,
          content: obj,
          customerServicePlugin: 0,
        }),
      },
    });
    await store.dispatch(receiveMessageThunk({ data: [message.data] }));
    await store.dispatch(sendMessage({ message: message.data }));
    messageBoxScrollToBottom();
  }
}

export default new CRMMessage();
