import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

export function Loading(props) {
  const fontSize = typeof props.size === 'number' ? `${props.size}px` : props.size;
  return (
    <SvgIcon
      viewBox="0 0 100 100"
      enable-background="new 0 0 0 0"
      fontSize={'inherit'}
      style={{
        fontSize,
      }}
      htmlColor={props.color}
      {...props}
    >
      <circle fill="currentColor" stroke="none" cx="22" cy="46" r="8">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 0;0 -24;0 -36;0 -24;0 -12;0 0;0 0;0 0;0 0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill="currentColor" stroke="none" cx="50" cy="46" r="8">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 0;0 -12;0 -24;0 -36;0 -24;0 -12; 0 0;0 0;0 0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill="currentColor" stroke="none" cx="78" cy="46" r="8">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 0;0 0;0 -12;0 -24;0 -36;0 -24; 0 -12;0 0;0 0"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </SvgIcon>
  );
}

Loading.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
