import { useState, useRef, useEffect } from 'react';

import { Button, Card, CardContent, Input, makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import DSForm from 'src/components/DSFormControls/DSForm/DSForm';
import DSFormControl from 'src/components/DSFormControls/DSForm/DSFormControl';

import services, { debugLogRequest } from 'src/services';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { getSession } from '../../components/VerifyCode/captchaSessionMange';
import VerifyCode from '../../components/VerifyCode/VerifyCode';

import { offline } from '../../store/actions/agent.thunk';
import { updateBindMobile } from '../../store/slices/userInfo';
import sessionManage from '../../utils/sessionManage';

import styles from './PerfectInfo.style';

const useStyles = makeStyles(styles);
function PerfectInfo() {
  const { t } = useTranslation();
  const classes = useStyles();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [value, changeValue] = useState({
    captcha: '',
    mobile: '',
    name: '',
  });
  const bindInfo = () => {
    const validData = formRef.current.validate();
    if (validData.isValid) {
      services['/tccclogin/account/bindMobile']({
        mobile: value.mobile,
        nickName: value.name,
        captcha: {
          value: value.captcha,
          sessionId: getSession(value.mobile),
          module: 'bindMobile',
        },
        isInstance: true,
      }).then(() => {
        dispatch(updateBindMobile(true));
      });
    }
  };
  const handleValue = (msg) => {
    changeValue(msg);
  };

  useEffect(() => {
    debugLogRequest('PerfectInfo dispatch offline');
    dispatch(offline());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Header>
        <Button color="primary" onClick={() => sessionManage.loginOut()} className={classes.registerBtn}>
          {t('切换账号')}
        </Button>
      </Header>
      <div className={classes.contentBox}>
        <header className={classes.header}>
          <h1 className={classes.title}>{t('欢迎登录')}</h1>
          <p className={classes.info}>{t('首次登录，请先完善账号信息')}</p>
        </header>
        <Card className={classes.card}>
          <CardContent className={classes.formContent}>
            <h1 className={classes.desc}>
              {t('绑定手机号用于验证码登录与密码找回功能，仅系统内使用，不会展示于外部客户。')}
            </h1>
            <DSForm
              ref={formRef}
              model={value}
              onChange={handleValue}
              rules={{
                mobile: [
                  {
                    type: 'required',
                    message: t('请输入您的手机号码'),
                  },
                  {
                    type: 'phone',
                    message: t('手机号码格式不正确，请重新输入'),
                  },
                ],
                captcha: [
                  {
                    type: 'required',
                    message: t('请输入您的验证码'),
                  },
                  {
                    validate(data) {
                      if (data.mobile && !getSession(data.mobile)) {
                        return {
                          valid: false,
                          message: t('您未获取验证码或验证码已过期，请重新获取'),
                        };
                      }
                      return {
                        valid: true,
                        message: '',
                      };
                    },
                  },
                ],
                name: [
                  {
                    message: t('请输入您的客服昵称'),
                  },
                ],
              }}
            >
              <DSFormControl name="mobile" label={t('手机号')}>
                <Input className={classes.textField} placeholder={t('请输入手机号')} />
              </DSFormControl>
              <DSFormControl name="captcha" label={t('验证码')}>
                <Input
                  className={classes.textField}
                  classes={{
                    input: classes.input,
                  }}
                  placeholder={t('请输入验证码')}
                  endAdornment={
                    // eslint-disable-next-line no-undefined
                    <VerifyCode module="bindMobile" mobile={value.mobile} />
                  }
                />
              </DSFormControl>
              <DSFormControl name="name" label={t('客服昵称')}>
                <Input className={classes.textField} placeholder={t('用于与外部客户沟通时使用')} />
              </DSFormControl>
            </DSForm>
            <div className={classes.formButtonBox}>
              <Button className={classes.formButton} variant="contained" color="primary" onClick={bindInfo}>
                {t('确认信息')}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      <Footer></Footer>
    </div>
  );
}

PerfectInfo.defaultProps = {};
PerfectInfo.propTypes = {};

export default PerfectInfo;
