import newUserAudio from 'src/assets/audio/newUser.mp3';
import phoneSvg from 'src/components/MediaCallInTips/new-phone.svg';
import radioSVg from 'src/components/MediaCallInTips/new-radio.svg';
import videoSvg from 'src/components/MediaCallInTips/new-video.svg';

const audioKeyList: { [key: string]: string } = {
  newUserAudio,
};
const imageKeyList: { [key: string]: string } = {
  phoneSvg,
  radioSVg,
  videoSvg,
};
// const lastFileData: { [key: string]: string } = {};
function getFileDataByKey(key: string, defaultUrl: string) {
  return defaultUrl;
}
export function getNewUserAudioStr() {
  return getFileDataByKey('newUserAudio', audioKeyList.newUserAudio);
}
export function getPhoneSvgStr() {
  return getFileDataByKey('phoneSvg', imageKeyList.phoneSvg);
}
export function getRadioSVgStr() {
  return getFileDataByKey('radioSVg', imageKeyList.radioSVg);
}
export function getVideoSvgStr() {
  return getFileDataByKey('videoSvg', imageKeyList.videoSvg);
}
