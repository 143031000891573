import { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { Button, PopConfirm, notification } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { aegis } from 'src/aegis';
import { ReactComponent as NoiseReductionIcon } from 'src/assets/images/ai-noise-reduction.svg';
import { judgeInWhitelistByKey } from 'src/services/httpClient';
import { RootState } from 'src/store';

export const useAINoiseReductionToggle = () => {
  const { t } = useTranslation();
  const [isNoiseReductionEnabled, setIsNoiseReductionEnabled] = useState(
    localStorage.getItem('wasmWhiteList') === '1' || window.location.pathname.includes('/demo'),
  );
  const sdkAppId = useSelector((state: RootState) => state.userInfo.sdkAppId);
  const [isAiNoiseReductionInWhiteList, setIsAiReductionInWhiteList] = useState(false);

  useEffect(() => {
    if (sdkAppId) {
      judgeInWhitelistByKey('wasmTRTC', sdkAppId).then((isInWhiteList) => {
        if (isInWhiteList) {
          setIsAiReductionInWhiteList(true);
        } else {
          setIsNoiseReductionEnabled(false);
          setIsAiReductionInWhiteList(false);
          if (localStorage.getItem('wasmWhiteList') === '1') {
            localStorage.setItem('wasmWhiteList', '0');
          }
        }
      });
    }
  }, [sdkAppId]);

  useEffect(() => {
    if (isNoiseReductionEnabled !== (localStorage.getItem('wasmWhiteList') === '1')) {
      localStorage.setItem('wasmWhiteList', isNoiseReductionEnabled ? '1' : '0');
      notification.success({
        duration: 3000,
        title: isNoiseReductionEnabled ? t('噪声抑制开启成功') : t('噪声抑制关闭成功'),
        description: t('下一通会话将{{0}} AI 降噪。', { 0: isNoiseReductionEnabled ? t('会开启') : t('不会开启') }),
      });
    }
  }, [isNoiseReductionEnabled]);

  const turnOnNoiseReduction = (onClose?: () => void) => () => {
    setIsNoiseReductionEnabled(true);
    aegis.reportEvent('noise_reduction_popup_turn_on');
    onClose?.();
  };

  const turnOffNoiseReduction = (onClose?: () => void) => () => {
    setIsNoiseReductionEnabled(false);
    aegis.reportEvent('noise_reduction_popup_turn_off');
    onClose?.();
  };

  const trackPopupOpen = (visible: boolean) => {
    if (visible) {
      aegis.reportEvent('noise_reduction_popup_open');
    }
  };

  return {
    isNoiseReductionEnabled,
    isAiNoiseReductionInWhiteList,
    turnOffNoiseReduction,
    turnOnNoiseReduction,
    trackPopupOpen,
  };
};

export type NoiseReductionProps = {
  isNoiseReductionEnabled: boolean;
  isAiNoiseReductionInWhiteList: boolean;
  turnOffNoiseReduction: (onClose?: (e?: any) => void) => () => void;
  turnOnNoiseReduction: (onClose?: (e?: any) => void) => () => void;
  trackPopupOpen: (visible: boolean) => void;
};

export const NoiseReduction = ({
  isNoiseReductionEnabled,
  isAiNoiseReductionInWhiteList,
  turnOffNoiseReduction,
  turnOnNoiseReduction,
  trackPopupOpen,
}: NoiseReductionProps) => {
  const { t } = useTranslation();
  if (!isAiNoiseReductionInWhiteList) {
    return null;
  }
  return (
    <PopConfirm
      onVisibleChange={trackPopupOpen}
      title={
        <Typography variant="subtitle1">
          {t('背景音降噪')}{' '}
          {isNoiseReductionEnabled ? (
            <Typography component={'span'} style={{ color: '#27c565' }} variant="subtitle2">
              {t('(已开启)')}
            </Typography>
          ) : (
            <Typography component={'span'} variant="subtitle2">
              {t('(未开启)')}
            </Typography>
          )}
        </Typography>
      }
      message={
        <Typography variant="subtitle2">
          {t('开启背景音降噪！比如在说话时拍手，您的客户只会听到您说话的声音。（限时免费体验中，快来开启吧！）')}
        </Typography>
      }
      footer={(close) => (
        <Box display="flex" justifyContent="flex-end" gridGap={8}>
          {isNoiseReductionEnabled ? (
            <Button type="link" onClick={turnOffNoiseReduction(close)}>
              {t('关闭')}
            </Button>
          ) : (
            <Button type="link" onClick={turnOnNoiseReduction(close)}>
              {t('开启')}
            </Button>
          )}
          <Button type="link" style={{ color: 'black' }} onClick={close}>
            {t('取消')}
          </Button>
        </Box>
      )}
    >
      <Box
        style={{ cursor: 'pointer' }}
        display={'flex'}
        gridGap={8}
        alignItems={'center'}
        flexDirection={'row'}
        justifyContent="flex-end"
      >
        <NoiseReductionIcon
          style={{
            color: isNoiseReductionEnabled ? '#27c565' : 'white',
            flexBasis: '24px',
            flexShrink: 0,
            flexGrow: 0,
          }}
        />
        <Typography
          noWrap
          component={'span'}
          variant="subtitle1"
          style={{ color: 'white', flexBasis: 'max-content', flexShrink: 0, flexGrow: 1 }}
        >
          {t('背景音降噪')}
        </Typography>
      </Box>
    </PopConfirm>
  );
};
