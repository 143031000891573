// 对外状态api映射
export enum UserStatus {
  offline = 0, // 离线
  free = 100, // 空闲
  busy = 200,
  rest = 300,
  countdown = 400,
  arrange = 500,
  stopRest = 600,
  notReady = 700,
  stopNotReady = 800,
}

// 对外状态类型
export type UserStatusType = 'offline' | 'free' | 'busy' | 'rest' | 'countdown' | 'arrange' | 'notReady';
export type LogicStateType = 'idle' | 'busy' | 'deal';
export type LogicStatusType = 'ready' | 'notReady' | 'rest' | 'afterCallWork' | 'offline';

// sdk内部用枚举
export enum UserStatusEnum {
  offline = 'offline',
  free = 'free',
  busy = 'busy',
  rest = 'rest',
  countdown = 'countdown',
  arrange = 'arrange',
  notReady = 'notReady',
}

// saas侧map
export const UserStatusMap: Record<UserStatusType, UserStatusType> = {
  offline: 'offline',
  free: 'free',
  busy: 'busy',
  rest: 'rest',
  countdown: 'countdown',
  arrange: 'arrange',
  notReady: 'notReady',
};
export const UserLogicStatusMap: Record<LogicStatusType, LogicStatusType> = {
  ready: 'ready',
  notReady: 'notReady',
  rest: 'rest',
  afterCallWork: 'afterCallWork',
  offline: 'offline',
};

// 内部逻辑
export enum UserLogicStatus {
  offline = '0', // 离线
  ready = '1', // 示闲
  busy = '2', // 示忙
  reset = '3', // 小休
  stopRest = '5', // 结束小休(预约场景使用)
  stopBusy = '6', // 结束示忙(预约场景使用)
}
export enum UserLogicState {
  USER_STATE_IDEL = 'idle', // 空闲
  USER_STATE_BUSY = 'busy', // 忙碌
  USER_STATE_DEAL = 'deal', // 话后整理
}

// 状态组件
const showFree = (status: UserStatusType) =>
  [
    UserStatusMap.free,
    UserStatusMap.rest,
    UserStatusMap.arrange,
    UserStatusMap.notReady,
    UserStatusMap.countdown,
  ].includes(status);

const showBusy = (status: UserStatusType) => [UserStatusMap.busy].includes(status);
const showNotReady = (status: UserStatusType, logicStatus?: LogicStatusType) =>
  [UserStatusMap.notReady, UserStatusMap.free, UserStatusMap.rest].includes(status) ||
  (status === UserStatusMap.busy && logicStatus !== UserLogicStatusMap.notReady);

const showArrange = (status: UserStatusType) => [UserStatusMap.arrange, UserStatusMap.countdown].includes(status);

const showRest = (status: UserStatusType, logicStatus?: LogicStatusType) =>
  [UserStatusMap.rest, UserStatusMap.free, UserStatusMap.notReady].includes(status) ||
  (status === UserStatusMap.busy && logicStatus !== UserLogicStatusMap.rest);

const showOffline = (status: UserStatusType) =>
  [
    UserStatusMap.offline,
    UserStatusMap.free,
    UserStatusMap.rest,
    UserStatusMap.arrange,
    UserStatusMap.busy,
    UserStatusMap.notReady,
  ].includes(status);
export const statusVisiable = (
  targetStatus: UserStatusType,
  params: {
    status: UserStatusType;
    logicStatus?: LogicStatusType;
  },
) => {
  if (targetStatus === 'free') {
    return showFree(params.status);
  }
  if (targetStatus === 'busy') {
    return showBusy(params.status);
  }
  if (targetStatus === 'notReady') {
    return showNotReady(params.status, params.logicStatus);
  }
  if (targetStatus === 'rest') {
    return showRest(params.status, params.logicStatus);
  }
  if (targetStatus === 'arrange') {
    return showArrange(params.status);
  }
  if (targetStatus === 'offline') {
    return showOffline(params.status);
  }
};

export const statusChecked = (
  targetStatus: UserStatusType,
  params: {
    status: UserStatusType;
    logicStatus?: LogicStatusType;
    nextStatus?: UserStatusType;
  },
) => {
  if (
    [UserStatusMap.free, UserStatusMap.offline, UserStatusMap.arrange, UserStatusMap.countdown].includes(targetStatus)
  ) {
    return targetStatus === UserStatusMap[params.status];
  }
  if (targetStatus === 'busy') {
    return (
      params.status === UserStatusMap.busy &&
      ((params.logicStatus === UserLogicStatusMap.ready && !params.nextStatus) ||
        (params.logicStatus === UserLogicStatusMap.notReady && params.nextStatus === UserLogicStatusMap.notReady) ||
        (params.logicStatus === UserLogicStatusMap.rest && params.nextStatus === UserLogicStatusMap.rest))
    );
  }
  if (targetStatus === 'notReady') {
    return (
      [UserStatusMap.notReady].includes(params.status) ||
      (UserStatusMap.busy === params.status && params.nextStatus === UserStatusMap.notReady)
    );
  }
  if (targetStatus === 'rest') {
    return (
      [UserStatusMap.rest].includes(params.status) ||
      (UserStatusMap.busy === params.status && params.nextStatus === UserStatusMap.rest)
    );
  }
};
