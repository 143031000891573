import { makeStyles } from '@material-ui/core';

import { container, transition } from 'src/assets/jss/material-dashboard-react.js';
export const useStyles = makeStyles(() => ({
  workbench: {
    flex: '1',
    visibility: 'visible',
    height: 'auto',
    minHeight: 0,
    padding: '0',
  },
  otherWorkbench: {
    flex: 'unset',
    visibility: 'hidden',
    height: '0',
    minHeight: 0,
    padding: '0',
  },
  expWorkbench: {
    width: 905,
    height: 712,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    background: '#FFF',
    overflowX: 'hidden',
  },
  MuiTabWrapper: {
    padding: 0,
    '& span': {
      display: 'block',
      width: '100%',
      height: '48px',
    },
  },
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
  },
  mainPanel: {
    /* [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }, */
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  indicator: {
    display: 'none',
  },
  splitLine: {
    width: 1,
    height: 23,
    opacity: 0.3,
    margin: '0 8px 0 20px',
  },
  tabsRoot: {
    fontSize: 14,
    '&$selected': {
      opacity: 1,
    },
  },
  tabRoot: {
    width: 90,
    minWidth: 90,
    fontSize: 14,
    opacity: 0.3,
  },
  tabSelected: {
    opacity: 1,
  },
  content: {
    marginTop: '0',
    padding: '0',
    margin: '0',
    minHeight: 'calc(100vh - 123px)',
  },
  container,
  map: {
    marginTop: '70px',
  },
  manageBox: {
    zIndex: 5,
    overflow: 'auto',
    height: 'calc(100vh - 56px)',
  },
  audio: {
    position: 'absolute',
    width: 1,
    height: 1,
    bottom: -1,
    right: -1,
  },
}));
