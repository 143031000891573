const tableStyle = () => ({
  footerWrapper: {
    width: '100%',
    background: '#000',
    height: 164,
    display: 'flex',
    justifyContent: 'center',
  },
  footerBox: {
    width: 1180,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerContent: {
    opacity: 0.5,
    fontFamily: 'PingFangSC-Regular',
    fontSize: '14px',
    color: '#FFFFFF',
    '& > p': {
      margin: '0 0 12px 0',
      padding: 0,
    },
  },
});

export default tableStyle;
