import logo from 'src/assets/images/tcccLogo.png';

const tableStyle = () => ({
  logoWrapper: {
    height: 60,
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  siteTitle: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& h1': {
      fontFamily: 'PingFang SC',
      fontWeight: '500',
      fontSize: '18px',
      paddingRight: 10,
      borderRight: '1px solid #b5bbbf',
      height: 18,
      lineHeight: '18px',
      marginRight: 10,
    },
    '& h2': {
      fontFamily: 'PingFang SC',
      fontWeight: '400',
      fontSize: '18px',
      height: 18,
      lineHeight: '18px',
    },
  },
  logoImage: {
    width: 33,
    height: 24,
    marginRight: 8,
    backgroundImage: `url(${logo})`,
    backgroundSize: 'auto 24px',
  },
  logoHeader: {
    padding: '0 20px',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default tableStyle;
