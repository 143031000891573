import { makeStyles } from '@material-ui/core';

import { MicStatus } from '../components/MicStatus';

const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
const useStyles = makeStyles({
  container: {
    height: 30,
    position: 'absolute',
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    zIndex: 1,
  },
  avatar: {
    width: 30,
    height: 30,
    background: '#006EFF',
    ...flexCenter,
  },
  name: {
    maxWidth: 120,
    height: 30,
    lineHeight: '30px',
    padding: '0px 8px',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: '#fff',
    fontSize: 14,
    fontWeight: 500,
    mixBlendMode: 'normal',
    textAlign: 'right',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  mic: {
    height: 30,
    width: 30,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    mixBlendMode: 'normal',
    ...flexCenter,
  },
});
export const StreamInfo = (props: {
  name: string;
  side: 'seat' | 'client';
  sessionId: string;
  micStatus?: boolean;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.avatar}>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.50699 3.34748C3.50699 5.19623 5.07111 6.69496 7 6.69496C8.93044 6.69496 10.4938 5.19621 10.4938 3.34745C10.4938 1.49873 8.92968 0 7 0C5.07032 0 3.50699 1.49801 3.50699 3.34748ZM1.27325 14H12.7275C13.4299 14 14 13.4547 14 12.7827C14 10.0933 11.7204 7.91368 8.9079 7.91368H5.0921C2.27968 7.91368 0 10.0933 0 12.7827C0 13.4547 0.570117 14 1.27325 14Z"
            fill="white"
          />
        </svg>
      </div>
      <div className={classes.name}>{props.name}</div>
      <div className={classes.mic}>
        <MicStatus
          side={props.side === 'seat' ? 'local' : 'remote'}
          sessionId={props.sessionId}
          disabled={props.micStatus}
        />
      </div>
    </div>
  );
};
