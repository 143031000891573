import { useEffect, useState } from 'react';

import loadable from '@loadable/component';
import queryString from 'query-string';

import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import { aegis } from 'src/aegis';
import head from 'src/assets/images/head.png';
import { AutoAccessAudio, NewMessageAudio } from 'src/components/Assets';
import { LineUnavailableWarningAlert } from 'src/components/LineUnavailableWarningAlert/LineUnavailableWarningAlert';
import MediaCallInTips from 'src/components/MediaCallInTips/MediaCallInTips';
import { Left, Right } from 'src/components/TopBar/AgentTopBar';
import { selectAllSession } from 'src/store';

import { isPhoneLikeType } from 'src/store/slices/sessions';
import { getPostWhiteUrls } from 'src/store/slices/userInfo';

import { onReceiveIframeEvent } from 'src/utils/communicate';
import CRMMessage from 'src/utils/CRMMessage';
import postSendMessage from 'src/utils/postSendMessage';
import ServiceRecords from 'src/views/Agent/ServiceRecords';
import { FloatVideoCard } from 'src/views/Agent/Workbench/Content/FloatVideoCard/FloatVideoCard';

import Workbench from 'src/views/Agent/Workbench/Workbench';

import { NotificationList } from '../../components/NotificationList/NotificationList';
import { AutoRouteLoading } from '../AutoRouteLoading';
import { Layout } from '../Layout';

import { useStyles } from './Agent.style';

const SaasDemoLogin = loadable(
  () => import(/* webpackChunkName: "demoLogin" */ 'src/components/SaasDemo/SaasDemoLogin'),
  {
    fallback: <AutoRouteLoading />,
  },
);

function Agent() {
  const { currentView } = useParams<{ currentView: string }>();
  const classes = useStyles({ currentView });
  const sessions = useSelector(selectAllSession);
  const dispatch = useDispatch();
  const [showMainContent, setShowMainContent] = useState(false);
  const { from } = queryString.parse(window.location.search);
  const isFromExperienceCenter = from === 'exp';
  const isFromSdk = window.location.search.includes('fromSdk');
  // 初始化crm message
  useEffect(() => {
    if (currentView !== 'demo') {
      dispatch(getPostWhiteUrls());
    }
    !(isFromSdk && currentView === 'manage') && postSendMessage.create();
    !(isFromSdk && currentView === 'manage') && CRMMessage.create();
    onReceiveIframeEvent('notify-main-content', (msgObj: { params: { content: Record<string, string> } }) => {
      if (['verifyCode', 'reset'].includes(msgObj.params.content.type)) {
        return;
      }
      setShowMainContent(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!window.AGENT_FROM_ONLINE) {
      aegis.reportTime(
        'saas-FMP',
        Math.floor(performance.now() + performance.timeOrigin) - performance.timing.domainLookupStart,
      );
    }
  }, []);

  const getWorkbenchStyle = () => {
    if (isFromExperienceCenter) {
      return classes.expWorkbench;
    }
    if (['demo', 'agent'].includes(currentView)) {
      return classes.workbench;
    }
    return classes.otherWorkbench;
  };

  const callInfo = sessions.find((item) => {
    if (isPhoneLikeType(item.type) && item.status === '100' && item.direction === 0) return item;
    if (item.type === 'video' && item.status === '100' && item.direction === 0) return item;
    if (['im'].includes(item.type) && item.media && item.media.status === '100') return item;
    return null;
  });
  const isSaasDemoPage = currentView === 'demo';
  const mobile = localStorage.getItem('T3CDemoLoginMobile');
  if (isFromExperienceCenter && !showMainContent && !mobile) {
    return <SaasDemoLogin fallback={<AutoRouteLoading />} isFromExperienceCenter={isFromExperienceCenter} />;
  }
  return (
    <Layout
      topBar={{
        right: <Right head={head} />,
        left: <Left />,
      }}
    >
      {!isSaasDemoPage ? <LineUnavailableWarningAlert /> : <></>}
      <div className={getWorkbenchStyle()}>
        <Workbench />
      </div>
      <Switch>
        <Route path="/agent/:sdkAppId"></Route>
        <Route path="/demo/:sdkAppId"></Route>
        <Route path="/record/:sdkAppId">
          <ServiceRecords fromSdk={isFromSdk} />
        </Route>
        <Redirect to={`/online`} />
      </Switch>
      <audio id="clientAudio" className={classes.audio} muted autoPlay playsInline />
      <audio id="serverAudio" className={classes.audio} autoPlay playsInline />
      <FloatVideoCard />
      <NewMessageAudio />
      <AutoAccessAudio />
      {callInfo ? <MediaCallInTips callInfo={callInfo} /> : ''}
      <NotificationList />
    </Layout>
  );
}

export default Agent;
