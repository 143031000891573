import { combineReducers } from 'redux';

import agent from '../slices/agent';
import { appSettings } from '../slices/appSettings';
import { asrReducer } from '../slices/asr';
import { beginnerGuide } from '../slices/beginner';
import { lineUnavailableWarning } from '../slices/lineUnavailableWarning';
import { sessions } from '../slices/sessions';
import { tim } from '../slices/tim';
import userAgent from '../slices/userAgent';
import { userInfo } from '../slices/userInfo';

import CRM from './CRM';
import im from './im';
import tipsData from './tipsData';

export default combineReducers({
  tipsData,
  userInfo,
  agent,
  im,
  appSettings,
  CRM,
  sessions,
  tim,
  asr: asrReducer,
  beginnerGuide,
  userAgent,
  lineUnavailableWarning,
});
