import { styled, SvgIcon } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 24,
  height: 24,
});
export function SwitchAccountSvg(props) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path opacity=".11" d="M0 0h24v24H0z" />
        <circle stroke="#666" cx="12" cy="6" r="3.5" />
        <path
          d="M12 10a8.978 8.978 0 0 1 6.709 3H17.29A8 8 0 0 0 4 19H3a9 9 0 0 1 9-9z"
          fill="#666"
          fillRule="nonzero"
        />
        <rect fill="#666" x="14" y="17" width="8" height="1" rx=".5" />
        <path
          d="M19.73 15.021l2.121 2.122a.5.5 0 0 1-.707.707l-2.121-2.122a.5.5 0 0 1 .707-.707zM14.851 19.154l2.122 2.122a.5.5 0 0 1-.707.707l-2.122-2.121a.5.5 0 0 1 .707-.708z"
          fill="#666"
        />
        <rect fill="#666" x="14" y="19" width="8" height="1" rx=".5" />
      </g>
    </Svg>
  );
}
