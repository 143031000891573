import { useEffect, useState } from 'react';

import { Button, SvgIcon } from '@material-ui/core';
import { isRejected } from '@reduxjs/toolkit';
import { Segment, message } from '@tencent/tea-component';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MicAuthGuide from 'src/assets/images/mic-auth-guide.png';
import { ReactComponent as callOutInfoIcon } from 'src/components/CCCIcon/ccc/callout-phone.svg';
import { getServerTypeName } from 'src/components/SessionStatusDisplay/SessionStatusDisplay';
import { hideConfirmDialog, showConfirmDialog } from 'src/components/universal/ConfirmDialogTips/ConfirmDialogTips';
import store, { selectAllSession } from 'src/store';
import { startCall } from 'src/store/slices/sessions/pstn';

import { startCallInternal } from 'src/store/slices/sessions/rtc';

import { Direction } from '../../../store/slices/sessions';
import CRMMessage from '../../../utils/CRMMessage';

import useStyles from './CallOut.style';

import { ExtensionCall } from './ExtensionCall';
import { EditablePhoneNumber } from './InputablePhone';
import { InternalCall } from './InternalCall';
import { useCallOut } from './useCallOut';

type CalloutType = 'callout' | 'internal' | 'extension';

function CallOut(props: { open: boolean }) {
  const classes = useStyles();
  const sessions = useSelector(selectAllSession);
  const callInfo = sessions.find(
    (item) => item.type === 'phone' && item.direction === Direction.callOut && item.status !== '400',
  );
  const [isCalling, setIsCalling] = useState(false);
  const [isCallingExtension, setIsCallingExtension] = useState(false);
  const userId = useSelector((state: any) => state.userInfo.userId);
  const [editingPhoneNumber, setEditingPhoneNumber] = useState<string>('');
  const [calloutType, setCalloutType] = useState<CalloutType>('callout');
  const { endCallFunc, endCallText, callDisplayText, callingStatusText, numberReflectMode } = useCallOut({
    callInfo,
  });
  const { t } = useTranslation();
  useEffect(() => {
    if (userId?.endsWith('@tcccdemo.com')) {
      setEditingPhoneNumber(userId.replace('@tcccdemo.com', '').replace(/^0086/, ''));
    }
  }, [userId, setEditingPhoneNumber, props.open]);
  useEffect(() => {
    CRMMessage.onCallOut(
      ({ phoneNumber, phoneDesc, sdkCall = false }: { phoneNumber: string; phoneDesc: string; sdkCall?: boolean }) => {
        store.dispatch(startCall({ phoneNumber: phoneNumber.replace(/\s/g, ''), phoneDesc, sdkCall }));
      },
    );
  }, []);
  // TODO: 验证这里的 throttle 功能
  const throttleCallInternal = throttle(
    async (userId: string) => {
      const res = await store.dispatch(
        startCallInternal({
          calleeUserId: userId,
        }),
      );
      if (isRejected(res)) {
        const msg = res.error.message?.includes('API_CALL_TIMEOUT')
          ? t('内线呼出超时，请稍后重试')
          : t('内线呼出失败，错误信息：{{0}}', { 0: res.error.message || t('呼出失败') });
        message.error({
          content: msg,
        });
      }
    },
    3000,
    { leading: true, trailing: true },
  );
  const throttleCallout = throttle(
    async (phoneNumber, servingNum) => {
      if (!phoneNumber) return;
      setIsCalling(true);
      const res = await store.dispatch(startCall({ phoneNumber, servingNum }));
      if (isRejected(res)) {
        if (res.error.code === '-4013') {
          const [head, tail] = res.error.message?.split(t('控制台购买外呼套餐包')) || [];
          message.error({
            content: (
              <span>
                {head}
                <a target="_blank" href="https://buy.cloud.tencent.com/ccc_pkg" rel="noreferrer">
                  {t('控制台购买外呼套餐包')}
                </a>
                {tail}
              </span>
            ),
          });
          return;
        }
        if (res.error.message?.includes(t('用户麦克风没有授权'))) {
          showConfirmDialog({
            title: t('提示'),
            content: (
              <>
                <span className={classes.hint}>{t('未授权麦克风，请按照下图指引授权麦克风：')}</span>
                <img src={MicAuthGuide} alt={t('麦克风授权指引')} />
              </>
            ),
            enterText: t('确定'),
            onEnter: () => {
              hideConfirmDialog();
            },
            cancelText: '',
          });
        } else {
          message.error({
            content: t('外呼失败：{{0}}', { 0: res.error.message || t('呼出失败') }),
          });
        }
      } else {
        if (res.payload.serverType && res.payload.serverType !== 'staffSeat') {
          message.success({
            content: t('发起{{0}}外呼成功', { 0: getServerTypeName(res.payload.serverType) }),
          });
        }
      }
      setIsCalling(false);
    },
    3000,
    { leading: true, trailing: false },
  );

  const throttleCallExtension = throttle(
    async (userId: string) => {
      setIsCallingExtension(true);
      const res = await store.dispatch(
        startCallInternal({
          calleeUserId: userId,
        }),
      );
      if (isRejected(res)) {
        message.error({
          content: t('话机呼叫失败，错误信息：{{0}}', { 0: res.error.message || t('呼叫失败') }),
        });
      }
      setIsCallingExtension(false);
    },
    3000,
    { leading: true, trailing: true },
  );

  return (
    <div className={classes.card}>
      {!callInfo && (
        <div className={classes.calloutTypeSegment}>
          <Segment
            value={calloutType}
            onChange={(value: CalloutType) => setCalloutType(value)}
            options={[
              { value: 'callout', text: t('外呼') },
              { value: 'internal', text: t('内部呼叫'), disabled: userId?.endsWith('@tcccdemo.com') },
              { value: 'extension', text: t('话机呼叫') },
            ]}
          />
        </div>
      )}
      {calloutType === 'callout' && !callInfo && (
        <EditablePhoneNumber
          isCalling={isCalling}
          active={props.open && calloutType === 'callout'}
          numberReflectMode={numberReflectMode}
          handleCallout={(phone, servingNum) => throttleCallout(phone, servingNum)}
          editingPhoneNumber={editingPhoneNumber}
          setEditingPhoneNumber={setEditingPhoneNumber}
        />
      )}
      {calloutType === 'internal' && !callInfo && (
        <InternalCall handleCallout={(userId) => throttleCallInternal(userId)} />
      )}
      {calloutType === 'extension' && !callInfo && (
        <ExtensionCall
          open={props.open}
          isCalling={isCallingExtension}
          handleCallout={(userId) => throttleCallExtension(userId)}
        />
      )}
      {callInfo && (callInfo.status === '100' || callInfo.status === '150' || callInfo.status === '200') && (
        <div className={classes.callingWrapper}>
          <SvgIcon
            component={callOutInfoIcon}
            style={{
              height: 'unset',
              width: 'unset',
              alignSelf: 'center',
            }}
            viewBox="0 0 40 40"
          />
          <div className={classes.callingText}>{callingStatusText}</div>
          <div className={classes.calledNumber}>{callDisplayText}</div>
          <Button className={classes.callButton} variant={'contained'} color={'secondary'} onClick={endCallFunc}>
            {endCallText}
          </Button>
        </div>
      )}
    </div>
  );
}

CallOut.propTypes = {
  open: PropTypes.bool,
};

export default CallOut;
