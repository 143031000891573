import { createAsyncThunk } from '@reduxjs/toolkit';

import tccc from 'src/utils/tccc';

export const startRealtimeAsr = createAsyncThunk('sessions/startRealtimeAsr', async (params: { sessionId: string }) => {
  await tccc.Asr.startRealtimeAsr(params);
  return params;
});

export const stopRealtimeAsr = createAsyncThunk('/sessions/stopRealtimeAsr', async (params: { sessionId: string }) => {
  await tccc.Asr.stopRealtimeAsr(params);
  return params;
});
