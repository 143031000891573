import { useEffect, useRef, useState, useCallback, cloneElement } from 'react';

import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import PropTypes from 'prop-types';

export default function CustomPopper(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const eventRef = useRef(false);
  const currentAnchorElRef = useRef();
  const handleClick = (event) => {
    event.stopPropagation();
    eventRef.current = true;
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const documentClick = useCallback(() => {
    if (props.disableBackdropClick) return;
    if (eventRef.current) {
      eventRef.current = false;
      return;
    }
    setAnchorEl(null);
  }, [eventRef, props.disableBackdropClick]);

  const open = Boolean(props.enabled && anchorEl);
  // eslint-disable-next-line no-undefined
  const id = open ? props.id : undefined;
  const contentClick = () => {
    eventRef.current = true;
  };
  /* eslint-disable */
  useEffect(function () {
    const handle = {
      toShow: () => {
        setAnchorEl(currentAnchorElRef.current)
      },
      toClose: () => {
        setAnchorEl(null)
      }
    }
    props.onCreated && props.onCreated(handle)
  }, [])
  /* eslint-enable */

  useEffect(() => {
    document.addEventListener('click', documentClick);
    return function () {
      document.removeEventListener('click', documentClick);
    };
  }, [documentClick]);
  return (
    <div style={{ position: 'relative' }}>
      <div ref={currentAnchorElRef} style={{ display: 'inline-block' }} onClick={handleClick} className="popper-target">
        {props.children}
      </div>
      <Popper
        style={{
          zIndex: 10,
        }}
        keepMounted={true}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={props.placement || 'top'}
      >
        <Paper>
          <div
            style={{ display: open ? 'flex' : 'none', zIndex: 10 }}
            onClick={contentClick}
            className={props.popperClassName}
          >
            {cloneElement(props.renderPopper, { open })}
          </div>
        </Paper>
      </Popper>
    </div>
  );
}

CustomPopper.defaultProps = {
  enabled: true,
  disableBackdropClick: false,
};
CustomPopper.propTypes = {
  children: PropTypes.object,
  renderPopper: PropTypes.object,
  onCreated: PropTypes.func,
  id: PropTypes.string,
  key: PropTypes.string,
  enabled: PropTypes.bool,
  placement: PropTypes.string,
  popperClassName: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
};
