import { styled, SvgIcon } from '@material-ui/core';

const Svg = styled(SvgIcon)({
  width: 10,
  height: 10,
});
export function IMSvg() {
  return (
    <Svg viewBox="0 0 10 10">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H9C9.55228 0 10 0.447715 10 1V8V9.37581C10 9.62736 9.70902 9.76722 9.51259 9.61007L7.77391 8.21913C7.5966 8.07728 7.37629 8 7.14922 8H1C0.447715 8 0 7.55228 0 7V1ZM2 2.5C2 2.22386 2.22386 2 2.5 2H7.5C7.77614 2 8 2.22386 8 2.5C8 2.77614 7.77614 3 7.5 3H2.5C2.22386 3 2 2.77614 2 2.5ZM2.5 4C2.22386 4 2 4.22386 2 4.5C2 4.77614 2.22386 5 2.5 5H5.5C5.77614 5 6 4.77614 6 4.5C6 4.22386 5.77614 4 5.5 4H2.5Z"
        fill="#fff"
      />
    </Svg>
  );
}
