import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CUSTOM_MESSAGE_SRC } from 'src/constants/im';
const EndElementWrapper = styled.div`
  .EndElementWrapper {
    text-align: center;
    font-size: 14px;
    color: #999;
    overflow: hidden;
  }
  .inner {
    position: relative;
    color: #999;
  }
  .inner::before,
  .inner::after {
    position: absolute;
    content: '';
    width: 100px;
    height: 1px;
    background: #ccc;
    top: 50%;
  }
  .inner::before {
    right: 100%;
    margin-right: 10px;
  }
  .inner::after {
    margin-left: 10px;
  }
`;

const isUserEndSession = (data, message) => {
  try {
    if (data === CUSTOM_MESSAGE_SRC.END && message?.payload?.data?.content?.endType === 1) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const EndElement = (props) => {
  const { t } = useTranslation();
  const EndMap = new Map([
    [CUSTOM_MESSAGE_SRC.END, t('会话正常结束')],
    [CUSTOM_MESSAGE_SRC.NO_SEAT_ONLINE, t('会话因无座席在线结束')],
    [CUSTOM_MESSAGE_SRC.TIMEOUT, t('会话因超时结束')],
  ]);
  return (
    <EndElementWrapper className="EndElementWrapper">
      <span className="inner">
        {isUserEndSession(props.data, props.message) ? t('用户结束会话') : EndMap.get(props.data)}
      </span>
    </EndElementWrapper>
  );
};
