import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  message_in: {
    background: '#E3E7EB',
    padding: '9px 12px',
    display: 'inline-block',
    borderRadius: 4,
    wordBreak: 'break-all',
  },
  message_out: {
    background: '#CCE2FF',
    padding: '9px 12px',
    display: 'inline-block',
    borderRadius: 4,
    wordBreak: 'break-all',
  },
  textMessage: {
    textAlign: 'left',
    display: 'inline',
    padding: 0,
    margin: 0,
    whiteSpace: 'pre-wrap',
    fontSize: 12,
    fontFamily: theme.base.fontFamily.Regular,
    color: '#333',
    lineHeight: '18px',
  },
}));
