import { useEffect, useState, useCallback } from 'react';

import { makeStyles, SvgIcon, styled, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import tccc from 'src/utils/tccc';

const useStyles = makeStyles(() => ({
  icon: {
    width: 16,
    height: 16,
  },
  popover: {
    padding: 4,
  },
}));

const Svg = styled(SvgIcon)({
  width: 16,
  height: 16,
});

enum QUALITY_LEVEL {
  Unknown,
  Excellent,
  Good,
  Fair,
  Poor,
  Bad,
  Offline,
}

export const WifiStatus = (props: { onNetworkQuality?: Function }) => {
  const classes = useStyles();
  const [offLine, setOffline] = useState(!navigator.onLine);
  const [networkQualityLevel, setNetworkQualityLevel] = useState(0);
  const [networkQuality, setNetworkQuality] = useState({
    uplinkNetworkQuality: 0,
    downlinkNetworkQuality: 0,
    uplinkRTT: 0,
    downlinkRTT: 0,
    uplinkLoss: 0,
    downlinkLoss: 0,
  });

  const onOnLineEvent = useCallback(() => {
    setOffline(false);
  }, []);

  const onOffLineEvent = useCallback(() => {
    setOffline(true);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNetworkQuality = (event: any) => {
    setNetworkQuality(event);
  };

  useEffect(() => {
    window.addEventListener('online', onOnLineEvent);
    window.addEventListener('offline', onOffLineEvent);
    return () => {
      window.removeEventListener('online', onOnLineEvent);
      window.removeEventListener('offline', onOffLineEvent);
    };
  }, [onOffLineEvent, onOnLineEvent]);

  useEffect(() => {
    tccc.on('networkQuality', handleNetworkQuality);
    return () => {
      tccc.off('networkQuality', handleNetworkQuality);
    };
  }, [handleNetworkQuality]);

  useEffect(() => {
    const level = getNetworkQualityLevel(networkQuality);
    setNetworkQualityLevel(level);
    props.onNetworkQuality?.(level);
  }, [networkQuality]);
  // 图标的计算方式为，有任何一个断网就显示断网，有任何一个网络质量差就为差。
  const getNetworkQualityLevel = (networkQuality: {
    uplinkNetworkQuality?: number;
    downlinkNetworkQuality?: number;
    uplinkRTT: any;
    downlinkRTT: any;
    uplinkLoss?: number;
    downlinkLoss?: number;
  }) => {
    if (offLine || networkQuality.downlinkNetworkQuality === 6 || networkQuality.uplinkNetworkQuality === 6) {
      return QUALITY_LEVEL.Offline;
    }
    if (
      networkQuality.downlinkNetworkQuality === 5 ||
      networkQuality.uplinkNetworkQuality === 5 ||
      networkQuality.downlinkRTT >= 1000 ||
      networkQuality.uplinkRTT >= 1000
    ) {
      return QUALITY_LEVEL.Bad; // Edge,极差
    }
    if (
      networkQuality.downlinkNetworkQuality === 4 ||
      networkQuality.uplinkNetworkQuality === 4 ||
      networkQuality.downlinkRTT >= 500 ||
      networkQuality.uplinkRTT >= 500
    ) {
      return QUALITY_LEVEL.Poor; // 3G,差
    }
    if (
      networkQuality.downlinkNetworkQuality === 3 ||
      networkQuality.uplinkNetworkQuality === 3 ||
      networkQuality.downlinkRTT >= 200 ||
      networkQuality.uplinkRTT >= 200
    ) {
      return QUALITY_LEVEL.Fair; // LTE DSL,一般
    }
    if (
      networkQuality.downlinkNetworkQuality === 2 ||
      networkQuality.uplinkNetworkQuality === 2 ||
      networkQuality.downlinkRTT >= 100 ||
      networkQuality.uplinkRTT >= 100
    ) {
      return QUALITY_LEVEL.Good; // Wifi,较好
    }
    if (
      networkQuality.downlinkNetworkQuality === 1 ||
      networkQuality.uplinkNetworkQuality === 1 ||
      networkQuality.downlinkRTT > 0 ||
      networkQuality.uplinkRTT > 0
    ) {
      return QUALITY_LEVEL.Excellent; // 极佳
    }
    return QUALITY_LEVEL.Unknown; // 未知
  };

  const StatusText = () => {
    const { t } = useTranslation();
    const QualityText = [t('未知'), t('极佳'), t('较好'), t('一般'), t('差'), t('极差'), t('已断开')];
    return (
      <>
        <Typography>
          {t('当前上行网络质量：')}
          {offLine ? t('已断开') : QualityText[networkQuality.uplinkNetworkQuality]}
        </Typography>
        <Typography>
          {t('当前上行RTT：')}
          {networkQuality.uplinkRTT}
        </Typography>
        <Typography>
          {t('当前上行丢包率：')}
          {networkQuality.uplinkLoss}
        </Typography>
        <Typography>
          {t('当前下行网络质量：')}
          {offLine ? t('已断开') : QualityText[networkQuality.downlinkNetworkQuality]}
        </Typography>
        <Typography>
          {t('当前下行RTT：')}
          {networkQuality.downlinkRTT}
        </Typography>
        <Typography>
          {t('当前下行丢包率：')}
          {networkQuality.downlinkLoss}
        </Typography>
      </>
    );
  };
  const getQualitySvg = () => {
    switch (networkQualityLevel) {
      case QUALITY_LEVEL.Offline:
        return (
          <Svg width="25" height="18" viewBox="0 0 25 18" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.5 0C20.6716 0 20 0.671573 20 1.5V16.5C20 17.3284 20.6716 18 21.5 18C22.3284 18 23 17.3284 23 16.5V1.5C23 0.671573 22.3284 0 21.5 0ZM15 4.5C15 3.67157 15.6716 3 16.5 3C17.3284 3 18 3.67157 18 4.5V16.5C18 17.3284 17.3284 18 16.5 18C15.6716 18 15 17.3284 15 16.5V4.5ZM10 7.5C10 6.67157 10.6716 6 11.5 6C12.3284 6 13 6.67157 13 7.5V16.5C13 17.3284 12.3284 18 11.5 18C10.6716 18 10 17.3284 10 16.5V7.5ZM5 10.5C5 9.67157 5.67157 9 6.5 9C7.32843 9 8 9.67157 8 10.5V16.5C8 17.3284 7.32843 18 6.5 18C5.67157 18 5 17.3284 5 16.5V10.5ZM1.5 12C0.671573 12 0 12.6716 0 13.5V16.5C0 17.3284 0.671573 18 1.5 18C2.32843 18 3 17.3284 3 16.5V13.5C3 12.6716 2.32843 12 1.5 12Z"
              fill="#D8D8D8"
            />
            <circle cx="20" cy="13" r="5" fill="#E54545" />
            <rect
              x="17.1719"
              y="14.4141"
              width="6"
              height="2"
              rx="1"
              transform="rotate(-45 17.1719 14.4141)"
              fill="white"
            />
            <rect
              x="21.4141"
              y="15.8286"
              width="6"
              height="2"
              rx="1"
              transform="rotate(-135 21.4141 15.8286)"
              fill="white"
            />
          </Svg>
        );
      case QUALITY_LEVEL.Excellent:
        return (
          <Svg width="23" height="18" viewBox="0 0 23 18" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.5 0C20.6716 0 20 0.671573 20 1.5V16.5C20 17.3284 20.6716 18 21.5 18C22.3284 18 23 17.3284 23 16.5V1.5C23 0.671573 22.3284 0 21.5 0ZM15 4.5C15 3.67157 15.6716 3 16.5 3C17.3284 3 18 3.67157 18 4.5V16.5C18 17.3284 17.3284 18 16.5 18C15.6716 18 15 17.3284 15 16.5V4.5ZM10 7.5C10 6.67157 10.6716 6 11.5 6C12.3284 6 13 6.67157 13 7.5V16.5C13 17.3284 12.3284 18 11.5 18C10.6716 18 10 17.3284 10 16.5V7.5ZM5 10.5C5 9.67157 5.67157 9 6.5 9C7.32843 9 8 9.67157 8 10.5V16.5C8 17.3284 7.32843 18 6.5 18C5.67157 18 5 17.3284 5 16.5V10.5ZM1.5 12C0.671573 12 0 12.6716 0 13.5V16.5C0 17.3284 0.671573 18 1.5 18C2.32843 18 3 17.3284 3 16.5V13.5C3 12.6716 2.32843 12 1.5 12Z"
              fill="#27C565"
            />
          </Svg>
        );
      case QUALITY_LEVEL.Good:
        return (
          <Svg width="23" height="18" viewBox="0 0 23 18" fill="none">
            <path
              d="M10 7.5C10 6.67157 10.6716 6 11.5 6V6C12.3284 6 13 6.67157 13 7.5V16.5C13 17.3284 12.3284 18 11.5 18V18C10.6716 18 10 17.3284 10 16.5V7.5Z"
              fill="#27C565"
            />
            <path
              d="M5 10.5C5 9.67157 5.67157 9 6.5 9V9C7.32843 9 8 9.67157 8 10.5V16.5C8 17.3284 7.32843 18 6.5 18V18C5.67157 18 5 17.3284 5 16.5V10.5Z"
              fill="#27C565"
            />
            <rect y="12" width="3" height="6" rx="1.5" fill="#27C565" />
            <path
              d="M15 4.5C15 3.67157 15.6716 3 16.5 3V3C17.3284 3 18 3.67157 18 4.5V16.5C18 17.3284 17.3284 18 16.5 18V18C15.6716 18 15 17.3284 15 16.5V4.5Z"
              fill="#27C565"
            />
            <path
              d="M20 1.5C20 0.671573 20.6716 0 21.5 0V0C22.3284 0 23 0.671573 23 1.5V16.5C23 17.3284 22.3284 18 21.5 18V18C20.6716 18 20 17.3284 20 16.5V1.5Z"
              fill="#D8D8D8"
            />
          </Svg>
        );
      case QUALITY_LEVEL.Fair:
        return (
          <Svg width="23" height="18" viewBox="0 0 23 18" fill="none">
            <path
              d="M10 7.5C10 6.67157 10.6716 6 11.5 6V6C12.3284 6 13 6.67157 13 7.5V16.5C13 17.3284 12.3284 18 11.5 18V18C10.6716 18 10 17.3284 10 16.5V7.5Z"
              fill="#27C565"
            />
            <path
              d="M5 10.5C5 9.67157 5.67157 9 6.5 9V9C7.32843 9 8 9.67157 8 10.5V16.5C8 17.3284 7.32843 18 6.5 18V18C5.67157 18 5 17.3284 5 16.5V10.5Z"
              fill="#27C565"
            />
            <rect y="12" width="3" height="6" rx="1.5" fill="#27C565" />
            <path
              d="M15 4.5C15 3.67157 15.6716 3 16.5 3V3C17.3284 3 18 3.67157 18 4.5V16.5C18 17.3284 17.3284 18 16.5 18V18C15.6716 18 15 17.3284 15 16.5V4.5Z"
              fill="#D8D8D8"
            />
            <path
              d="M20 1.5C20 0.671573 20.6716 0 21.5 0V0C22.3284 0 23 0.671573 23 1.5V16.5C23 17.3284 22.3284 18 21.5 18V18C20.6716 18 20 17.3284 20 16.5V1.5Z"
              fill="#D8D8D8"
            />
          </Svg>
        );
      case QUALITY_LEVEL.Poor:
        return (
          <Svg width="23" height="18" viewBox="0 0 23 18" fill="none">
            <path
              d="M10 7.5C10 6.67157 10.6716 6 11.5 6V6C12.3284 6 13 6.67157 13 7.5V16.5C13 17.3284 12.3284 18 11.5 18V18C10.6716 18 10 17.3284 10 16.5V7.5Z"
              fill="#D8D8D8"
            />
            <path
              d="M5 10.5C5 9.67157 5.67157 9 6.5 9V9C7.32843 9 8 9.67157 8 10.5V16.5C8 17.3284 7.32843 18 6.5 18V18C5.67157 18 5 17.3284 5 16.5V10.5Z"
              fill="#FEB004"
            />
            <rect y="12" width="3" height="6" rx="1.5" fill="#FEB004" />
            <path
              d="M15 4.5C15 3.67157 15.6716 3 16.5 3V3C17.3284 3 18 3.67157 18 4.5V16.5C18 17.3284 17.3284 18 16.5 18V18C15.6716 18 15 17.3284 15 16.5V4.5Z"
              fill="#D8D8D8"
            />
            <path
              d="M20 1.5C20 0.671573 20.6716 0 21.5 0V0C22.3284 0 23 0.671573 23 1.5V16.5C23 17.3284 22.3284 18 21.5 18V18C20.6716 18 20 17.3284 20 16.5V1.5Z"
              fill="#D8D8D8"
            />
          </Svg>
        );
      case QUALITY_LEVEL.Bad:
        return (
          <Svg width="23" height="18" viewBox="0 0 23 18" fill="none">
            <path
              d="M10 7.5C10 6.67157 10.6716 6 11.5 6V6C12.3284 6 13 6.67157 13 7.5V16.5C13 17.3284 12.3284 18 11.5 18V18C10.6716 18 10 17.3284 10 16.5V7.5Z"
              fill="#D8D8D8"
            />
            <path
              d="M5 10.5C5 9.67157 5.67157 9 6.5 9V9C7.32843 9 8 9.67157 8 10.5V16.5C8 17.3284 7.32843 18 6.5 18V18C5.67157 18 5 17.3284 5 16.5V10.5Z"
              fill="#D8D8D8"
            />
            <rect y="12" width="3" height="6" rx="1.5" fill="#E54545" />
            <path
              d="M15 4.5C15 3.67157 15.6716 3 16.5 3V3C17.3284 3 18 3.67157 18 4.5V16.5C18 17.3284 17.3284 18 16.5 18V18C15.6716 18 15 17.3284 15 16.5V4.5Z"
              fill="#D8D8D8"
            />
            <path
              d="M20 1.5C20 0.671573 20.6716 0 21.5 0V0C22.3284 0 23 0.671573 23 1.5V16.5C23 17.3284 22.3284 18 21.5 18V18C20.6716 18 20 17.3284 20 16.5V1.5Z"
              fill="#D8D8D8"
            />
          </Svg>
        );
      default:
        return (
          <Svg width="16" height="16" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="1.5" cy="16.5" r="1.5" fill="#626E7B" />
            <circle cx="6.5" cy="16.5" r="1.5" fill="#626E7B" />
            <circle cx="11.5" cy="16.5" r="1.5" fill="#626E7B" />
            <circle cx="16.5" cy="16.5" r="1.5" fill="#626E7B" />
            <circle cx="21.5" cy="16.5" r="1.5" fill="#626E7B" />
          </Svg>
        );
    }
  };
  return (
    <Tooltip
      arrow
      className={classes.icon}
      title={
        <div className={classes.popover}>
          <StatusText />
        </div>
      }
    >
      {getQualitySvg()}
    </Tooltip>
  );
};
