import { isTestEnv } from './env';
import { storage } from './storage';

const checkIsIntl = () => {
  const isIntlLocal = storage.getItem('isIntl');
  if (isIntlLocal) {
    // 0-国内站 1-国际站
    return !!Number(isIntlLocal);
  }
  return false;
};

export function isInternational() {
  // 在测试环境模拟国际站
  if (isTestEnv() && checkIsIntl()) {
    return true;
  }
  return window.location.host === 'connect.tencentcloud.com';
}
