/* eslint-disable import/no-anonymous-default-export */
export default function () {
  return {
    root: {
      height: 44,
      display: 'flex',
      padding: '12px 20px',
      justifyContent: 'space-between',
      color: '#006EFF',
      fontWeight: 400,
      fontSize: 14,
      background: 'rgba(205,221,243,1)',
      cursor: 'pointer',
    },
    ringingStatus: {
      color: '#30983F',
      background: '#D3EFD7',
    },
    operation: {
      alignSelf: 'flex-end',
      display: 'flex',
      alignItems: 'center',
      width: 64,
      height: '100%',
      justifyContent: 'space-between',
    },
  };
}
