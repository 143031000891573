import { useEffect, useState } from 'react';

import axios from 'axios';
import { ulid } from 'ulid';

import { showConfirmDialog } from 'src/components/universal/ConfirmDialogTips/ConfirmDialogTips';
import { ERROR_CODES } from 'src/constants/manage';
import i18n from 'src/i18n';
import { isTestEnv, showError } from 'src/utils/index';

import logger from 'src/utils/logger';

import { aegis } from '../aegis';
import { logger as loggerSdk } from '../utils/log';
import { RequestError } from '../utils/requestError';
import sessionManage from '../utils/sessionManage';

import { apis } from './apis';
import { axiosInstance } from './axios';
import throwErrorCodeApi from './throwErrorCodeApi';

const uploadFileApis = ['/ccc/report/seatrecord'];
const { t } = i18n;
const createDownloadFileApi = (url) => (data, fileName) => {
  const id = ulid();
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'post',
      url,
      data: {
        nonce: id,
        requestId: id,
        ...data,
      },
      responseType: 'blob',
    })
      .then((response) => {
        if (response.data?.errorCode) {
          showError(response.data?.msg);
          reject(response.data?.msg);
        }
        let fName = fileName;
        if (!fName) {
          try {
            fName = response.headers['content-disposition'].split(';')[1].split('=')[1];
          } catch (e) {
            fName = id;
          }
        }
        const link = document.createElement('a');
        link.setAttribute('download', id);
        link.href = URL.createObjectURL(response.data);
        link.download = fName;
        document.body.appendChild(link);
        link.click();
        link.remove();
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const createUploadFileApi =
  (url) =>
  (data, requestOption = {}) => {
    const params = new FormData();
    const id = ulid();
    params.append('nonce', id);
    Object.keys(data).forEach((key) => {
      params.append(key, data[key]);
    });
    return axiosInstance
      .request({
        url,
        method: 'post',
        ...Object.assign({ timeout: 30000 }, requestOption),
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: params,
      })
      .then((res) => {
        if (Number(res.data.errorCode) === 0) {
          return res.data;
        }
        throw new RequestError(res.data.msg, { code: res.data.errorCode, url: url.split('/').pop() });
      })
      .catch((error) => {
        logger.error('requestError', error);
        if (!error) {
          debugLogRequest(`[${url}未知请求错误]`);
          showError(t('网络错误请重试'));
          throw error;
        }
        // eslint-disable-next-line no-underscore-dangle
        if (error?.__CANCEL__) {
          logger.warn(error);
        } else {
          if (error?.response?.status && typeof error.response.status === 'number' && error.response.status !== 200) {
            showError(t('HTTP请求错误，{{0}}', { 0: error.response.data }), {
              code: error.response.status,
              url: error.url,
            });
          } else if (error.code === 'ECONNABORTED') {
            showError(t('请求超时，请稍后再试'));
          } else if (error && error.message === 'Network Error') {
            showError(t('网络错误请重试'));
          } else {
            showError(error.message || error, { code: error.code, url: error.url });
          }
          debugLogRequest(`[${url}网络请求失败,${error.message} ,${error} , ${error.msg}]`);
        }
        throw error;
      });
  };
const createApiFunction =
  (url) =>
  (data = {}, requestOptions = {}) => {
    const { cancelToken, ...options } = data;
    const id = ulid();
    return axiosInstance
      .request({
        method: 'post',
        url,
        cancelToken,
        ...requestOptions,
        data: {
          clientType: '1',
          nonce: id,
          requestId: id,
          timestamp: String(Date.now()),
          ...options,
        },
      })
      .then((response) => {
        if (Number(response.data?.errorCode) === 0) {
          return response.data;
        }
        if (response.data?.errorCode === ERROR_CODES.TOKEN_EXPIRED) {
          showConfirmDialog({
            content: t('登录已过期或在别的地方登录，请重新登录'),
            onEnter() {
              sessionManage.loginOut();
            },
            cancelText: '',
          });
          return response.data;
        }
        if (response.data?.errorCode === '-2019') {
          // 未开通AI/账号不存在的用户，获取不到token，这里先屏蔽，但是要加上日志
          debugLogRequest(`[接口请求成功但是报错, 错误信息: ${response.data.msg}]`);
          return response.data;
        }
        if (response.data?.errorCode === '-9006' || (!response.data && url === '/wxwork/getAuthInfo')) {
          // 未开通企业微信加微授权，这里先屏蔽，但是要加上日志
          return response.data;
        }
        if (throwErrorCodeApi.includes(url)) {
          throw response.data;
        } else {
          throw new RequestError(response.data.msg, { code: response.data.errorCode, url: url.split('/').pop() });
        }
      })
      .catch((error) => {
        aegis.report(`url: ${url}, nonce:${id}, message: ${error}`);
        if (!error) {
          debugLogRequest(`[${url}未知请求错误]`);
          showError(t('网络错误请重试'));
          throw error;
        }
        if (url !== '/ccc/debug/seatLog') {
          debugLogRequest(`[${url}请求失败,${error} ,${error?.errorCode} , ${error?.msg}]`);
        }
        // eslint-disable-next-line no-underscore-dangle
        if (error?.__CANCEL__) {
          logger.warn(error);
          return;
        }
        if (throwErrorCodeApi.includes(url)) {
          throw error;
        }

        if (error?.response?.status && typeof error.response.status === 'number' && error.response.status !== 200) {
          showError(t('HTTP请求错误，{{0}}', { 0: error.response.data }), {
            code: error.response.status,
            url: error.url,
          });
        } else if (error.code === 'ECONNABORTED') {
          showError(t('请求超时，请稍后再试'));
        } else if (error && error.message === 'Network Error') {
          showError(t('网络错误请重试'));
        } else {
          showError(error.message || error, { code: error.code, url: error.url });
        }
        throw error;
      });
  };

const returnServices = (apis) =>
  Object.fromEntries(
    apis
      .map((api) => [api, createApiFunction(api)])
      .concat(uploadFileApis.map((api) => [api, createUploadFileApi(api)])),
  );

function useRequest(url, options, lifecycle, deps = []) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});
  const [updateNum, setUpdateNum] = useState(0);

  const source = axios.CancelToken.source();
  const canceller = () => source.cancel('cancel request');
  const id = ulid();

  let queryString = '';
  try {
    queryString = JSON.stringify(options);
  } catch (e) {
    setError(true);
    setData({
      message: e.toString(),
    });
  }

  useEffect(() => {
    const canRequest = lifecycle && typeof lifecycle.before === 'function' ? Boolean(lifecycle.before()) : true;
    if (canRequest) {
      let error;
      let data;
      setLoading(true);
      axiosInstance
        .request({
          method: 'post',
          url,
          cancelToken: source.cancelToken,
          data: {
            nonce: id,
            requestId: id,
            ...JSON.parse(queryString),
          },
        })
        .then((response) => {
          if (Number(response.data.errorCode) === 0) {
            error = false;
            data = response.data;
          } else {
            error = true;
            data = {
              message: response.data.msg,
            };
            showError(response.data.msg);
          }
          setError(error);
          setData({ ...data });
          lifecycle &&
            typeof lifecycle.complete === 'function' &&
            lifecycle.complete({
              ...data,
              error,
            });
        })
        .catch((error) => {
          setError(true);
          setData(error);
          lifecycle &&
            typeof lifecycle.complete === 'function' &&
            lifecycle.complete({
              ...error,
              error: true,
            });
        })
        .finally(() => {
          setLoading(false);
        });
      return canceller;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString, ...deps, updateNum]);
  function reRequest() {
    setUpdateNum(updateNum + 1);
  }
  return [canceller, data, error, loading, reRequest];
}

function download(href, filename) {
  const axiosInstance = axios.create({
    timeout: 10 * 60 * 1000,
    withCredentials: false,
    headers: {},
  });
  axiosInstance.defaults.headers = {};
  axiosInstance
    .get(href, {
      responseType: 'blob',
    })
    .then((res) => {
      const blob = new Blob([res.data]);
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    })
    .catch((e) => {
      debugLogRequest(t('下载失败: {{0}}; url: {{1}}', { 0: e.message, 1: href }));
      throw e;
    });
}

// 由于部分下载数据接口与获取数据接口相同，因此另起一个downloadServices
const downloadFileApis = [
  '/tcccadmin/chat/exportChatText',
  '/tcccadmin/crm/exportClientProfileList',
  '/tcccadmin/crm/exportBriefSummaryListAggregateBySession',
];
const downloadServices = (() => {
  const services = {};
  downloadFileApis.forEach((url) => {
    services[url] = createDownloadFileApi(url);
  });
  return services;
})();

function debugLogRequest(log, level = 'INFO') {
  const seatLog = `${log}`;
  const date = new Date();
  const now = `${[date.getFullYear(), date.getMonth() - 1, date.getDate()].join('-')} ${[
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ].join(':')}`;

  if (isTestEnv()) {
    console.log(`[TCCC ${now}]`, seatLog);
  }
  aegis.info(seatLog);
  try {
    loggerSdk.info(JSON.stringify(`[${now}][${level}][${seatLog}]`));
  } catch (e) {}
}
export function requestBlob(url, data) {
  const id = ulid();
  return axiosInstance({
    method: 'post',
    url,
    data: {
      nonce: id,
      requestId: id,
      ...data,
    },
    responseType: 'blob',
  });
}
function request({ url, options, data }) {
  return axios({
    method: 'post',
    url,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    data: {
      ...data,
    },
  });
}
export { debugLogRequest, download, downloadServices, request, useRequest };
export default returnServices(apis);
