import React from 'react';

import { Card, CardContent, CardMedia, styled } from '@material-ui/core';

import ImageFallback from 'src/assets/images/image-fallback.svg';

type UserCustomElementProps = {
  data: {
    title: string;
    description: string;
    iconUrl: string;
  };
};

const StyledCard = styled(Card)({
  display: 'flex',
  backgroundColor: 'white',
  border: '1px solid #d1d2d3',
  borderRadius: '8px',
  padding: '5px',
  cursor: 'pointer',
});
const Media = styled(CardMedia)({
  width: '120px',
  height: '120px',
  objectFit: 'cover',
  flexShrink: 0,
});
const Content = styled(CardContent)({
  padding: '0px',
  display: 'flex',
  margin: '10px',
  minWidth: '150px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '&:last-child': {
    paddingBottom: '0px',
  },
});
const Header = styled('div')({
  fontSize: 12,
  color: 'black',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '3',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  wordBreak: 'break-all',
});
const Desc = styled('div')({
  fontSize: 14,
  color: '#ff6c2e',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '1',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  wordBreak: 'break-all',
});

export const UserCustomElement: React.FC<UserCustomElementProps> = (props) => (
  <StyledCard>
    <Media
      image={props.data.iconUrl}
      title="cardPic"
      style={{
        backgroundImage: `url(${props.data.iconUrl}), url(${ImageFallback})`,
      }}
    />
    <Content>
      <Header>{props.data.title}</Header>
      <Desc>{props.data.description}</Desc>
    </Content>
  </StyledCard>
);
