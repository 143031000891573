import { useCallback } from 'react';

import { makeStyles, ListItem, Typography } from '@material-ui/core';
import { isRejected } from '@reduxjs/toolkit';
import { message } from '@tencent/tea-component';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Timing } from 'src/components/Timing/Timing';
import { Session } from 'src/store/slices/sessions';
import { changeMicStatus } from 'src/store/slices/sessions/rtc';
import { endSession } from 'src/store/slices/sessions/sessions.thunk';

import { RoundedButton } from './components/Button';

import { Header } from './components/Header';
const useStyles = makeStyles({
  itemRoot: {
    width: 415,
    borderRadius: 4,
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
  },
  callPhoneInfo: {
    margin: '10px 0',
    display: 'inline-block',
    alignSelf: 'flex-start',
  },
  moreAction: {
    display: 'inline-block',
    marginLeft: 8,
    cursor: 'pointer',
  },
  buttonGroup: {
    width: '100%',
    display: 'flex',
  },
  button: {
    marginRight: 18,
  },
});
const useTypographyStyles = makeStyles(() => ({
  subtitle1: {
    height: 18,
    lineHeight: '18px',
    fontWeight: 500,
    fontSize: 12,
    color: '#fff',
    marginRight: 4,
  },
  subtitle2: {
    height: 18,
    lineHeight: '18px',
    fontSize: 12,
    color: '#fff',
  },
  body1: {
    fontSize: 18,
    color: 'rgba(255, 255, 255, 0.8)',
    borderBox: '1px solid rgba(0,0,0,0.3)',
  },
}));

export function MediaTips(props: { callInfo: Session }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const typographyClasses = useTypographyStyles();
  const dispatch = useDispatch();
  const { callInfo } = props;
  const { micStatus } = callInfo;

  const endMediaSession = useCallback(
    throttle(
      async () => {
        const res = await dispatch(endSession({ sessionId: callInfo.sessionId, closeBy: 'seat' }));
        if (isRejected(res)) {
          message.error({
            content: t('挂断失败，原因: {{0}}', { 0: res.error.message }),
          });
        }
      },
      1500,
      {
        leading: true,
        trailing: false,
      },
    ),
    [],
  );
  const changeMic = useCallback(
    throttle(
      async () => {
        await dispatch(
          changeMicStatus({
            sessionId: callInfo.sessionId,
            micStatus: callInfo.micStatus === 'off' ? 'on' : 'off',
          }),
        );
      },
      1500,
      { leading: true, trailing: false },
    ),
    [callInfo],
  );

  if (callInfo.media?.status !== '200') {
    return null;
  }

  return (
    <ListItem className={classes.itemRoot}>
      <Header
        time={callInfo.media?.startTime && <Timing startTime={callInfo.media.startTime} />}
        statusText={t('通话中')}
        callInfo={callInfo}
      />
      <Typography className={classes.callPhoneInfo} classes={typographyClasses} component={'div'} variant={'body1'}>
        {callInfo.nickname}
      </Typography>
      <div className={classes.buttonGroup}>
        <RoundedButton className={classes.button} variant="contained" onClick={changeMic}>
          {micStatus === 'off' ? t('取消静音') : t('静音')}
        </RoundedButton>
        <RoundedButton className={classes.button} variant="contained" color="secondary" onClick={endMediaSession}>
          {t('挂断')}
        </RoundedButton>
      </div>
    </ListItem>
  );
}
