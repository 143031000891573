import { Card, CardContent, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ChromeIcon from 'src/assets/images/chrome.png';
import EdgeIcon from 'src/assets/images/edge.png';
import OnlineTopBar from 'src/components/TopBar/OnlineTopBar';

import { Layout } from '../../layouts/Layout';

import styles from './TipPage.style';

const useStyles = makeStyles(styles);
function TipPage(props) {
  const { logo } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Layout
      topBar={{
        right: <OnlineTopBar logo={logo} />,
      }}
    >
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <h4>{t('浏览器兼容提示')}</h4>
            <div className={classes.message}>
              {t(
                '尊敬的用户，您好！很抱歉腾讯云联络中心不支持您当前的浏览器，或者chrome内核版本过低，我们推荐您使用如下浏览器或升级版本，以继续您的管理工作',
              )}
              <div
                style={{
                  display: 'inline',
                  height: '150px',
                }}
              >
                <a className={classes.link} href="http://www.google.cn/intl/zh-CN/chrome/browser/desktop/index.html">
                  <img src={ChromeIcon} height="100" alt={t('谷歌浏览器')} />
                  <p>{t('Chrome 浏览器')}</p>
                </a>
                <a className={classes.link} href="https://www.microsoft.com/zh-cn/edge">
                  <img src={EdgeIcon} height="100" alt={t('微软Edge浏览器')} />
                  <p>{t('Edge 浏览器')}</p>
                </a>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </Layout>
  );
}
TipPage.defaultProps = {
  text: '',
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  logo: require('src/assets/images/logo.jpg'),
};
TipPage.propTypes = {
  location: PropTypes.any,
  text: PropTypes.any,
  logo: PropTypes.string,
};
export default TipPage;
