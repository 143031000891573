import { useState } from 'react';

import { ClickAwayListener, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { RootState } from 'src/store';

import { Session } from 'src/store/slices/sessions';

import { MicStatus } from '../components/MicStatus';

const useStyles = makeStyles<{}, { show: boolean }>({
  root: {
    position: 'absolute',
    zIndex: 2,
    margin: '14px 20px',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 4,
  },
  wrapper: {
    maxWidth: 280,
    padding: '4px 7px',
    display: 'inline-flex',
    alignItems: 'center',
  },
  nickname: {
    lineHeight: '24px',
    color: '#fff',
    fontSize: 14,
    fontWeight: 500,
    userSelect: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  icon: {
    marginLeft: 6,
  },
});
export const LocalUserInfo = ({ callInfo }: { callInfo: Session }) => {
  const [infoVisible, setInfoVisible] = useState(false);
  const classes = useStyles({ show: infoVisible });
  const { staffName } = useSelector((state: RootState) => state.userInfo);
  return (
    <ClickAwayListener onClickAway={() => setInfoVisible(false)}>
      <div className={classes.root}>
        <div className={classes.wrapper} onClick={() => setInfoVisible((visible) => !visible)}>
          <div className={classes.nickname}>{staffName}</div>
          <MicStatus
            darkMode
            className={classes.icon}
            side="local"
            sessionId={callInfo.sessionId}
            disabled={callInfo.micStatus === 'off' || callInfo.holdStatus}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};
