import * as t from 'io-ts';

import { nullableArray } from 'src/utils/codecs';
const TelRecordTraceEvent = t.type({
  eventId: t.keyof({
    userCallIn: null, // 用户呼入
    seatCallout: null, // 座席呼出
    seatEnterOk: null, //	座席接听
    userAccepted: null, // 用户接听
    userHungUp: null, //	用户挂断
    seatExitOk: null, // 座席挂断
    seatDispatched: null, // 座席被分配，目前此事件被隐藏了，暂时可以不处理
    seatGiveUp: null, //	座席超时转接，值为转接座席与被转接座席
    seatForward: null, // 座席手动转接，值为转接座席与被转接座席
    ivrKeyPress: null, // ivr按键，值为按键轨迹
    waitingDuration: null, // 等待时长，值为毫秒
    servingDuration: null, // 服务时长，值为毫秒
    noSeatOnline: null, //	无座席在线
    blackList: null, //	黑名单
    record: null, //	录音地址，值为录音地址
    error: null, //	值为错误内容
  }),
  eventValue: t.string,
});
export const recordAPIs = {
  '/tcccadmin/cdr/telRecordTrace': {
    Input: t.type({
      uid: t.string,
      seat: t.string,
    }),
    Output: t.type({
      data: nullableArray(TelRecordTraceEvent),
    }),
  },
  '/tcccadmin/cdr/getAsrData': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({
      data: nullableArray(
        t.type({
          user: t.string,
          message: t.string,
          timestamp: t.string,
        }),
      ),
    }),
  },
};
