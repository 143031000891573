import { CUSTOM_MESSAGE_SRC } from 'src/constants/im';
import i18n from 'src/i18n';
import store from 'src/store';

import { sendMessage, createTextMessage, createCustomMessage, receiveMessageThunk } from 'src/store/slices/tim.thunk';

import { showError } from 'src/utils';

import { messageBoxScrollToBottom } from './index';
const { t } = i18n;

class PostSendMessage {
  constructor() {
    this.isCreated = false;
    this.whiteList = [];
  }
  create() {
    if (this.isCreated) return;
    this.isCreated = true;

    window.addEventListener(
      'message',
      (message) => {
        this.whiteList = store.getState().userInfo.postWhiteUrls.map((item) => item.url);
        if (this.whiteList.includes(message.origin)) {
          const data = message.data.payload;
          data && message?.data?.source === 'ccc-sdk' && this[data.event] && this[data.event](data.message);
        } else {
        }
      },
      false,
    );
  }
  async sendTextMessage(text) {
    const { currentUser } = store.getState().userInfo;
    if (!currentUser.sessionId) {
      showError(t('当前没有选择会话，无法发送文本'));
      return;
    }
    const res = await store.dispatch(
      createTextMessage({
        sessionId: currentUser.sessionId,
        payload: { text },
      }),
    );
    await store.dispatch(sendMessage({ message: res.payload }));
    messageBoxScrollToBottom();
  }
  async sendCustomMessage(payload) {
    const { currentUser } = store.getState().userInfo;
    if (!currentUser.sessionId) {
      showError(t('当前没有选择会话，无法发自定义消息'));
      return;
    }
    if (!payload.title || !payload.description || !payload.iconUrl || !payload.fromPostMessage === 1) {
      showError(t('请检查传入参数完整性'));
      return;
    }

    const res = await store.dispatch(
      createCustomMessage({
        sessionId: currentUser.sessionId,
        payload: {
          data: JSON.stringify(payload),
        },
      }),
    );
    await store.dispatch(receiveMessageThunk({ data: [res.payload] }));
    await store.dispatch(sendMessage({ message: res.payload }));
    messageBoxScrollToBottom();
  }
  async sendOrderMessage(payload) {
    const { currentUser } = store.getState().userInfo;
    if (!currentUser.sessionId) {
      showError(t('当前没有选择会话，无法发自定义消息'));
      return;
    }

    const res = await store.dispatch(
      createCustomMessage({
        sessionId: currentUser.sessionId,
        payload: {
          data: JSON.stringify({
            src: CUSTOM_MESSAGE_SRC.ORDER_CARD,
            content: payload,
            customerServicePlugin: 0,
          }),
        },
      }),
    );
    await store.dispatch(receiveMessageThunk({ data: [res.payload] }));
    await store.dispatch(sendMessage({ message: res.payload }));
    messageBoxScrollToBottom();
  }
}

export default new PostSendMessage();
