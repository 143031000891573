export const getTeaFormStatus = (
  meta: {
    active?: boolean;
    touched?: boolean;
    error?: string | undefined;
  },
  validating?: boolean,
): { status: 'validating' | 'error' | 'success'; error?: string; message?: string } | undefined => {
  if (meta.active && validating) {
    return { status: 'validating' };
  }
  if (!meta.touched) {
    return undefined;
  }
  return { status: meta.error ? 'error' : 'success', error: meta.error, message: meta.error };
};
