import { createReducer, createAction } from '@reduxjs/toolkit';

import { UserStatusEnum, LogicStatusType, UserStatusType, UserLogicStatusMap, UserStatusMap } from 'tccc-utils';

// TODO：这段引入去掉后会导致项目运行崩溃，暂未找到解决方案，先保留
import { setEnableSpeechRecognition } from '../actions/agent';

import { updateAssignAccept, loginInstance } from './app.thunk';

type ConnectionState = 'disconnected' | 'connected' | 'connecting' | 'reconnecting';
export type IMMessage = {
  sessionId: string;
  userId: string;
  from: string;
  nickName: string;
  staffNo: string;
  staffName: string;
  count: number;
  lastMessage: any;
  status: UserStatusEnum;
  skillGroupId: string;
};

const defaultState: {
  connectionState: ConnectionState;
  status: UserStatusType;
  logicStatus?: LogicStatusType;
  nextStatus?: UserStatusType;
  reason?: string;
  innerIMMessage: IMMessage[];
  IM: {
    total: number;
    used: number;
  };
  assignCall: boolean;
  assignIM: boolean;
  existCall: boolean;
  existIM: boolean;
  queueCount?: Record<string, number>;
  enableSpeechRecognition?: boolean;
} = {
  connectionState: 'disconnected',
  status: UserStatusMap.offline,
  logicStatus: UserLogicStatusMap.ready,
  reason: '',
  innerIMMessage: [] as IMMessage[],
  IM: {
    total: 0,
    used: 0,
  },
  assignCall: true,
  assignIM: true,
  existCall: true,
  existIM: true,
  queueCount: {},
  enableSpeechRecognition: false,
};

export const changeStatus = createAction<{
  connectionState?: ConnectionState;
  status?: UserStatusType;
  logicStatus?: LogicStatusType;
  reason?: string;
  nextStatus?: UserStatusType;
  IM?: {
    total: number;
    used: number;
  };
  queueCount?: Record<string, number>;
}>('agent/changeStatus');
export const clearAgentState = createAction('agent/clearAgentState');

export default createReducer(defaultState, (builder) => {
  builder
    .addCase(changeStatus, (state, action) => {
      if (action.payload.status) {
        state.status = action.payload.status;
      }
      if (action.payload.connectionState) {
        state.connectionState = action.payload.connectionState;
      }
      state.reason = action.payload.reason;
      state.nextStatus = action.payload.nextStatus;
      state.logicStatus = action.payload.logicStatus;
      if (action.payload.IM) {
        state.IM = action.payload.IM;
        state.existIM = action.payload.IM.total > 0;
      }
      if (action.payload.queueCount) {
        state.queueCount = action.payload.queueCount;
      }
    })
    .addCase(updateAssignAccept.fulfilled, (state, action) => {
      Object.assign(state, action.payload);
    })
    .addCase(clearAgentState, () => defaultState)
    .addCase(setEnableSpeechRecognition, (state, action) => {
      state.enableSpeechRecognition = action.payload || false;
    })
    .addCase(loginInstance.fulfilled, (state, action) => {
      Object.assign(state, action.payload.agentAssignConfig);
    });
});
