import { configureStore, isRejected, isRejectedWithValue } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { ThunkMiddleware } from 'redux-thunk';

import { debugLogRequest } from 'src/services';
import { logger } from 'src/utils/log';

import { isTestEnv } from '../utils';

import rootReducer from './reducers';
import { sessionsAdapter } from './slices/sessions';

const actionLogger: ThunkMiddleware = () => (next) => (action) => {
  if (isRejected(action) || isRejectedWithValue(action)) {
    try {
      logger.warn(`Rejected Action, ${JSON.stringify(action)}}`);
      debugLogRequest(
        `Rejected action type: ${action.type}, payload: ${JSON.stringify(action.payload)}, meta: ${JSON.stringify(
          action.meta,
        )}`,
      );
    } catch {
      // do nothing
    }
  }
  const result = next(action);
  return result;
};
const crashReporter: ThunkMiddleware = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (err: any) {
    logger.error('Caught an exception in store!', err);
    try {
      debugLogRequest(
        `[Caught an exception in store!], ${err?.message || err?.msg || err}, ${JSON.stringify({
          extra: {
            action,
            state: store.getState(),
          },
        })}`,
      );
    } catch {
      // do nothing
    }
    throw err;
  }
};

const store = configureStore({
  reducer: rootReducer,
  devTools: isTestEnv(),
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    actionLogger,
    crashReporter,
  ],
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export const sessionsSelector = sessionsAdapter.getSelectors<RootState>((state) => state.sessions);
export const { selectById: selectBySessionId, selectAll: selectAllSession } = sessionsSelector;

export default store;
