import theme from 'src/themes/tencentBlue';
const getStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginBottom: 0,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  contentBox: {
    margin: '100px 0 auto 0',
  },
  card: {
    padding: '56px 116px 60px 116px',
    overflow: 'visible',
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 0,
  },
  header: {},
  title: {
    fontSize: '32px',
    color: '##1F232A',
    margin: '0 0 10px 0',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  desc: {
    color: '#888',
    fontSize: '14px;',
    margin: 0,
    padding: '0',
    marginBottom: '30px',
    width: 400,
  },
  info: {
    color: '1F232A',
    margin: '0 0 40px 0',
    padding: '0',
    fontSize: '20px',
    textAlign: 'center',
  },
  formButtonBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  formButton: {
    width: 300,
    margin: '0 auto',
    height: '40px',
  },
  error: {
    color: '#E54446',
  },
  textField: {
    width: 300,
  },
  input: {
    width: 'auto',
  },
});
export default getStyles;
