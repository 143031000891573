import React, { KeyboardEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

function groupBy<T extends { index: number }>(array: T[], keyFunction: (item: T) => string): Record<string, T[]> {
  const result: Record<string, T[]> = {};
  let index = 0;
  for (const item of array) {
    const key = keyFunction(item);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
  }
  Object.keys(result).forEach((key) => {
    result[key].forEach((item: T) => {
      item.index = index;
      index += 1;
    });
  });
  return result;
}

const ItemsContinaer = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9rem;
  overflow: hidden;
  padding: 0.2rem;
  position: relative;
`;

const ItemTitle = styled.div`
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  display: block;
  margin: 0;
  padding: 0.2rem 0.4rem;
  text-align: left;
  width: 100%;
  word-break: break-all;
  color: rgba(0, 0, 0, 0.5);
`;

const Item = styled.div`
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  display: block;
  margin: 0;
  padding: 0.2rem 0.4rem;
  padding-left: 20px;
  text-align: left;
  width: 100%;
  word-break: break-all;
  cursor: pointer;

  &.is-selected {
    background: rgb(242, 242, 242);
  }
`;
export default forwardRef(
  (
    props: {
      items: Array<{ content: string; id: string; title: string; group: string; index: number }>;
      command: (args: { content: string }) => void;
    },
    ref,
  ) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { t } = useTranslation();
    const propsItems = groupBy(props.items, (item) => item.group);

    const selectItem = (selectIndex: number) => {
      let replyItem = { content: '' };
      Object.keys(propsItems).forEach((key) => {
        propsItems[key].forEach((item) => {
          if (item.index === selectIndex) {
            replyItem = item;
          }
        });
      });

      if (replyItem) {
        props.command(replyItem);
      }
    };

    const upHandler = () => {
      setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [props.items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          downHandler();
          return true;
        }

        if (event.key === 'Enter') {
          if (!props.items.length) {
            return true;
          }
          enterHandler();
          event.stopPropagation();
          return true;
        }

        return false;
      },
    }));

    return (
      <ItemsContinaer>
        {props.items.length ? (
          Object.keys(propsItems).map((key) => (
            <div key={key}>
              <ItemTitle>{key}</ItemTitle>
              {propsItems[key].map((item) => (
                <Item
                  key={item.index}
                  className={`${item.index === selectedIndex ? 'is-selected' : ''}`}
                  onClick={() => selectItem(item.index)}
                >
                  <p>
                    {t('标题')}：{item.title}
                  </p>
                  <strong>
                    {t('回复内容')}：{item.content}
                  </strong>
                </Item>
              ))}
            </div>
          ))
        ) : (
          <Item>No result</Item>
        )}
      </ItemsContinaer>
    );
  },
);
