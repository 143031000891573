import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';

import { Document } from '@tiptap/extension-document';
import { Dropcursor } from '@tiptap/extension-dropcursor';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Text } from '@tiptap/extension-text';
import { useEditor, EditorContent } from '@tiptap/react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import logger from '../../../../utils/logger';

import CustomImage from './messageInputFile';
import { QuickReplyExtension } from './QuickReply/extension';
import suggestion from './QuickReply/suggestion';

const EditorInput = styled(EditorContent)`
  flex: 1;
  display: flex;
  overflow-y: auto;
  height: 90px;
  width: 100%;
  word-break: break-all;
  .ProseMirror {
    height: 100%;
    width: 100%;
  }
  .ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .ProseMirror-selectednode {
    outline: 1px solid #68cef8;
  }
  .customImageNormal {
    max-height: 120px;
    max-width: 200px;
  }
  .customImageFile {
    height: 50px;
    width: 160px;
    border: 1px solid #e8e8e9;
    border-radius: 5px;
  }
  .customImageEmoji {
    height: 20px;
    width: 20px;
  }
`;

const MessageInputEditor = (props, ref) => {
  const { t } = useTranslation();
  // const [inputBlur, setInputBlur] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [fileMap, setFileMap] = useState(new Map());
  const [fileIconDomMap, setFileIconDomMap] = useState(new Map());
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Dropcursor,
      Placeholder.configure({
        placeholder: t('“Enter”键发送，“Shift+Enter”键换行，“#”键快捷回复'),
      }),
      CustomImage.configure({
        inline: true,
        allowBase64: true,
        HTMLAttributes: {
          class: 'customImage',
        },
      }),
      QuickReplyExtension.configure({
        suggestion,
      }),
    ],

    autofocus: true,
    editable: true,
    injectCSS: false,

    onUpdate({ _editor, transaction }) {
      if (transaction?.doc?.content?.size > 2) {
        props.sendTypingState();
      } else {
        // setInputContentEmpty(true);
      }
    },
    onFocus() {
      setInputFocus(true);
    },
    onBlur() {
      setInputFocus(false);
    },
  });

  // IM SDK创建图片与文件消息需要file对象，不存入SessionStorage，因此遍历去除
  const excludeFile = (messageList) => {
    try {
      const messageContents = messageList.content;
      for (const item of messageContents) {
        if (item?.content)
          for (let i = 0; i < item.content.length; i++) {
            if (item.content[i].type !== 'text') {
              item.content.splice(i, 1);
            }
          }
      }
      return messageList;
    } catch (e) {
      console.log(e);
      logger.error('excludeFile: excludeFile failed', e);
    }
    return messageList;
  };

  useEffect(() => {
    try {
      if (sessionStorage.getItem(`${props?.callInfo?.sessionId}_draft`)) {
        editor?.commands?.setContent(
          excludeFile(JSON.parse(sessionStorage.getItem(`${props?.callInfo?.sessionId}_draft`))),
        );
      } else {
        editor?.commands?.setContent({
          content: [{ type: 'paragraph' }],
          type: 'doc',
        });
      }
    } catch (e) {
      console.log(e);
      logger.error('setConverSationDraft: setConverSationDraft failed', e);
    }
  }, [editor, props?.callInfo?.sessionId]);

  // 将用户的草稿存入SessionStorage，5MB可存入2621440文字，理论上在单次打开网页可满足，不会溢出
  useEffect(
    () => () => {
      try {
        const messageList = editor?.getJSON();
        if (isEmpty(messageList)) {
          return;
        }
        sessionStorage.setItem(`${props?.callInfo?.sessionId}_draft`, JSON.stringify(editor?.getJSON()));
      } catch (e) {
        console.log(e);
        logger.error('saveConverSationDraft: saveConverSationDraft failed', e);
      }
    },
    [editor, props.callInfo],
  );

  const handleNameForShow = (value) => {
    if (!value) {
      return value;
    }
    let res = '';
    let length = 0;
    for (let i = 0; i < value?.length; i++) {
      if (length > 16) {
        res += '...';
        break;
      }
      res += value[i];
      if (/[a-z]|[0-9]|[,;.!@#-+/\\$%^*()<>?:"'{}~]/i.test(value[i])) {
        length += 1;
      } else {
        length += 2;
      }
    }
    return res;
  };

  const handleFileIconForShow = (type) => {
    const urlBase = 'https://web.sdk.qcloud.com/component/TUIKit/assets/file-';
    const fileTypes = ['image', 'pdf', 'text', 'ppt', 'presentation', 'sheet', 'zip', 'word', 'video', 'unknown'];
    let url = '';
    let iconType = '';
    fileTypes.forEach((typeName) => {
      if (type.includes(typeName)) {
        url = `${urlBase + typeName}.svg`;
        iconType = typeName;
      }
    });
    return {
      iconSrc: url ? url : `${urlBase}unknown.svg`,
      iconType: iconType ? iconType : 'unknown',
    };
  };

  const addImageProcess = (src, type) =>
    new Promise((resolve, reject) => {
      if (fileIconDomMap.has(type)) {
        resolve(fileIconDomMap.get(type));
      } else {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = () => {
          fileIconDomMap.set(type, img);
          setFileIconDomMap(fileIconDomMap);
          resolve(img);
        };
        img.onerror = reject;
        img.src = src;
      }
    });

  const drawFileCanvasToImageUrl = async (file) => {
    const { name, type } = file;
    const canvas = document.createElement('canvas');
    const width = 160;
    const height = 50;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    const scale = window.devicePixelRatio;
    canvas.width = Math.floor(width * scale);
    canvas.height = Math.floor(height * scale);
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return '';
    }
    ctx.scale(scale, scale);
    const { iconSrc, iconType } = handleFileIconForShow(type);
    const img = await addImageProcess(iconSrc, iconType);
    ctx?.drawImage(img, 10, 10, 30, 30);
    const nameForShow = handleNameForShow(name);
    ctx.fillText(nameForShow, 45, 22);
    const dataURL = canvas.toDataURL();
    return dataURL;
  };

  const handleFileDropOrPaste = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    if ((type === 'drop' && e.dataTransfer) || (type === 'paste' && e.clipboardData)) {
      const files = type === 'drop' ? e?.dataTransfer?.files : e?.clipboardData?.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const isImage = file.type.startsWith('image/');
        const fileSrc = isImage ? URL.createObjectURL(file) : await drawFileCanvasToImageUrl(file);
        editor?.commands?.insertContent({
          type: 'customImage',
          attrs: {
            src: fileSrc,
            alt: file?.name,
            title: file?.name,
            class: isImage ? 'Normal' : 'File',
          },
        });
        fileMap.set(fileSrc, file);
        setFileMap(fileMap);
        if (i === files.length - 1) {
          setTimeout(() => {
            editor?.commands?.focus('end');
            editor?.commands?.scrollIntoView();
          }, 10);
        }
      }
    }
  };

  const getEditorContent = (messageList) => {
    const editorJSON = messageList || editor?.getJSON();
    const content = [];
    const handleEditorContent = (root) => {
      if (!root || !root.type) {
        return;
      }
      if (root.type !== 'text' && root.type !== 'customImage') {
        if (root.type === 'paragraph') {
          handleEditorNode(root);
        }
        if (root.content?.length) {
          root.content.forEach((item) => {
            handleEditorContent(item);
          });
        }
        return;
      }
      handleEditorNode(root);
    };
    const handleEditorNode = (node) => {
      if (node.type === 'paragraph') {
        if (content.length > 0 && content[content.length - 1] && content[content.length - 1]?.type === 'text') {
          content[content.length - 1].payload.text += '\n';
        }
      } else if (node.type === 'text' || (node.type === 'customImage' && node?.attrs?.class === 'Emoji')) {
        const text = node.type === 'text' ? node?.text : node?.attrs?.alt;
        if (content.length > 0 && content[content.length - 1] && content[content.length - 1]?.type === 'text') {
          content[content.length - 1].payload.text += text;
        } else {
          content.push({
            type: 'text',
            payload: { text },
          });
        }
      } else if (node.type === 'customImage' && node?.attrs?.class === 'Normal') {
        content.push({
          type: 'image',
          payload: { file: fileMap?.get(node?.attrs?.src) },
        });
      } else if (node.type === 'customImage' && node?.attrs?.class === 'File') {
        const file = fileMap?.get(node?.attrs?.src);
        content.push({
          type: file?.type?.includes('video') ? 'video' : 'file',
          payload: { file },
        });
      }
    };
    handleEditorContent(editorJSON);
    if (
      content.length > 0 &&
      content[content.length - 1] &&
      content[content.length - 1]?.type === 'text' &&
      content[content.length - 1]?.payload?.text?.endsWith('\n')
    ) {
      const { text } = content[content.length - 1].payload;
      content[content.length - 1].payload.text = text?.substring(0, text.lastIndexOf('\n'));
    }
    return content;
  };

  useImperativeHandle(ref, () => ({
    getEditorContent,
    addEmoji: (emoji) => {
      editor?.commands?.insertContent({
        type: 'customImage',
        attrs: {
          src: emoji?.url,
          alt: emoji?.name,
          title: emoji?.name,
          class: 'Emoji',
        },
      });
    },
    resetEditor: () => {
      editor?.commands?.clearContent(true);
      fileMap?.clear();
      setFileMap(fileMap);
      editor?.commands?.focus('end');
    },
    setEditorContent: (content) => {
      editor?.commands?.insertContent(content);
    },
    getInputStatus: () => inputFocus,
  }));

  return (
    <EditorInput
      editor={editor}
      onDrop={(e) => {
        handleFileDropOrPaste(e, 'drop');
      }}
      onPaste={(e) => {
        handleFileDropOrPaste(e, 'paste');
      }}
    />
  );
};

export default forwardRef(MessageInputEditor);
