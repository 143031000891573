import { makeStyles } from '@material-ui/core';
export default makeStyles({
  card: {
    position: 'relative',
    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.15)',
    borderRadius: 4,
    minWidth: 480,
    padding: '20px 10px',
    boxSizing: 'border-box',
  },
  callingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    padding: '40px 30px',
    margin: '0 auto',
  },
  callingText: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: '12px',
    color: '#767B81',
  },
  calledNumber: {
    marginTop: 10,
    fontSize: 26,
    fontWeight: 500,
    color: '#101B2A',
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  calloutTypeSegment: {
    display: 'flex',
    justifyContent: 'center',
  },
  callButton: {
    width: 140,
    height: 32,
    borderRadius: 17,
    margin: '0 auto',
    marginTop: 10,
  },
  hint: {
    marginBottom: 8,
    fontWeight: 500,
  },
});
