import { useCallback, useEffect, useMemo, useState, ReactNode } from 'react';

import { makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { isRejected } from '@reduxjs/toolkit';
import { message } from '@tencent/tea-component';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { transferSession } from 'src/store/slices/sessions/sessions.thunk';

import { ActionButtonGroup } from '../../components/ActionButtonGroup';
import { RoundedButton } from '../../components/Button';
import { Input } from '../../components/Input';

import { SeatList } from './SeatList';
import { useFreeStaffList } from './useSeatList';

interface TransferSeatProps {
  sessionId: string;
  onCancel: () => void;
}

const useStyles = makeStyles({
  label: {
    fontSize: 14,
  },
  searchBox: {
    width: '100%',

    '& input': {
      fontSize: 14,
      background: '#282828',
      borderRadius: '5px',
      padding: '8px 0 8px 10px',
    },
  },
  actionBtnGroupWrapper: {
    width: '100%',
    padding: '8px 0',
    height: '45px !important',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  alert: {
    width: '93%',
    color: 'rgb(250, 179, 174)',
    backgroundColor: 'rgb(24, 6, 5)',
    border: 0,
    marginTop: 8,
  },
});
interface Option {
  email: any;
  name: ReactNode;
  status: any;
}
export const TransferSeat = (props: TransferSeatProps) => {
  const { sessionId, onCancel } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [options, setOptions] = useState<Option[]>([]);
  const [pageInfo, setPageInfo] = useState({ pageIndex: 1, pageSize: 50 });
  const [selectSeatId, setSelectSeatId] = useState('');
  const [errorInfo, setErrorInfo] = useState('');
  const [transferLoading, setTransferLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [currentSearchValue, setCurrentSearchValue] = useState('');

  const { data, loading, fetchList } = useFreeStaffList({ ...pageInfo, currentSearchValue }, true);
  const list = useMemo(
    () =>
      (data?.staffList || []).map(({ staffName, userId, status }) => ({
        email: userId,
        name: staffName,
        status,
      })),
    [data?.staffList],
  );
  const total = useMemo(() => data?.total || [], [data?.total]);

  const isLoadMore = useMemo(
    () => pageInfo.pageIndex * pageInfo.pageSize <= total,
    [pageInfo.pageIndex, pageInfo.pageSize, total],
  );

  const onHandleSearchChange = useCallback(
    debounce(
      (currentSearchValue) => {
        setOptions([]);
        setSelectSeatId('');
        setCurrentSearchValue(currentSearchValue);
        setPageInfo({ pageIndex: 1, pageSize: 50 });
      },
      800,
      { leading: false, trailing: true },
    ),
    [],
  );

  const handleTransfer = useCallback(() => {
    setTransferLoading(true);
    dispatch(transferSession({ userId: selectSeatId, sessionId }))
      // @ts-ignore
      .then((e) => {
        setTransferLoading(true);
        if (isRejected(e)) {
          setErrorInfo(e.payload || e.error.message);
        } else {
          message.success({
            content: t('转接成功'),
          });
        }
        setTransferLoading(false);
      })
      .catch((error: Error) => {
        setTransferLoading(false);
        setErrorInfo(error.message);
      });
  }, [dispatch, selectSeatId, sessionId]);

  useEffect(() => {
    if (refresh) {
      return;
    }
    setOptions((pre) => [...pre, ...list]);
  }, [list, refresh]);

  return (
    <>
      <Input
        label={<span className={classes.label}>{t('选择要转接的座席')}</span>}
        className={classes.searchBox}
        placeholder={t('模糊搜索不区分姓名邮箱')}
        value={inputValue}
        onChange={(e) => {
          const { value } = e.target;
          setRefresh(false);
          setInputValue(value);
          onHandleSearchChange(value);
        }}
      />
      <SeatList
        loading={loading}
        options={options}
        emptyTips={t('暂无空闲座席可以转接')}
        value={selectSeatId}
        onSelected={(value) => {
          setSelectSeatId(value);
          setErrorInfo('');
        }}
        onRefresh={() => {
          setRefresh(true);
          fetchList();
        }}
        isLoadMore={isLoadMore}
        onLoadMore={() => {
          setPageInfo({ pageIndex: pageInfo.pageIndex + 1, pageSize: pageInfo.pageSize });
        }}
      />
      <ActionButtonGroup className={classes.actionBtnGroupWrapper}>
        {options?.length !== 0 && (
          <RoundedButton
            variant={'contained'}
            color={'primary'}
            disabled={!selectSeatId || transferLoading}
            onClick={handleTransfer}
          >
            {t('开始转接')}
          </RoundedButton>
        )}
        <RoundedButton variant={'outlined'} color={'default'} onClick={onCancel}>
          {t('取消转接')}
        </RoundedButton>
      </ActionButtonGroup>
      {errorInfo && (
        <Alert severity="error" className={classes.alert}>
          {errorInfo}
        </Alert>
      )}
    </>
  );
};
