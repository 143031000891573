import { useRef, useEffect, useCallback } from 'react';

export default function useDraggable(
  MoveTargetRef,
  OuterRef,
  func = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    mouseDown() {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    mouseMove() {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    mouseUp() {},
  },
) {
  const ref = useRef();
  const moveTargetRef = MoveTargetRef || ref;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMouseDown = useCallback((mouseDownEvent) => {
    if (mouseDownEvent.target === ref.current) {
      const moveTarget = moveTargetRef.current;
      const moveTargetRect = moveTarget.getBoundingClientRect();
      const outerTarget = OuterRef?.current ? OuterRef.current : document.documentElement;
      const outerTargetRect = outerTarget.getBoundingClientRect();
      const mouseDownX = mouseDownEvent.clientX - moveTargetRect.left + outerTargetRect.left;
      const mouseDownY = mouseDownEvent.clientY - moveTargetRect.top + outerTargetRect.top;

      const onMouseMove = (mouseMoveEvent) => {
        const mouseMoveX = mouseMoveEvent.clientX;
        const mouseMoveY = mouseMoveEvent.clientY;

        func.mouseMove && typeof func.mouseMove === 'function' && func.mouseMove();

        if (mouseMoveY - mouseDownY < 0) {
          moveTarget.style.top = '0px';
        } else if (mouseMoveY - mouseDownY + moveTargetRect.height > outerTargetRect.height) {
          moveTarget.style.top = `${outerTargetRect.height - moveTargetRect.height}px`;
        } else {
          moveTarget.style.top = `${mouseMoveY - mouseDownY}px`;
        }
        if (mouseMoveX - mouseDownX < 0) {
          moveTarget.style.left = '0px';
        } else if (mouseMoveX - mouseDownX + moveTargetRect.width > outerTargetRect.width) {
          moveTarget.style.left = `${outerTargetRect.width - moveTargetRect.width}px`;
        } else {
          moveTarget.style.left = `${mouseMoveX - mouseDownX}px`;
        }
      };

      const onMouseUp = () => {
        func.mouseUp && typeof func.mouseUp === 'function' && func.mouseUp();
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mousemove', onMouseMove);
      };

      func.mouseDown && typeof func.mouseDown === 'function' && func.mouseDown();
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    }
  });
  useEffect(() => {
    window.addEventListener('mousedown', onMouseDown);
    return () => {
      window.removeEventListener('mousedown', onMouseDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return ref;
}
