import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { isFulfilled } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { TIMSDK as tim } from 'tccc-sdk';

import CustomPopper from 'src/components/CustomPopper/CustomPopper';
import store from 'src/store';
import { deleteMessage } from 'src/store/actions/im';
import { createTextMessage, sendMessage as sendMessageThunk } from 'src/store/slices/tim.thunk';

import messageStore from 'src/utils/messageStore';

import style from './errorTip.style';

const useStyles = makeStyles(style);

export function ErrorTip(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  async function sendMessage() {
    if ([tim.TYPES.MSG_FILE, tim.TYPES.MSG_IMAGE, tim.TYPES.MSG_VIDEO].includes(props.message.type)) {
      document.getElementById('filePicker').click();
      document.getElementById('filePicker').addEventListener(
        'change',
        () => {
          dispatch(
            deleteMessage({
              id: props.message.to,
              msgId: props.message.ID,
            }),
          );
        },
        { once: true },
      );
    } else {
      const res = store.dispatch(createTextMessage(messageStore.get(props.message.ID)));
      if (isFulfilled(res)) {
        store.dispatch(sendMessageThunk(res.payload));
      }
    }
  }
  return (
    <CustomPopper
      id="error-message"
      renderPopper={
        <div className={classes.content}>
          {/* eslint-disable */}
        {props.message.errorMsg} <a onClick={sendMessage} href="javascript:void(0)">{t('重发')}</a>
        {/* eslint-enable */}
        </div>
      }
    >
      <ErrorIcon className={classes.errorIcon} />
    </CustomPopper>
  );
}

ErrorTip.propTypes = {
  message: PropTypes.object,
};
