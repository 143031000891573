import { useState, useEffect } from 'react';

import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import BaseDialog from './BaseDialog';
import style from './BaseDialog.style';

function ConfirmDialog(props) {
  const [state, setState] = useState(props);
  const { t } = useTranslation();
  useEffect(() => {
    setState(props);
  }, [props]);

  const { classes, children, onConfirm, confirmText, ...rest } = state;
  return (
    <BaseDialog
      actions={{
        [confirmText || t('确定')]: {
          onClick: (event) => {
            setState({
              ...state,
              open: false,
            });
            onConfirm?.(event);
          },
        },
      }}
      {...rest}
    >
      {children}
    </BaseDialog>
  );
}

ConfirmDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  confirmText: PropTypes.string,
  actions: PropTypes.node,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(style)(ConfirmDialog);
