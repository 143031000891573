import { GET_API_BAK_DOMAIN, GET_API_DOMAIN } from 'tccc-env/src/url';
import { axios, backupUrlInterceptor, csrfInterceptor } from 'tccc-utils';

import i18next from 'src/i18n';

import sessionManage from '../utils/sessionManage';
const baseURL = `https://${GET_API_DOMAIN()}`;
const backupDomain = GET_API_BAK_DOMAIN();

const options = {
  baseURL,
  backupURL: backupDomain ? `https://${backupDomain}` : '',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosInstance = axios.create(options);
backupUrlInterceptor(axiosInstance);
csrfInterceptor(axiosInstance);

axiosInstance.interceptors.request.use(
  (config) => {
    const sdkAppId = sessionManage.getGlobalSdkAppId();
    if (
      sdkAppId &&
      config.url &&
      !['/tccclogin/login/loginInstance', '/tccclogin/login/checkLogin'].includes(config.url)
    ) {
      Object.assign(config.data, {
        instanceId: sdkAppId,
        sdkAppId,
      });
    }
    if (
      config.url &&
      ['/tccclogin/login/checkLogin', '/tccclogin/login/queryUserInstanceList'].includes(config.url) &&
      config.headers.Authorization
    ) {
      Object.assign(config.headers, {
        Authorization: undefined,
      });
    }
    Object.assign(config.data, {
      language: i18next.language,
    });
    return config;
  },
  (err) => Promise.reject(err),
);

export { axiosInstance };
