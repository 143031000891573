import PropTypes from 'prop-types';

import useStyles from './MediaElement.style';

function MediaElement({ data, index }) {
  const classes = useStyles();
  return (
    <div key={data.ID + index} className={classes[`message_${data.flow}`]}>
      <pre className={classes.textMessage} style={{ textAlign: 'left' }} key={index}>
        {data.data.text}
      </pre>
    </div>
  );
}

MediaElement.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default MediaElement;
