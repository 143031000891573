import { useMemo } from 'react';

import { makeStyles } from '@material-ui/styles';
import { List, Text } from '@tencent/tea-component';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// import { ExtensionStatusText } from 'src/components/ExtensionStatus/ExtensionStatus';
import { useRequest } from 'src/services/httpClient';
import { RootState } from 'src/store';

const useStyles = makeStyles({
  extensionInfo: {
    padding: '5px 0',
    width: '100%',
    alignItems: 'center',
    borderTop: '1px solid #F7F7F7',
  },
  wrapperItem: {
    opacity: '1 !important',
    padding: 0,
    lineHeight: '26px!important',
    marginBottom: '0!important',
  },
});

export const ExtensionInfo = ({ open }: { open: boolean }) => {
  const classes = useStyles();
  const userId = useSelector((state: RootState) => state.userInfo.userId);
  const { t } = useTranslation();
  const { data } = useRequest(
    '/tcccadmin/extension/getList',
    {
      pageNum: 0,
      pageSize: 10,
      isNeedStatus: true,
      relation: userId,
    },
    [userId],
    open,
  );
  const extension = useMemo(() => {
    const extension = data?.extensionList?.[0];
    return extension?.relation === userId ? extension : null;
  }, [data?.extensionList, userId]);

  return (
    <>
      {!isEmpty(extension) && (
        <List className={classes.extensionInfo}>
          <List.Item className={classes.wrapperItem}>
            <Text>
              {t('分机号')}：{extension?.extensionId || '-'}
            </Text>
          </List.Item>
          {/* <List.Item className={classes.wrapperItem}>
            <Text>
              话机状态：
              <ExtensionStatusText status={extension?.status} registerStatus={extension?.register} />
            </Text>
          </List.Item> */}
          <List.Item className={classes.wrapperItem}>
            <Text>
              {t('话机注册状态')}：
              {extension?.register ? (
                <Text theme={'success'}>{t('已注册')}</Text>
              ) : (
                <Text theme={'label'}>{t('未注册')}</Text>
              )}
            </Text>
          </List.Item>
        </List>
      )}
    </>
  );
};
