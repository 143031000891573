import { aegis } from 'src/aegis';

import { isTestEnv } from './index';
class Logger {
  logInfoToConsole = isTestEnv();
  error(...args: any[]) {
    aegis.error(...args);
    console.error(...args);
  }

  warn(...args: any[]) {
    if (!window.navigator.onLine) {
      console.error('未联网，请检查网络设置');
      return;
    }
    aegis.infoAll(...args);
    if (this.logInfoToConsole) {
      console.warn(...args);
    }
  }

  info(...args: any[]) {
    if (!window.navigator.onLine) {
      console.error('未联网，请检查网络设置');
      return;
    }
    aegis.info(...args);
    if (this.logInfoToConsole) {
      console.info(...args);
    }
  }

  report(...args: any[]) {
    if (!window.navigator.onLine) {
      console.error('未联网，请检查网络设置');
      return;
    }
    aegis.report(...args);
    if (this.logInfoToConsole) {
      console.warn(...args);
    }
  }

  debug(...args: any[]) {
    if (!window.navigator.onLine) {
      console.error('未联网，请检查网络设置');
      return;
    }
    aegis.info(...args);
    if (this.logInfoToConsole) {
      console.debug(...args);
    }
  }
}

export default new Logger();
