export const BROWSER = {
  CHROME: 'Chrome',
  FIREFOX: 'Firefox',
  OPERA: 'Opera',
  SAFARI: 'Safari',
  EDGE: 'Edge',
  WECHAT: 'WeChat',
};

// 支持浏览器的最低版本由是否支持完全的 webrtc 能力来决定(https://caniuse.com/?search=webrtc)
// {name: minimum supported version}, 0 代表任意版本
export const SUPPORTED_BROWSERS = {
  [BROWSER.CHROME]: 56,
  [BROWSER.EDGE]: 79,
  [BROWSER.SAFARI]: 11,
  [BROWSER.FIREFOX]: 44,
  [BROWSER.OPERA]: 43,
  [BROWSER.WECHAT]: 0,
};
