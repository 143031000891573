import lscache from 'lscache';
type Keys = 'userInfo' | 'i18n_language' | 'isIntl';

const ExpiryMilliseconds = 1000 * 60 * 24;
class Storage<T extends Keys> {
  constructor() {
    lscache.setBucket('tccc');
    lscache.flushExpired();
  }
  setItem = (key: T, value: string | object) => {
    lscache.set(key, value, ExpiryMilliseconds);
  };
  getItem = (key: T) => lscache.get(key);
  removeItem = (key: T) => {
    lscache.remove(key);
  };
  clear() {
    lscache.flush();
  }
}

export const storage = new Storage();
