import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core';
import { isRejected } from '@reduxjs/toolkit';
import { message } from '@tencent/tea-component';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Timing } from 'src/components/Timing/Timing';
import { TransferSeat } from 'src/components/Transfer/TransferSeat';
import TransferSkillGroup from 'src/components/Transfer/UserTransfer';
import { Session } from 'src/store/slices/sessions';
import { changeMicStatus } from 'src/store/slices/sessions/rtc';
import { endSession, muteVideo, unmuteVideo } from 'src/store/slices/sessions/sessions.thunk';

import tccc from '../../../../../utils/tccc';
import { AsyncPhoneCard as PhoneCard } from '../AsyncPhoneCard';
import { BaseButton } from '../components/BaseButton';

import { LocalUserInfo } from './LocalUserInfo';
import { RemoteUserInfo } from './RemoteUserInfo';
import { useVideoCardObserver } from './useVideoCardObserver';

const useStyles = makeStyles<
  {},
  {
    miniScreen: boolean;
    remoteStreamHeight: number;
    headerHeight: number;
    bottomPadding: number;
    layout: 'vertical' | 'horizontal';
  }
>({
  videoCard: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: '#fff',
    minHeight: 460,
  },
  videoWrapper: {
    background: 'rgb(52,52,52)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: (props) => props.headerHeight,
    flexShrink: 1,
    flexGrow: 0,
  },
  timing: {
    color: 'rgba(253,253,253,1)',
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 30,
  },
  buttonGroup: {
    padding: (props) => (props.miniScreen ? props.bottomPadding : '30px 30px 0 30px'),
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    transition: 'all 0.3s ease-in-out',
    '& > i': {
      width: (props) => (props.miniScreen ? 92 : 170),
    },
  },
  buttonItem: {
    flexShrink: 0,
    marginBottom: (props) => (props.miniScreen ? 8 : 30),
    height: (props) => (props.miniScreen ? 32 : 44),
    width: (props) => (props.miniScreen ? 92 : 170),
    paddingLeft: (props) => (props.miniScreen ? 2 : 5),
    paddingRight: (props) => (props.miniScreen ? 2 : 5),
  },
  streamList: (props) =>
    props.layout === 'horizontal'
      ? {
          display: 'flex',
          flexBasis: 'calc(100% - 53px)',
          flexGrow: 0,
          flexShrink: 0,
        }
      : {
          display: 'flex',
          height: props.remoteStreamHeight,
          flexDirection: 'column',
          flexGrow: 0,
        },
  mainVideo: {
    flexBasis: '66%',
    flexGrow: 2,
    height: (props) => (props.layout === 'horizontal' ? props.remoteStreamHeight : 0.67 * props.remoteStreamHeight),
    '& video': {
      objectFit: 'scale-down',
    },
    '&.mini-player': {
      width: '320px',
      overflow: 'visible',
      position: 'fixed',
      bottom: 20,
      visibility: 'visible',
      height: 'auto',
      right: 20,
      zIndex: 99999,
      margin: 0,
      boxShadow: 'none',
    },
  },
  secondaryVideoList: {
    flexBasis: '33%',
    flexGrow: 1,
    flexDirection: (props) => (props.layout === 'vertical' ? 'row' : 'column'),
    maxHeight: (props) => (props.layout === 'vertical' ? props.remoteStreamHeight * 0.33 : props.remoteStreamHeight),
    display: 'flex',
  },
  secondaryVideo: {
    flexBasis: '50%',
    flexGrow: 0,
    '& video': {
      objectFit: 'scale-down',
    },
  },
});

export const VideoCard = (props: { callInfo: Session; selected: boolean }) => {
  const { t } = useTranslation();
  const { callInfo } = props;
  const dispatch = useDispatch();
  const { videoCardRef, miniScreen, remoteStreamHeight, bottomRef, headerHeight, bottomPadding, layout } =
    useVideoCardObserver();

  const [transferSeatFlag, setTransferSeatFlag] = React.useState(false);
  const [transferSkillGroupFlag, setTransferSkillGroupFlag] = React.useState(false);
  const classes = useStyles({ miniScreen, remoteStreamHeight, headerHeight, bottomPadding, layout });
  const handleEnd = async () => {
    const res = await dispatch(endSession({ sessionId: callInfo.sessionId, closeBy: 'seat' }));
    if (isRejected(res)) {
      message.error({
        content: t('视频挂断异常，请稍后重试；如仍未解决，请刷新页面后重试'),
      });
    }
  };

  const handleMic = () => {
    dispatch(
      changeMicStatus({ sessionId: callInfo.sessionId, micStatus: callInfo.micStatus === 'off' ? 'on' : 'off' }),
    );
  };
  const handleCamera = () => {
    if (callInfo.disableCamera) {
      dispatch(unmuteVideo(callInfo));
    } else {
      dispatch(muteVideo(callInfo));
    }
  };
  const showMiniPlayer = false;

  useEffect(() => {
    if (callInfo.status === '200') {
      tccc.Video.play({ sessionId: callInfo.sessionId, id: 'remoteVideo' }).catch(console.error);
      tccc.Video.play({ sessionId: callInfo.sessionId, id: 'mainVideo', local: true }).catch(console.error);
    }
  }, [callInfo.status, callInfo.sessionId]);

  return (
    <>
      {callInfo.status !== '200' && <PhoneCard sessionId={callInfo.sessionId} />}
      {callInfo.status !== '400' && (
        <div
          className={classes.videoCard}
          ref={videoCardRef}
          style={callInfo.status === '200' && props.selected ? {} : { visibility: 'hidden', width: 0, height: 0 }}
        >
          {transferSeatFlag && (
            <TransferSeat isTransfer sessionId={callInfo.sessionId} onComplete={() => setTransferSeatFlag(false)} />
          )}
          {transferSkillGroupFlag && (
            <TransferSkillGroup
              isTransfer
              callInfo={callInfo}
              skillGroupType={4}
              onComplete={() => setTransferSkillGroupFlag(false)}
            />
          )}
          <div
            className={classes.videoWrapper}
            style={transferSeatFlag || transferSkillGroupFlag ? { visibility: 'hidden' } : {}}
          >
            <div className={classes.header}>
              <div className={classes.timing}>{callInfo.timestamp && <Timing startTime={callInfo.timestamp} />}</div>
            </div>
            <div className={classes.streamList}>
              <Draggable
                onStart={() => {
                  if (!showMiniPlayer) {
                    return false;
                  }
                }}
              >
                <div
                  id="remoteVideo"
                  className={showMiniPlayer ? `${classes.mainVideo} mini-player` : classes.mainVideo}
                >
                  <RemoteUserInfo callInfo={callInfo} />
                </div>
              </Draggable>
              <ul className={classes.secondaryVideoList}>
                <div id="mainVideo" className={classes.secondaryVideo}>
                  <LocalUserInfo callInfo={callInfo} />
                </div>
              </ul>
            </div>
          </div>
          <div className={classes.buttonGroup} ref={bottomRef}>
            <BaseButton className={classes.buttonItem} variant={'contained'} color={'secondary'} onClick={handleEnd}>
              {t('挂断')}
            </BaseButton>
            <BaseButton className={classes.buttonItem} variant={'outlined'} onClick={handleMic}>
              {callInfo.micStatus === 'off' ? t('取消静音') : t('静音')}
            </BaseButton>
            <BaseButton className={classes.buttonItem} variant={'outlined'} onClick={handleCamera}>
              {callInfo.disableCamera ? t('开启摄像头') : t('关闭摄像头')}
            </BaseButton>
            <BaseButton
              className={classes.buttonItem}
              variant={'outlined'}
              onClick={() => setTransferSkillGroupFlag(true)}
            >
              {t('转技能组')}
            </BaseButton>
            <BaseButton className={classes.buttonItem} variant={'outlined'} onClick={() => setTransferSeatFlag(true)}>
              {t('转座席')}
            </BaseButton>
            <i />
            <i />
            <i />
            <i />
          </div>
        </div>
      )}
    </>
  );
};
