import React from 'react';

import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import i18n from 'src/i18n';

import { Direction, ServerType, Session } from 'src/store/slices/sessions/types';

import { Duration } from '../Duration';
import { Timing } from '../Timing/Timing';

const { t } = i18n;
export const serverTypeName: Record<ServerType, string> = {
  miniProgramSeat: t('小程序'),
  staffExtensionSeat: t('分机'),
  staffPhoneSeat: t('手机'),
  staffSeat: '',
  customer: '',
};

export const getServerTypeName = (type: ServerType) => serverTypeName[type] || '';

export const SessionStatusDisplay = ({ callInfo, children }: { callInfo: Session; children?: React.ReactNode }) => {
  const { t } = useTranslation();
  if (!callInfo) {
    return t('未知状态');
  }
  if (children) return children;
  const prefix = callInfo.serverType ? serverTypeName[callInfo.serverType] : '';
  if (callInfo.status === '100') {
    if (callInfo.direction === Direction.callOut) {
      return t('{{0}}呼叫中', { 0: prefix });
    }
    return t('请求{{0}}接入中', { 0: prefix });
  }
  if (callInfo.status === '150') {
    if (callInfo.direction === Direction.callOut) {
      if (callInfo.serverType === 'staffSeat') {
        return (
          <>
            {`${t('呼叫中')}`}
            (<Timing startTime={callInfo.timestamp} />)
          </>
        );
      }
      if (prefix) {
        return t('呼叫中({{0}})', { 0: prefix });
      }
    }
    return t('处理接入中');
  }
  if (callInfo.status === '400') {
    return (
      <>
        <span>{t('已结束')}</span>
        (<Duration seconds={callInfo.duration} />)
      </>
    );
  }
  if (callInfo.status === '200') {
    return (
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {callInfo.ivrSoundPlaying ? t('用户放音中') : ''}
        {prefix ? t('{{0}}通话中', { 0: prefix }) : t('通话中')}
        {callInfo.timestamp && (
          <>
            (<Timing startTime={callInfo.timestamp} />)
          </>
        )}
      </Box>
    );
  }
  return null;
};
