const getStyles = {
  type: 'light',
  secondary: {
    main: '#e84545',
    contrastText: '#fff',
  },
  primary: {
    main: '#006eff',
    light: '#4696FF',
    contrastText: '#fff',
  },
  warn: {
    main: '#ff9d00',
  },
  error: {
    main: '#e54545',
  },
  success: {
    main: '#0abf5b',
  },
  background: {
    paper: '#ffffff',
    default: 'rgba(255,255,255,0.01)',
    bar: '#D4D8DC ',
    active: 'linear-gradient(90deg, #006eff 0%, #4696FF 100%)',
    tableHeader: '#DDDDDD',
    level1: '#F6F7F9',
    level2: '#E9EAEE',
  },
};
export default getStyles;
